<template>
  <g>
    <path
        class="vue-flow__connection animated"
        fill="none"
        stroke="var(--el-color-primary-light-3)"
        :stroke-width="2.5"
        :d="`M${sourceX},${sourceY} C${targetX},${targetY} ${sourceX},${sourceY} ${targetX},${targetY}`"
    />

    <circle :cx="targetX" :cy="targetY" fill="#fff" :r="4" stroke="#6F3381" :stroke-width="1.5" />
  </g>
</template>

<script>

export default {
  name: "CustomConnectionLine",
  props: {
    sourceX: {
      type: Number,
      required: true,
    },
    sourceY: {
      type: Number,
      required: true,
    },
    targetX: {
      type: Number,
      required: true,
    },
    targetY: {
      type: Number,
      required: true,
    },
  }
}
</script>

<style>

.edgebutton{
  
}


</style>