<template>

  <el-dialog
      v-model="operatorSpecificRequest.modalVisible"
      :title="`Обновление заявки для оператора ${operatorSpecificRequest.operName}`" class="modal-95-70-60"
  >
    <client-sender-names-application-description />
    <client-sender-name-application-operator-form
      :operator-key="operatorSpecificRequest.operKey"
      :free-name-available="false"
      ref="operatorSpecificApplicationForm"
    />

    <el-button type="success" plain @click="sendOperatorSpecificApplication" :loading="sendingRequest" style="margin-top: 30px">
      Отправить заявку
    </el-button>
  </el-dialog>
  <el-dialog
      v-model="operatorSpecificFileUploadModalVisible"
      :title="`Дополнительные файлы для оператора ${operatorSpecificFileUpload.operName}`" class="modal-95-70-60"
  >
    <el-upload
        :auto-upload="false" style="margin-top:15px;margin-bottom: 50px"
        v-model:file-list="operatorSpecificFileUpload.files"
        :multiple="true"
    >
      <template #trigger>
        <el-button type="primary" size="small">Выберите файлы</el-button>
      </template>
    </el-upload>
    <el-button type="success" plain @click="sendOperatorSpecificFileUpload" :loading="sendingRequest">Загрузить
    </el-button>
  </el-dialog>
  <el-dialog
      v-model="nameManagementVisible"
      width="100%"
      :title="`Настройки подписи ${managingNameName}`"
  >
    <p style="font-weight: bold; word-break: break-word"><span style="color: red">*</span>&nbsp;Отзыв имени
      отправителя с платной регистрации возможен только со следующего месяца. Заявку необходимо направлять не позднее,
      чем за 3 рабочих дня до конца месяца. Вы можете пользоваться именем на платном тарифе "Профи" в течении
      оплаченного месяца.</p>
    <el-table :data="nameManagementTableData">
      <el-table-column label="Оператор" width="110" prop="operatorName"/>
      <el-table-column label="Тип подписи" width="150" prop="nameType"/>
      <el-table-column label="Активация" width="150" >
        <template #default="scope">
          {{scope.row.__activation === 'immediate' ? 'Сразу по согласованию с оператором' : 'С 1 числа следующего месяца'}}
        </template>
      </el-table-column>
      <!--        <el-table-column label="Юр.лицо" width="110" prop="legalEntity"/>-->
      <el-table-column label="Статус" width="130" prop="status">
        <template #default="scope">
            <span
                :style="`color: ${scope.row.__status === 'sent' ? 'var(--el-color-primary)' : scope.row.__status === 'rejected' ? 'var(--el-color-danger)' : scope.row.__status === 'approved' ? 'var(--el-color-success)' : 'default'};font-weight: bold`"
            >{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Дата изменения" width="120" prop="lastChangeDate"/>
      <el-table-column label="Комментарий" min-width="160" prop="comment"/>
      <el-table-column label="Документы" width="300">
        <template #default="scope">
          <p v-for="file in scope.row.files" style="margin: 0 0 5px 0;">{{ file.name }}</p>
          <el-button text class="text-button table-text-button" size="small" v-if="scope.row.files.length"
                     @click="showAdditinalFileUploadModal(scope.row)">
            <font-awesome-icon icon="plus"></font-awesome-icon> &nbsp;Загрузить
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="" width="130">
        <template #default="scope">
          <div v-if="scope.row.__status !== '' && scope.row.__status !== 'sent'">
            <el-button text class="text-button table-text-button" size="small"
                       v-if="scope.row.__type === 'individual' && scope.row.__status !== 'rejected' && !scope.row.moveToFreeRateRequested && !managingNamemoveToFreeRateOnAllOperatorsRequested"
                       @click="requestFreeNameMove(scope.row)">
              Перенести на бесплатный тариф со следующего месяца
            </el-button>
            <el-button text class="text-button table-text-button" size="small"
                       v-if="(scope.row.__type === 'common' || scope.row.__status === 'rejected')"
                       @click="createOperSpecificApplication(scope.row)">
              Подать заявку заново
            </el-button>
          </div>
          <!--            <ActionsDropdown >-->
          <!--              <ActionButton v-if="scope.row.__type === 'individual' && scope.row.__status !== 'rejected'" @click="requestFreeNameMove(scope.row)">-->
          <!--                Перенести на бесплатный тариф-->
          <!--              </ActionButton>-->
          <!--              <ActionButton-->
          <!--                  v-if="(scope.row.__type === 'common' || scope.row.__status === 'rejected')"-->
          <!--                  @click="createOperSpecificApplication(scope.row)">Подать заявку заново-->
          <!--              </ActionButton>-->
          <!--            </ActionsDropdown>-->
        </template>
      </el-table-column>
    </el-table>
    <div v-show="managingNameApproved" style="margin-top: 20px">
      <el-button type="danger" @click="removeFromAllOperators"
                 v-if="!managingNamemoveToFreeRateOnAllOperatorsRequested">Отозвать со всех операторов
      </el-button>
      <span style="color: var(--el-color-danger); font-weight: bold; word-break: break-word" v-else>Запрос на отзыв имени со всех операторов отправлен</span>
    </div>
  </el-dialog>

</template>

<script>

import ActionsDropdown from "../../../shared/components/layout/actionsDropdown.component.vue";
import ActionButton from "../../../shared/components/layout/actionButton.component.vue";
import ClientSenderNamesApplicationDescription from "./client-sender-name-application-description.component.vue";
import ClientSenderNameApplicationOperatorForm from "./client-sender-name-application-operator-form.component.vue";


const NAME_STATUSES = {
  '': "Не обработано",
  'sent': "Отправлено на регистрацию",
  "approved": "Подтверждено",
  "rejected": "Отклонено"
}

export default {
  name: "ClientSenderNameManagementBlock",
  components: {
    ClientSenderNameApplicationOperatorForm,
    ClientSenderNamesApplicationDescription,
    ActionButton,
    ActionsDropdown,
  },
  mounted() {
  },
  computed: {

  },
  methods: {
    manageName(request) {
      this.managingNameId = request._id
      this.managingNameName = request.name
      this.managingNameApproved = String(request.active) === "1"
      this.managingNamemoveToFreeRateOnAllOperatorsRequested = request.moveToFreeRateOnAllOperatorsRequested
      this.nameManagementTableData = Object.keys(SENDERS_OPERATOR_CONFIG).map(k => {
        return {
          operKey: k,
          operatorName: SENDERS_OPERATOR_CONFIG[k].name,
          nameType: request.operatorSettings[k].type === "individual" ? "Платная" + `${request.operatorSettings[k].moveToFreeRateRequested ? ' (Запрошен перенос на бесплатный тариф)' : ''}` : 'Бесплатная', // ['mts', 'megafon'].includes(k) ? 'Общая' :
          lastChangeDate: this.formatDateTime(request.operatorSettings[k].lastChangeDate),
          legalEntity: "",
          status: NAME_STATUSES[request.operatorSettings[k].currentStatus],
          comment: request.operatorSettings[k].comment,
          files: request.operatorSettings[k].files,
          moveToFreeRateRequested: request.operatorSettings[k].moveToFreeRateRequested,
          __type: request.operatorSettings[k].type,
          __status: request.operatorSettings[k].currentStatus,
          __activation: request.operatorSettings[k].activation
        }
      })
      this.nameManagementVisible = true
    },
    async sendOperatorSpecificApplication() {
      this.sendingRequest = true
      let requestData = await this.$refs.operatorSpecificApplicationForm.getState()
      if(requestData === 'E_NO_FILES'){
        this.sendingRequest = false
        return this.$gNotify("Загрузите документы", "error")
      }
      requestData.nameId = this.managingNameId

      this.axios.post("/sender_names/createOperatorSpecificRequest", requestData)
          .then(resp => {
            this.sendingRequest = false
            if (resp.data.success) {
              this.operatorSpecificRequest.modalVisible = false
              this.nameManagementVisible = false
              this.$gNotify("Заявка подана", "success")
              this.$emit('onNameUpdated')
            } else {
              if (resp.data.msg && resp.data.msg === "E_NEN_MONEY")
                this.$gNotify("Неодстаточно средств для подачи заявки", "error")
            }

          })
    },

    createOperSpecificApplication(operRow) {
      this.operatorSpecificRequest.operName = operRow.operatorName
      this.operatorSpecificRequest.operKey = operRow.operKey
      this.operatorSpecificRequest.modalVisible = true
    },
    requestFreeNameMove(operRow) {
      this.$confirm("Подтвердите запрос на переход на бесплатный тариф", {
        type: "danger",
        confirmButtonText: "Подтвердить",
        cancelButtonText: "Отмена",
        confirmButtonClass: "force-danger-button"
      })
          .then(() => {
            this.axios.post("/sender_names/requestMoveToFreeRate", {
              nameId: this.managingNameId,
              operator: operRow.operKey
            })
                .then(resp => {
                  this.nameManagementVisible = false
                  this.$gNotify("Запрос на перенос имени в бесплатный тариф отправлен", "success")
                  this.$emit('onNameUpdated')
                })
          }).catch()
    },
    removeFromAllOperators() {
      this.$confirm("Подтвердите запрос на отзыв имени со всех операторов", {
        type: "danger",
        confirmButtonText: "Подтвердить",
        cancelButtonText: "Отмена",
        confirmButtonClass: "force-danger-button"
      })
          .then(() => {
            this.axios.post("/sender_names/requestMoveToFreeRateAllOperators", {nameId: this.managingNameId,})
                .then(resp => {
                  this.nameManagementVisible = false
                  this.$gNotify("Запрос на отзыв имени отправлен", "success")
                  this.$emit('onNameUpdated')
                })
          }).catch()
    },
    showAdditinalFileUploadModal(operRow) {
      this.operatorSpecificFileUpload.operName = operRow.operatorName
      this.operatorSpecificFileUpload.operKey = operRow.operKey
      this.operatorSpecificFileUpload.files = []
      this.operatorSpecificFileUploadModalVisible = true
    },
    async sendOperatorSpecificFileUpload() {
      this.sendingRequest = true
      let requestData = JSON.parse(JSON.stringify(this.operatorSpecificFileUpload))
      requestData.nameId = this.managingNameId
      if (!this.operatorSpecificFileUpload.files.length) {
        this.sendingRequest = false
        return this.$gNotify("Загрузите документы", "error")
      } else {
        requestData.filesParsed = await Promise.all(this.operatorSpecificFileUpload.files.map(async f => {
          return {name: f.name, mime: f.raw.type, content: await this.$fileToBase64(f.raw)}
        }))
        delete requestData.files
      }
      this.axios.post("/sender_names/uploadOperatorSpecificFiles", requestData)
          .then(resp => {
            this.sendingRequest = false
            this.operatorSpecificFileUploadModalVisible = false
            this.nameManagementVisible = false
            this.$gNotify("Файлы загружены", "success")
            this.$emit('onNameUpdated')
          })
    }
  },
  data() {
    return {
      sendingRequest: false,
      nameManagementVisible: false,
      nameManagementTableData: [],
      managingNameId: "",
      managingNameName: "",
      managingNamemoveToFreeRateOnAllOperatorsRequested: false,
      managingNameApproved: false,
      operatorSpecificRequest: {
        operKey: "",
        operName: "",
        modalVisible: false
      },
      operatorSpecificFileUploadModalVisible: false,
      operatorSpecificFileUpload: {
        operKey: "",
        operName: "",
        files: [],
      }
    }
  }
}

</script>

<style>

.file-upload-like-label {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>