<template>
  <Breadcrumbs primary="Личный кабинет" secondary="Сервисные шаблоны"/>

  <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
    <el-tab-pane label="Для СМС" name="sms">
      <service-templates-table
          ref="mTable"
          :templates="templates"
          :loading="dataLoading"
          :allowed-senders-per-operator="allowedSendersPerOperator"
          @refresh="refresh"
          @onSave="upsertTemplates"
      />
    </el-tab-pane>
    <el-tab-pane label="Для Вконтакте / Одноклассники" name="vk">
      <vk-templates-table />
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import ServiceTemplatesTable from "@shared/components/lk/service-templates-table.component.vue";
import VkTemplatesTable from "../../components/lk/vk-templates-table.component.vue";

export default {
  name: "ServiceTemplates",
  title: "Шаблоны сервисных сообщений",
  components: {VkTemplatesTable, ServiceTemplatesTable},
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.dataLoading = true
      Promise.all([
        this.axios.get("/user/getMyServiceTemplates"),
        this.axios.get("/sender_names/profiSenderNames")
      ]).then(([templates, senders]) => {
        this.templates = templates.data
        this.allowedSendersPerOperator = senders.data
        this.$refs.mTable.setSenders(Array.from(new Set(Object.values(senders.data).reduce((acc, v) => acc.concat(v), []))))
        this.dataLoading = false
      })
          .catch(console.error)

    },
    upsertTemplates({addForm, onSuccess}) {
      this.axios.post("/user/upsertServiceTemplates", addForm)
          .then(resp => {
            onSuccess()
            if (resp.data.error) {
              this.$gNotify("Такой шаблон для этой подписи уже назначен", "error")
            } else {
              this.addFormVisible = false
              this.$gNotify(`Добавлено: ${resp.data.added} | Дубли: ${resp.data.skipped}`, "success")
              this.refresh()
            }
          })
    }
  },
  data() {
    return {
      templates: [],
      dataLoading: false,
      allowedSendersPerOperator: null,
      visiblePanel: "sms",
    }
  }
}

</script>