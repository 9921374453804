<template>
  <el-dialog v-model="addEditTriggerDialogVisible" class="modal-95-70-60"
             :title="editingTrigger._id ? 'Новый триггер' : 'Редактирование триггера'">
    <el-form label-position="top" :model="editingTrigger" ref="triggerForm">
      <el-form-item prop="_id" label=" " style="display: none">
        <el-input v-model="editingTrigger._id"/>
      </el-form-item>
      <el-form-item label="Название">
        <el-input v-model="editingTrigger.name" :disabled="editingTrigger.isSystem"/>
      </el-form-item>
      <el-form-item label="Ключевые слова">
        <el-tag
            v-for="kword in editingTrigger.keywords"
            closable
            class="tag-with-margin"
            :disable-transitions="false"
            @close="removeKeywordFromList(kword)"
        >
          {{ kword }}
        </el-tag>
        <el-input
            v-model="newKword"
            style="width: 200px;"
            clearable
            class="input-with-append"
        >
          <template #append>
            <div style="padding: 0px 15px;color: var(--el-color-success); cursor: pointer; "
                 @click="addKeywordToList()">
              <font-awesome-icon icon="plus" style=""/>
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="Алгоритм">
        <el-select v-model="editingTrigger.linkedAlgo" class="full-width">
          <el-option value="" label="Не подключён">
            <span style="color: var(--el-color-danger); font-weight: bold;">Не подключён</span>
          </el-option>
          <el-option v-for="algo in algorithms" :label="algo.name" :value="algo._id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Активен">
        <el-switch v-model="editingTrigger.active"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addEditTriggerDialogVisible = false">Закрыть</el-button>
        <el-button type="success" @click="saveTrigger()" :loading="triggerSaving">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>


  <el-row :gutter="40">
    <el-col :xs="24" :md="16">
      <h4 class="report-parameters-title" style="margin-bottom: 20px; display: flex;align-items: center; justify-content: flex-start">
        Основные действия
        <el-button :loading="defaultActionsSaving" text class="text-button table-text-button" type="success" style=" margin-left: 40px;" @click="saveDefaultActions()">
          <font-awesome-icon icon="check" />&nbsp;Сохранить
        </el-button>
      </h4>
      <el-row :gutter="40">
        <el-col :xs="24" :md="8">
          <p style="font-size: var(--el-font-size-base); margin-top: 0px">После подписки</p>
          <el-switch  active-text="Активно" v-model="botDefaultActions.onSubscribe.active"/>
          <el-select placeholder="Алгоритм" style="margin-top: 15px" v-model="botDefaultActions.onSubscribe.algoId">
            <el-option value="" label="Не подключён" />
            <el-option v-for="algo in algorithms" :value="algo._id" :label="algo.name" />
          </el-select>
        </el-col>
        <el-col :xs="24" :md="8">
          <p style="font-size: var(--el-font-size-base); margin-top: 0px">
            После отписки
            <el-popover
                placement="top-start"
                title="Отписка клиента"
                :width="300"
                trigger="hover"
            >
              <template #reference>
                <font-awesome-icon icon="question-circle"/>
              </template>
              <span style="word-break: break-word">Отписка клиента осуществляется при помощи команды <b>/stop</b><br/>
                Можете указать алгоритм, который будет выполнен после этого события</span>
            </el-popover>
          </p>
          <el-switch  active-text="Активно"  v-model="botDefaultActions.onUnsubscribe.active"/>
          <el-select placeholder="Алгоритм" style="margin-top: 15px"  v-model="botDefaultActions.onUnsubscribe.algoId">
            <el-option value="" label="Не подключён" />
            <el-option v-for="algo in algorithms" :value="algo._id" :label="algo.name" />
          </el-select>
        </el-col>
        <el-col :xs="24" :md="8">
          <p style="font-size: var(--el-font-size-base); margin-top: 0px">
            Стандартный ответ
            <el-popover
                placement="top-start"
                title="Стандартный ответ"
                :width="300"
                trigger="hover"
            >
              <template #reference>
                <font-awesome-icon icon="question-circle"/>
              </template>
              <span  style="word-break: break-word">Укажите алгоритм, который будет запущен если сообщение клиента не соответствует ни одному триггеру.<br/>
                Данное действие <b>не будет</b> выполнено если клент находится в процессе исполнения алгоритма<br/>
                Не рекомендуем включать эту настройку, если вы собираетесь вести живые диалоги с подписчиками
              </span>
            </el-popover>
          </p>
          <el-switch  active-text="Активно"  v-model="botDefaultActions.defaultResponse.active" />
          <el-select placeholder="Алгоритм" style="margin-top: 15px"  v-model="botDefaultActions.defaultResponse.algoId">
            <el-option value="" label="Не подключён" />
            <el-option v-for="algo in algorithms" :value="algo._id" :label="algo.name" />
          </el-select>
        </el-col>
      </el-row>
      <el-divider/>
      <h4 class="report-parameters-title" style="display: flex; align-items: center">
        Триггеры
        <font-awesome-icon icon="plus" @click="showAddEditTriggerForm()"
                           style="cursor: pointer; color: var(--el-color-success); margin-left: 20px"/>
      </h4>
      <el-table :data="(bot.triggers || []).sort((t1, t2) => t2.isSystem ? 1 : -1)">
        <el-table-column label="" width="20px">
          <template #default="scope">
            <div :class="`circle small ${scope.row.active ? 'success' : 'danger'}`"
                 style="display: inline-flex; margin-right: 10px"></div>
          </template>
        </el-table-column>
        <el-table-column label="Название" prop="name" min-width="150px"/>
        <el-table-column label="Ключевые слова" min-width="150px">
          <template #default="scope">
            <div>
            <el-tag v-for="kw of scope.row.keywords || []" style="margin-right: 5px; margin-bottom: 5px">{{ kw }}
            </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Алгоритм" :formatter="r => (algorithms.find(a => a._id === r.linkedAlgo) || {name: r.linkedAlgo ? 'N/A' : ''}).name" min-width="150px" />

        <el-table-column min-width="100px">
          <template #default="scope">
            <ActionsDropdown>
              <ActionButton icon="pencil-alt" @click="showAddEditTriggerForm(scope.row)">Изменить</ActionButton>
              <ActionButton type="remove" @confirm="removeTrigger(scope.row)" :loading="removingTrigger" v-if="!scope.row.isSystem">Удалить</ActionButton>
            </ActionsDropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :xs="24" :md="8">
      <h4 class="report-parameters-title">
        Алгоритмы
        <router-link :to="`/cab/chat_bots/manage_algorithm?bot_id=${bot._id}&algo_id=`">
          <font-awesome-icon icon="plus" style="cursor: pointer; color: var(--el-color-success); margin-left: 20px"/>
        </router-link>
      </h4>
        <el-table :data="algorithms">
          <el-table-column label="" width="20px">
            <template #default="scope">
              <div :class="`circle small ${isAlgoLinked(scope.row) ? 'success' : 'danger'}`"
                   style="display: inline-flex; margin-right: 10px"></div>
            </template>
          </el-table-column>
          <el-table-column label="Название" prop="name" min-width="150px"/>
          <!--        <el-table-column label="Последнее изменение" prop="lastChangeAt" min-width="150px"/>-->
          <el-table-column min-width="100px">
            <template #default="scope">
              <ActionsDropdown>
                <ActionButton icon="pencil-alt" @click="showAddEditAlgoForm(scope.row)">Изменить</ActionButton>
                <ActionButton type="remove" @confirm="removeAlgo(scope.row)" :loading="algoRemoving">Удалить</ActionButton>
              </ActionsDropdown>
            </template>
          </el-table-column>
        </el-table>
    </el-col>
  </el-row>

</template>

<script>


export default {
  name: "TgBotAlgorithms",
  components: {},
  props: ["bot", "algorithms"],
  watch:{
    bot(v){
      if(v) this.botDefaultActions = this.bot.defaultActions
    }
  },
  methods: {
    showAddEditTriggerForm(t) {
      console.log("SHOW FORM")
      if (!t) {
        if (this.$refs.triggerForm) this.$refs.triggerForm.resetFields()
      } else {
        this.editingTrigger._id = t._id
        this.editingTrigger.name = t.name
        this.editingTrigger.linkedAlgo = t.linkedAlgo
        this.editingTrigger.keywords = t.keywords || []
        this.editingTrigger.active = t.active || false
        this.editingTrigger.isSystem = t.isSystem || false
        this.editingTrigger.cantEditTags = t.cantEditTags || false
      }
      this.addEditTriggerDialogVisible = true
    },
    removeTrigger(t) {
      this.removingTrigger = true
      this.axios.delete("/chatbot/deleteTrigger", {params: {triggerId: t._id, botId: this.bot._id}})
          .then(resp => {
            this.removingTrigger = false
            this.$emit("refresh")
          })
    },
    saveTrigger() {
      this.triggerSaving = true
      this.axios.post("/chatbot/saveTrigger", {botId: this.bot._id, trigger: this.editingTrigger})
          .then(resp => {
            this.triggerSaving = false
            this.addEditTriggerDialogVisible = false
            this.$gNotify("Триггер сохранён", "success")
            this.$nextTick(() => {
              setTimeout(() => {
                this.$emit("refresh")
              }, 300)
            })
          })
    },
    addKeywordToList() {
      this.editingTrigger.keywords.push(String(this.newKword))
      this.newKword = ""
    },
    removeKeywordFromList(kword) {
      this.editingTrigger.keywords.splice(this.editingTrigger.keywords.findIndex(w => w === kword), 1)
    },


    showAddEditAlgoForm(algo) {
      this.$router.push({path: "/cab/chat_bots/manage_algorithm", query: {bot_id: this.bot._id, algo_id: algo._id}})
    },
    removeAlgo(algo) {
      this.algoRemoving = true
      this.axios.delete("/chatbot/deleteAlgo", {params: {algoId: algo._id}})
          .then(resp => {
            this.algoRemoving = false
            this.$emit("refresh")
          })
    },
    isAlgoLinked(algo) {
      return !!this.bot.triggers.find(t => t.linkedAlgo === algo._id) ||
          this.bot.defaultActions.onSubscribe.algoId === algo._id ||
          this.bot.defaultActions.onUnsubscribe.algoId === algo._id ||
          this.bot.defaultActions.defaultResponse.algoId === algo._id
    },
    saveDefaultActions(){
      this.defaultActionsSaving = true
      this.axios.post("/chatbot/saveDefaultActions", {botId: this.bot._id, actions: this.botDefaultActions})
          .then(resp => {
            this.defaultActionsSaving = false
            this.$gNotify("Конфигурация обновлена", "success")
            this.$emit("refresh")

          })
    }
  },
  computed: {},
  data() {
    return {

      addEditTriggerDialogVisible: false,
      editingTrigger: {
        _id: "",
        name: "",
        linkedAlgo: "",
        keywords: [],
        active: false,
        isSystem: false,
        cantEditTags: false
      },
      newKword: "",
      triggerSaving: false,
      algoRemoving: false,
      defaultActionsSaving: false,
      removingTrigger: false,
      botDefaultActions: {
        onSubscribe: {
          active: false,
          algoId: null
        },
        onUnsubscribe: {
          active: false,
          algoId: null,
        },
        defaultResponse: {
          active: false,
          algoId: null
        }
      }
    }
  }
}

</script>

<style>
.input-with-append .el-input-group__append {
  padding: 0px !important;
}

.tag-with-margin {
  margin-right: 10px;
  min-height: 32px;
  font-size: 14px;
}
</style>