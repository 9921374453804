<template>
  <div>
    <Breadcrumbs primary="Отчеты" secondary="Отправленные сообщения"/>

    <el-row :gutter="20">
      <el-col :span="24">
          <el-tabs v-model="visiblePanel"  class="primary-heading-tabs ">
            <el-tab-pane label="Общий отчет" name="common" v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.MONITORING)">
              <CommonTable />
            </el-tab-pane>
            <el-tab-pane label="Подробный отчет" name="detail"  v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.MONITORING)">
              <DetailTable :is-call-view="false"/>
            </el-tab-pane>
            <el-tab-pane label="Статистика" name="stats">
              <SentMessagesStatistics />
            </el-tab-pane>

          </el-tabs>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import CommonTable from "@shared/components/reports/common-sent-messages-table.component"
import DetailTable from "@shared/components/reports/detail-sent-messages-table.component"
import SentMessagesStatistics from "@shared/components/reports/statistics.component.vue";

export default {
  name: "SentMessages",
  title: "Отправленные сообщения",
  components: {
    SentMessagesStatistics,

    CommonTable,
    DetailTable
  },
  mounted() {},
  computed: {},
  methods: {},
  data() {
    return {
      visiblePanel : this.$store.getters.user.hasPrivilege(this.$PRIVILEGES.MONITORING) ? "stats" : "common"
    }
  }
}

</script>