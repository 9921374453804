<template>

  <el-dialog
      v-model="intVisible"
      :title="title"
      class="modal-95-70-60"
      :before-close="() => $emit('close')"
  >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="() => $emit('close')">Отмена</el-button>
        <el-button type="success" :loading="isSaving" @click="addEditTemplate">{{ confirmButtonText }}</el-button>
      </span>
    </template>

    <el-form
        :model="templateData"
        label-position="top"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="Название шаблона" class="bold-label">
            <el-input v-model="templateData.title" :disabled="!!editingTemplateId"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="Текст шаблона" class="bold-label">
            <el-input type="textarea" v-model="templateData.text" :rows="10"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :md="12" :xs="24">
          <el-form-item label="Email для уведомлений" class="bold-label">
            <el-input v-model="templateData.notifyEmail"></el-input>
          </el-form-item>
          <el-form-item label="Наименование сервиса" class="bold-label">
            <el-input v-model="templateData.vkService"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="12" :xs="24">
          <el-form-item label="URL группы Вконтакте" class="bold-label">
            <el-input v-model="templateData.vkGroupUrl"></el-input>
          </el-form-item>
          <el-form-item label="URL группы в Одноклассниках" class="bold-label">
            <el-input v-model="templateData.okGroupUrl"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
  </el-dialog>

</template>


<script>


export default {
  name: 'VkTemplateForm',
  props: ["visible", "editingTemplateId"],
  emits: ["templateSaved", 'close'],
  watch: {
    visible(v){
      this.intVisible = v
    },
    editingTemplateId(newVal){
      if(newVal === "") this.clear()
    }
  },
  computed: {
    isEditing() {
      return !!this.editingTemplateId
    },
    title() {
      return this.isEditing ? "ИЗМЕНИТЬ ШАБЛОН" : "СОЗДАТЬ ШАБЛОН"
    },
    confirmButtonText() {
      return this.isEditing ? "Изменить" : "Добавить"
    }
  },
  methods: {
    clear() {
      this.templateData.title = ""
      this.templateData.text = ""
      this.templateData.oldName = ""
      this.templateData.notifyEmail = ""
      this.templateData.vkService = ""
      this.templateData.vkGroupUrl = ""
      this.templateData.okGroupUrl = ""
    },
    addEditTemplate() {
      this.isSaving = true
      this.axios.post("/sms/upsertVkTemplate", {
        templateId: this.editingTemplateId,
        templateData: {
          vk_template_name: this.templateData.title,
          service: this.templateData.vkService,
          notificationEmail: this.templateData.notifyEmail,
          vkGroupUrl: this.templateData.vkGroupUrl,
          okGroupUrl: this.templateData.okGroupUrl,
          template: this.templateData.text,
          vkTemplate: this.templateData.text,
          okTemplate: this.templateData.text
        }
      })
          .then(resp => {
            this.isSaving = false
            if (resp.data.error === "E_DUPLICATE_NAME")
              return this.$gNotify("Шаблон с таким именем уже существует", "error")

            this.$emit("templateSaved")
            this.$emit("close")
            this.clear()
          })
          .catch(resp => {
            this.isSaving = false
            this.$emit("templateSaved")
            this.$emit("close")
            this.clear()
          })
    },
    preloadEditingTemplate(_id, callback) {
      this.axios.get("/sms/getVkTemplateById", {params: {templateId: _id}})
          .then(resp => {
            this.templateData.title = resp.data.vk_name
            // this.templateData.oldName = resp.data.vk_name
            this.templateData.text = resp.data.template
            this.templateData.notifyEmail = resp.data.notificationEmail
            this.templateData.vkService = resp.data.service
            this.templateData.vkGroupUrl = resp.data.vkGroupUrl
            this.templateData.okGroupUrl = resp.data.okGroupUrl
            callback()
            // this.$refs.templateTextInput.setTextByTemplate({text: resp.data.text})
          })

    }
  },
  data() {
    return {
      templateData: {
        title: "",
        text: "",
        oldName: "",
        notifyEmail: "",
        vkService: "",
        vkGroupUrl: "",
        okGroupUrl: ""
      },
      isSaving: false,
      intVisible: false
    }
  }
}

</script>

<style>

</style>