<template>
  <el-row justify="center">
    <el-col :lg="7" :xl="5" :md="10" :sm="12" :xs="20">
      <el-card style="min-width: 300px; max-width:400px;text-align: center">
      <h3 class="uppercase align-center no-margin-top">Подтвердите доступ</h3>
      <el-form ref="form" :model="form" label-position="top" class="align-center">
        <el-form-item label="Введите проверочный код из смс">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" :loading="loading" @click="confirm" class="uppercase full-width" size="medium">Подтвердить</el-button>
        </el-form-item>

      </el-form>
      </el-card>
    </el-col>

  </el-row>

</template>

<script>

export default {
  name: "ConfirmRegistrationCode",
  title:"Подтверждение кода регистрации",
  methods: {
    confirm: function () {
      this.loading = true
      this.axios.post("/auth/confirmRegistrationCode", {code: this.form.code, regId: this.$route.query.regId})
          .then(resp => {
            this.loading = false
            if (resp.data.error === "notFound")
              return this.$gNotify("Неверный проверочный код", "error")
            this.$router.push({path: "/login", query: {successReg: "1"}})
          })

    }
  },
  data(){
    return {
      form: {
        code: "",
      },
      loading: false
    }
  },
}

</script>