<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="Мои задачи"/>
    <el-row :gutter="20">
      <el-col :span="24">

        <!--          <template #header>-->
        <!--            <div class="card-header">-->
        <!--              <span>Мои задачи</span>-->
        <!--            </div>-->
        <!--          </template>-->
        <el-tabs class="primary-heading-tabs">
          <el-tab-pane label="Все">
            <TableSpinner :loading="dataLoading">
              <SendTasksTable
                  :tasks="allTasks"
                  :show-status="true"
                  :contact-groups="contactGroups"
                  @refresh="loadTasks()"

              />
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane label="Завершенные">
            <TableSpinner :loading="dataLoading">
              <SendTasksTable
                  :tasks="finishedTasks"
                  :show-status="false"
                  :contact-groups="contactGroups"
                  @refresh="loadTasks()"
              />
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane label="Незавершенные">
            <TableSpinner :loading="dataLoading">
              <SendTasksTable
                  :tasks="unfinishedTasks"
                  :show-status="false"
                  :contact-groups="contactGroups"
                  @refresh="loadTasks()"
              />
            </TableSpinner>
          </el-tab-pane>

        </el-tabs>

      </el-col>
    </el-row>

  </div>
</template>

<script>


import SendTasksTable from "@/components/new_send/send-tasks-table.component"


export default {
  name: "SendTasks",
  title: "Мои задачи",
  components: {
    SendTasksTable
  },
  mounted() {
    this.loadTasks()
  },
  methods: {
    loadTasks() {
      this.dataLoading = true
      Promise.all([this.$store.dispatch("loadTasks"), this.axios.get("/contacts/myGroups")])
          .then(([tasks, groups]) => {
            this.allTasks = tasks
            this.contactGroups = groups.data
            this.dataLoading = false
          })
      // this.axios.get("/scheduler/myTasks")
      //     .then(resp => {
      //
      //
      //     })
    }
  },
  computed: {
    finishedTasks() {
      return this.allTasks.filter(t => t.executed || t.rejected)
    },
    unfinishedTasks() {
      return this.allTasks.filter(t => !t.executed && !t.rejected)
    }
  },
  data() {
    return {
      dataLoading: false,
      allTasks: [],
      contactGroups: []
    }
  },
}

</script>