<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="Адресная книга"/>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Управление группами контактов" name="manage">
              <AddressBooksMain
                  @reloadGroups="loadGroups"
                  @filterGroups="filterGroups"
                  :groups="visibleGroups"
                  :groups-loading="groupsLoading"
              />
          </el-tab-pane>
          <el-tab-pane label="Импорт контактов" name="import" v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_CONTACTS_CHANGE)">
              <ImportContacts ref="importContactsComponent" :all-groups="groups"  @reloadGroups="loadGroups" @navigateToMain="visiblePanel = 'manage'"/>
          </el-tab-pane>
          <el-tab-pane label="Экспорт контактов" name="export">
              <ExportGroups ref="exportGroupsComponent" :all-groups="groups"/>
          </el-tab-pane>
          <el-tab-pane label="Новый контакт" name="new_contact" v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_CONTACTS_CHANGE)">
              <NewContact ref="newContactComponents" :all-groups="groups" @reloadGroups="loadGroups" :groups-loading="groupsLoading"/>
          </el-tab-pane>

        </el-tabs>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import AddressBooksMain from "@/components/addressbooks/groups.component"
import NewContact from "@/components/addressbooks/newContact.component";
import ExportGroups from "@/components/addressbooks/exportGroups.component";
import ImportContacts from "@/components/addressbooks/importContacts.component";


export default {
  name: "AddressBooks",
  title: "Адресная книга",
  components: {
    ImportContacts,
    ExportGroups,
    NewContact,

    AddressBooksMain
  },
  mounted() {
    this.loadGroups()
  },
  computed: {},
  watch: {
    // visiblePanel(newVal, oldVal){
    //   if(newVal !== oldVal && this.$refs.importContactsComponent) this.$refs.importContactsComponent.reset()
    // }
  },
  methods: {
    refreshAllComponents() {
      if (this.$refs.newContactComponents)
        this.$refs.newContactComponents.loadGroups()
      if (this.$refs.exportGroupsComponent)
        this.$refs.exportGroupsComponent.loadGroups()
      if (this.$refs.importContactsComponent)
        this.$refs.importContactsComponent.loadGroups()
    },
    loadGroups() {
      this.groupsLoading = true
      this.axios.get("/contacts/myGroups")
          .then(resp => {
            this.groups = resp.data
            this.visibleGroups = resp.data
            this.groupsLoading = false
          })
    },
    filterGroups(event){
      console.log(event)
      if(event.type === 'name'){
        if(event.token.trim()){
          this.visibleGroups = this.groups.filter(g => new RegExp(event.token.trim(), "gi").test(g.name))
        }else
          this.visibleGroups = this.groups
      }
      if(event.type === 'phone'){
        if(event.token.trim()) {
          this.groupsLoading = true
          this.axios.get("/contacts/myGroupsByPhone", {params: {phone: event.token.trim()}})
              .then(resp => {
                this.visibleGroups = resp.data
                this.groupsLoading = false
              })
        }else this.visibleGroups = this.groups
      }
    }
  },
  data() {
    return {
      visiblePanel: "manage",
      groupsLoading: false,
      groups: [],
      visibleGroups: []
    }
  }
}

</script>