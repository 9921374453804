<template>
  <el-dialog
      title="Новый диалог"
      v-model="newDialogModalVisible"
      class="modal-95-70-60"
  >

    <el-tabs v-model="newDialogVisibleTab" v-show="isTelegramChannel">

      <el-tab-pane label="По имени пользователя" name="byUsername">
        <el-input v-model="newDialogForm.username" placeholder="Имя пользователя" style="margin-bottom: 15px"/>
        <el-button type="success" @click="startNewDialog()">Начать</el-button>
      </el-tab-pane>
      <el-tab-pane label="По номеру телефона" name="byPhone">
        <el-input v-model="newDialogForm.phone" placeholder="Номер телефона" style="margin-bottom: 15px"/>
        <el-button type="success" @click="startNewDialog()" v-if="safeToWriteFirst">Начать</el-button>
        <span v-html="newDialogByPhoneProtectionFormatted" v-else/>
      </el-tab-pane>
    </el-tabs>

    <el-form v-model="newDialogForm" label-position="top" v-show="!isTelegramChannel">
      <el-form-item label="Телефон">
        <el-input v-model="newDialogForm.phone"/>
      </el-form-item>
      <el-button type="success" @click="startNewDialog()">Начать</el-button>

    </el-form>
  </el-dialog>
  <el-button @click="showNewDialogForm()" v-show="isTelegramChannel">
    <font-awesome-icon icon="plus"/>
  </el-button>
</template>

<script>


export default {
  name: "NewDialogModal",
  components: {},
  props: ["channel"],

  watch: {

  },
  mounted() {

  },
  beforeUnmount() {
  },
  methods: {

    showNewDialogForm() {
      this.newDialogForm = {phone: "", username: ""}
      if (this.isTelegramChannel)
        this.axios.get("/tgPersonal/writeFirstSafetyTimer", {params: {channelId: this.channel._id}})
            .then(resp => {
              clearInterval(this.safeDropTimer)

              this.newDialogByPhoneProtection = resp.data || ""
              this.safeToWriteFirst = !this.newDialogByPhoneProtection
              if (!this.safeToWriteFirst) {
                this.safeDropTimer = setInterval(() => {
                  this.newDialogByPhoneProtection = (3 * 60 * 1000) - (Date.now() - Number(resp.data))
                }, 1000)
              }
              this.newDialogModalVisible = true
            })
      else
        this.newDialogModalVisible = true
    },
    startNewDialog() {
      this.newDialogStarting = true
      this.axios.post("/dialogs/startNewDialog", {
        ...this.newDialogForm,
        channelType: this.channel.type,
        channelId: this.channel._id
      })
          .then(resp => {
            if (resp.data.error) {
              if (resp.data.error === "E_MULTIPLE_CHOICES") return this.$gNotify("Уже есть диалог с таким именем и/или телефоном", "error")
              if (resp.data.error === "E_NO_USER") return this.$gNotify("Пользователь удовлетворяющий запросу не найден", "error")
              if (resp.data.error === "E_FLOOD_RATELIMIT") return this.$gNotify("Сейчас нельзя начать диалог по номеру телефона", "error")
              return this.$gNotify("Неизвестная ошибка", "error")
            } else {
              let newDialog = {
                unreadCount: 0,
                ...resp.data.dialog
              }
              this.$emit('dialogCreated', newDialog)

              this.newDialogModalVisible = false
            }
          })
    },

  },
  computed: {
    isTelegramChannel() {
      return this.channel.type === 'tgPersonal'
    },
    newDialogByPhoneProtectionFormatted() {
      if (!this.newDialogByPhoneProtection) return ""

      let diff = this.newDialogByPhoneProtection//Date.now() - Number(this.newDialogByPhoneProtection)
      console.log(diff)
      let diffM = Math.floor(diff / 1000 / 60)
      let diffS = Math.floor(diff / 1000) % 60
      let s = `${diffM < 10 ? '0' + diffM : diffM}:${diffS < 10 ? '0' + diffS : diffS}`
      return `<span style="color: var(--el-color-danger)">Сейчас небезопасно писать по номеру телефона</span><br/>Можно писать через <b>${s}</b>`
    }
  },
  data() {
    return {

      newDialogVisibleTab: "byUsername",
      newDialogByPhoneProtection: "",
      safeToWriteFirst: true,
      safeDropTimer: null,
      newDialogModalVisible: false,
      newDialogForm: {
        phone: "",
        username: ""
      },
      newDialogStarting: false
    }
  }
}

</script>
