<template>
  <el-container class="full-height">

    <el-header class="light-header" height="100px" :style="`${$store.getters.isSurrogateLogin ? 'background: rgba(200, 200, 0, 0.3)' : ''}`">
      <Header ref="header"/>
    </el-header>
    <el-container>
      <el-aside class="dark-aside" id="main-menu-wrapper" :class="$store.getters.menuOpened ? 'prog-open': ''">
        <Menu @closeMenu="$store.dispatch('toggleMenu')"/>
      </el-aside>
      <div class="overlay" v-show="$store.getters.menuOpened" @click="$store.dispatch('toggleMenu')"></div>
      <el-container>
<!--        <div @click="$store.dispatch('toggleMenu')" id="menu-trigger">-->
<!--          <font-awesome-icon icon="bars" class="control-button"-->
<!--          ></font-awesome-icon>-->
<!--          <span>Меню</span>-->
<!--        </div>-->
        <el-main style="padding: 30px">
          <router-view></router-view>
        </el-main>
        <el-footer style="border-top: 1px solid  #CCC;display: flex;justify-content: space-between;height:auto; align-items: center">
          <span class="no-padding small" style="margin: 10px 0px; font-size:13px; color: var(--el-text-color-regular);">SmsGold © 2014 - {{ year }}</span>
<!--          <el-button text class="text-button" size="small" type="danger" @click="$refs.header.logout(true)">Выйти везде</el-button>-->
        </el-footer>
      </el-container>
    </el-container>
  </el-container>

</template>


<script>

import Menu from '@/components/layout/menu.component'
import Header from '@/components/layout/header.component'
import moment from "moment"

export default {
  name: 'CabinetLayout',
  components: {
    Menu,
    Header
  },
  data(){
    return {
      year: moment().format("YYYY")
    }
  }
}

</script>

<style>


#balance-container{
  padding-top: 40px;
  padding-left: 20px;
}

.dark-aside {
  background-color: #24313A;
  margin-top: -1px;
}

.light-header {
  /*background-color: #f9f9f9;*/
  /*box-shadow: 0 6px 8px -2px #CCC;*/
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 20px;
  height: auto;
}

</style>