<template>
  <div>
    <el-drawer
        v-model="editContactModalVisible"
        size="100%"
        :before-close="editingContactId=''"
    >
      <tg-bot-subscriber-form
          :contact-id="editingContactId"
          :bot-tags="bot.tags || []"
          :chat-type="'tgBot'"
          @removed="contactRemovedHandler()"
      />
    </el-drawer>
    <el-drawer
        v-model="chatVisible"
        size="100%"
        :before-close="chatContactId=''"
    >
      <div style="display:flex;flex-direction:column;height: 100%">
        <div style="display: flex; flex-direction: row; flex-grow: 1;">

          <div class="dialog-feed-container" style="border-left: 1px solid #CCC;">
            <tg-bot-subscriber-form :contact-id="chatContactId" :embedded="true"
                                    :bot-tags="bot.tags || []"
                                    :algorithms="[]"/>
            <dialog-message-feed
                :chat-id="chatContactId"
                chat-type="tgBot"
            />
          </div>
        </div>
      </div>
    </el-drawer>

    <form style="display: none" method="post" id="exportForm"
          :action="`${axios.defaults.baseURL}/chatbot/exportSubscribers`">
      <input name="botId" :value="bot._id"/>
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>

    <el-row>
      <el-col :span="24">

        <div style="display: flex;align-items: center;margin-bottom : 40px">
          <div style="flex-grow: 1">
            <el-popover placement="bottom" :width="600" :visible="filterVisible">
              <template #reference>
                <el-button type="primary" size="small" @click="filterVisible = !filterVisible">Поиск <span
                    v-if="numFilters > 0">&nbsp;({{ numFilters }} фильтр.)</span></el-button>
              </template>
              <tg-bot-subscribers-filter-form :bot="bot" @filter="setFilterAndLoad" ref="filterForm"/>
            </el-popover>
          </div>

<!--          <el-button text class="text-button table-text-button" @click="importSubscribers">-->
<!--            <font-awesome-icon icon="plus"/>&nbsp;Импортировать-->
<!--          </el-button>-->

          <el-button text class="text-button table-text-button" @click="exportSubscribers">
            <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
          </el-button>


        </div>


        <TableSpinner :loading="contactsLoading">
          <div style="display: flex; justify-content: flex-start; margin-bottom: 20px" v-show="selectedContacts.length">
            <el-button text class="table-text-button" @click="toggleBlockedState(false)"
                       :loading="contactsActionsBlocked">
              <font-awesome-icon icon="check"/>&nbsp;Разблокировать
            </el-button>
            <el-button text class="table-text-button" @click="toggleBlockedState(true)"
                       :loading="contactsActionsBlocked">
              <font-awesome-icon icon="times"/>&nbsp;Заблокировать
            </el-button>
            <remove-button @confirm="removeContacts()" :loading="contactsActionsBlocked" :icon="'trash'">
              Удалить
            </remove-button>
          </div>
          <el-table :data="contacts" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"/>
            <el-table-column label="Полное имя / Имя пользователя" min-width="150">
              <template #default="scope">
                <span style="display: block">{{ scope.row.userData.first_name }}</span>
                <span
                    style="color: var(--el-text-color-placeholder); display: block;"
                    v-if="scope.row.userData.username"
                >
                  @{{ scope.row.userData.username }}
                </span>
                <span class="small-label" style="color: var(--el-color-danger); font-weight: bold;"
                      v-if="scope.row.isAdmin">Администратор</span>
              </template>
            </el-table-column>
<!--            <el-table-column label="Username" prop="userData.username" min-width="150"/>-->
            <el-table-column label="Идентификатор" prop="_id" min-width="150"/>
            <el-table-column label="Телефон" prop="phone" min-width="150"/>
            <el-table-column label="Теги" min-width="150">
              <template #default="scope">
                <el-tag v-for="t in scope.row.tags || []" style="margin-right: 5px; margin-bottom: 5px">{{ t }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Статус" min-width="150">
              <template #default="scope">
                <div style="display: flex; flex-direction: row; align-items: center">
                  <div :class="`circle small ${scope.row.state === 'active' ? 'success' : 'danger'}`"
                       style="display: inline-flex; margin-right: 10px"></div>
                  <span>{{
                      scope.row.state === 'active' ? 'Активен' : scope.row.state === 'blocked' ? 'Заблокирован' : 'Отписан'
                    }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column>
              <template #default="scope">
                <el-button text class="text-button table-text-button" @click="showEditContact(scope.row)"
                           style="margin-right: 20px">
                  <font-awesome-icon icon="wrench"></font-awesome-icon>
                </el-button>
                <el-button text class="text-button table-text-button" @click="showConversation(scope.row)">
                  <font-awesome-icon icon="comments"></font-awesome-icon>
                </el-button>
              </template>
            </el-table-column>
          </el-table>

            <TableSpinner :loading="countLoading" :rows="1" :no-min-height="true">
              <div class="solo-pagination-container">
              <span style="color: var(--el-text-color-regular)">Количество подписчиков: {{ totalSubscribers }}</span>
              <el-pagination
                  background
                  layout="next, pager, prev, sizes"
                  :total="totalSubscribers"
                  class="wide-page-size"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :pager-count="3"
                  :hide-on-single-page="true"
                  @size-change="handlePageSizeChange"
                  @current-change="handleCurrentPageChange"
              >
              </el-pagination>
              </div>
            </TableSpinner>

        </TableSpinner>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import TgBotSubscriberForm from "./subscribers/tg-bot-subscriber-form.component.vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import DialogMessageFeed from "@shared/components/dialogs/dialog-message-feed.component.vue";
import TgBotSubscribersFilterForm from "./subscribers/subscribers-segmentation-form.component.vue";

export default {
  name: "TgBotSubscribers",
  props: ["bot", "visible"],
  components: {TgBotSubscribersFilterForm, DialogMessageFeed, RemoveButton, TgBotSubscriberForm},
  watch: {
    visible(newVal) {
      console.log(newVal)
      if (newVal) this.loadContacts()
    },
    bot(val) {
      if (val && val._id) this.loadContacts()
    }
  },
  mounted() {

  },
  methods: {
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.loadContacts(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.loadContacts(false)
    },
    handleSelectionChange(selectedContacts) {
      this.selectedContacts = selectedContacts
    },
    getParams() {
      return {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        botId: this.bot._id
      }
    },
    setFilterAndLoad(f) {
      this.numFilters = f.filters.length + (f.status !== "" ? 1 : 0)
      this.loadContacts(true)
      this.filterVisible = false
    },
    loadContacts(doCount = true) {
      this.contactsLoading = true
      if (doCount) this.countLoading = true
      let params = this.getParams()
      params.filter = this.$refs.filterForm ? this.$refs.filterForm.getFilter() : {}
      this.axios.post("/chatbot/getSubscribers", {params, doCount})
          .then(resp => {
            this.contactsLoading = false
            this.countLoading = false
            this.contacts = resp.data.subscribers
            if (doCount)
              this.totalSubscribers = resp.data.count || 0
            // this.currentPage = 1
            console.log(resp.data.subscribers)
          })
    },
    exportSubscribers() {
      document.getElementById("exportForm").submit()
    },
    importSubscribers() {

    },
    showEditContact(contact) {
      this.editContactModalVisible = true
      this.$nextTick(() => {
        this.editingContactId = contact._id
      })
    },
    contactRemovedHandler() {
      this.editContactModalVisible = false
      this.loadContacts(true)
    },
    showConversation(contact) {
      this.chatVisible = true
      this.$nextTick(() => {
        this.chatContactId = contact._id
      })

    },

    toggleBlockedState(state) {
      this.contactsActionsBlocked = true
      this.axios.post("/chatbot/toggleAllSubscribersBlockedState", {ids: this.selectedContacts.map(c => c._id), state})
          .then(resp => {
            this.loadContacts(true)
            this.contactsActionsBlocked = false
          })
    },
    removeContacts() {
      this.contactsActionsBlocked = true
      this.axios.post("/chatbot/deleteSubscribers", {ids: this.selectedContacts.map(c => c._id)})
          .then(resp => {
            this.loadContacts(true)
            this.contactsActionsBlocked = false
          })
    },
  },
  computed: {},
  data() {
    return {
      loading: false,
      contacts: [],
      currentPage: 1,
      pageSize: 50,
      totalSubscribers: 0,
      contactsLoading: false,
      countLoading: false,
      selectedContacts: [],
      editContactModalVisible: false,
      editingContactId: "",
      filterVisible: false,
      contactsActionsBlocked: false,
      chatVisible: false,
      chatContactId: "",
      numFilters: 0
    }
  }
}

</script>
