<template>
  <div>
    <Breadcrumbs primary="Личный кабинет" secondary="Роли"/>
    <el-dialog
        v-model="roleFormVisible"
        :title="editRoleId ? 'Изменить роль' : 'Добавить роль'"
        class="modal-95-70-60"
        @close="clearValues"
    >
      <el-row style="margin-bottom: 15px">
        <el-col :xs="24">
          <el-form label-position="top">
            <el-form-item label="Название">
              <el-input v-model="newRoleName" data-test="roleName"/>
            </el-form-item>
            <el-form-item label="Описание">
              <el-input v-model="newRoleDescription" />
            </el-form-item>
            <el-form-item label="Привилегии/Ограничения">
              <el-checkbox-group v-model="newRolePrivileges">
                <div v-for="(p, i) in privileges.filter(pr => pr.level === 1)">
                  <el-checkbox
                      class="small-top-margin-small-screen"
                      :label="p._id"
                      :key="i"
                      :data-test="`privilege-${i}`"
                  >
                    {{ p.name }}
                  </el-checkbox>


                </div>
              </el-checkbox-group>
            </el-form-item>

          </el-form>

        </el-col>
      </el-row>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="roleFormVisible = false" data-test="cancelRoleEdit">Отмена</el-button>
          <el-button type="success" @click="saveRole()" data-test="createRole">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>


    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>Роли</span>-->

<!--            </div>-->
<!--          </template>-->
          <TableSpinner :loading="loading">
            <el-table :data="roles"  style="width: 100%" data-test="rolesTable">
              <el-table-column label="Код роли" min-width="380" >
                <template #default="scope">
                  {{ scope.row.title }}&nbsp;{{ scope.row.isSystem ? "(системная роль) " : "" }}
                </template>
              </el-table-column>
              <el-table-column prop="description" label="Описание" min-width="380" />
              <el-table-column label="" min-width="150">
                <template #default="scope">
                  <ActionsDropdown  v-if="!scope.row.isSystem" data-test="roleActions">
                    <ActionButton icon="pencil-alt" @click="editRole(scope.row)" data-test="editRole">Изменить</ActionButton>
                    <ActionButton icon="times" type="remove" @confirm="removeRole(scope.row._id)" data-test="removeRole">Удалить</ActionButton>
                  </ActionsDropdown>

                </template>
              </el-table-column>
            </el-table>
          </TableSpinner>
          <el-button type="success" @click="roleFormVisible = true"  style="margin-top: 20px" data-test="addRole">
            <font-awesome-icon icon="plus" class="el-icon--left"></font-awesome-icon>
            Добавить роль
          </el-button>

        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>



import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";
import ActionButton from "@shared/components/layout/actionButton.component";

export default {
  name: "Roles",
  title: "Роли",
  components: {
    ActionButton,
    ActionsDropdown,
  },
  mounted() {
    this.loadRoles()
  },
  computed: {},
  methods: {
    getRoleName(roleId) {
      return (this.roles.find(r => r._id === roleId) || {}).title || ""
    },
    loadRoles() {
      this.loading = true
      this.axios.get("/user/getMyRoles")
          .then(respRoles => {
            this.axios.get("/user/getAvailablePrivileges")
                .then(respPrivileges => {
                  this.privileges = respPrivileges.data
                  this.roles = respRoles.data
                  this.loading = false
                })
          })
    },
    editRole(role) {
      this.newRoleName = role.title
      this.newRoleDescription = role.description
      this.newRolePrivileges = role.privileges
      this.editRoleId = role._id
      this.roleFormVisible = true
    },
    clearValues() {
      if (this.editRoleId) {
        this.newRoleName = ""
        this.newRoleDescription = ""
        this.newRolePrivileges = []
        this.editRoleId = ""
      }
    },
    saveRole() {
      this.axios.post("/user/saveRole", {
        name: this.newRoleName,
        description: this.newRoleDescription,
        privileges: this.newRolePrivileges,
        id: this.editRoleId
      }).then(resp => {
        if(resp.data && resp.data.error && resp.data.error === "duplicate_name")
          this.$gNotify("Роль с таким именем уже существует", "error")
        else {
          this.roleFormVisible = false
          this.newRoleName = ""
          this.newRoleDescription = ""
          this.newRolePrivileges = []
          this.loadRoles()
        }
      })
    },
    removeRole(roleId) {
      this.axios.delete("/user/removeRole", {params: {roleId}}).then(resp => {
        if(resp.data && resp.data.error === "role_used")
          this.$gNotify(`Данная роль назначена пользователю ${resp.data.by}`, "error")
        else
          this.loadRoles()
      })
    }
  },
  data() {
    return {
      roles: [],
      privileges: [],
      roleFormVisible: false,
      newRoleName: "",
      newRoleDescription: "",
      editRoleId: "",
      newRolePrivileges: [],
      loading: false
    }
  }
}

</script>