<template>
  <div>
    <edit-contact
      :contact="editedContact"
      :visible="editContactVisible"
      @close="editContactVisible = false"
      @saved="viewBase(previewBase, previewCurrentPage, previewPageSize, false, true)"
    />
  <el-dialog
      v-model="previewVisible"
      width="100%"
      @close="() => {searchToken = ''; fakeSearchToken=''}"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <span :id="titleId" :class="titleClass" style="display: flex;align-items: center" v-show="!renameMode">
          <span>{{ previewBase.name }}</span>
          <el-button text class="text-button" size="small" style="margin-left: 20px" @click="renameMode = true" v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_CONTACTS_CHANGE)">
            <font-awesome-icon icon="pencil-alt"></font-awesome-icon>&nbsp;Переименовать
          </el-button>

        </span>
        <span :id="titleId" :class="titleClass" style="display: flex;align-items: center" v-show="renameMode">
          <el-input v-model="renameInput"></el-input>
          <el-button style="margin-left: 10px" :loading="renameLoading" @click="commitRename" type="success">Сохранить
          </el-button>
          <el-button style="margin-left: 10px" @click="renameMode = false">Отмена
          </el-button>

        </span>

      </div>
    </template>

    <TableSpinner :loading="previewLoading">

        <div style="display: flex; margin-bottom: 40px; width: 100%">
          <el-input v-model="fakeSearchToken" ></el-input>
          <el-button style="margin-left: 10px" @click="viewBase(previewBase, 1, previewPageSize, true)" :loading="previewLoading">Поиск
          </el-button>
        </div>

      <el-table
          :data="previewContacts" style="width: 100%;"
          @selection-change="handlePartialContactSelect"
      >
<!--        <el-table-column type="selection" width="60" v-if="showPartialContactsSelect" />-->
        <el-table-column label="Телефон" width="130">
          <template #default="scope">
            <b>{{ scope.row.phone }}</b>
          </template>
        </el-table-column>
        <el-table-column prop="operator.OrgName" label="Оператор"  min-width="120"/>
        <el-table-column prop="operatorGroup" label="Группа операторов"  min-width="120"/>
        <el-table-column prop="regionName" label="Регион" min-width="120"/>
        <el-table-column :formatter="gender" width="60" label="Пол" />
        <el-table-column prop="show_name" label="Название контакта" min-width="120"/>
        <el-table-column prop="surname" label="Фамилия"  min-width="120"/>
        <el-table-column prop="name" label="Имя"  min-width="120"/>
        <el-table-column prop="patronymic" label="Отчество"  min-width="120"/>
        <el-table-column prop="email" label="Email"  min-width="120"/>
        <el-table-column prop="date_of_birth" label="Дата рождения"  min-width="120"/>
        <el-table-column prop="add_field_1" label="Доп. поле 1"  min-width="120"/>
        <el-table-column prop="add_field_2" label="Доп. поле 2"  min-width="120"/>
        <el-table-column label="" v-if="editMode && !$store.getters.user.hasPrivilege($PRIVILEGES.DENY_CONTACTS_CHANGE)" width="100">
          <template #default="scope">
            <ActionsDropdown>
              <ActionButton icon="pencil-alt" @click="showEditContact(scope.row)">Изменить</ActionButton>
              <ActionButton icon="times" type="remove" @confirm="removeContact(scope.row)">Удалить</ActionButton>
            </ActionsDropdown>
<!--            <el-button-group class="vertical-button-group" size="small">-->
<!--              <el-button plain class="text-button" @click="showEditContact(scope.row)" style="padding-left: 0px">-->
<!--                <font-awesome-icon icon="pencil-alt" class="el-icon&#45;&#45;left"></font-awesome-icon>-->
<!--                Изменить-->
<!--              </el-button>-->
<!--              <el-button plain class="text-button" type="danger" @click="removeContact(scope.row)" style="padding-left: 0px">-->
<!--                <font-awesome-icon icon="times" class="el-icon&#45;&#45;left"></font-awesome-icon>-->
<!--                Удалить-->
<!--              </el-button>-->
<!--            </el-button-group>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="solo-pagination-container">
        <span
            v-if="previewBase.last_update"
            style="font-size: 13px; color: var(--el-text-color-secondary)">Последнее изменение:&nbsp;{{ formatDateTime(previewBase.last_update) }}</span>
        <el-pagination
            class="wide-page-size"
            background
            layout="sizes, prev, pager, next"
            :total="previewTotalSize"
            :page-sizes="[10, 20, 50, 100]"

            :hide-on-single-page="false"
            :page-size="previewPageSize"
            @size-change="handlePreviewPageSizeChange"
            @current-change="handlePreviewCurrentPageChange"
        >
        </el-pagination>
      </div>
    </TableSpinner>

<!--    <template #footer>-->
<!--      <span class="dialog-footer">-->
<!--        -->
<!--      </span>-->
<!--    </template>-->
  </el-dialog>
  </div>
</template>

<script>

import EditContact from "@/components/addressbooks/editContact.component";
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";
import ActionButton from "@shared/components/layout/actionButton.component";
import moment from "moment";

export default {
  name: "AddressBookViewEdit",
  components: {ActionButton, ActionsDropdown, EditContact},
  props: ["editMode", "showPartialContactsSelect"],
  mounted() {
    this.axios.get("/resources/operators")
        .then(resp => {
          this.operators = resp.data
        })
        .catch(console.error)
  },
  computed: {
    previewTotalSize(){
      console.log([this.searchToken.trim(), this.tokenPortionTotalSize])
      return this.tokenPortionTotalSize > -1 ? this.tokenPortionTotalSize : Number(this.previewBase.size)
    }
  },
  methods: {
    handlePartialContactSelect(){

    },
    formatDateTime(v){
      if(!v) return ""
      return moment(v).utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss")
    },
    handlePreviewPageSizeChange(newSize) {
      this.previewPageSize = newSize
      this.viewBase(this.previewBase, this.previewCurrentPage, newSize)
    },
    handlePreviewCurrentPageChange(newPage) {
      this.previewCurrentPage = newPage
      this.viewBase(this.previewBase, newPage, this.previewPageSize)
    },
    viewBase(base, page = 1, pageSize = 10, replaceToken=false, setLastUpdatedValue=false) {
      this.previewVisible = true
      this.previewLoading = true
      // this.previewTotalSize = Number(base.size)
      this.previewBase = base

      this.renameInput = base.name
      let skip = (this.previewCurrentPage - 1) * this.previewPageSize
      let limit = this.previewPageSize
      if(replaceToken) this.searchToken = this.fakeSearchToken
      if(setLastUpdatedValue) this.previewBase.last_update = new Date()
      this.axios.get("/contacts/basePreview", {params: {base_id: base._id, skip, limit, token: this.searchToken}})
          .then(resp => {
            if(this.searchToken.trim())
              this.tokenPortionTotalSize = resp.data[1]
            else
              this.tokenPortionTotalSize = -1
            this.previewContacts = resp.data[0].map(c => {
              c.operator = (this.operators.find(o => o._id === c.operator_id) || {OrgName: "", group: ""})
              c.operatorGroup = (this.$store.getters.operatorsGroups.find(o => o._id === c.operator.group) || {title: ""}).title
              return c
            })

            this.previewLoading = false
            this.$emit("loaded")
          })
    },
    gender(contact) {
      return String(contact.sex) === "2" ? "" : String(contact.sex) === "1" ? "Ж" : "М"
    },
    showEditContact(contact) {
      this.editedContact = contact
      this.editContactVisible = true
    },
    removeContact(contact) {
      this.axios.delete("/contacts/removeOne", {params: {contactId: contact._id}})
      .then(resp=> {
        this.viewBase(this.previewBase, this.previewCurrentPage, this.previewPageSize)
      })
      .catch(console.error)
    },
    commitRename(){
      if(!this.renameInput.trim().length) return this.$gNotify("Некорректное имя группы", "error")
      this.renameLoading = true
      this.axios.post("/contacts/renameGroup", {groupId: this.previewBase._id, name: this.renameInput})
          .then(resp=> {
            this.renameLoading = false
            if (resp.data.error === 'group_exists')
              return this.$gNotify("Группа контактов с таким именем уже существует", "error")

            this.renameMode = false
            this.previewBase.name = this.renameInput
            this.previewBase.last_update = new Date()
          })
          .catch(console.error)

    }
  },
  data() {
    return {
      previewVisible: false,
      previewLoading: true,
      previewCurrentPage: 1,
      previewPageSize: 10,
      tokenPortionTotalSize: -1,
      previewContacts: [],
      previewBase: {size: 0, name: "", _id: "", last_update: ""},
      operators: [],
      fakeSearchToken : "",
      searchToken: "",
      editedContact: {},
      editContactVisible: false,
      renameMode: false,
      renameInput: "",
      renameLoading: false
    }
  }
}

</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>