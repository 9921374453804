<template>
  <div class="phone-preview">
    <div class="header">
      <span>{{displaySender}}</span>
    </div>
    <div class="preview-message-feed">
      <dialog-message
          :text="msg.text || ''"
          :randomisers="msg.randomisers || []"
          :from-client="false"
          :media="(msg.files || [])[0]"
          :buttons="msg.buttons || []"
          :inspect-file-base-string="true"
          :time-received="fakeReceivedTime"
          v-for="(msg, i) in messages"
          :class-name="'preview-message'"
          @textClicked="e => $emit('textClicked', {originalEvent: e, messageIndex: i})"
      />
    </div>
    <div class="footer"></div>

  </div>
</template>

<script>

import DialogMessage from "@shared/components/dialogs/dialog-message.component.vue";
import moment from "moment";

export default {
  name: "PhonePreview",
  components: {DialogMessage},
  props: ['messages', 'isEditMode', 'displaySender'],
  provide(){
    return {
      textAsHtml: true
    }
  },
  computed: {
    fakeReceivedTime(){
      return moment().format('HH:mm')
    }
  },
  data() {
    return {};
  }
};
</script>

<style>
.preview-message .bot-message-text{
  border: 1px solid #eee;
  background: #f5f5f5 !important;
}

.phone-preview {
  width: 288px;
  border-radius: 15px;
  border: 5px solid #CCC;
}

.phone-preview .header{
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./../../../shared/assets/static_img/phone_preview_header.png");
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 42px;
}

.phone-preview .header .nv{

}

.phone-preview .preview-message-feed{
  background: white;
  height: 500px;
  padding: 10px;
  overflow-y: scroll;
}
.phone-preview .footer{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: url("./../../../shared/assets/static_img/phone_preview_footer.png");
  height: 42px;
  background-size: cover;
  padding-bottom: 10px;
}

.preview-message-feed .message{
  border-radius: 5px;
  padding: 5px;
}

</style>