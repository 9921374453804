<template>
  <Breadcrumbs primary="Личный кабинет" secondary="Имена отправителей"/>

  <client-sender-name-management-block
      ref="nameManagementBlock"
      @onNameUpdated="refreshNamesTable"
  />
  <client-sender-names-request-form
      @onNameCreated="refreshNamesTable"
  />
  <el-row :gutter="20">
    <el-col :span="24">
      <el-card class="box-card no-border no-top-body-padding">
        <span class="text-bold" style="margin-bottom: 20px">Активные имена отправителей</span>
        <client-sender-names-table
            ref="allNamesTable"
            :loading="namesLoading"
            :senders="allUserSenders"
            @refresh="refreshNamesTable"
            @onManage="manageName"
        />
      </el-card>
    </el-col>
  </el-row>
</template>

<script>

import ClientSenderNamesTable from "@shared/components/lk/client-sender-names-table.component.vue";
import ClientSenderNamesRequestForm from "../../components/lk/client-sender-name-request-form.component.vue";
import ClientSenderNameManagementBlock from "../../components/lk/client-sender-name-management-block.component.vue";


export default {
  name: "SenderNames",
  title: "Подписи отправителя",
  components: {
    ClientSenderNameManagementBlock,
    ClientSenderNamesRequestForm,
    ClientSenderNamesTable,
  },
  mounted() {
    this.refreshNamesTable()
  },
  methods: {
    refreshNamesTable() {
      this.namesLoading = true
      this.axios.get("/sender_names/myNames")
          .then(resp => {
            this.allUserSenders = resp.data
            this.namesLoading = false
          })
    },
    manageName(request) {
      this.$refs.nameManagementBlock.manageName(request)
    },

  },
  data() {
    return {
      allUserSenders: [],
      namesLoading: false
    }
  }
}

</script>
