<template>
  <div>
    <Breadcrumbs primary="Отчеты" secondary="Входящие сообщения"/>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card no-top-body-padding no-border">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>Входящие сообщения</span>-->
<!--            </div>-->
<!--          </template>-->
          <el-row :gutter="20">
            <el-col :span="24">
              <h4 class="report-parameters-title">Параметры отчета</h4>
              <form style="display: none" method="post" id="exportForm"
                    :action="`${axios.defaults.baseURL}/reports/downloadInboxReport`">
                <input name="params" type="hidden" id="exportParams"/>
                <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
              </form>
              <el-row  style="margin-bottom: 60px">
                <el-col :md="12" :xs="24">
                  <TimeSearch
                      :full-width-select="true"
                      :free-date-time="true"
                      :month="true"
                      :short-date="true"
                      ref="timeSearch"
                  />
                  <el-form label-position="top" style="margin-bottom: 20px">
                    <el-form-item label="Поиск" style="margin-bottom: 10px">
                      <el-input v-model="freeSearchToken"/>
                    </el-form-item>
                    <el-checkbox-group v-model="searchTokenIn">
                      <el-checkbox label="text">по тексту</el-checkbox>
                      <el-checkbox label="sender">по отправителю</el-checkbox>
                    </el-checkbox-group>
                  </el-form>
                  <el-form label-position="top" inline>
                    <el-form-item label="Входящий номер">
                      <el-select v-model="inbox">
                        <el-option value="" label="Все"></el-option>
                        <el-option
                            v-for="(o, i) in inboxOptions"
                            :value="o._id"
                            :label="`${o.phone} (${o.type === 'common' ? 'Общий' : 'Индивидуальный'})`"
                            :key="i"
                        />

                      </el-select>
                    </el-form-item>
                    <el-form-item label="Префикс">
                      <el-select v-model="prefix">
                        <el-option value="" label="Все"></el-option>
                        <el-option
                            v-for="(o, i) in prefixOptions"
                            :value="o.prefix"
                            :label="o.prefix"
                            :key="i"
                        />
                      </el-select>
                    </el-form-item>
                  </el-form>

                  <el-button type="success" plain @click="getReport" :loading="reportLoading">Применить</el-button>
                </el-col>
              </el-row>
              <el-row v-show="reportReady">
                <el-col :span="24">
                  <TableSpinner :loading="dataLoading" :rows="10">

                    <el-button text class="text-button" @click="exportReportData" style="padding: 0px; min-height: auto;height: auto !important; margin-bottom : 20px">
                      <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать CSV файл
                    </el-button>

                    <el-table :data="reportData"  style="width: 100%" stripe>
                      <el-table-column label="Дата" :formatter="formatDateTime"  width="150"/>
                      <el-table-column label="Входящий номер" min-width="200"
                                       :formatter="row => `${row.inbox_phone} ${row.inbox_prefix ? `(${row.inbox_prefix})` : ''}`"
                                       />
                      <el-table-column label="Отправитель" prop="sender_phone"  width="150"/>
                      <el-table-column label="Сообщение" :formatter="textWithoutPrefix"  min-width="200"/>
                    </el-table>
                    <div class="solo-pagination-container">
                      <el-pagination
                          background
                          class="wide-page-size"
                          layout="sizes, prev, pager, next"
                          :total="totalRows"
                          :page-sizes="[10, 20, 50, 100]"
                          :page-size="pageSize"
                          :pager-count="3"
                          :hide-on-single-page="true"
                          @size-change="handlePageSizeChange"
                          @current-change="handleCurrentPageChange"
                      >
                      </el-pagination>
                    </div>
                  </TableSpinner>

                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"

import moment from "moment";

export default {
  name: "InboxReport",
  title: "Входящие сообщения",
  components: {

    TimeSearch,
    },
  mounted() {
    this.axios.get("/support/getMyInboxes")
        .then(resp => {
          this.inboxOptions = resp.data
          this.prefixOptions = resp.data.filter(i => i.type === "common").map(i => {
            return {prefix: i.prefix}
          })
        })
  },
  computed: {},
  methods: {
    textWithoutPrefix(row) {
      if (row.inbox_prefix) return row.text.replace(new RegExp(`^${row.inbox_prefix}`, "i"), "")
      return row.text
    },
    formatDateTime(row) {
      return moment(row.time_created).utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss")
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.getReport(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.getReport(false)
    },
    __getReportParams() {
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      console.log(dateFilter.from.format("DD.MM.YYYY HH:mm"))
      return {
        date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
        date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        pageSize: this.pageSize,
        currentPage: this.currentPage - 1,
        searchTokenIn: this.searchTokenIn.join(","),
        freeSearchToken: this.freeSearchToken,
        inbox_phone: this.inbox,
        inbox_prefix: this.prefix
      }
    },
    getReport(doCount = false) {
      if (doCount) {
        this.reportLoading = true
        this.reportReady = false
      } else {
        this.dataLoading = true
      }
      let params = this.__getReportParams()
      this.axios.get("/reports/inboxReport", {params})
          .then(resp => {
            if (doCount) {
              this.reportLoading = false
              this.reportReady = true
            } else {
              this.dataLoading = false
            }
            this.reportData = resp.data.records
            this.currentPage = 1
          })
      if (doCount)
        this.axios.get("/reports/inboxReportCount", {params})
            .then(resp => {
              this.totalRows = resp.data.totalCount
            })
    },
    exportReportData() {
      document.getElementById("exportParams").value = JSON.stringify(this.__getReportParams())
      document.getElementById("exportForm").submit()
    }
  },
  data() {
    return {
      reportReady: false,
      reportLoading: false,
      dataLoading: false,
      reportData: [],
      searchTokenIn: [],
      freeSearchToken: "",
      inbox: "",
      prefix: "",
      currentPage: 1,
      pageSize: 10,
      totalRows: 0,

      inboxOptions: [],
      prefixOptions: []
    }
  }
}

</script>