let {createWebHistory, createRouter} = require('vue-router')
let store = require('./store')
let PRIVILEGES = require('./privileges')
let USER_ROLES = require('./userRoles')
let AuthLayout = require('@shared/layouts/auth.layout').default
let CabLayout = require('@/layouts/cabinet.layout').default

const routes = [
  {
    path: "",
    meta: {redirectToCabIfAuthenticated: true},
    redirect: {path: "/login"}
  },
  {
    path: "/",
    meta: {redirectToCabIfAuthenticated: true},
    redirect: {path: "/login"}
  },
  {
    path: '/login',
    component: AuthLayout,
    name: "login",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@/views/login.view').default
      }
    ]
  },
  {
    path: '/offer',
    component: AuthLayout,
    name: "offer",
    children: [
      {
        path: '',
        component: require('@/views/ofert.view').default
      }
    ]
  },
  {
    path: '/resetPassword',
    component: AuthLayout,
    name: "resetPass",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@shared/views/resetPassword.view').default
      }
    ]
  },
  {
    path: '/registration/:agent_code',
    component: AuthLayout,
    name: "register_with_code",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@/views/registration/registration.view').default
      }
    ]
  },
  {
    path: '/registration',
    component: AuthLayout,
    name: "register",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@/views/registration/registration.view').default
      }
    ]
  },
  {
    path: '/confirmCode',
    component: AuthLayout,
    name: "confirmCode",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@/views/registration/confirmCode.view').default
      }
    ]
  },
  {
    path: "/cab/finalizeRegistration",
    component: CabLayout,
    children: [
      {
        path: "",
        name: "finalize_reg",
        component: require('@/views/registration/finalize.view').default,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/cab/messages",
    component: CabLayout,
    children: [
      {
        path: "",
        redirect: {name: "new_send"}
      },
      {
        path: "new_send",
        name: "new_send",
        component: require('@/views/sms/new-send.view').default,
        meta: {
          requiresAuth: true,
          nePrivilege: PRIVILEGES.DENY_SMS_SEND,
          neRole: ["bookkeeper", "monitoring"],
          requiresActiveBalance: true,
          requiresCabinetSendingEnabled: true
        }
      },
      {
        path: "awaiting_payment",
        name: "awaiting_payment",
        component: require('@/views/sms/awaiting-payment.view').default,
        meta: {
          requiresAuth: true,
          nePrivilege: PRIVILEGES.DENY_SMS_SEND,
          neRole: ["bookkeeper", "monitoring"],
          requiresActiveBalance: true,
          requiresCabinetSendingEnabled: true
        }
      },
      {
        path: "edit_task/:taskId",
        name: "edit_task",
        component: require('@/views/sms/new-send.view').default,
        meta: {
          requiresAuth: true,
          nePrivilege: PRIVILEGES.DENY_SMS_SEND,
          neRole: ["bookkeeper", "monitoring"],
          requiresActiveBalance: true,
          requiresCabinetSendingEnabled: true
        }
      },
      {
        path: "my_tasks",
        name: "my_send_tasks",
        component: require('@/views/sms/my-tasks.view').default,
        meta: {
          requiresAuth: true,
          nePrivilege: PRIVILEGES.DENY_SMS_SEND,
          neRole: ["bookkeeper", "monitoring"],
          requiresActiveBalance: true,
          requiresCabinetSendingEnabled: true
        }
      },
      {
        path: "templates",
        name: "templates",
        component: require('@/views/sms/message-templates.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "blacklist",
        name: "blacklist",
        component: require('@shared/views/messages/blacklist.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "addressbooks",
        name: "addressbooks",
        component: require('@/views/sms/addressbooks.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "sending_statistics/:task_id",
        name: "sending_statistics",
        component: require('@/views/sms/sending_statistics.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      }
    ]
  },
  {
    path: "/cab/chat_bots",
    component: CabLayout,
    children: [
      {
        path: "my_bots",
        name: "my_bots",
        component: require('@/views/chatbots/my-bots.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "manage_tg_bot",
        name: "manage_tg_bot",
        component: require('@/views/chatbots/manage-tg-bot.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "manage_algorithm",
        name: "manage_algorithm",
        component: require('@/views/chatbots/add-edit-algorithm.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "dialogs",
        name: "dialogs",
        component: require('@/views/chatbots/dialogs.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
    ]
  },
  {
    path: "/cab/lk",
    component: CabLayout,
    children: [
      {
        path: "service_templates",
        name: "service_templates",
        component: require('@/views/lk/service-templates.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "inbox",
        name: "inbox",
        component: require('@/views/lk/inbox.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "sender_names",
        name: "sender_names",
        component: require('@/views/lk/sender-names.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "settings",
        name: "settings",
        component: require('@/views/lk/settings.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "user_card",
        name: "user_card",
        component: require('@/views/lk/user-card.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "subusers",
        name: "subusers",
        component: require('@/views/lk/subusers.view').default,
        meta: {
          requiresAuth: true,
          nePrivilege: PRIVILEGES.DENY_USER_MANAGEMENT,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "roles",
        name: "roles",
        component: require('@/views/lk/roles.view').default,
        meta: {
          requiresAuth: true,
          nePrivilege: PRIVILEGES.DENY_USER_MANAGEMENT,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
    ]
  },
  {
    path: "/cab/system_reports",
    component: CabLayout,
    children: [
      {
        path: "authorizations",
        name: "authorizations",
        component: require('@/views/reports/authorizations.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "event_log",
        name: "event_log",
        component: require('@/views/reports/event-log.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      }
    ]
  },
  {
    path: "/cab/reports",
    component: CabLayout,
    children: [
      {
        path: "sent_messages",
        name: "sent_messages",
        component: require('@/views/reports/sent-messages.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper"]
        }
      },
      {
        path: "calls",
        name: "calls",
        component: require('@/views/reports/sent-calls.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      },
      {
        path: "inbox",
        name: "reports_inbox",
        component: require('@/views/reports/inbox-report.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["bookkeeper", "monitoring"]
        }
      }
    ]
  },
  {
    path: "/finance/paymentRobocass",
    component: CabLayout,
    children: [
      {
        path: "",
        name: "paymentRobocassCallback",
        component: require('@/views/finance/payment-callback.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },

    ]
  },
  {
    path: "/finance/paymentUmoney",
    component: CabLayout,
    children: [
      {
        path: "",
        name: "paymentUmoneyCallback",
        component: require('@/views/finance/payment-callback.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },

    ]
  },
  {
    path: "/cab/finance",
    component: CabLayout,
    children: [
      {
        path: "rates",
        name: "rates",
        component: require('@/views/finance/rates.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },
      {
        path: "operations",
        name: "operations",
        component: require('@/views/finance/operations.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },
      {
        path: "movement",
        name: "movement",
        component: require('@/views/finance/movement-report.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },
      {
        path: "detail",
        name: "detail",
        component: require('@/views/finance/detail-report.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },
      {
        path: "documents",
        name: "documents",
        component: require('@/views/finance/documents.view').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      }
    ]
  },
  {
    path: "/cab/support",
    component: CabLayout,
    children: [
      {
        path: "index",
        name: "index",
        component: require('@/views/support/index.view.vue').default,
        meta: {
          requiresAuth: true,
          neRole: ["monitoring"]
        }
      },
      // {
      //   path: "new_request",
      //   name: "new_request",
      //   component: require('@/views/support/new-request.view').default,
      //   meta: {
      //     requiresAuth: true,
      //     neRole: ["monitoring"]
      //   }
      // },
      // {
      //   path: "my_requests",
      //   name: "my_requests",
      //   component: require('@/views/support/index.view.vue').default,
      //   meta: {
      //     requiresAuth: true,
      //     neRole: ["monitoring"]
      //   }
      // },
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: {path: "/login"} }
]

let router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if(
    to.matched.some(record => record.meta && record.meta.redirectToCabIfAuthenticated) &&
    store.getters.isLoggedIn &&
    !(to.query.token && to.path === "/login")
  ) return next('/cab/messages/new_send')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) return next('/login?returnPath='+encodeURIComponent(to.path))
    if(to.meta.neRole && to.meta.neRole.some(r => !!USER_ROLES[r])) {
      let matchedOne = to.meta.neRole.find(v => store.getters.user.hasRole(USER_ROLES[v]))
      if(matchedOne)
        return next(USER_ROLES[matchedOne].defaultPath || '/cab/lk/settings')
    }
    if(to.meta.nePrivilege && store.getters.user.hasPrivilege(to.meta.nePrivilege))
      return next('/cab/lk/settings')
  }

  if(to.meta && to.meta.requiresActiveBalance && !store.getters.user.canDoMessaging)
    return next('/cab/lk/settings')
  if(to.meta && to.meta.requiresCabinetSendingEnabled && store.getters.user.cabinetSendingDenied)
    return next('/cab/lk/settings')
  next()


})

module.exports = router
