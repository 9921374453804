<template>
  <div>
    <el-row align="middle" style="margin-bottom: 30px">
      <el-col :xs="24" :sm="12">
        <router-link to="/cab/chat_bots/my_bots">
          <el-button size="small" type="primary">Назад к списку ботов</el-button>
        </router-link>
        <el-divider class="blue-divider hidden-sm-and-up" />
      </el-col>
      <el-col :xs="24" :sm="12">
        <TableSpinner :rows="1" :loading="loading" :no-min-height="true">
          <div style="display: flex" class="bot-name-container">
            <div style="text-align: left;width:fit-content">
              <h3 style="width:fit-content;margin-bottom: 10px; margin-top:0px">{{ bot.name }}</h3>
              <a target="_blank" style="width:auto;" :href="`https://t.me/${bot.username}`">{{ `https://t.me/${bot.username}` }}</a>
            </div>
          </div>
        </TableSpinner>
      </el-col>
    </el-row>

    <TableSpinner :loading="loading">
      <el-tabs v-model="visibleTab" class="primary-heading-tabs" >
        <el-tab-pane name="subscribers" label="Аудитория">
          <tg-bot-subscribers :bot="bot"  :visible="visibleTab === 'subscribers'"/>
        </el-tab-pane>
        <!--        <el-tab-pane name="stats" label="Статистика">-->
        <!--          <tg-bot-statistics :bot="bot"/>-->
        <!--        </el-tab-pane>-->
        <el-tab-pane name="algos" label="Алгоритмы" v-if="$store.getters.user.systemId() === '10004'">
          <tg-bot-algorithms :bot="bot" :algorithms="algos" @refresh="loadAll"/>
        </el-tab-pane>
        <el-tab-pane name="menu" label="Настройки меню" v-if="$store.getters.user.systemId() === '10004'">
          <tg-bot-menu-settings :bot="bot" :algorithms="algos" @refresh="loadAll"/>
        </el-tab-pane>
        <el-tab-pane name="hooks" label="Веб-хуки"  v-if="$store.getters.user.systemId() === '10004'">
          <tg-bot-webhooks :bot="bot" />
        </el-tab-pane>
      </el-tabs>
    </TableSpinner>
  </div>
</template>

<script>
import TgBotSubscribers from "../../components/chatbots/telegram/tg-bot-subscribers.component.vue";
import TgBotStatistics from "../../components/chatbots/telegram/tg-bot-stats.component.vue";
import TgBotWebhooks from "../../components/chatbots/telegram/tg-bot-webhooks.component.vue";
import TgBotAlgorithms from "../../components/chatbots/telegram/tg-bot-algorithms.component.vue";
import TgBotMenuSettings from "../../components/chatbots/telegram/tg-bot-menu-settings.component.vue";

export default {
  name: "ManageTelegramBot",
  title: "Управление ботом",
  components: {TgBotMenuSettings, TgBotAlgorithms, TgBotWebhooks, TgBotStatistics, TgBotSubscribers},
  mounted() {
    this.loadAll()
  },
  methods: {
    loadAll(){
      this.loading = true
      Promise.all([this.loadBot(), this.loadAlgos()])
          .then(([bot, algos]) => {
            this.bot = bot.data.bot
            this.algos = algos.data
            console.log(this.algos)
            this.loading = false
          })
          .catch(console.error)
    },
    loadBot() {
      return this.axios.get("/chatbot/getOne", {params: {botId: this.botId}})
    },
    loadAlgos() {
      return this.axios.get("/chatbot/getAlgorithms", {params: {botId: this.botId, short: true}})
    },
    open() {
      this.visible = true
    }
  },
  computed: {
    botId() {
      return this.$route.query.bot_id
    }
  },
  data() {
    return {
      visible: false,
      loading: true,
      visibleTab: "subscribers",
      bot: {},
      algos: []
    }
  }
}

</script>

<style>
  @media (max-width: 767px){
    .bot-name-container{
      justify-content: start;
    }
  }
  @media (min-width: 768px){
    .bot-name-container{
      justify-content: end;
    }
  }
</style>