<template>

  <el-row>
    <el-col :span="24">
      <el-row>
        <el-col :span="24">
          <!--          <el-divider class="dark-divider"></el-divider>-->
          <div id="balance-container">
            <div style="border-left: 3px solid #ffcc01;display: flex; align-items: center;">
              <div style="padding-left:20px">
                <font-awesome-icon icon="info-circle" style="color: #ffcc01;"></font-awesome-icon>
              </div>
              <div style=" color: #FFF;">
                <span style="margin-left: 20px; font-size:13px; color: white">
                  Пользователь: {{
                    $store.getters.user.systemId(true)
                  }}
                </span>
                <br/>
                <span style="margin-left: 20px; font-size:13px; color: white">
                  Баланс:&nbsp;{{
                    formatNumber($store.getters.userBalance)
                  }}&nbsp;р.
                </span>
                <router-link
                    to="/cab/finance/operations"
                    class="text-yellow" style="margin-left: 10px; font-size:13px" @click.native="$emit('closeMenu')">
                  +Пополнить
                </router-link>
                <br/>
                <div v-show="$store.getters.userOverdraft > 0">
                  <span style="margin-left: 20px; font-size:13px; color: white">
                    Овердрафт:&nbsp;{{
                      formatNumber($store.getters.userOverdraft)
                    }}&nbsp;р.
                  </span>
                  <br/>
                </div>

              </div>
            </div>
          </div>
          <div v-if="$store.getters.manager && $store.getters.manager.name">
            <el-divider class="dark-divider"></el-divider>
            <span style="margin-left: 20px; font-size:13px; color: white"
                  v-if="$store.getters.manager.name">
              <span>Менеджер:</span>&nbsp;{{ $store.getters.manager.name }}
            </span>
            <br/>
            <span style="margin-left: 20px; font-size:13px; color: white"
                  v-if="$store.getters.manager.email">
              <span>E-mail:</span>&nbsp;{{ $store.getters.manager.email }}
            </span>
            <br v-if="$store.getters.manager.email"/>
            <span style="margin-left: 20px; font-size:13px; color: white"
                  v-if="$store.getters.manager.phone">
              <span>Тел.:</span>&nbsp;{{ $store.getters.manager.phone }}
            </span>
<!--            <br v-if="$store.getters.manager.phone"/>-->
<!--            <span style="margin-left: 20px; font-size:13px; color: white"-->
<!--                  v-if="$store.getters.manager && $store.getters.manager.skype">-->
<!--              <span>Skype:</span>&nbsp;<b>{{ $store.getters.manager.skype }}</b>-->
<!--            </span>-->
          </div>
          <el-divider class="dark-divider"></el-divider>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-menu
              active-text-color="#f53a33"
              background-color="#31373a"
              class="main-gold-menu"
              text-color="#fff"
              ref="main-nav-menu"
              :default-active="activeItem"
              mode="vertical"
              :collapse="false"
              @open="handleOpen"
          >
            <el-sub-menu index="1"
                         v-if="!$store.getters.user.hasRole($USER_ROLES.bookkeeper) && !$store.getters.user.hasRole($USER_ROLES.monitoring)">
              <template #title>
                <font-awesome-icon icon="envelope" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Сообщения</span>
              </template>
              <router-link to="/cab/messages/new_send" class="no-style-link" @click="$store.dispatch('toggleMenu')"
                           v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_SMS_SEND) && $store.getters.user.canDoMessaging && !$store.getters.user.cabinetSendingDenied">
                <el-menu-item index="1-1">Отправка сообщений</el-menu-item>
              </router-link>
              <router-link to="/cab/messages/my_tasks" class="no-style-link" @click="$store.dispatch('toggleMenu')"
                           v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_SMS_SEND) && $store.getters.user.canDoMessaging && !$store.getters.user.cabinetSendingDenied">

                <el-menu-item index="1-6">

                  Мои задачи
                  <el-tag class="menu-number-badge" effect="dark" type="danger"
                          style="border: none; background-color: var(--el-menu-active-color)"
                          v-show="$store.getters.activeTasks.length">{{ $store.getters.activeTasks.length }}
                  </el-tag>
                </el-menu-item>

              </router-link>
              <router-link to="/cab/messages/awaiting_payment" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')"
                           v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_SMS_SEND) && $store.getters.user.canDoMessaging && !$store.getters.user.cabinetSendingDenied">
                <el-menu-item index="1-2">Ожидающие оплаты</el-menu-item>
              </router-link>
              <router-link to="/cab/messages/templates" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="1-3">Черновики</el-menu-item>
              </router-link>
              <router-link to="/cab/messages/blacklist" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="1-4">Запрещенные номера</el-menu-item>
              </router-link>
              <router-link to="/cab/messages/addressbooks" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="1-5">Адресная книга</el-menu-item>
              </router-link>
            </el-sub-menu>

            <el-sub-menu index="8"
                         v-if="!$store.getters.user.hasRole($USER_ROLES.bookkeeper) && !$store.getters.user.hasRole($USER_ROLES.monitoring)">
              <template #title>
                <font-awesome-icon icon="comments" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Чат-боты</span>
              </template>
              <router-link to="/cab/chat_bots/my_bots" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="8-1">Боты и Каналы</el-menu-item>
              </router-link>
              <router-link to="/cab/chat_bots/dialogs" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="8-2">Диалоги</el-menu-item>
              </router-link>
            </el-sub-menu>

            <el-sub-menu index="2" data-test="--menu-lk"
                         v-if="!$store.getters.user.hasRole($USER_ROLES.bookkeeper) && !$store.getters.user.hasRole($USER_ROLES.monitoring)">
              <template #title>
                <font-awesome-icon icon="home" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Личный кабинет</span>
              </template>
              <router-link to="/cab/lk/settings" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="2-1">Настройки</el-menu-item>
              </router-link>
<!--              <router-link to="/cab/lk/tg_bot" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
<!--                <el-menu-item index="2-8">Telegram бот</el-menu-item>-->
<!--              </router-link>-->
              <!--              <router-link to="/cab/lk/inbox" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
              <!--                <el-menu-item index="2-2">Номера для приёма смс</el-menu-item>-->
              <!--              </router-link>-->

              <router-link to="/cab/lk/service_templates" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="2-3">Сервисные шаблоны</el-menu-item>
              </router-link>
              <router-link to="/cab/lk/sender_names" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="2-4">Имена отправителей</el-menu-item>
              </router-link>
              <router-link to="/cab/lk/subusers" class="no-style-link" @click="$store.dispatch('toggleMenu')"
                           v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_USER_MANAGEMENT)"
                           data-test="--menu-lk-users">
                <el-menu-item index="2-5">Пользователи</el-menu-item>
              </router-link>
              <router-link to="/cab/lk/roles" class="no-style-link" @click="$store.dispatch('toggleMenu')"
                           v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_USER_MANAGEMENT)"
                           data-test="--menu-lk-roles">
                <el-menu-item index="2-6">Роли</el-menu-item>
              </router-link>
              <router-link to="/cab/lk/user_card" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="2-7">Карточка пользователя</el-menu-item>
              </router-link>

            </el-sub-menu>

            <el-sub-menu index="3" v-if="!$store.getters.user.hasRole($USER_ROLES.bookkeeper)">
              <template #title>
                <font-awesome-icon icon="chart-bar" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Отчеты</span>
              </template>
              <router-link to="/cab/reports/sent_messages" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="3-1">Отправленные сообщения</el-menu-item>
              </router-link>
              <router-link to="/cab/reports/calls" class="no-style-link" @click="$store.dispatch('toggleMenu')"
                           v-if="!$store.getters.user.hasRole($USER_ROLES.monitoring)">
                <el-menu-item index="3-2">Детализация звонков</el-menu-item>
              </router-link>
              <!--              <router-link to="/cab/reports/inbox" class="no-style-link" @click="$store.dispatch('toggleMenu')" v-if="!$store.getters.user.hasRole($USER_ROLES.monitoring)">-->
              <!--                <el-menu-item index="3-3">Входящие сообщения</el-menu-item>-->
              <!--              </router-link>-->
            </el-sub-menu>

            <el-sub-menu index="4"
                         v-if="!$store.getters.user.hasRole($USER_ROLES.bookkeeper) && !$store.getters.user.hasRole($USER_ROLES.monitoring)">
              <template #title>
                <font-awesome-icon icon="edit" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Системные отчеты</span>
              </template>
              <router-link to="/cab/system_reports/authorizations" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="4-1">Отчет по авторизациям</el-menu-item>
              </router-link>
              <router-link to="/cab/system_reports/event_log" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="4-2">Журнал событий</el-menu-item>
              </router-link>
            </el-sub-menu>

            <el-sub-menu index="5" v-if="!$store.getters.user.hasRole($USER_ROLES.monitoring)">
              <template #title>
                <font-awesome-icon icon="credit-card" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Финансы</span>
              </template>
              <router-link to="/cab/finance/operations" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-1">Финансовые операции</el-menu-item>
              </router-link>
              <router-link to="/cab/finance/rates" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-2">Тарифы</el-menu-item>
              </router-link>
              <router-link to="/cab/finance/movement" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-3">Движение средств</el-menu-item>
              </router-link>
              <router-link to="/cab/finance/detail" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-4">Детализация</el-menu-item>
              </router-link>

              <router-link to="/cab/finance/documents" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-5">Документы</el-menu-item>
              </router-link>
            </el-sub-menu>

<!--            <el-sub-menu index="6" v-if="!$store.getters.user.hasRole($USER_ROLES.monitoring)">-->
<!--              <template #title>-->
<!--                <font-awesome-icon icon="life-ring" class="menu-primary-icon"></font-awesome-icon>-->
<!--                <span class="menu-heading-with-icon">Поддержка</span>-->
<!--              </template>-->
<!--              -->
<!--              <router-link to="/cab/support/new_request" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
<!--                <el-menu-item index="6-1">Новый запрос</el-menu-item>-->
<!--              </router-link>-->
<!--              <router-link to="/cab/support/my_requests" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
<!--                <el-menu-item index="6-2">Мои запросы</el-menu-item>-->
<!--              </router-link>-->

<!--            </el-sub-menu>-->
            <el-menu-item index="6" v-if="!$store.getters.user.hasRole($USER_ROLES.monitoring)" @click="$router.push({path: '/cab/support/index'})"
                           style="padding-left: 23px">
              <font-awesome-icon icon="life-ring" class="menu-primary-icon"></font-awesome-icon>
              <span class="menu-heading-with-icon">Поддержка</span>
            </el-menu-item>

            <li class="el-menu-item" @click="$store.dispatch('logout', {callback: () => $router.push({path: '/login'})})"
                          id="logout-item" style="padding-left: 23px">
              <font-awesome-icon icon="sign-out-alt" class="menu-primary-icon"></font-awesome-icon>
              <span class="menu-heading-with-icon">Выйти</span>
            </li>

          </el-menu>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

</template>


<script>

const NUM_MAIN_MENU_ELEMENTS = 8

const MENU_MAPPINGS = {
  "messages": {
    'new_send': '1-1',
    'edit_task': '1-1',
    'sending_statistics': '1-1',
    'awaiting_payment': '1-2',
    'templates': '1-3',
    'blacklist': '1-4',
    'addressbooks': '1-5',
    'my_tasks': '1-6'
  },
  'lk': {
    'settings': '2-1',
    'inbox': '2-2',
    'service_templates': '2-3',
    'sender_names': '2-4',
    'subusers': '2-5',
    'roles': '2-6',
    'user_card': '2-7',
    'tg_bot': '2-8'
  },
  'reports': {
    'sent_messages': '3-1',
    'calls': '3-2',
    'inbox': '3-3'
  },
  'system_reports': {
    'authorizations': '4-1',
    'event_log': '4-2'
  },
  'finance': {
    'operations': '5-1',
    'rates': '5-2',
    'movement': '5-3',
    'detail': '5-4',
    'documents': '5-5'
  },
  'support': {
    'index': '6',
    'new_request': '6-1',
    'my_requests': '6-2'
  },
  'chat_bots': {
    'my_bots': '8-1',
    'dialogs': '8-2'
  }
}

export default {
  name: 'Menu',
  watch: {
    $route() {
      this.updateActiive()
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(`Opening ${key}`)
      for (let i = 0; i < NUM_MAIN_MENU_ELEMENTS; i++)
        if (String(i + 1) !== key)
          this.$refs['main-nav-menu'].close(String(i + 1))
    },
    updateActiive() {
      let path = window.location.pathname
      let prime = path.split("/")[2]
      let sec = path.split("/")[3]
      this.activeItem = (MENU_MAPPINGS[prime] || {})[sec]
      // console.log(`Active: ${prime}`)
    }
  },
  created() {
    this.updateActiive()
    // this.$refs['main-nav-menu'].open
  },
  mounted() {
    // console.log(this.activeItem)
    this.$store.dispatch("loadTasks")
  },
  data() {
    return {
      activeItem: '1'
    }
  }
}

</script>

<style>

.main-gold-menu {
  padding: 20px !important;
}

.main-gold-menu:not(.el-menu--collapse) {
  min-width: 320px;
}

.el-menu.main-gold-menu {
  border: none !important;
}

.el-sub-menu__title,
#logout-item {
  height: 46px !important;
  font-size: 16px !important;
}

.el-sub-menu .el-menu .el-menu-item {
  height: 30px
}

.main-gold-menu .el-sub-menu {
  border-left: 3px solid transparent;
}

.main-gold-menu .el-sub-menu.is-opened {
  border-left: 3px solid var(--el-menu-active-color);
}

.main-gold-menu .menu-heading-with-icon {
  margin-left: 20px
}

.dark-divider {
  border-top: 1px solid #3f4649;
}

.menu-number-badge {
  margin-left: 5px;
  height: auto !important;
  line-height: initial !important;
  padding: 5px;

}

.el-sub-menu .el-menu-item {
  padding-left: 56px !important;
}
</style>