<template>
  <div>
    <el-dialog
        :append-to-body="true"
        v-model="codeResendVisible"
        title=""
        class="modal-95-50-30"
    >
      <el-form label-position="top">
        <CaptchaInput v-model="form.captcha" ctype="login" ref="captchaResendLoginCode"/>
        <el-form-item class="center-content">
          <el-button type="success" @click="resendCode()" :loading="loading">Отправить код</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
        :append-to-body="true"
        v-model="codeConfirmVisible"
        title="Подтверждение авторизации"
        class="modal-95-50-30"
    >

      <p>На ваш номер телефона было отправлено сообщение с кодом доступа</p>
      <el-form label-position="top" ref="codeForm" :rules="codeFormRules" :model="form">
        <el-form-item label="" style="margin-bottom: 20px" prop="authCode">
          <el-input v-model="form.authCode" placeholder="Введите код из СМС"/>
        </el-form-item>
        <CaptchaInput v-model="form.captcha" ctype="login" ref="captchaLoginCode"/>
      </el-form>
      <el-alert title="Неверный проверочный код" type="error" effect="light" style="margin-top: 20px"
                v-show="badCodeAlert"/>

      <template #footer>
        <span class="dialog-footer" style="display: flex; justify-content: space-between; align-items: center">

          <el-button text class="text-button table-text-button" @click="showResendCodeForm()"
                     :disabled="resendCodeTimeout > 0"> Отправить код повторно <span
              v-show="resendCodeTimeout">({{ resendCodeTimeout }} с.)</span></el-button>
          <el-button type="success" @click="login(true)" :loading="loading" :disabled="!formComplete">Подтвердить</el-button>
        </span>
      </template>
    </el-dialog>
    <el-row justify="center">
      <el-col :lg="7" :xl="5" :md="10" :sm="12" :xs="20">
        <el-card style="min-width: 300px; max-width:400px; text-align: center">
        <el-alert v-show="$route.query.successReg" title="Данные доступа были высланы на вашу электронную почту"
                  type="success" style="margin-bottom: 15px; text-align: left"/>
        <el-alert v-show="$route.query.successReset" title="Пароль успешно обновлен" type="success"  style="margin-bottom: 15px; text-align: left"/>
        <h3 class="uppercase align-center no-margin-top">Авторизация</h3>
        <el-form ref="form" :model="form" label-position="top" class="align-center">
          <el-form-item label="Логин">
            <el-input v-model="form.systemId" data-test="login" name="login"></el-input>
          </el-form-item>
          <el-form-item label="Пароль">
            <el-input v-model="form.password" type="password" data-test="password" name="password"></el-input>
          </el-form-item>
          <CaptchaInput v-model="form.captcha" ctype="login" ref="captchaLogin"/>

          <el-form-item>
            <el-button type="success" @click="login(false)" class="uppercase full-width" size="medium"
                       :loading="loading" :disabled="!formComplete">Войти
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="default" @click="$router.push({path: '/registration'})" class="uppercase full-width"
                       size="medium" data-test="regButton">
              Зарегистрироваться
            </el-button>
          </el-form-item>
        </el-form>
        <el-button text @click="$router.push({path : '/resetPassword'})" data-test="passResetButton">Забыли пароль?
        </el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import CaptchaInput from "@shared/components/captcha-input.component.vue";

export default {
  name: "Login",
  components: {CaptchaInput},
  title: "Авторизация",
  beforeMount() {
    if (this.$route.query.token) {
      this.$store.dispatch("logout", {allDevices: false, callback: () => {}})
      this.$store.commit("auth_success", {token: this.$route.query.token})
      this.$store.dispatch("setAuthToken")
      this.$store.dispatch("refreshUser").then(() => {
        this.$store.dispatch("connectWs")
        this.$router.push({path: "/cab/messages/new_send"})
      }).catch(console.error)
    }
  },
  computed: {
    formComplete(){
      return this.form.systemId && this.form.password && this.form.captcha && (this.form.authCode || !this.codeConfirmVisible)
    }
  },
  methods: {
    login(validateCodeForm, isCodeResent = false) {
      let commit = () => {
        this.loading = true
        let returnPath = this.$route.query.returnPath
        this.$store.dispatch('login', this.form)
            .then(() => this.$router.push(returnPath || '/cab/messages'))
            .catch(e => {
              this.loading = false
              this.$refs.captchaLogin.getNewCaptcha()

              try {
                if (this.codeConfirmVisible) {
                  if (this.codeResendVisible) {
                    if (e.response.data.error === "wrongCaptcha")
                      this.$refs.captchaResendLoginCode.getNewCaptcha()
                    else {
                      this.codeResendVisible = false
                      this.$refs.captchaLoginCode.getNewCaptcha()
                      this.form.captcha = ""
                    }
                  } else
                    this.$refs.captchaLoginCode.getNewCaptcha()
                } else
                  this.$refs.captchaLogin.getNewCaptcha()
              } catch (e) {
                console.log("INV")
              }


              let message = "Неверный логин или пароль"
              if (e.response.data.error === "bad_code") {
                this.badCodeAlert = true
              } else if (e.response.data.error.startsWith("awaiting_code")) {
                this.form.captcha = ""
                if (!e.response.data.error === "awaiting_code_active" || this.resendCodeTimeout <= 0) {
                  clearInterval(this.resendCodeTimer)

                  this.resendCodeTimeout = 60
                  this.resendCodeTimer = setInterval(() => {
                    if (this.resendCodeTimeout <= 0) clearInterval(this.resendCodeTimer)
                    else this.resendCodeTimeout--
                  }, 1000)
                }
                this.codeConfirmVisible = true

              } else {
                if (e.response.data.error === "cabinet_auth_denied") message = "Пользователю запрещена авторизация в кабинет"
                if (e.response.data.error === "bad_ip") message = "Вход в систему с Вашего IP-адреса запрещен"
                if (e.response.data.error === "bad_country") message = "Вход в систему для Вашей страны запрещен"
                if (e.response.data.error === "user_blocked") message = "Пользователь заблокирован"
                if (e.response.data.error === "wrongCaptcha") {
                  message = "Неправильный код с картинки"
                  // this.form.captcha = ""
                }
                this.$gNotify(message, "error")
              }

            })
      }
      if (validateCodeForm) {
        console.log("Validating")
        this.$refs.codeForm.validate().then(resp => {
          if (resp) commit()
        }).catch(console.log)
      } else commit()


    },
    resendCode() {
      this.form.authCode = ""
      this.login(false, true)
    },
    async showResendCodeForm() {
      this.form.captcha = ""
      if (this.$refs.captchaResendLoginCode)
        this.$refs.captchaResendLoginCode.getNewCaptcha()
      this.codeResendVisible = true
    }
  },
  beforeUnmount() {
    clearInterval(this.resendCodeTimer)
  },
  data() {
    return {
      form: {
        authCode: "",
        systemId: "",
        password: "",
        captcha: "",
      },
      loading: false,
      resendCodeTimeout: 0,
      resendCodeTimer: null,
      codeConfirmVisible: false,
      codeResendVisible: false,
      badCodeAlert: false,
      codeFormRules: {
        authCode: [{required: true, message: "Введите код", trigger: 'change'}]
      }
    }
  },
}

</script>