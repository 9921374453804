<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="Прогресс рассылки"/>
    <el-row>
      <el-col :span="24">
        <el-card>
          <!--          <template #header>-->
          <!--            <div class="card-header">-->
          <!--              <span>Прогресс рассылки</span>-->
          <!--            </div>-->
          <!--          </template>-->
          <TableSpinner :loading="!initialRequestDone">
            <h4 class="report-parameters-title">Всего в рассылке - <span class="text-bold">{{ totalInDelivery }}</span>&nbsp;сообщений
            </h4>

            <MsgProgressBar
                label="В очереди на обработку"
                :global-total-messages="totalInDelivery"
                :display-messages="enqueued"
                color="blue"
            />
            <el-row>
              <el-col :span="4" style="text-align: right;padding-right: 20px">
                <h4 class="block-title" >Статистика
                  отправки</h4>
              </el-col>
            </el-row>

            <MsgProgressBar
                label="Передано в транспорт"
                :global-total-messages="totalInDelivery"
                :display-messages="readyForSending"
                color="green"
            />
            <MsgProgressBar
                label="Отправлено"
                :global-total-messages="totalInDelivery"
                :display-messages="sent"
                color="green"
            />
            <el-row>
              <el-col :span="4" style="text-align: right;padding-right: 20px">
                <h4 class="block-title">Статусы доставки</h4>
              </el-col>
            </el-row>

            <MsgProgressBar
                label="Доставлено"
                :global-total-messages="totalInDelivery"
                :display-messages="delivered"
                color="blue"
            />
            <MsgProgressBar
                label="Не доставлено"
                :global-total-messages="totalInDelivery"
                :display-messages="deliveryError"
                color="red"
            />
            <el-row>
              <el-col :span="4" style="text-align: right;padding-right: 20px">
                <h4 class="block-title">Ошибки</h4>
              </el-col>
            </el-row>

            <MsgProgressBar
                label="Валидация"
                :global-total-messages="totalInDelivery"
                :display-messages="validationError"
                color="red"
            />
            <MsgProgressBar
                label="Маршрутизация"
                :global-total-messages="totalInDelivery"
                :display-messages="routingError"
                color="red"
            />
            <MsgProgressBar
                label="Тарификация"
                :global-total-messages="totalInDelivery"
                :display-messages="ratingError"
                color="red"
            />
            <MsgProgressBar
                label="Отправка"
                :global-total-messages="totalInDelivery"
                :display-messages="sendingError"
                color="red"
            />
          </TableSpinner>
        </el-card>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import MsgProgressBar from "@/components/new_send/messages-progress-bar.component"



export default {
  name: "SendProgress",
  title: "Статистика отправки",
  components: {

    MsgProgressBar,
    },
  mounted() {
    this.refresh()
    this.__updateInterval = setInterval(() => {
      this.refresh()
    }, 2000)
  },
  beforeUnmount: function () {
    clearInterval(this.__updateInterval)
  },
  methods: {
    refresh() {
      if (this.requestOngoing) return
      this.requestOngoing = true
      this.axios.get("/sms/deliveryStatistics", {params: {deliveryId: this.$route.params.task_id}})
          .then(resp => {
            if (!this.initialRequestDone) this.initialRequestDone = true
            this.totalInDelivery = resp.data.total
            this.enqueued = resp.data.enqueued
            this.readyForSending = resp.data.readyForSending
            this.sent = resp.data.sent
            this.delivered = resp.data.delivered
            this.deliveryError = resp.data.deliveryError
            this.validationError = resp.data.validationError
            this.routingError = resp.data.routingError
            this.ratingError = resp.data.ratingError
            this.sendingError = resp.data.sendingError
            this.requestOngoing = false

          })
          .catch(resp => {
            this.requestOngoing = false
          })
    }
  },
  data() {
    return {
      initialRequestDone: false,
      requestOngoing: false,
      totalInDelivery: 0,
      enqueued: 0,
      readyForSending: 0,
      sent: 0,
      delivered: 0,
      deliveryError: 0,

      validationError: 0,
      routingError: 0,
      ratingError: 0,
      sendingError: 0,
      __updateInterval: null
    }
  },
}

</script>

<style>
  .block-title{
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 14px
  }
</style>