<template>
  <div
      style="padding: 15px; background: rgba(200, 200, 200, .1); border-radius: 5px; margin-bottom:15px; position: relative">
    <font-awesome-icon icon="times"
                       style="color: red; font-size: 16px; cursor: pointer; position: absolute; top: 5px; right: 5px"
                       @click="$emit('remove')" v-if="showRemove"/>
    <!--    v-show="['oneFile', 'album'].includes(modelValue.type)"-->

    <!--    v-show="['text', 'oneFile'].includes(modelValue.type)"-->
    <el-row style="margin-bottom: 10px">
      <el-col :xs="24">
        <el-form-item label="Текст сообщения" style="margin-bottom: 0px;" class="message-text-input-form-item">
          <div style="display: flex; border: 1px solid #DDD;border-top-left-radius: 4px; border-top-right-radius: 4px;">
            <el-input v-model="modelValue.text" ref="originalTextInput" type="textarea" :rows="4"
                      class="message_text_area"/>
          </div>

          <div style="border: none;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #edf2f7;
          line-height: normal;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          justify-content: center;">
            <span style="margin: 10px 0px; color: #727272; font-size: 12px;">Введено {{ amountOfSymbols }} симв. Макс: 4096 символов</span>
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <!--    v-show="['text', 'oneFile'].includes(modelValue.type)"-->
    <el-row style="margin-bottom: 20px">
      <el-col :xs="24">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <el-upload
              v-model:file-list="uploadedFiles"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleFileUpload"
              :on-success="addUploadThumbnail"
              v-if="!modelValue.files.length"
          >
            <el-button text class="text-button table-text-button">
              <font-awesome-icon icon="paperclip"></font-awesome-icon>&nbsp;Файл
            </el-button>

          </el-upload>
          <span v-for="file in modelValue.files" v-else>
              <font-awesome-icon icon="trash" style="color: var(--el-color-danger); cursor: pointer" @click="removeAttachment(file)"/>&nbsp;&nbsp;{{file.name}}
            </span>

          <el-dropdown trigger="click" style="cursor:pointer;" :hide-on-click="false" ref="variablesDropdown">
              <span class="el-dropdown-link">
                <font-awesome-icon icon="wrench" class="el-icon--left"></font-awesome-icon>Переменные
              </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="injectVariable('$$contact_name$$')">Имя</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(v)" v-for="v in bot.variables || []">{{ v }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <!--    v-show="modelValue.type === 'text'"-->
    <el-divider class="gray-divider">
      Кнопки &nbsp;
      <el-button text class="table-text-button" @click="addButton()">
        <font-awesome-icon icon="plus"/>&nbsp;Добавить
      </el-button>
    </el-divider>
    <el-row>
      <el-col :span="24">
        <!--        <span class="el-form-item__label"><u>Кнопки</u></span><br/>-->
        <el-form label-position="top" size="small">
          <div style="display: flex; flex-direction: row; gap:10px" v-for="(button, bi) in modelValue.buttons">
            <div style="flex: 0 0 45%">
              <el-form-item label="Текст" class="full-width">
                <el-input v-model="modelValue.buttons[bi].text"></el-input>
              </el-form-item>
            </div>
            <div style="flex: 0 0 45%">
              <el-form-item label="URL" error="Некорректный URL" :show-message="!validateUrl(modelValue.buttons[bi].url)">
                <el-input v-model="modelValue.buttons[bi].url"></el-input>
              </el-form-item>
            </div>
            <div style="flex: 1 1 auto">
              <el-form-item label=" ">
                <el-button @click="removeButton(button)" type="danger" style="height: 32px;">
                  <font-awesome-icon icon="trash" style="color: white"/>
                </el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>

export default {
  name: "TelegramSingleMessageForm",
  props: ["modelValue", "showRemove", "bot", "isEditMode"],
  emits: ['update:modelValue'],
  watch: {
    "modelValue.text": function () {
      this.$emit('change')
    },
    "modelValue.buttons": function () {
      this.$emit('change')
    },
    "modelValue.files": function () {
      this.$emit('change')
    }
  },
  mounted() {
  },
  computed: {
    amountOfSymbols() {
      return this.replaceCarriage(this.modelValue.text).length
    }
  },
  methods: {
    injectVariable(v) {
      let cursorPosition = this.$refs.originalTextInput.textarea.selectionStart
      this.modelValue.text = this.modelValue.text.slice(0, cursorPosition) + `{{${v}}}` + this.modelValue.text.slice(cursorPosition)
    },
    substitutionCheckTrigger() {
      this.$emit('substitutionCheck')
      this.$refs.variablesDropdown.handleClose()
    },
    replaceCarriage(val) {
      return val.replace(/(?:\r\n|\r|\n)/g, '\n')
    },
    addButton() {
      this.modelValue.buttons.push({
        text: "",
        url: "",
        id: String(Math.random())
      })

    },
    removeButton(button) {
      this.modelValue.buttons.splice(this.modelValue.buttons.findIndex(b => b.id === button.id), 1);
    },

    async handleFileUpload(file, list) {
      console.log(file)
      this.uploadedFiles = [file]
      this.modelValue.files = [
        {name: file.name, mime: file.raw.type, baseString: await this.$fileToBase64(file.raw)}
      ]
    },
    addUploadThumbnail(arg, file, list) {
      console.log(arg)
      let index = list.findIndex(f => f.uid === file.uid)
      list[index].__uploaded_uid = arg
    },
    removeAttachment(file) {
      let index = this.modelValue.files.findIndex(f => f.uid === file.uid)
      this.modelValue.files.splice(index, 1)
    }
  },
  data() {
    return {
      uploadedFiles: []
    }
  },
}

</script>