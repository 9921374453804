<template>
  <div>
    <Breadcrumbs primary="Личный кабинет" secondary="Пользователи"/>
    <account-users />
  </div>
</template>

<script>

import AccountUsers from "@shared/components/lk/account-users.component.vue";

export default {
  name: "Subusers",
  title: "Пользователи",
  components: {
    AccountUsers

  },

  data() {
    return {

    }
  }
}
</script>