<template>
  <DefaultNode @removeMe="events.removeMe(id)">
    <template #header>
      <span>{{data.type === 'setVariable' ? 'Записать переменную' : 'Удалить переменную'}}</span>
      <Handle type="target" position="left"/>

    </template>
    <template #body>
      <span>Имя: {{data.settings.name}}</span>
      <span v-if="data.type === 'setVariable'">Значение: {{data.settings.value}}</span>
      <Handle type="source" position="right" />
    </template>
  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";

export default {
  name: "SetRemoveVariableNode",
  components: {DefaultNode, Handle},
  props: ["events", "id", "data"],
  watch: {},
  mounted(){},
  methods: {},
  computed: {},
  data() {
    return {}
  }
}

export let settingsModel = () => {
  return {
    name: "",
    value: "",
  }
}
</script>