<template>
  <div>
    <form style="display: none" method="post" id="exportForm" :action="`${axios.defaults.baseURL}/reports/downloadAuthReport`">
      <input name="params" type="hidden" id="exportParams"/>
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>
    <Breadcrumbs primary="Системные отчеты" secondary="Авторизации"/>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card no-top-body-padding no-border">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>Отчет по авторизациям</span>-->
<!--            </div>-->
<!--          </template>-->
          <el-row >
            <el-col :md="12" :xs="24">
              <TimeSearch
                  :full-width-select="true"
                  ref="timeSearch"
                  :short-date="true"
                  :month="true"
                  :free-date-time="true"
              />
              <el-button type="success" plain @click="getReport()" :loading="reportLoading">Применить</el-button>
            </el-col>
          </el-row>
          <div v-show="reportReady" style="margin-top: 50px">
              <p style="margin-bottom: 3px"> Отчетный период c <b>{{ fromDate }}</b> по <b>{{ toDate }}</b></p>
              <p style="margin-top: 0px; margin-bottom: 60px"> За указанный период произведено авторизаций: <b>{{totalAuths}}</b></p>

              <h4 >Типы авторизации</h4>
              <el-table :data="statData"  style="width: 100%; margin-bottom: 60px" >
                <el-table-column prop="type" label="Тип" />
                <el-table-column prop="count" label="Абсолютно" min-width="120" />
                <el-table-column prop="relative" label="Относительно" :formatter="row => row.relative + ' %'" min-width="150"/>
              </el-table>
              <h4 >Все авторизации</h4>
              <TableSpinner :loading="dataLoading">
                <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom : 20px">
                <el-button text class="text-button" @click="exportReportData"  style="padding: 0px; min-height: auto;height: auto !important">
                  <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать CSV файл
                </el-button>

                </div>

              <el-table :data="allAuths" style="width: 100%" >
                <el-table-column prop="user_id" label="Пользователь" min-width="120" />
                <el-table-column :formatter="userFio" label="Имя" min-width="180" />
                <el-table-column prop="type" label="Тип" />
                <el-table-column prop="ip" label="IP-адрес"  min-width="180" />
                <el-table-column :formatter="authStatus" min-width="120" label="Статус" />
                <el-table-column label="Данные" min-width="150" >
                  <template #default="scope">
                    {{scope.row.browser}}<br/> {{scope.row.os}}
                  </template>
                </el-table-column>
                <el-table-column :formatter="formatDate" fixed="right" label="Дата"  min-width="100" />
              </el-table>
                <div class="solo-pagination-container">
                <el-pagination
                    background
                    class="wide-page-size"
                    layout="sizes, prev, pager, next"
                    :total="totalAuths"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :hide-on-single-page="true"
                    :pager-count="3"
                    @size-change="handlePageSizeChange"
                    @current-change="handleCurrentPageChange"
                >
                </el-pagination>
                </div>
              </TableSpinner>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>



import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"

export default {
  name: "Authorizations",
  title: "Авторизации",
  components: {


    TimeSearch
  },
  methods: {
    formatDate(row){
      return moment(row.date).utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss")
    },
    userFio(row){
      let subuser = this.subusers.find(s => s.system_id === row.user_id)
      if(!subuser) return ""
      return `${subuser.info.lastname} ${subuser.info.firstname} ${subuser.info.patronymic}`
    },
    authStatus(row){
      return String(row.result) === '1' ? 'Успешно' : 'Ошибка'
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.getReport(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.getReport(false)
    },
    __getReportParams(){
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      return {
        date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
        date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        pageNumber: this.currentPage,
        pageSize: this.pageSize
      }
    },
    getReport(doCount=true) {
      if (doCount) {
        this.reportLoading = true
        this.reportReady = false
      }else{
        this.dataLoading = true
      }
      let params = this.__getReportParams()
      this.fromDate = params.date_from
      this.toDate = params.date_to
      let p1 = this.axios.get("/reports/authReport", {params})
          .then(resp => {
            this.allAuths = resp.data.result.allAuths
          })

      params.pageNumber = -1
      params.pageSize = -1
      let toWait = [p1]
      if(doCount) {
        let p2 = this.axios.get("/reports/authReport", {params})
            .then(resp => {
              this.statData = resp.data.result.stat
            })
        let p3 = this.axios.get("/user/getMySubusers")
            .then(resp => {
              this.subusers = resp.data
            })
        toWait.push(...[p2, p3])
      }

      Promise.all(toWait).then(() => {
        this.reportReady = true
        this.reportLoading = false
      })
      if(doCount){
        params.justCount = true
        params.stat = false
        let p4 = this.axios.get("/reports/authReport", {params})
            .then(resp => {
              this.totalAuths = resp.data.result
            })
        toWait.push(p4)
      }
      Promise.all(toWait).then(() => this.dataLoading = false)
    },
    exportReportData(){
      document.getElementById("exportParams").value = JSON.stringify(this.__getReportParams())
      document.getElementById("exportForm").submit()
    }
  },
  computed: {},
  data() {
    return {
      fromDate: "",
      toDate: "",
      reportReady: false,
      reportLoading: false,
      dataLoading: false,
      statData: [],
      allAuths: [],
      subusers: [],
      totalAuths: 0,
      currentPage: 1,
      pageSize: 10
    }
  },
}

</script>