let {createStore} = require('vuex')
let axios = require('../shared/axios')
let UserPrototype = require('../shared/user')
let WsManager = require("../shared/ws")

const TOKEN_NAME = '__gl_auth_token__'

let STORAGE = window.opener && window !== window.opener ? sessionStorage : localStorage

module.exports = createStore({
  state: {
    status: '',
    token: STORAGE.getItem(TOKEN_NAME) || '',
    user: {},//JSON.parse(STORAGE.getItem("__sg_user") || "{}") || {},
    manager: {},
    balance: {balance: 0, overdraft: 0},
    countries: [],
    operatorsGroups: [],
    tasks: [],
    refreshTimer: null,
    menuOpened: false,
    offertLoaded: false,
    isSurrogateLogin: window.opener && window !== window.opener,
    defaultSenderNamesPrices: {mts: 0, megafon: 0, beeline: 0, tele2: 0}
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      STORAGE.setItem(TOKEN_NAME, payload.token)
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user.clear()
      state.manager = {}
      state.balance = {}
      state.tasks = []
      state.menuOpened = false
    },
    setCountries(state, payload) {
      state.countries = payload.countries
    },
    setOperatorsGroups(state, payload) {
      state.operatorsGroups = payload.operatorsGroups
        .filter(g => g.title.startsWith("РФ"))
        .sort((g1, g2) => g1.title.localeCompare(g2.title, "ru"))
        .concat(
          payload.operatorsGroups.filter(g => !g.title.startsWith("РФ")).sort((g1, g2) => g1.title.localeCompare(g2.title))
        )
    },
    setBalance(state, payload) {
      state.balance = payload
    },
    setUser(state) {
      state.user = new UserPrototype(
        JSON.parse(STORAGE.getItem("__sg_user") || "{}") || {},
        JSON.parse(STORAGE.getItem("__sg_user_privileges") || "[]") || {}
      )
      state.manager = JSON.parse(STORAGE.getItem("__sg_manager") || "{}") || {}

    },
    setAcceptedOffer(state, payload) {
      state.user.offerAccepted = payload.accepted
      state.offertLoaded = true
    },
    setTasks(state, payload) {
      state.tasks = (payload || []).sort((s1, s2) => new Date(s2.date).valueOf() - new Date(s1.date).valueOf())
    },
    setDefaultSenderNamesPrices(state, payload){
      state.defaultSenderNamesPrices = payload
    },
    changeMenuState(state) {
      state.menuOpened = !state.menuOpened
    }
  },
  actions: {
    loadCountries({commit}) {
      if (!this.getters.token) return
      axios.get("/resources/countries")
        .then(resp => {
          commit('setCountries', {countries: resp.data.sort((c1, c2) => c1.country_name.localeCompare(c2.country_name))})
        })
    },
    loadOperatorsGroups({commit}) {
      if (!this.getters.token) return
      axios.get("/resources/operGroups")
        .then(resp => {
          commit('setOperatorsGroups', {operatorsGroups: resp.data})
        })
        .catch(console.error)

    },
    loadUserBalance({commit}, callback) {
      if (!this.getters.token) return
      axios.get("/user/getMyBalance")
        .then(resp => {
          commit("setBalance", resp.data.balance)
        })
        .catch(console.error)
    },
    setUserBalance({commit}, balance){
      commit("setBalance", balance)
    },
    loadAcceptedOffer({commit}) {
      if (!this.getters.token) return
      axios.get("/documents/getAcceptedOffer")
        .then(resp => {
          commit("setAcceptedOffer", resp.data)
        })
    },
    setAuthToken() {
      console.log("Setting auth token")
      axios.defaults.headers.common['Authorization'] = this.getters.token
    },
    setUser({commit}) {
      commit("setUser")
      this.dispatch('loadAcceptedOffer')
    },

    __afterLoginRefreshAction({commit}, resp) {
      const token = resp.data.token
      const user = resp.data.user
      const manager = resp.data.manager

      commit('auth_success', {token})
      STORAGE.setItem("__sg_user", JSON.stringify(user))
      STORAGE.setItem("__sg_manager", JSON.stringify(manager))
      STORAGE.setItem("__sg_user_privileges", JSON.stringify(resp.data.userPrivileges || []))
      this.dispatch("__loadResources")
    },
    __loadResources({commit}) {
      this.dispatch('setAuthToken')
      this.dispatch('setUser')
      this.dispatch('loadUserBalance')
      this.dispatch('loadCountries')
      this.dispatch('loadOperatorsGroups')
      this.dispatch('loadTasks')
      this.dispatch('loadDefaultSenderNamesPrices')
    },
    connectWs({commit}, eventBus){
      WsManager.linkStore(this)
      if (eventBus) WsManager.linkEventBus(eventBus)
      WsManager.connect()
    },
    refreshUser({commit}) {
      return new Promise((res, rej) => {
        axios.get("/auth/refreshUser")
          .then(resp => {
            this.dispatch("__afterLoginRefreshAction", resp)
            res()
          })
          .catch(rej)
      })
    },
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/auth/login', user)
          .then(resp => {
            if (!resp.data.token || !resp.data.user) return reject()
            this.dispatch("__afterLoginRefreshAction", resp)
            this.dispatch("connectWs")
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            STORAGE.removeItem(TOKEN_NAME)
            reject(err)
          })
      })
    },
    logout({commit}, data={}) {
      if(!this.getters.isLoggedIn) return
      let {allDevices, callback} = data || {}
      axios.post("/auth/logout", {allDevices: !!allDevices})
        .then(resp => {
          commit('logout')
          STORAGE.removeItem(TOKEN_NAME)
          STORAGE.removeItem("__sg_user")
          STORAGE.removeItem("__sg_manager")
          STORAGE.removeItem("__sg_user_privileges")
          WsManager.disconnect()
          this.dispatch('setAuthToken')
          if(callback) callback()
        }).catch(console.error)
    },
    loadTasks({commit}) {
      if (!this.getters.token) return
      return new Promise((res, rej) => {
        axios.get("/scheduler/myTasks")
          .then(resp => {
            commit('setTasks', resp.data)
            res(resp.data)
          })
          .catch(rej)
      })
    },
    loadDefaultSenderNamesPrices({commit}){
      if(this.getters.isLoggedIn)
        axios.get("/resources/defaultPricesForNewNames")
          .then(resp => {
            commit('setDefaultSenderNamesPrices', resp.data)
            })
    },
    toggleMenu({commit}) {
      if (window.innerWidth <= 1500)
        commit("changeMenuState")
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    token: state => state.token,
    manager: state => state.manager,
    isManager: state => state.user.system_id.includes('.'),
    bareToken: state => state.token.split(" ")[1],
    authStatus: state => state.status,
    user: state => state.user,
    isShortReg: state => state.user.isShortReg,
    offerAccepted: state => state.user.offerAccepted || false,
    offertLoaded: state => state.offertLoaded,
    registrationFullyFinalized: (state, getters) => !getters.isShortReg && getters.offerAccepted,
    userOwnPhone: state => state.user.info.phone,
    userOwnEmail: state => state.user.info.email,
    countries: state => state.countries,
    operatorsGroups: state => state.operatorsGroups,
    operatorsGroupsSms: state => state.operatorsGroups.filter(g => !g.type || g.type === 'classic'),
    menuOpened: state => state.menuOpened,
    userBalance: state => state.balance.balance || 0,
    userOverdraft: state => state.balance.overdraft || 0,
    allTasks: state => state.tasks.filter(t => !t.rejected && !t.removed),
    activeTasks: state => state.tasks.filter(t => !t.executed && !t.rejected && !t.removed),
    isSurrogateLogin: state => state.isSurrogateLogin,
    defaultSenderNamesPrices: state => state.defaultSenderNamesPrices
  }
})