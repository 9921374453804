<template>
  <DefaultNode @removeMe="events.removeMe(id)">
    <template #header>
      <span>Заблокировать подписчика</span>
      <Handle type="target" position="left"/>
    </template>
  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";

export default {
  name: "BlockSubscriberNode",
  components: {DefaultNode, Handle},
  props: ["events", "id", "data"],
  watch: {},
  mounted(){},
  methods: {},
  computed: {},
  data() {
    return {}
  }
}

export let settingsModel = () => {
  return {
  }
}
</script>