<template>

  <div v-for="(tableRows, rateName, k) in tableData">
    <el-divider style="margin: 40px 0px" v-if="!omitFirstSeparator || k !== 0"/>
    <h4 style="margin-top: 0px">{{ rateName }}</h4>

    <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table"
         style="overflow-x: scroll">
      <div style=" min-width: 1200px; ">
        <div class="el-table__header-wrapper">
          <table class="el-table__header" border="0" cellpadding="0" cellspacing="0" style="width: 100%">
            <thead class="">
            <tr class="">
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1"
                  v-if="unwrapBy.includes('countryName')">
                <div class="cell">Страна</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1"
                  v-if="unwrapBy.includes('type')">
                <div class="cell">Продукт</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1"
                  v-if="unwrapBy.includes('trafficType')">
                <div class="cell">Тип трафика</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1"
                  v-if="unwrapBy.includes('senderName')">
                <div class="cell">Подпись</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1"
                  v-if="unwrapBy.includes('operatorName')">
                <div class="cell">{{ grouping === 1 ? 'Оператор' : 'Группа операторов' }}</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1" v-if="!unwrapBy.length">
                <div class="cell"></div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1">
                <div class="cell">Кол-во сообщений</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1">
                <div class="cell">Цена</div>
              </th>
              <th class="is-leaf el-table__cell" colspan="1" rowspan="1">
                <div class="cell">Расходы</div>
              </th>
            </tr>
            </thead>
          </table>
        </div>
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body custom-table-with-summary" style="width: 100%">
            <tbody>
            <tr class="el-table__row" v-for="row in tableRows">
              <td class=" el-table__cell" v-if="unwrapBy.includes('countryName')">{{ row.countryName }}</td>
              <td class=" el-table__cell" v-if="unwrapBy.includes('type')">{{ row.type }}</td>
              <td class=" el-table__cell" v-if="unwrapBy.includes('trafficType')">{{ row.trafficType }}</td>
              <td class=" el-table__cell" v-if="unwrapBy.includes('senderName')">{{ row.senderName }}</td>
              <td class=" el-table__cell" v-if="unwrapBy.includes('operatorName')">{{ row.operatorName }}</td>
              <td class=" el-table__cell"  v-if="!unwrapBy.length">&nbsp;</td>
              <td class=" el-table__cell">{{ row.sent }}</td>
              <td class=" el-table__cell">{{ formatNumber(row.avgPrice, '.', row.__precision) }}</td>
              <td class=" el-table__cell">{{ formatNumber(row.spent, '.', row.__precision) }}</td>
            </tr>
            <tr class="el-table__row summary-row">
              <td class=" el-table__cell">Итог по тарифу</td>
              <td class=" el-table__cell"  v-if="unwrapBy.length" v-for="i in (unwrapBy.length - 1)"></td>
              <td class=" el-table__cell">{{ reduceSum(tableRows, 'sent') }}</td>
              <td class=" el-table__cell">{{ formatNumber(reduceAvg(tableRows, 'avgPrice'), '.', Math.max(...tableRows.map(r => r.__precision)) || 2) }}</td>
              <td class=" el-table__cell">{{ formatNumber(reduceSum(tableRows, 'spent'), '.', Math.max(...tableRows.map(r => r.__precision)) || 2) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--
    <el-table
        :data="tableRows"
        style="width: 100%"
    >
      <el-table-column prop="countryName" label="Страна" v-if="unwrapBy.includes('countryName')"/>
      <el-table-column label="Продукт" prop="type" min-width="90px" v-if="unwrapBy.includes('type')"/>
      <el-table-column label="Тип трафика" prop="trafficType" min-width="130px"
                       v-if="unwrapBy.includes('trafficType')"/>

      <el-table-column label="Подпись" prop="senderName" min-width="110px" v-if="unwrapBy.includes('senderName')"/>
      <el-table-column label="Оператор" prop="operatorName" min-width="130px"
                       v-if="grouping === 1 && unwrapBy.includes('operatorName')"/>
      <el-table-column label="Группа операторов" prop="operatorName" min-width="130px"
                       v-if="grouping === 0 && unwrapBy.includes('operatorName')"/>
      <el-table-column label="Кол-во сообщений" prop="sent" min-width="110px"/>
      <el-table-column label="Цена" min-width="60px" prop="avgPrice"/>
      <el-table-column label="Расходы" min-width="100px" prop="spent"/>

    </el-table>-->
  </div>

</template>


<script>

export default {
  name: 'FinDetailUserSmsTable',
  props: [
    "tableData",
    "omitFirstSeparator",
    "grouping",
    "unwrapBy"
  ],
  data() {
    return {}
  }
}

</script>
