<template>
  <div style="width: 100%">
    <el-row>
      <el-col :xs="24">
        <el-form-item :label="hideLabel ? '' : 'Текст сообщения'" style="margin-bottom: 0px;"
                      class="message-text-input-form-item">
          <div
              style="display: flex; border: 1px solid #DDD;border-top-left-radius: 4px; border-top-right-radius: 4px;">
            <el-input v-model="originalText" ref="originalTextInput" type="textarea" :rows="10"
                      class="message_text_area">
            </el-input>

          </div>


          <div
              style="border: none;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #edf2f7;
          line-height: normal;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          justify-content: center;">
          <span style="margin: 10px 0px; color: #727272; font-size: 12px;" >
          Введено {{
              amountOfSymbols
            }} симв. Всего СМС - {{ amountOfMessages }} шт. <span v-show="amountOfSymbols > 0">Расчётная длина одного СМС - {{
              oneMessageLength
            }} симв.</span>
        </span>
          </div>
          <div style="border: 1px solid #DDD;border-radius: 4px;margin-top: 10px"
               v-show="transliterate && originalText.length">
            <el-input v-model="transliteratedText" type="textarea" :rows="3"
                      class="message_text_area regular-input-color"
                      disabled></el-input>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <el-checkbox v-model="transliterate" label="Транслитерация" style="margin-right: 20px"></el-checkbox>
          <el-dropdown trigger="click" style="cursor:pointer;"  :hide-on-click="false" ref="variablesDropdown">
              <span class="el-dropdown-link">
                <font-awesome-icon icon="wrench" class="el-icon--left"></font-awesome-icon>Переменные
              </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="injectVariable(7)">Уважаемый(ая)</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(0)">Название контакта</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(1)">Имя</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(2)">Фамилия</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(3)">Отчество</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(4)">Email</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(5)">Доп. поле 1</el-dropdown-item>
                <el-dropdown-item @click="injectVariable(6)">Доп. поле 2</el-dropdown-item>
                <el-dropdown-item v-if="showCheckSubstitution && this.outputText.length"
                                  style="background: var(--el-color-danger); color: white"
                                  @click="substitutionCheckTrigger">Проверка подстановки
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

const TRANSLIT_ALPHABET = {
  "а": "a",
  "б": "b",
  "в": "v",
  "г": "g",
  "д": "d",
  "е": "e",
  "ё": "jo",
  "ж": "zh",
  "з": "z",
  "и": "i",
  "к": "k",
  "л": "l",
  "м": "m",
  "н": "n",
  "о": "o",
  "п": "p",
  "р": "r",
  "с": "s",
  "т": "t",
  "у": "u",
  "ф": "f",
  "х": "h",
  "ц": "c",
  "ч": "ch",
  "ш": "sh",
  "щ": "sch",
  "э": "e",
  "ю": "ju",
  "я": "ja",
  "ъ": "",
  "ы": "y",
  "ь": "",
  "й": "j",
  "А": "A",
  "Б": "B",
  "В": "V",
  "Г": "G",
  "Д": "D",
  "Е": "E",
  "Ё": "Jo",
  "Ж": "Zh",
  "З": "Z",
  "И": "I",
  "К": "K",
  "Л": "L",
  "М": "M",
  "Н": "N",
  "О": "O",
  "П": "P",
  "Р": "R",
  "С": "S",
  "Т": "T",
  "У": "U",
  "Ф": "F",
  "Х": "H",
  "Ц": "C",
  "Ч": "Ch",
  "Ш": "Sh",
  "Щ": "Sch",
  "Э": "E",
  "Ю": "Ju",
  "Я": "Ja",
  "Ъ": "",
  "Ы": "y",
  "Ь": "",
  "Й": "J"
};

const TEXT_VARIABLES = [
  "{{Назв. контакта}}",
  "{{Имя}}",
  "{{Фамилия}}",
  "{{Отчество}}",
  "{{Е-мейл}}",
  "{{Знач.1}}",
  "{{Знач.2}}",
  "{{Уважаемый(ая)}}"
]

export default {
  name: "MessageTextInput",
  props: [
    "primary",
    "secondary",
    "hideLabel",
    "defaultValue",
    "showCheckSubstitution"
  ],
  watch: {
    originalText(val) {
      // val = val.replace(/[ъь]+/gi, '').replace(/й/gi, 'i');
      this.transliteratedText = this.replaceCarriage(val).split("").reduce((acc, l) => acc + (TRANSLIT_ALPHABET.hasOwnProperty(l) ? TRANSLIT_ALPHABET[l] : l), "")
      this.$emit('change')
    }
  },
  mounted() {
    this.originalText = String(this.defaultValue || "")
  },
  computed: {
    outputText() {
      return this.transliterate ? this.transliteratedText : this.originalText
    },
    amountOfSymbols() {
      return this.replaceCarriage(this.outputText).length + (this.outputText.match(/[\^\{\}\\\[\~\]\|\€]/g) || []).length
    },
    amountOfMessages() {
      let numMessages = this.amountOfSymbols ? 1 : 0
      let textHasLatin = this.textHasLatin(this.outputText)
      if (this.amountOfSymbols > 160 && textHasLatin)
        numMessages = Math.ceil(this.amountOfSymbols / 153)
      if (this.amountOfSymbols > 70 && !textHasLatin)
        numMessages = Math.ceil(this.amountOfSymbols / 67)
      return numMessages
    },
    oneMessageLength() {
      let lng = 70
      let textHasLatin = this.textHasLatin(this.outputText)
      if (textHasLatin || !this.outputText.length) lng = 160
      if (this.amountOfSymbols > 160 && textHasLatin) lng = 153
      if (this.amountOfSymbols > 70 && !textHasLatin) lng = 67
      return lng
    }
  },
  methods: {
    textHasLatin(txt) {
      return /^[\^{}\\\[~\]|€@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-.\/0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà\d\s]+$/g.test(txt)
    },
    setTextByTemplate(template) {
      this.originalText = template.text
    },
    injectVariable(varIndex) {
      let cursorPosition = this.$refs.originalTextInput.textarea.selectionStart
      this.originalText = this.originalText.slice(0, cursorPosition) + TEXT_VARIABLES[varIndex] + this.originalText.slice(cursorPosition)
    },
    getText() {
      return this.replaceCarriage(this.transliterate ? this.transliteratedText : this.originalText)
    },
    getOriginalText() {
      return this.replaceCarriage(this.originalText)
    },
    isTransliterated() {
      return this.transliterate
    },
    forceTranslit() {
      this.transliterate = true
    },
    reset() {
      this.originalText = ""
      this.transliterate = false
    },
    substitutionCheckTrigger() {
      this.$emit('substitutionCheck')
      this.$refs.variablesDropdown.handleClose()
    },
    replaceCarriage(val) {
      return val.replace(/(?:\r\n|\r|\n)/g, '\n')
    },
    amountOfSymbolz(){
      return this.amountOfSymbols
    }
  },
  data() {
    return {
      originalText: "",
      transliterate: false,
      // showVariables: false,
      transliteratedText: "",
    }
  },
}

</script>

<style>

.message_text_area .el-textarea__inner {
  box-shadow: none !important;
}

.message_text_area textarea,
.message_text_area textarea:hover {
  border: none !important;
  resize: none !important;
}

#variables_choice {
  flex: 1;
  padding: 20px;
  background-color: #edf2f7;
  display: flex;
  flex-flow: column;
}

.message-text-input-form-item .el-form-item__content {
  display: initial !important;
}
</style>