<template>

  <el-dialog
      v-model="intVisible"
      :title="title"
      class="modal-95-70-60"
      :before-close="() => $emit('close')"
  >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="() => $emit('close')">Отмена</el-button>
        <el-button type="success" :loading="isSaving" @click="addEditTemplate">{{ confirmButtonText }}</el-button>
      </span>
    </template>

    <el-form
        :model="templateData"
        label-position="top"
    >
      <el-form-item label="Название шаблона" class="bold-label">
        <el-input v-model="templateData.title"></el-input>
      </el-form-item>

      <el-form-item label="Текст шаблона" class="bold-label">
        <MessageTextInput
            ref="templateTextInput"
            :hide-label="true"
            :default-value="templateData.text"
        />
<!--        <el-input v-model="templateData.text" type="textarea" :rows="10"></el-input>-->
      </el-form-item>
    </el-form>
  </el-dialog>

</template>


<script>

import MessageTextInput from "@/components/new_send/message-text-input.component"

export default {
  name: 'MessageTemplateForm',
  props: ["visible", "editingTemplateId"],
  emits: ["templateSaved", 'close'],
  components: {
    MessageTextInput
  },
  watch: {
    visible(v){
      this.intVisible = v
    }
  },
  computed: {
    isEditing(){
      return !!this.editingTemplateId
    },
    title() {
      return this.isEditing ? "ИЗМЕНИТЬ ШАБЛОН" : "СОЗДАТЬ ШАБЛОН"
    },
    confirmButtonText() {
      return this.isEditing ? "Изменить" : "Добавить"
    }
  },
  methods: {
    clear(){
      this.templateData.title = ""
      this.templateData.text = ""
      this.$refs.templateTextInput.reset()
    },
    addEditTemplate() {
      this.isSaving = true
      let text = this.$refs.templateTextInput.getText()
      this.axios.post("/sms/upsertMessageTemplate", {
        templateId : this.editingTemplateId,
        templateData: {
          title: this.templateData.title,
          text
        }
      })
          .then(resp => {
            this.isSaving = false
            if(resp.data.error === "E_DUPLICATE_NAME")
              return this.$gNotify("Шаблон с таким именем уже существует", "error")

            this.$emit("templateSaved")
            this.$emit("close")
            this.clear()
          })
          .catch(resp => {
            this.isSaving = false
            this.$emit("templateSaved")
            this.$emit("close")
            this.clear()
          })
    },
    preloadEditingTemplate(_id, callback){
      this.axios.get("/sms/getTemplateById", {params: {templateId: _id}})
        .then(resp => {
          this.templateData.title = resp.data.title
          this.templateData.text = resp.data.text
          callback()
          // this.$refs.templateTextInput.setTextByTemplate({text: resp.data.text})
        })

    }
  },
  data(){
    return {
      templateData :{
        title: "",
        text: ""
      },
      isSaving: false,
      intVisible: false
    }
  }
}

</script>

<style>

</style>