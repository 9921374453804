<template>
  <el-card class="" id="manage-algo-header">
    <router-link :to="`/cab/chat_bots/manage_tg_bot?bot_id=${botId}`" style="width: fit-content">
      <el-button  type="primary">Назад</el-button>
    </router-link>
    <div style="margin-left: 20px; flex-grow: 1; display: flex;flex-direction: column">
      <el-input style="margin: 0px; width: 70%" size="small" v-model="editingAlgo.name"/>
      <p style="color: var(--el-text-color-secondary); margin-top: 5px; margin-bottom: 0px; font-size: 14px">
        Последнее обновление:&nbsp;{{ formatDateTime(editingAlgo.lastUpdatedAt) }}
      </p>
    </div>
    <el-button type="success" @click="saveAlgo">Сохранить</el-button>
  </el-card>
<!--  <el-divider class="blue-divider"/>-->
  <new-algo-block :visible="newBlockModalVisible" @close="newBlockModalVisible = false" @blockSelected="addNewBlock"/>
  <node-settings ref="nodeSettings" :bot="bot"/>
  <div id="flow-container">
    <VueFlow
        v-model="visibleFlow.elements"
        :default-viewport="{zoom: 1}" :min-zoom="0.2" :max-zoom="1"
        :node-types="nodeTypes"
    >

      <template #edge-custom="props">
        <CustomEdge v-bind="props" @removeEdge="removeEdge"/>
      </template>
      <template #connection-line="{ sourceX, sourceY, targetX, targetY }">
        <CustomConnectionLine :source-x="sourceX" :source-y="sourceY" :target-x="targetX" :target-y="targetY" />
      </template>

      <template #node-start="{ data, id }">
        <StartNode/>
      </template>
      <template #node-messageChain="{ data, id }">
        <MessageChainNode :data="data" :id="id" @removeMe="removeNode" />
      </template>
      <template #node-contactRequest="{ data, id }">
        <ContactRequestNode :data="data" :id="id" @removeMe="removeNode" />
      </template>

      <template #node-addTag="{ data, id }">
        <AddRemoveTagNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>
      <template #node-removeTag="{ data, id }">
        <AddRemoveTagNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>

      <template #node-setVariable="{ data, id }">
        <SetRemoveVariableNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>
      <template #node-removeVariable="{ data, id }">
        <SetRemoveVariableNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>

      <template #node-blockSubscriber="{ data, id }">
        <BlockSubscriberNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>
      <template #node-removeSubscriber="{ data, id }">
        <RemoveSubscriberNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>

      <template #node-switchCase="{ data, id }">
        <SwitchCaseNode :data="data" :id="id" :bot="bot" @removeMe="removeNode" />
      </template>
      <Background variant="lines" :pattern-color="'#ccc'" :gap="80" :line-width=".2"/>
      <Panel position="top-right" class="controls">
        <el-button type="primary" size="small" @click="newBlockModalVisible = true">
          <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Блок
        </el-button>
      </Panel>
    </VueFlow>
  </div>


</template>

<script>

import {Panel, VueFlow, useVueFlow} from '@vue-flow/core'
import {nodeFactory, nodeTypes} from "../../components/chatbots/telegram/algorithms/nodes"
import NewAlgoBlock from "./../../components/chatbots/telegram/algorithms/new-block.component.vue";
import {Background} from '@vue-flow/background'
import NodeSettings from "../../components/chatbots/telegram/algorithms/node-settings.component.vue";
import MessageChainNode from "../../components/chatbots/telegram/algorithms/nodes/messageChain.node.vue";
import StartNode from "../../components/chatbots/telegram/algorithms/nodes/start.node.vue";
import CustomEdge from "../../components/chatbots/telegram/algorithms/customEdge.edge.vue";
import CustomConnectionLine from "../../components/chatbots/telegram/algorithms/customConnectionLine.edge.vue";
import ContactRequestNode from "../../components/chatbots/telegram/algorithms/nodes/contactRequest.node.vue";
import AddRemoveTagNode from "../../components/chatbots/telegram/algorithms/nodes/addRemoveTag.node.vue";
import SetRemoveVariableNode from "../../components/chatbots/telegram/algorithms/nodes/setRemoveVariable.node.vue";
import BlockSubscriberNode from "../../components/chatbots/telegram/algorithms/nodes/blockSubscriber.node.vue";
import RemoveSubscriberNode from "../../components/chatbots/telegram/algorithms/nodes/removeSubscriber.node.vue";
import SwitchCaseNode from "../../components/chatbots/telegram/algorithms/nodes/switchCase.node.vue";

let FLOW

export default {
  name: "TgBotAlgorithm",
  title: "Редактирование алгоритма",
  components: {
    SwitchCaseNode,
    RemoveSubscriberNode,
    BlockSubscriberNode,
    SetRemoveVariableNode,
    AddRemoveTagNode,
    ContactRequestNode,
    CustomConnectionLine,
    CustomEdge, StartNode, MessageChainNode, NodeSettings, NewAlgoBlock, VueFlow, Panel, Background},
  props: [],
  beforeMount() {
    FLOW = useVueFlow()
    FLOW.onPaneReady((i) => {
      if(!this.algoId)
        this.visibleFlow.elements.push(nodeFactory('start'))
    })
    FLOW.onConnect((params) => {
      params.type = "custom"
      params.markerEnd = "arrow"
      FLOW.addEdges([params])
    })
    FLOW.onNodeClick(({node, connectedEdges}) => {
      if(node.type.startsWith("__")) return
      if(node.noSettings) return

      this.$refs.nodeSettings.open(node)
    })
    // this.loadBot()
  },
  mounted() {
    this.loading = true
    Promise.all([
        this.loadAlgo(),
        this.loadBot()
    ]).then(() => {
      this.loading = false
    })
    this.loadAlgo()
  },
  methods: {
    removeEdge(id){
      FLOW.removeEdges([id])
    },
    removeNode(nodeId) {
      if(!nodeId) return
      console.log("Remove requested", nodeId)
      this.visibleFlow.elements.splice(this.visibleFlow.elements.findIndex(e => e.id === nodeId), 1)
    },
    addNewBlock(nodeDef) {
      this.newBlockModalVisible = false
      let node = nodeFactory(nodeDef.type, nodeDef, this.removeNode)
      this.visibleFlow.elements.push(node)
    },
    loadAlgo() {
      if (this.algoId)
        return this.axios.get("/chatbot/getAlgorithm", {params: {botId: this.botId, algoId: this.algoId}})
            .then(resp => {
              this.editingAlgo.name = resp.data.algo.name
              this.editingAlgo.lastUpdatedAt = resp.data.algo.lastUpdatedAt
              FLOW.addNodes(resp.data.algo.structure.filter(n => n.__type === 'node'))
              FLOW.addEdges(resp.data.algo.structure.filter(n => n.__type === 'edge'))
            }).catch(console.error)
      else {
        this.editingAlgo.name = "Новый алгоритм"
        this.editingAlgo.lastUpdatedAt = new Date()
      }
    },

    saveAlgo(){
      this.savingAlgo = true
      let postData = {
        botId: this.botId,
        _id: this.algoId,
        name: this.editingAlgo.name
      }
      postData.structure = this.visibleFlow.elements.map(e => {
        if(e.source && e.target){ // Edge
          return {
            source: e.source, sourceHandle: e.sourceHandle, target: e.target, targetHandle: e.targetHandle, __type: 'edge'
          }
        }else{ // Node
          return {
            id: e.id, type: e.type, position: e.position, data: e.data, __type: 'node'
          }
        }
      })
      this.axios.post("/chatbot/saveAlgo", postData)
          .then(resp => {
            this.savingAlgo = false
            this.$gNotify("Алгоритм сохранён", "success")
            if(!this.algoId){
              this.$router.replace({path: "/cab/chat_bots/manage_algorithm", query: {bot_id: this.botId, algo_id: resp.data.algoId}})
            }
          })
    },
    loadBot() {
      return this.axios.get("/chatbot/getOne", {params: {botId: this.botId}})
          .then(resp => {
            this.bot = resp.data.bot
          }).catch(console.error)

    },
  },
  computed: {
    botId() {
      return this.$route.query.bot_id
    },
    algoId() {
      return this.$route.query.algo_id
    }
  },
  data() {
    return {
      loading: false,
      bot: {tags: [], variables: []},
      visibleFlow: {
        elements: [],
      },
      newBlockModalVisible: false,
      nodeTypes,
      editingAlgo: {
        name: "",
        lastUpdatedAt: new Date()
      },
      savingAlgo: false
    }
  }
}

</script>

<style>

#flow-container {
  /*border: 1px solid #ccc;*/
  /*border-radius: 4px;*/
  display: flex;
  flex-grow: 1;
  margin-left: -30px;
  margin-right: -30px;
}

#manage-algo-header {
  padding: 0px !important;
  margin-bottom: 30px;
}

#manage-algo-header .el-card__body {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>