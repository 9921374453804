<template>

  <el-row style="margin-bottom: 30px">
    <el-col :span="24">
<!--      <h4>Правила</h4>-->
      <el-row style="margin-bottom: 40px">
        <el-col :xs="5" :sm="2">
          <el-switch v-model="ipEnabled"> </el-switch>
        </el-col>
        <el-col :xs="19" :sm="22">
          <h4 style="margin-top: 0px">Разрешённые IP-адреса</h4>
          <div v-show="ipEnabled">
            <el-tag
                v-for="ip in editingRule.allowedIP"
                :key="ip"
                style="margin: 5px"
                closable
                @close="removeIp(ip)"
                type="default"
            >
              {{ ip }}
            </el-tag>
            <el-input v-model="intermediateIpStore" placeholder="Введите IP адрес" style="margin-top: 10px"  data-test="ipRestrictionInput">
              <template #append>
                <el-button @click="addIp"  data-test="ipRestrictionAdd">Добавить</el-button>
              </template>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="5" :sm="2">
          <el-switch v-model="countryEnabled"> </el-switch>
        </el-col>
        <el-col :xs="19" :sm="22">
          <h4 style="margin-top: 0px">Разрешённые страны</h4>
          <div v-show="countryEnabled">
            <el-select
                v-model="editingRule.allowedCountries"
                style="width: 100%;"
                multiple
                data-test="countryRestrictionSelect"
            >
              <el-option
                  v-for="(country, i) in $store.getters.countries"
                  :key="i"
                  :value="country.country_code"
                  :label="country.country_name"
                  :data-test="`countryRestrictionOption-${country.country_code}`"
              />

            </el-select>
          </div>
        </el-col>
      </el-row>
<!--      <el-collapse v-model="enabledRules" @change="handleRuleListChange" style="margin-top: 40px; margin-bottom: 20px">-->
<!--        <el-collapse-item title="По времени" name="byTime" v-show="false">-->
<!--          <el-row :gutter="60">-->
<!--            <el-col :span="12">-->
<!--              <p>По будням</p>-->
<!--              <el-time-picker-->
<!--                  v-model="editingRule.timeRestriction.week.time"-->
<!--                  is-range-->
<!--                  format="HH:mm"-->
<!--                  style="width: 100%; margin-bottom: 20px"-->
<!--                  range-separator="-"-->
<!--                  start-placeholder="С"-->
<!--                  end-placeholder="По"-->
<!--              />-->
<!--              <el-checkbox-group v-model="editingRule.timeRestriction.week.days">-->
<!--                <el-checkbox label="mon">Понедельник</el-checkbox>-->
<!--                <el-checkbox label="tue">Вторник</el-checkbox>-->
<!--                <el-checkbox label="wed">Среда</el-checkbox>-->
<!--                <el-checkbox label="thu">Четверг</el-checkbox>-->
<!--                <el-checkbox label="fri">Пятница</el-checkbox>-->

<!--              </el-checkbox-group>-->
<!--            </el-col>-->
<!--            <el-col :span="12">-->
<!--              <p>По выходным</p>-->
<!--              <el-time-picker-->
<!--                  v-model="editingRule.timeRestriction.end.time"-->
<!--                  is-range-->
<!--                  format="HH:mm"-->
<!--                  style="width: 100%; margin-bottom: 20px"-->
<!--                  range-separator="-"-->
<!--                  start-placeholder="С"-->
<!--                  end-placeholder="По"-->
<!--              />-->
<!--              <el-checkbox-group v-model="editingRule.timeRestriction.end.days">-->
<!--                <el-checkbox label="sat">Суббота</el-checkbox>-->
<!--                <el-checkbox label="sun">Воскресенье</el-checkbox>-->
<!--              </el-checkbox-group>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="По IP-адресу" name="byIp"  data-test="ipRestriction">-->
<!--          <el-tag-->
<!--              v-for="ip in editingRule.allowedIP"-->
<!--              :key="ip"-->
<!--              style="margin: 5px"-->
<!--              closable-->
<!--              @close="removeIp(ip)"-->
<!--              type=""-->
<!--          >-->
<!--            {{ ip }}-->
<!--          </el-tag>-->
<!--          <el-input v-model="intermediateIpStore" placeholder="Введите IP адрес" style="margin-top: 10px"  data-test="ipRestrictionInput">-->
<!--            <template #append>-->
<!--              <el-button @click="addIp"  data-test="ipRestrictionAdd">Добавить</el-button>-->
<!--            </template>-->
<!--          </el-input>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="По стране" name="byCountry" data-test="countryRestriction">-->
<!--          -->
<!--        </el-collapse-item>-->
<!--      </el-collapse>-->


<!--      <el-form :model="editingRule" ref="ruleForm" style="margin-top: 20px">-->
<!--        <el-form-item label="">-->
<!--          <el-checkbox v-model="editingRule.timeIntervalEnabled" >По времени</el-checkbox>-->
<!--          <div v-show="editingRule.timeIntervalEnabled">-->
<!--            <el-row :gutter="20">-->
<!--              <el-col :span="12">-->
<!--                <p>По будням</p>-->
<!--                <el-time-picker-->
<!--                    v-model="editingRule.timeRestriction.week"-->
<!--                    is-range-->
<!--                    range-separator="-"-->
<!--                    start-placeholder="С"-->
<!--                    end-placeholder="По"-->
<!--                />-->
<!--              </el-col>-->
<!--              <el-col :span="12">-->
<!--                <p>По выходным</p>-->
<!--                <el-time-picker-->
<!--                    v-model="editingRule.timeRestriction.end"-->
<!--                    is-range-->
<!--                    range-separator="-"-->
<!--                    start-placeholder="С"-->
<!--                    end-placeholder="По"-->
<!--                />-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="">-->
<!--          <el-checkbox v-model="editingRule.ipEnabled" >По IP-адресу</el-checkbox>-->
<!--          <div v-show="editingRule.ipEnabled">-->

<!--          <el-tag-->
<!--              v-for="ip in editingRule.allowedIP"-->
<!--              :key="ip"-->
<!--              style="margin: 5px"-->
<!--              closable-->
<!--              @close="removeIp(ip)"-->
<!--              type=""-->
<!--          >-->
<!--            {{ ip }}-->
<!--          </el-tag>-->
<!--            <el-input v-model="intermediateIpStore" placeholder="Введите IP адрес" style="margin-top: 10px">-->
<!--              <template #append>-->
<!--                <el-button @click="addIp">Добавить</el-button>-->
<!--              </template>-->
<!--            </el-input>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="">-->
<!--          <el-checkbox v-model="editingRule.countryEnabled" >По стране</el-checkbox>-->
<!--          <el-select-->
<!--              v-model="editingRule.allowedCountries"-->
<!--              style="width: 100%;"-->
<!--              v-show="editingRule.countryEnabled"-->
<!--              multiple-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="(country, i) in $store.getters.countries"-->
<!--              :key="i"-->
<!--              :value="country.country_code"-->
<!--              :label="country.country_name"-->
<!--            />-->

<!--          </el-select>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <el-divider />-->
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <h4 >Двухфакторная аутентификация (СМС)</h4>
      <el-select
        v-model="editingRule.smsAuth"
        style="width: 100%;"
      >
        <el-option value="never" label="Отключена" />
        <el-option value="always" label="Включена всегда" />
        <el-option value="rule_breach" label="Включена при нарушении правил доступа" />
      </el-select>
    </el-col>
  </el-row>

</template>


<script>

export default {
  name: 'LoginRestrictions',
  props: [],
  methods: {
    addIp(){
      this.editingRule.allowedIP.push(String(this.intermediateIpStore))
      this.intermediateIpStore = ""
    },
    removeIp(ip){
      console.log(ip)
      this.editingRule.allowedIP = this.editingRule.allowedIP.filter(i => i !== ip)
    },
    handleRuleListChange(vals){

    },
    setRules(rule){
      console.log(rule)
      this.enabledRules = rule.enabledRules
      this.ipEnabled = rule.enabledRules.includes('byIp')
      this.countryEnabled = rule.enabledRules.includes('byCountry')
      this.editingRule.timeRestriction.week.days  = rule.time_interval.weekday.days || []
      this.editingRule.timeRestriction.week.time  = [
        rule.time_interval.weekday.from || "",
        rule.time_interval.weekday.to || ""
      ].filter(i => i.trim())
      this.editingRule.timeRestriction.end.days  = rule.time_interval.weekend.days || []
      this.editingRule.timeRestriction.end.time  = [
        rule.time_interval.weekend.from || "",
        rule.time_interval.weekend.to || ""
      ].filter(i => i.trim())
      this.editingRule.allowedIP = rule.allowed_ips
      this.editingRule.allowedCountries = rule.allowed_countries
      this.editingRule.smsAuth = rule.smsAuth
    },
    getRules(){
      return {
        enabledRules: [this.ipEnabled ? 'byIp' : '', this.countryEnabled ? 'byCountry' : ''].filter(i => i),
        rules: this.editingRule
      }
    }
  },
  data(){
    return {
      intermediateIpStore: "",
      // enabledRules: [],
      ipEnabled: false,
      countryEnabled: false,
      editingRule: {
        timeRestriction: {
          week: {
            days: [],
            time: []
          },
          end: {
            days: [],
            time: []
          }
        },
        smsAuth: "never",
        allowedCountries: [],
        allowedIP: []
      }
    }
  }
}

</script>

<style>

.el-collapse{
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black
}
.el-collapse-item{
  border-bottom: 1px solid black;
}

.el-collapse-item__wrap{
  padding-left: 20px;
  padding-right: 20px;
}

.el-collapse-item__header{
  padding: 10px
}
.el-collapse-item__header{
  font-size: 14px !important;
  /*font-weight: bold;*/
}
.el-collapse-item__content{
  padding: 10px;
  /*font-weight: bold;*/
}
.el-collapse-item__header.is-active{
  border-bottom: 1px solid #d8d8d8
}

.el-collapse-item__header,
.el-collapse-item__wrap{
  /*border: none !important;*/
}

</style>