<template>
  <div>

    <el-row>
      <el-col :span="24">
<!--        <h4 style="margin-bottom: 20px;margin-top: 0px" >Новый контакт</h4>-->

        <el-form label-position="top" :rules="rules" :model="contact" ref="newContactForm" :validate-on-rule-change="false">
          <el-row :gutter="30">
            <el-col :md="12" :xs="24">
              <el-form-item label="Имя">
                <el-input v-model="contact.name"/>
              </el-form-item>
              <el-form-item label="Отчество">
                <el-input v-model="contact.patronymic"/>
              </el-form-item>
              <el-form-item label="Пол">
                <el-select v-model="contact.sex" style="width: 100%;">
                  <el-option :value="'2'" label="Не определен"></el-option>
                  <el-option :value="'0'" label="Мужской"></el-option>
                  <el-option :value="'1'" label="Женский"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Дата рождения">
                <el-date-picker v-model="contact._date_of_birth" type="date" format="DD.MM.YYYY" style="width: 100%">
                </el-date-picker>
              </el-form-item>

            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item label="Фамилия">
                <el-input v-model="contact.surname"/>
              </el-form-item>
              <el-form-item label="Название контакта">
                <el-input v-model="contact.show_name"/>
              </el-form-item>
              <el-form-item label="Номер телефона" prop="phone">
                <el-input v-model="contact.phone"/>
              </el-form-item>
              <el-form-item label="Email" prop="email">
                <el-input v-model="contact.email"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Доп. поле 1">
                <el-input type="textarea" v-model="contact.add_field_1"/>
              </el-form-item>
              <el-form-item label="Доп. поле 2">
                <el-input type="textarea" v-model="contact.add_field_2"/>
              </el-form-item>
              <el-form-item label="Добавить в группы" prop="groups">
                <el-select v-model="contact.groups" multiple style="width: 100%">
                  <el-option
                      v-for="item in allGroups"
                      :key="item._id"
                      :label="item.name"
                      :value="item._id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                    <span
                        style="
          float: right;
          color: var(--el-text-color-secondary);
          font-size: 13px;
        "
                    >{{ item.size }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="success" @click="addContact()" style="margin-top: 0px">Добавить</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
<!--      <el-col :span="8">-->
<!--        <h3 style="margin-bottom: 50px">Добавить в группы</h3>-->

        <!--        <TableSpinner :loading="groupsLoading">-->
        <!--          <el-checkbox-group-->
        <!--              v-model="selectedGroups"-->
        <!--              style="display: flex; flex-flow: column"-->
        <!--          >-->

        <!--            <el-checkbox-->
        <!--                v-for="(group, i) in allGroups"-->
        <!--                :key="'group_'+i"-->
        <!--                :label="group._id"-->
        <!--                style="height: 30px"-->
        <!--            >{{ group.name }} ({{ group.size }})-->
        <!--            </el-checkbox>-->

        <!--          </el-checkbox-group>-->
        <!--        </TableSpinner>-->
<!--      </el-col>-->
    </el-row>

  </div>
</template>

<script>

import moment from "moment"



export default {
  name: "NewContact",
  props: ['allGroups', 'groupsLoading'],
  mounted() {
    // this.loadGroups()
  },
  methods: {
    addContact() {
      let sendData = this.contact
      sendData.date_of_birth = ""
      if (this.contact._date_of_birth)
        sendData.date_of_birth = moment(this.contact._date_of_birth).format("DD.MM.YYYY")
      // console.log(this.$refs.newContactForm.validate())

      this.$refs.newContactForm.validate()
          .then(isValid => {
            if (isValid)
              this.axios.post("/contacts/addContact", {contact: sendData, bases: sendData.groups})
                  .then(() => {
                    // this.loadGroups()
                    this.$emit("reloadGroups")
                    // Object.assign(this.$data, initialState())
                    this.$refs.newContactForm.resetFields()
                    return this.$gNotify("Новый контакт добавлен", "success")
                  })
          }).catch(console.error)

    },
    // loadGroups() {
    //   this.groupsLoading = true
    //   this.axios.get("/contacts/myGroups")
    //       .then(resp => {
    //         this.allGroups = resp.data
    //         this.groupsLoading = false
    //       })
    // }
  },
  computed: {},

  data() {
    return initialState()
  },
}

function initialState(){
  return {
    // selectedGroups: [],
    // allGroups: [],
    // groupsLoading: false,
    contact: {
      name: "",
      surname: "",
      patronymic: "",
      show_name: "",
      phone: "",
      email: "",
      _date_of_birth: "",
      sex: "2",
      add_field_1: "",
      add_field_2: "",
      groups: []
    },
    rules: {
      phone: [{
        required: true,
        message: "Неверный номер телефона",
        trigger: 'change',
        validator: (_, value) => validatePhone(patchPhone(value))
      }],
      email: [{required: false, message: "Неверный E-mail адрес", trigger: 'change', type: 'email'}],
      groups: [{required: true, message: "Выберите группы", trigger: 'change'}]
    }
  }
}

</script>

<style>


</style>