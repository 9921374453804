<template>
  <div>
    <Breadcrumbs primary="Финансы" secondary="Операции"/>


    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card no-border no-top-body-padding">

          <!--          <h4 class="report-parameters-title">Подтверждение оплаты</h4>-->

          <el-row>
            <el-col :span="24" class="align-center">
              <div class="spinner-loading-container">
                <div class="spinner-loading" v-show="status === 'loading'"></div>
                <div v-show="status === 'accepted'" class="align-center">
                  <h4 style="color: var(--el-color-success-dark-2)">Оплата прошла успешно!</h4>
                </div>
                <div v-show="status === 'pending'" class="align-center">
                  <h4>Обработка платежа занимает чуть дольше чем ожидалось.</h4>
                  <p>Немного подождите, а затем обновите страницу.</p>
                </div>
                <div v-show="status === 'fail'" class="align-center">
                  <h4 style="color: var(--el-color-danger-dark-2)">Ошибка платежа!</h4>
                  <p>Обратитесь в техподдержку. <span v-show="billNumber !== ''">Номер платежа: {{billNumber}}</span></p>
                </div>
              </div>
              <router-link to="/cab/finance/operations" v-show="status !== 'loading'" class="el-form-item__label">Вернуться к финансовым операциям</router-link>
            </el-col>
          </el-row>
        </el-card>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import moment from "moment"


export default {
  name: "RoboPaymentConfirmation",
  components: {

  },
  mounted() {
    this.validatePayment()
  },
  computed: {},
  methods: {
    validatePayment() {
      console.log(this.$route.query)
      if(this.$route.query.InvId)
        this.billNumber = this.$route.query.InvId
      if(this.$route.query.SignatureValue){
        this.axios.post("/finance/checkRoboPaymentStatus", this.$route.query)
            .then(resp => {
              if (resp.data.accepted) this.status = "accepted"
              else this.status = "pending"
            })
            .catch((e) => {
              console.error(e)
              this.status = "fail"
            })
      }else this.status = "fail"
    }
  },
  data() {
    return {
      status: "loading",
      billNumber: ""
    }
  }
}

</script>

<style>

.green-bill-row td {
  background-color: transparent !important;
}

.green-bill-row td .cell {

  color: var(--el-color-success) !important;
  font-weight: bold;
}

</style>