<template>
  <div>
    <Breadcrumbs primary="Финансы" secondary="Движение средств"/>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <el-form label-position="top" :inline="true">

            <TimeSearch
                ref="timeSearch"
                :month="true"
                :show-last-month="true"
            />

            <el-form-item label="Группировка">
              <el-select v-model="detailType">
                <el-option value="days" label="По дням"/>
                <el-option value="daysRates" label="По дням и тарифам"/>
              </el-select>
            </el-form-item>

            <el-form-item label=" ">
              <el-button type="success" plain @click="getReport" :loading="reportLoading">Применить</el-button>
            </el-form-item>

          </el-form>

          <div v-show="reportReady" style="margin-top: 50px">

            <p style="margin-bottom: 3px">Баланс на начало отчетного периода:<span class="text-bold"
                                                                                   style="font-size: 15px">{{
                formatNumber(enterSaldo)
              }} руб.</span></p>
            <p style="margin-top: 0px; margin-bottom: 0px" v-show="hadOverdraft">Разрешенный овердрафт на начало
              отчетного периода: <span class="text-bold"
                                       style="font-size: 15px">{{
                  formatNumber(enterOverdraft)
                }} руб.</span></p>

            <el-table
                :data="tableData"
                style="width: 100%; margin-bottom: 50px; margin-top: 50px"
                :summary-method="getSummaries"
                show-summary
            >
              <el-table-column :formatter="dateFormat" label="Дата" width="120"/>
              <el-table-column :formatter="row => row.direction === 1 ? formatNumber(row.total) : ''" label="Приход"
                               width="150px"
              />
              <el-table-column :formatter="row => row.direction === 0 ? formatNumber(row.total) : ''" label="Расход"
                               width="150px"
              />
              <!--              <el-table-column label="Потребление услуг">-->
              <!--                -->
              <!--              </el-table-column>-->
              <el-table-column prop="description" label="Основание" min-width="150" v-if="descriptionVisible"/>
              <el-table-column :formatter="row => formatNumber(row.direction === 0 ? row.min_balance : row.max_balance)"
                               label="Баланс" min-width="150"/>
            </el-table>

            <p style="margin-bottom: 3px">Баланс на конец отчетного периода: <span class="text-bold"
                                                                                   style="font-size: 15px">{{
                formatNumber(exitSaldo)
              }} руб.</span></p>
            <p style="margin-top: 0px" v-show="hadOverdraft">Разрешенный овердрафт на конец отчетного периода:<span
                class="text-bold"
                style="font-size: 15px">{{
                formatNumber(exitOverdraft)
              }} руб.</span>
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"

import moment from "moment"

export default {
  name: "FinancialOperations",
  title: "Движение средств",
  components: {

    TimeSearch
  },
  computed: {
    hadOverdraft() {
      return this.tableData.some(v => v.overdraft > 0)
    }
  },
  methods: {
    dateFormat(row) {
      return moment(row.cat).format("DD.MM.YYYY")
    },
    getReport() {
      this.reportReady = false
      this.reportLoading = true
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      this.axios.get("/finance/getMovementReport", {
        params: {
          month: dateFilter.from.format("DD.MM.YYYY"),
          group: this.detailType
        }
      })
          .then(resp => {
            this.descriptionVisible = this.detailType === "daysRates"
            this.tableData = resp.data.records.sort((r1, r2) => r1.cat_m - r2.cat_m)
            let enterOp = resp.data.firstMonthOp ? resp.data.firstMonthOp : resp.data.lastEverOp
            if (resp.data.firstMonthOp) {
              if (enterOp.direction === 0) enterOp.balance = Number(enterOp.balance) + Number(enterOp.summ)
              else enterOp.balance = Number(enterOp.balance) - Number(enterOp.summ)
            }

            let exitOp = resp.data.lastMonthOp ? resp.data.lastMonthOp : resp.data.lastEverOp
            if ((exitOp && exitOp.cat > dateFilter.from.endOf("month").valueOf()) || !exitOp)
              exitOp = {balance: "0.00", overdraft: "0.00"}

            if ((enterOp && enterOp.cat > dateFilter.from.valueOf()) || !enterOp)
              enterOp = {balance: "0.00", overdraft: "0.00"}

            // if (exitOp.direction === 0) exitOp.balance = Number(exitOp.balance) + Number(exitOp.summ)
            // else exitOp.balance = Number(exitOp.balance) - Number(exitOp.summ)

            this.enterSaldo = enterOp.balance || "0"
            this.enterOverdraft = enterOp.overdraft || "0"
            this.exitSaldo = exitOp.balance || "0"
            this.exitOverdraft = exitOp.overdraft || "0"
            this.reportReady = true
            this.reportLoading = false
          })
          .catch(console.error)
    },
    getSummaries() {
      let finals = this.tableData.reduce((acc, r) => {
        if (r.direction === 0) acc.down += Number(r.total)
        else acc.up += Number(r.total)
        return acc
      }, {up: 0, down: 0})
      let lastOp = this.tableData.length ? this.tableData[this.tableData.length - 1] : null
      return [
        "Итого",
        `${this.formatNumber(finals.up)} руб.`,
        `${this.formatNumber(finals.down)} руб.`,
        "",
        ""//lastOp ? `${this.formatNumber(lastOp.direction === 0 ? lastOp.min_balance : lastOp.max_balance)} руб.` : '0.00 руб.'
      ]
    },
    headerCellStyle({row, column, rowIndex, columnIndex}) {
      if (rowIndex === 0 && columnIndex === 1)
        return {borderRight: "2px solid black !important", borderLeft: "2px solid black !important"}
      if (rowIndex === 1 && columnIndex === 0)
        return {
          borderLeft: "2px solid black !important",
          borderRight: "2px solid black !important",
          "paddingLeft": "10px !important"
        }
      if (rowIndex === 1 && columnIndex === 1)
        return {borderRight: "2px solid black !important"}
    }
  },
  data() {
    return {
      reportLoading: false,
      reportReady: false,
      tableData: [],
      enterSaldo: 0,
      enterOverdraft: 0,
      exitSaldo: 0,
      exitOverdraft: 0,
      detailType: "days",
      descriptionVisible: false
    }
  }
}

</script>