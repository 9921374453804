<template>
  <el-switch  active-text="Включить меню"  v-model="menuActive" style="margin-bottom: 20px" />
  <div id="bot-menu-items-container">
    <div class="bot-menu-item" v-for="item in menuItems" >
      <!--            <span v-if="!item.isEditing" style="font-weight: bold;margin-right: 15px">/{{item.command}}</span>-->
      <!--            <span v-if="!item.isEditing" style="flex-grow: 1">{{item.description}}</span>-->
      <!--            <span v-if="!item.isEditing" style="color: var(&#45;&#45;el-text-color-secondary)">{{algorithms.find(a => a._id === item.algoId).name}}</span>-->
      <font-awesome-icon icon="times"
                         style="color: var(--el-color-danger); font-size: 16px; cursor: pointer; position: absolute; top: 10px;right: 10px" @click="removeCommand(item._id)"
      />
      <div class="bot-menu-item-edit-form-container">

        <el-form :model="item" label-position="top">
          <el-row :gutter="40">
            <el-col :xs="24" :md="12">
              <el-form-item label="Команда">
                <el-input v-model="item.command">
                  <template #prepend>/</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :md="12">
              <el-form-item label="Алгоритм">
                <el-select v-model="item.algoId" class="full-width">
                  <el-option value="" label="Не подключён">
                    <span style="color: var(--el-color-danger); font-weight: bold;">Не подключён</span>
                  </el-option>
                  <el-option v-for="algo in algorithms" :label="algo.name" :value="algo._id"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Описание" style="margin-bottom: 0px">
                <el-input v-model="item.description"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
  <div style="display: flex;justify-content: space-between">
    <el-button type="primary" @click="addCommand">Добавить команду</el-button>
    <el-button type="success" :loading="saving" @click="saveMenu()">Сохранить меню</el-button>
  </div>

</template>

<script>

export default {
  name: "TgBotMenuSettings",
  props: ["bot", "algorithms"],
  components: {},
  watch: {
    bot(botVal) {
      if (botVal && botVal.menu){
        this.menuItems = botVal.menu
        this.menuActive = botVal.menuActive || false
      }
    }
  },
  mounted() {

  },
  methods: {
    addCommand() {
      this.menuItems.push({
        command: "",
        description: "",
        algoId: "",
        _id: String(Math.random())
      })
    },
    removeCommand(commandId) {
      this.menuItems.splice(this.menuItems.findIndex(m => m._id === commandId), 1)
    },
    saveMenu(){
      this.saving = true
      this.axios.post("/chatbot/saveMenu", {items: this.menuItems, botId: this.bot._id, menuActive: this.menuActive})
          .then(() => {
            this.saving = false
            this.$gNotify("Меню обновлено", "success")
            this.$emit("refresh")
          })
    }
  },
  computed: {},
  data() {
    return {
      menuItems: [],
      saving: false,
      menuActive: false
    }
  }
}

</script>

<style>
#bot-menu-items-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;

}

.bot-menu-item {
  display: flex;
  padding: 15px 40px;
  border: 1px solid var(--el-text-color-secondary);
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.bot-menu-item-edit-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>