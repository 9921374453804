<template>

  <div class="gold-flow-node" @mouseover="mouseOver = true" @mouseleave="mouseOver = false">


    <div class="header">
      <div class="gold-flow-node-toolbar" v-show="mouseOver" v-if="nodeType !== 'start'">
        <button @click="$emit('removeMe')">
          <font-awesome-icon icon="trash"/>
        </button>
      </div>
      <slot name="header" />
    </div>
    <div class="body">
      <slot name="body"/>
    </div>
  </div>
</template>


<script>


export default {
  name: "DefaultNode",
  components: {},
  props: ["nodeType"],
  methods: {},
  computed: {},
  data() {
    return {
      mouseOver: false
    }
  }
}
</script>