<template>
  <div>
        <Breadcrumbs primary="Регистрация" secondary="Завершение"/>
    <el-row>
      <el-col :span="24">

        <el-card>
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>ПОЛНАЯ РЕГИСТРАЦИЯ</span>-->
<!--            </div>-->
<!--          </template>-->
          <div class="full-width-incard-row danger hint" style="margin-bottom: 20px" v-show="innInvalid">
            <span>ПО ВАШЕМУ ИНН НИЧЕГО НЕ НАЙДЕНО</span>
          </div>
          <el-form label-position="top">
            <el-form-item class="bold-label  close-label" label="Тип клиента">
              <el-select v-model="clientType" class="full-width">
                <el-option
                    v-for="item in clientTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="bold-label  close-label" label="ИНН" v-show="isIndividual">
              <el-input v-model="clientInn"></el-input>
            </el-form-item>
            <el-button type="success" :loading="enrichLoading" @click="loadSuggestions()"
                       v-show="isIndividual" :disabled="!clientInn.trim().length">Продолжить
            </el-button>
          </el-form>
        </el-card>

        <el-card style="margin-top: 20px" v-show="isIndividual && innEnrichedData.length">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>Выберите ваш вариант из списка</span>-->
<!--            </div>-->
<!--          </template>-->
          <h4 class="report-parameters-title">Выберите ваш вариант из списка</h4>
          <div
              class="inn-option"
              :class="selectedInnOptionKey === opt.value ? 'selected' : ''"
              v-for="(opt, k) in innEnrichedData"
              :key="k"
              @click="selectInnOption(opt)"
          >
            <h4 class="no-margin">{{ opt.unrestricted_value }}</h4>
          </div>
        </el-card>

        <el-card style="margin-top: 20px" v-show="isIndividual && innEnrichedData.length && innOptionSelected">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>ЮРИДИЧЕСКАЯ ИНФОРМАЦИЯ</span>-->
<!--            </div>-->
<!--          </template>-->
          <h4 class="report-parameters-title">Юридическая информация</h4>
          <el-form
              :model="individualInfoForm.data.legalInformation"
              label-position="top"
              :rules="individualInfoForm.rules.legalInformation"
              ref="legalForm"
          >
            <el-row :gutter="20">
              <el-col :md="12" :xs="24">
                <el-form-item class="bold-label  close-label" label="ИНН" prop="inn">
                  <el-input v-model="individualInfoForm.data.legalInformation.inn"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :xs="24">
                <el-form-item class="bold-label  close-label" label="КПП" prop="kpp">
                  <el-input v-model="individualInfoForm.data.legalInformation.kpp"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item class="bold-label  close-label" label="Юридическое название организации" prop="orgName">
                  <el-input v-model="individualInfoForm.data.legalInformation.orgName"></el-input>
                </el-form-item>
                <el-form-item class="bold-label  close-label" label="Юридический адрес" prop="orgAddress">
                  <el-input v-model="individualInfoForm.data.legalInformation.orgAddreess"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>

        <el-card style="margin-top: 20px" v-show="isIndividual && innEnrichedData.length && innOptionSelected">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>КОНТАКТНАЯ ИНФОРМАЦИЯ</span>-->
<!--            </div>-->
<!--          </template>-->
          <h4 class="report-parameters-title">Контактная информация</h4>
          <el-form
              :model="individualInfoForm.data.contactInformation"
              label-position="top"
              :rules="individualInfoForm.rules.contactInformation"
              ref="contactForm"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item class="bold-label  close-label" label="Фамилия" prop="surname">
                  <el-input v-model="individualInfoForm.data.contactInformation.surname"></el-input>
                </el-form-item>
                <el-form-item class="bold-label  close-label" label="Имя" prop="name">
                  <el-input v-model="individualInfoForm.data.contactInformation.name"></el-input>
                </el-form-item>
                <el-form-item class="bold-label  close-label" label="Отчество" prop="patronymic">
                  <el-input v-model="individualInfoForm.data.contactInformation.patronymic"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="bold-label  close-label" label="Email" prop="email">
                  <el-input v-model="individualInfoForm.data.contactInformation.email"></el-input>
                </el-form-item>
                <el-form-item class="bold-label  close-label" label="Номер телефона" prop="phone">
                  <el-input v-model="individualInfoForm.data.contactInformation.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
        </el-card>

        <el-card style="margin-top: 20px" v-show="isIndividual && innEnrichedData.length && innOptionSelected">
<!--          <template #header>
            <div class="card-header">
              <span>СПОСОБ ПОЛУЧЕНИЯ БУХГАЛТЕРСКИХ ДОКУМЕНТОВ</span>
            </div>
          </template>-->
          <h4 class="report-parameters-title">Способ получения бухгалтерских документов</h4>
          <el-form
              :model="individualInfoForm.data.documentsReceiving"
              label-position="top"
              :rules="individualInfoForm.rules.documentsReceiving"
              ref="documentsReceivingForm"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                    class="bold-label  close-label"
                    label="Список e-mail адресов для получения закрывающих документов (через запятую)"
                    prop="emails"
                >
                  <el-input v-model="individualInfoForm.data.documentsReceiving.emails"></el-input>
                </el-form-item>

<!--                <el-checkbox v-model="individualInfoForm.data.documentsReceiving.receiveInEDO"-->
<!--                             style="margin-bottom: 22px"-->
<!--                             label="Получать в ЭДО"-->
<!--                ></el-checkbox>-->

<!--                <el-form-item-->
<!--                    class="bold-label  close-label"-->
<!--                    label="Тип ЭДО"-->
<!--                    prop="inn"-->
<!--                    style="width: 100%;"-->
<!--                    v-show="individualInfoForm.data.documentsReceiving.receiveInEDO"-->
<!--                >-->
<!--                  <el-select-->
<!--                      v-model="individualInfoForm.data.documentsReceiving.edoType"-->
<!--                      style="width: 100%"-->
<!--                  >-->
<!--                    <el-option value="sbis" label="СБИС"></el-option>-->
<!--                    <el-option value="other" label="Другие ЭДО"></el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item-->
<!--                    class="bold-label  close-label" label="Наименование системы ЭДО" prop="otherEDOName"-->
<!--                    v-show="individualInfoForm.data.documentsReceiving.receiveInEDO && individualInfoForm.data.documentsReceiving.edoType === 'other'"-->
<!--                >-->
<!--                  <el-input v-model="individualInfoForm.data.documentsReceiving.otherEDOName"></el-input>-->
<!--                </el-form-item>-->
              </el-col>
            </el-row>
          </el-form>
        </el-card>

        <el-card style="margin-top: 20px" v-show="isIndividual && innEnrichedData.length && innOptionSelected">
<!--          <template #header>-->
<!--            <div class="card-header">-->
<!--              <span>СОГЛАШЕНИЕ</span>-->
<!--            </div>-->
<!--          </template>-->
          <h4 class="report-parameters-title">Соглашение</h4>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-checkbox v-model="termsAccepted" style="margin-bottom: 20px" class="break-label">
                Я ознакомился с условиями <a href="https://web.smsgold.ru/offer" target="_blank">договора</a> и согласен
                с ними
              </el-checkbox>
              <br/>
              <el-button class="uppercase" type="success" :loading="regiserLoading" @click="finalizeRegistration()"
                         :disabled="!termsAccepted">Завершить регистрацию
              </el-button>
            </el-col>
          </el-row>

        </el-card>
      </el-col>

    </el-row>
  </div>
</template>

<script>



export default {
  name: "FinalizeRegistration",
  title: "Завершение регистрации",
  beforeMount() {
    this.axios.get("/user/getPossibleRegistrationTypes")
        .then(resp => {
          this.clientTypeOptions = resp.data
        })
        .catch(console.error)
  },
  watch: {
    "individualInfoForm.data.documentsReceiving.edoType": function () {
      console.log(this.individualInfoForm.data.documentsReceiving.edoType)
      this.individualInfoForm.rules.documentsReceiving.otherEDOName[0].required = this.individualInfoForm.data.documentsReceiving.edoType === 'other'
    }
  },
  methods: {
    loadSuggestions() {
      this.enrichLoading = true
      this.axios.get("/user/innEnrichment", {params: {inn: this.clientInn.trim()}})
          .then(resp => {
            this.enrichLoading = false
            this.innEnrichedData = resp.data.suggestions
            this.innInvalid = !resp.data.suggestions.length
          })
          .catch(e => {
            console.error(e)
            this.$gNotify("Неверный ИНН или ошибка сервера", "error")
            this.enrichLoading = false
          })
    },
    fillIndividualInfoForm(opt) {
      // console.log(opt)
      this.individualInfoForm.rules.legalInformation.kpp[0].required = !(opt && opt.data && opt.data.opf && opt.data.opf.short && opt.data.opf.short.toLowerCase() === "ип")
      this.individualInfoForm.data.legalInformation.inn = opt.data.inn
      this.individualInfoForm.data.legalInformation.kpp = opt.data.kpp
      this.individualInfoForm.data.legalInformation.orgName = opt.data.name.short_with_opf
      this.individualInfoForm.data.legalInformation.orgAddreess = opt.data.address.unrestricted_value
      this.individualInfoForm.data.contactInformation.email = this.$store.getters.userOwnEmail
      this.individualInfoForm.data.contactInformation.phone = this.$store.getters.userOwnPhone

    },
    selectInnOption(opt) {
      if (this.selectedInnOptionKey === opt.value) {
        this.selectedInnOptionKey = ""
        this.innOptionSelected = false
        this.selectedInnOption = {}
      } else {
        if(opt.data && opt.data.state && ["LIQUIDATED", "LIQUIDATING", "BANKRUPT"].includes(opt.data.state.status))
          return this.$gNotify("Введите ИНН действующей компании!", "error")
        this.innOptionSelected = true
        this.selectedInnOptionKey = opt.value
        this.selectedInnOption = opt
        if (this.isIndividual) this.fillIndividualInfoForm(opt)
      }
    },
    finalizeRegistration() {
      Promise.all([
        this.$refs.legalForm.validate(),
        this.$refs.contactForm.validate(),
        this.$refs.documentsReceivingForm.validate()
      ]).then(isValid => {
        if (isValid.every(e => e)) {
          this.regiserLoading = true
          this.axios.post(
              "/user/finalizeRegistration",
              {
                data: {
                  clientType: this.clientType,
                  enrichmentData: this.selectedInnOption,
                  ...this.individualInfoForm.data
                }
              }
          )
              .then(resp => {
                this.$store.dispatch("refreshUser").then(() => {
                  this.$router.push({path: "/cab/messages/new_send", query: {notice: "reg_success"}})
                })

              })
              .catch(console.error)
        }
      }).catch(console.error)
    }
  },
  computed: {
    isIndividual() {
      return this.clientType === 'individual'
    }
  },
  data() {
    return {
      clientTypeOptions: [],
      clientType: "individual",
      clientInn: "",
      innInvalid: false,
      enrichLoading: false,
      innEnrichedData: [],
      innOptionSelected: false,
      selectedInnOptionKey: "",
      selectedInnOption: {},
      individualInfoForm: {
        data: {
          legalInformation: {
            inn: "",
            kpp: "",
            orgName: "",
            orgAddreess: ""
          },
          contactInformation: {
            surname: "",
            name: "",
            patronymic: "",
            email: "",
            phone: ""
          },
          documentsReceiving: {
            emails: "",
            receiveInEDO: false,
            edoType: "sbis",
            otherEDOName: ""
          }
        },
        rules: {
          legalInformation: {
            inn: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            kpp: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            orgName: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            orgAddreess: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            email: [{required: true, message: "Invalid email address", trigger: 'change', type: 'email'}]
          },
          contactInformation: {
            surname: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            name: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            patronymic: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            phone: [{
              required: true,
              message: "Это поле обязательно к заполнению",
              trigger: 'change',
              validator: (_, value) => validatePhone(patchPhone(value))
            }],
            email: [{required: true, message: "Неверный E-mail адрес", trigger: 'change', type: 'email'}]
          },
          documentsReceiving: {
            emails: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
            otherEDOName: [{required: false, message: "Это поле обязательно к заполнению", trigger: 'change'}],
          }
        }
      },
      physicInfoForm: {
        data: {},
        rules: []
      },
      termsAccepted: false,
      regiserLoading: false
    }
  },
}

</script>

<style>


</style>