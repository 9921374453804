<template>
  <div>
    <el-button text class="text-button table-text-button" @click="addEmptyMessage('simple')" style="margin-bottom: 10px">
      <font-awesome-icon icon="plus"/>&nbsp;Добавить сообщение
    </el-button>
    <WhatsappSingleMessageForm
        v-for="(message, i) in messages"
        v-model="messages[i]"
        :show-remove="messages.length > 1"
        :is-edit-mode="isEditMode"
        :bot="selectedChannel"
        :ref="'singleMessageInput' + i"
        @remove="removeMessage(i)"
        @change="$emit('change')"
    />

    <el-form-item class="no-margin">
      <div style="display: flex; flex-direction: column">
        <el-checkbox v-model="send_test_message" label="Протестировать рассылку"></el-checkbox>
        <el-input
            v-model="test_sms_number"
            v-show="send_test_message"
            placeholder="Введите номер"
            style="margin-bottom: 15px"
        >
          <template #append>
            <el-button
                :type="!$store.getters.registrationFullyFinalized ? 'success' : 'default'"
                @click="sendTestMessage()"
                :loading="sendingTestMessage"
                :disabled="!test_sms_number.trim()"
            >Отправить
            </el-button>
          </template>
        </el-input>

      </div>
    </el-form-item>
  </div>
</template>

<script>

import MessageTextInput from "../message-text-input.component.vue";
import store from "@/store";
import WhatsappSingleMessageForm from "./whatsapp-single-message-input.component.vue";


export default {
  name: "WhatsappMessageInput",
  props: ["selectedChannel", "validateForm", "isEditMode"],
  components: {WhatsappSingleMessageForm, MessageTextInput},
  mounted() {
    this.loadAll()
  },
  watch: {
    messages() {
      this.$emit('change')
    }
  },
  methods: {
    loadAll() {
    },
    async sendTestMessage() {
      let payload = {
        channelId: this.selectedChannel._id,
        whatsapp: {messages:  this.messages},
        phone: this.test_sms_number
      }
      this.sendingTestMessage = true
      this.axios.post("/sms/sendTestWaMessage", payload)
          .then(resp => {
            this.sendingTestMessage = false
            this.$gNotify("Тестовая рассылка отправлена", "success")
          })
    },

    getEmptyMessage() {
      return {
        text: "",
        type: "simple",
        files: [],
        randomisers: []
      }
    },
    addEmptyMessage(type) {
      let m = this.getEmptyMessage()
      m.type = type
      this.messages.push(m)
    },
    reset() {
      this.messages = []
      this.addEmptyMessage("simple")
    },
    removeMessage(i) {
      this.messages.splice(i, 1)
    },
    getMessages() {
      return this.messages
    },
    setMessages(messages){
      this.messages = messages
    },
    getChannelName(){
      return (this.selectedChannel || {name: ""}).name
    },
    onPreviewTextClicked(e){
      if(this.$refs['singleMessageInput'+e.messageIndex])
        this.$refs['singleMessageInput'+e.messageIndex][0].onPreviewTextClicked(e.originalEvent)
    }
  },
  computed: {},
  data() {
    return {
      messages: [this.getEmptyMessage()],
      targetTestAdmin: "",
      send_test_message: false,
      test_sms_number: String(store.getters.userOwnPhone || ""),
      sendingTestMessage: false
    }
  },
}

</script>

<style>
</style>
}