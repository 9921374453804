<template>
  <div>
    <Breadcrumbs primary="Финансы" secondary="Операции"/>

    <form method="post" ref="roboForm"
          action="https://auth.robokassa.ru/Merchant/Index.aspx" style="display:none;">
      <input type="hidden" name="MerchantLogin" :value="static.roboLogin"/>
      <input type="hidden" name="InvId" value="" id="ordNum"/>
      <input type="hidden" name="Description" value=""/>
      <input type="hidden" name="SignatureValue" id="roboHash" value=""/>
      <input type="hidden" name="IsTest" :value="static.roboIsTest"/>
      <input type="number" name="OutSum" id="roboOutSum"/>
      <input type="hidden" name="Shp_id" id="roboPaymentId"/>
      <input type="hidden" name="Receipt" id="roboPaymentReceipt"/>
    </form>

    <form method="POST" action="https://yoomoney.ru/quickpay/confirm.xml" ref="uMoneyForm"
          style="display: none">
      <input type="hidden" name="receiver" value="41001728538315"/>
      <input type="hidden" name="quickpay-form" value="shop"/>
      <input type="hidden" name="targets" value="Пополнение лицевого счета SMSGold"/>
      <input type="hidden" name="successURL" value="https://web.smsgold.ru/cab/finance/operations"/>
      <input type="hidden" name="label" value="" id="uMoneyPaymentLabel"/>
      <input type="number" name="sum" id="uMoneyAmount" class="form-control"/>
      <input name="paymentType" id="uMoneyMethod"/>
    </form>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card no-border no-top-body-padding" v-if="$store.getters.user.canDoMessaging && !$store.getters.user.isShortReg">
          <h4 class="report-parameters-title">Пополнение баланса</h4>
          <TableSpinner :loading="!ratesLoaded">
            <el-row style="margin-top: 40px" v-if="canPayBank || canPayOnline">
              <el-col :span="24">
                <el-form label-width="200" label-position="top">
                  <el-form-item label="Введите сумму платежа в руб." class="text-bold">
                    <el-input type="number" v-model="paymentAmount"/>
                  </el-form-item>
                  <el-form-item label="Выберите способ платежа" class="text-bold">
                    <el-select v-model="paymentOption" style="width: 100%">
                      <el-option value="bank" label="Банковский перевод" v-if="canPayBank"></el-option>
                      <el-option value="robocass" label="Картой (Робокасса)"
                                 v-if="!$store.getters.isShortReg && $store.getters.offerAccepted && canPayOnline"></el-option>
                      <el-option value="umoney" label="ЮMoney"
                                 v-if="!$store.getters.isShortReg && $store.getters.offerAccepted && canPayOnline"></el-option>
                    </el-select>
                    <!--                  <h4-->
                    <!--                      style="color: var(&#45;&#45;el-color-danger);cursor: pointer;text-decoration: underline">-->
                    <!--                    Оплата через Робокассу и ЮМани временно недоступна-->
                    <!--                  </h4>-->
                  </el-form-item>
                  <!--                <el-form-item label="Способ пополнения" class="text-bold">-->
                  <!--                  <el-radio-group v-model="uMoneyPaymentMethod">-->
                  <!--                    <el-radio label="AC">Банковской картой</el-radio>-->
                  <!--                    <el-radio label="PC">ЮMoney</el-radio>-->
                  <!--                  </el-radio-group>-->
                  <!--                </el-form-item>-->
                </el-form>
                <div v-show="paymentOption !== 'bank'">
                  <span style="color: var(--el-text-color-regular)" v-if="canPayOnline && paymentRates.robo.comission" v-show="comissionValue > 0">
                    Комиссия <span>{{ parseFloat(comissionValue).toFixed(2) }}</span> руб.({{comissionPercent }}%) но не меньше {{ minimalComissionValue }} руб.</span>
                    <br  v-if="canPayOnline && paymentRates.robo.comission && comissionValue > 0"/>
                    <span style="color: var(--el-text-color-regular)" v-if="canPayOnline && paymentRates.robo.minimalDepositAmount > 0">
                      Минимальная сумма пополнения <span>{{paymentRates.robo.minimalDepositAmount }}</span> руб.
                    </span>
                  <span v-if="canPayOnline && paymentOption === 'umoney'">
                    <br/>
                    <br/>
                    <span style="color: var(--el-color-danger); font-weight: bold">Внимание</span>: При оплате по кнопке платеж зачисляется сразу, но взимается комиссия: 3% при оплате картой или 1% при оплате кошельком ЮMoney.<br/>
                    Для оплаты без комиссии, но с ожиданием ручного зачисления можно осуществить перевод из кошелька ЮMoney на кошелек № <b>41001728538315</b>
и отправить информацию об оплате, указав Ваш логин, на e-mail: support@smsgold.ru
                  </span>
                </div>
                <el-button type="success" plain @click="createPayment" :loading="paymentProcessing" style="margin-top: 20px">{{
                    paymentButtonText }}
                </el-button>


              </el-col>
            </el-row>
          </TableSpinner>
        </el-card>
        <el-card class="box-card no-border " style="margin-top: 20px">
          <h4 class="report-parameters-title">Счета и зачисления</h4>
          <TableSpinner :loading="dataLoading">
            <el-table
                :data="billsVisible"
                style="width: 100%"
                stripe
                :row-class-name="opsRowClassName"
            >
              <el-table-column :formatter="dateTimeFormat" label="Создан" min-width="150"/>
              <el-table-column prop="name" label="Название" min-width="280"/>
              <el-table-column prop="sum" label="Сумма" min-width="140"/>
              <el-table-column prop="payment_order" label="Платежное поручение" min-width="200"/>
              <el-table-column min-width="120" align="right">
                <template #default="scope">
                  <a :href="`${axios.defaults.baseURL}/finance/downloadBill?billId=${scope.row._id}&token=${$store.getters.bareToken}`"
                     target="_blank"
                     v-if="scope.row.number && !scope.row.robo_signature && !scope.row.isUMoney"
                  >
                    <el-button text class="text-button" size="small" style="min-height: auto; padding: 0px"
                    >
                      <font-awesome-icon icon="external-link-alt" class="el-icon--left"></font-awesome-icon>
                      Скачать
                    </el-button>
                  </a>
                </template>
              </el-table-column>
            </el-table>
            <div class="solo-pagination-container">
              <el-pagination
                  background
                  class="wide-page-size"
                  layout="sizes, prev, pager, next"
                  :total="bills.length"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :hide-on-single-page="true"
                  :pager-count="3"
                  :current-page="currentPage"
                  @size-change="handlePageSizeChange"
                  @current-change="handleCurrentPageChange"
              >
              </el-pagination>
            </div>
          </TableSpinner>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import moment from "moment"



export default {
  name: "FinancialOperations",
  components: {


  },
  mounted() {
    this.getMyOperations()

    this.axios.get("/finance/getPaymentRates")
        .then(resp => {
          this.ratesLoaded = true
          this.paymentRates = resp.data
          this.paymentOption = this.canPayBank ? "bank": this.canPayOnline ? "robocass" : ""
        })
        .catch(console.error)
  },
  computed: {
    billsVisible() {
      return this.bills.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    paymentButtonText() {
      return this.paymentOption === "bank" ? "Сформировать новый счет" : "Оплатить"
    },
    canPayBank(){
      return this.paymentRates && this.paymentRates.bill && this.paymentRates.bill.active
    },
    canPayOnline(){
      return this.paymentRates && this.paymentRates.robo && this.paymentRates.robo.active
    },
    comissionValue() {
      return this.paymentOption === "robocass" && this.paymentRates.robo ? (Number(this.paymentAmount) / 100) * (Number(this.paymentRates.robo.comission) || 0) : 0
    },
    comissionPercent() {
      return this.paymentRates.robo ? this.paymentRates.robo.comission : 0
    },
    minimalComissionValue() {
      return this.paymentRates.robo ? this.paymentRates.robo.minimalComission : 0
    }
  },
  methods: {
    opsRowClassName({row}) {
      let className = ""
      if (String(row.accepted) === '1') className = ' blue-bill-row'
      if (row.is_automatic) className = ' green-bill-row'
      return className
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    dateTimeFormat(row) {
      return moment(row.date_create).format("DD.MM.YYYY HH:mm")
    },
    getMyOperations() {
      this.dataLoading = true
      this.axios.get("/finance/myBills")
          .then(resp => {
            this.dataLoading = false
            this.bills = resp.data.autopayments.concat(resp.data.bills).sort((b1, b2) => moment(b2.date_create).valueOf() - moment(b1.date_create).valueOf())
          })
          .catch(console.error)
    },
    createPayment() {
      this.paymentProcessing = true
      if (this.paymentOption === "bank") this.createBill()
      if (this.paymentOption === "robocass") this.payRobo()
      if (this.paymentOption === "umoney") this.payUmoney()

    },
    createBill() {
      if (this.paymentAmount <= 0) {
        this.paymentProcessing = false
        return this.$gNotify("Неверная сумма счета", "error")
      }
      setTimeout(() => {
        this.axios.post("/finance/createBill", {amount: this.paymentAmount})
            .then(resp => {
              this.$gNotify("Счет успешно сформирован и выслан на ваш е-мейл", "success")
              this.getMyOperations()
              this.paymentProcessing = false
            })
            .catch(console.error)
      }, 700)

    },
    payRobo() {
      if (this.paymentAmount < this.paymentRates.robo.minimalDepositAmount) {
        this.paymentProcessing = false
        return this.$gNotify(`Сумма пополнения должна быть не менее ${this.paymentRates.robo.minimalDepositAmount} рублей`, "error")
      }
      this.axios.post("/finance/createRoboPayment", {sum: this.paymentAmount})
          .then(resp => {
            document.getElementById("roboHash").value = resp.data.hash
            document.getElementById("ordNum").value = resp.data.number
            document.getElementById("roboOutSum").value = resp.data.correctedAmount
            document.getElementById("roboPaymentId").value = resp.data.paymentId
            document.getElementById("roboPaymentReceipt").value = resp.data.paymentReceipt || ""
            this.paymentProcessing = false
            this.$refs.roboForm.submit()
          })
          .catch(console.error)

    },
    payUmoney(event) {
      // event.preventDefault()
      if (this.paymentAmount < this.paymentRates.robo.minimalDepositAmount) {
        this.paymentProcessing = false
        return this.$gNotify(`Сумма пополнения должна быть не менее ${this.paymentRates.robo.minimalDepositAmount} рублей`, "error")
      }
      document.getElementById("uMoneyAmount").value = this.paymentAmount
      document.getElementById("uMoneyMethod").value = this.uMoneyPaymentMethod
      this.axios.post("/finance/createYooMoneyPayment", {sum: this.paymentAmount})
          .then(resp => {
            document.getElementById("uMoneyPaymentLabel").value = resp.data.label
            this.paymentProcessing = false
            this.$refs.uMoneyForm.submit()
          })
          .catch(console.error)


      // if ($("#").val() < $("#").attr("min")) {
      //   toastr.error("Сумма пополнения меньше минимальной!");
      //   return;
      // }
      // $.ajax({
      //   url: "/finance/createYoomoneyPayment",
      //   type: "POST",
      //   data: $(this).serialize(),
      //   success: (data) => {
      //     $("#yoomoney_payment_label").val(data.id);
      //     $(this).unbind('submit').submit()
      //   }
      // })
    }
  },
  data() {
    return {
      ratesLoaded: false,
      dataLoading: false,
      paymentProcessing: false,
      paymentAmount: 0,
      paymentOption: "",
      // billCreating: false,
      bills: [],
      pageSize: 10,
      currentPage: 1,
      // bankPaymentAmount: 0,
      // roboPaymentAmount: 300,
      // uMoneyPaymentAmount: 300,
      uMoneyPaymentMethod: "PC",
      // visibleTab: "bank"
      paymentRates: {robo: null, bill: {active: 0}},
      static: {
        roboIsTest: process.env.VUE_APP_ROBO_IS_TEST,
        roboLogin: process.env.VUE_APP_ROBO_LOGIN,
        MINIMAL_TOPUP: 300
      }
    }
  }
}

</script>

<style>

.green-bill-row td,
.blue-bill-row td {
  background-color: transparent !important;
}

.green-bill-row td .cell {
  font-weight: bold;
  color: var(--el-color-success) !important;
}

.blue-bill-row > td:nth-child(2) > .cell {
  color: var(--el-color-primary-dark-2) !important;
}

</style>
