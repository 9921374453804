<template>
  <DefaultNode @removeMe="$emit('removeMe', id)">
    <template #header>
      <span>Цепочка сообщений</span>
      <Handle type="source" position="right"/>
      <Handle type="target" position="left" />
    </template>
    <template #body>
      <div v-for="(msg, i) in data.settings.messages" class="message-chain-message">
        <el-divider v-if="i > 0" style="margin: 5px 0px"/>
        <span>{{ msg.text }}</span>
        <div class="flow-button" v-for="button in msg.buttons">
          <span>{{ button.text }}</span>
          <Handle :id="button.id" type="source" position="right" :style="buttonHandleStyle" v-if="button.type === 'continueAlgo'"/>
        </div>
      </div>
    </template>
  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";
import {v1} from "uuid";

export default {
  name: "MessageChainNode",
  components: {DefaultNode, Handle},
  props: ["events", "id", "data"],
  mounted() {
  },
  watch: {},
  methods: {},
  computed: {

  },
  data() {
    return {
      buttonHandleStyle : {}
    }
  }
}

export let oneMessageModel = () => {
  return {text: '', buttons: [], id: String(v1())}
}

export let settingsModel = () => {
  return {
    messages: [
      oneMessageModel()
    ],
    settings: {}
  }
}
</script>