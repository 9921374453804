<template>
  <support-view
      :ticket-title-formatter="ticketTitleFormatter"
      :load-tickets-method="loadTickets"
      :fetch-conversation-method="fetchConversation"
      :send-message-method="sendMessage"
  >
    <template #manage-ticket="{ticket, onTicketStatusChange}">
      <manage-ticket  :ticket="ticket"
                      :close-ticket-method="closeTicket"
                      :reopen-ticket-method="reopenTicket"
                     @onTicketStatusChange="onTicketStatusChange"/>
    </template>
  </support-view>
</template>


<script>


import SupportView from "@shared/views/support/index.view.vue";
import ManageTicket from "@shared/components/support/manage-ticket.component.vue";

export default {
  name: 'ClientSupportRequests',
  title: "Тех.поддержка",
  components: {ManageTicket, SupportView, },
  props: [],
  watch: {},
  methods: {
    ticketTitleFormatter(d) {
      return d.title
    },
    loadTickets(filterToken, offset, archetype) {
      return this.axios.get("/support/myRequests", {params: {filterToken, offset, archetype}})
    },
    fetchConversation(sentOffset, receivedOffset, ticket, signal){
      return this.axios.get("/support/getConversation2", {
        signal,
        params: {ticket, sentOffset, receivedOffset}
      })
    },
    sendMessage(payload){
      return this.axios.post("/support/replyTicket", payload)
    },
    closeTicket(ticket){
      return this.axios.post("/support/closeTicket", {ticketId: ticket._id})
    },
    reopenTicket(ticket){
      return this.axios.post("/support/reopenTicket", {ticketId: ticket._id})
    },
  },
  computed: {},
  data() {
    return {

    }
  }
}


</script>

<style>

</style>