<template>
  <div>
    <el-row>
      <el-col :xs="24" :sm="12">
      </el-col>
      <el-col :xs="24" :sm="12">

      </el-col>
    </el-row>

  </div>
</template>

<script>


export default {
  name: "TgBotStatistics",
  props: ["bot"],
  components: {},
  mounted() {
  },
  methods: {

  },
  computed: {

  },
  data() {
    return {

    }
  }
}

</script>
