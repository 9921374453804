<template>

  <el-dialog
      v-model="intVisible"
      title="Редактирование контакта"
      class="modal-95-70-60"
      @close="$emit('close')"
  >
    <el-form label-position="top" :rules="rules" :model="editData" ref="editContactForm">
      <el-row :gutter="30">
        <el-col :md="12" :xs="24">
          <el-form-item label="Имя">
            <el-input v-model="editData.name"/>
          </el-form-item>
          <el-form-item label="Отчество">
            <el-input v-model="editData.patronymic"/>
          </el-form-item>
          <el-form-item label="Пол">
            <el-select v-model="editData.sex" style="width: 100%;">
              <el-option :value="'2'" label="Не определен"></el-option>
              <el-option :value="'0'" label="Мужской"></el-option>
              <el-option :value="'1'" label="Женский"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Дата рождения">
            <el-date-picker v-model="editData._date_of_birth" type="date" format="DD.MM.YYYY" style="width: 100%">
            </el-date-picker>
          </el-form-item>

        </el-col>
        <el-col :md="12" :xs="24">
          <el-form-item label="Фамилия">
            <el-input v-model="editData.surname"/>
          </el-form-item>
          <el-form-item label="Название контакта">
            <el-input v-model="editData.show_name"/>
          </el-form-item>
          <el-form-item label="Номер телефона" prop="phone">
            <el-input v-model="editData.phone"/>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="editData.email"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="Доп. поле 1">
            <el-input type="textarea" v-model="editData.add_field_1"/>
          </el-form-item>
          <el-form-item label="Доп. поле 2">
            <el-input type="textarea" v-model="editData.add_field_2"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="success" @click="saveContact()">Сохранить</el-button>
        <el-button @click="$emit('close')">Закрыть</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

import moment from "moment";

export default {
  name: "EditContact",
  props: ["contact", "visible"],
  watch: {
    visible(v){
      this.intVisible = v
    },
    contact(newVal){
      this.editData = {...newVal}
    }
  },
  methods: {
    saveContact() {
      let sendData = this.editData
      sendData.date_of_birth = ""
      delete sendData.regionName
      delete sendData.operator_id
      if (this.editData._date_of_birth)
        sendData.date_of_birth = moment(this.editData._date_of_birth).format("DD.MM.YYYY")
      // console.log(this.$refs.newContactForm.validate())
      this.$refs.editContactForm.validate()
          .then(isValid => {
            if(isValid)
              this.axios.post("/contacts/updateContact", {contact: sendData, _id: this.contact._id})
                  .then(() => {
                    this.$emit("close")
                    this.$emit("saved")
                    return this.$gNotify("Контакт обновлен", "success")
                  })
          }).catch(console.error)

    }
  },
  data() {
    return {
      editData: {
        name: this.contact.name,
        surname: this.contact.surname,
        patronymic: this.contact.patronymic,
        show_name: this.contact.show_name,
        phone: this.contact.phone,
        email: this.contact.email,
        _date_of_birth: moment(this.contact._date_of_birth) || "",
        sex: this.contact.sex,
        add_field_1: this.contact.add_field_1,
        add_field_2: this.contact.add_field_2

      },
      rules: {
        phone: [{required: true, message: "Неверный номер телефона", trigger: 'change', validator: (_, value) => validatePhone(patchPhone(value))}],
        email: [{required: false, message: "Неверный E-mail адрес", trigger: 'change', type: 'email'}]
      },
      intVisible: false
    }
  }
}

</script>