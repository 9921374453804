<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="Черновики"/>
    <MessageTemplateForm
        ref="addEditTemplate"
        :visible="templateFormVisible"
        :editing-template-id="editingTemplateId"
        @close="templateFormVisible = false"
        @templateSaved="refreshMyTemplates()"
    />

    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">

            <TableSpinner :loading="dataLoading">
              <el-table :data="smsTemplatesData" style="width: 100%" stripe>
                <el-table-column prop="date" label="Дата создания" min-width="130"
                                 :formatter="dateTimeFormat"/>
                <el-table-column prop="title" label="Название шаблона" min-width="200"/>
                <el-table-column prop="text" label="Текст шаблона" min-width="280"/>
                <el-table-column label="" min-width="150" align="right">
                  <template #default="scope">
                    <ActionsDropdown>
                      <ActionButton icon="pencil-alt" @click="() => showEditForm(scope.$index)">Изменить
                      </ActionButton>
                      <ActionButton icon="copy" @click="() => showEditForm(scope.$index, true)">Копировать
                      </ActionButton>
                      <ActionButton type="remove" icon="times" @confirm="() => removeTemplate(scope.$index)">Удалить
                      </ActionButton>
                    </ActionsDropdown>
                  </template>
                </el-table-column>
              </el-table>
            </TableSpinner>
            <el-button class="button" type="success" @click="showAddForm" style="margin-top: 40px">
              <font-awesome-icon icon="plus" class="el-icon--left"></font-awesome-icon>
              Добавить шаблон
            </el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import MessageTemplateForm from "@/components/message_template_form.component"
import moment from "moment"
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";
import ActionButton from "@shared/components/layout/actionButton.component";

export default {
  name: "MessageTemplates",
  title: "Черновики",
  components: {
    ActionButton,
    ActionsDropdown,
    MessageTemplateForm,
  },
  mounted() {
    this.refreshMyTemplates()
  },
  methods: {
    dateTimeFormat(row) {
      return moment(row.date).format("DD.MM.YYYY HH:mm")
    },
    refreshMyTemplates() {
      this.dataLoading = true
      this.axios.get("/sms/messageTemplates/sms")
          .then(resp => {
            this.dataLoading = false
            this.smsTemplatesData = resp.data || []
          })
          .catch(console.error)
    },
    showAddForm() {
      this.editingTemplateId = ""
      this.templateFormVisible = true
    },
    showEditForm(index, eraseTemplateId = false) {
      if (!eraseTemplateId)
        this.editingTemplateId = this.smsTemplatesData[index]._id
      else
        this.editingTemplateId = ""
      this.editPreload = true
      this.$refs.addEditTemplate.preloadEditingTemplate(this.smsTemplatesData[index]._id, () => {
        this.editPreload = false
        this.templateFormVisible = true
      })
    },
    removeTemplate(index) {
      this.removing = true
      this.axios.delete("/sms/removeMessageTemplate", {params: {templateId: this.smsTemplatesData[index]._id}})
          .then(() => {
            this.removing = false
            this.refreshMyTemplates()
          })
    },
  },
  data() {
    return {
      smsTemplatesData: [],

      templateFormVisible: false,
      editingTemplateId: "",

      editPreload: false,
      removing: false,
      dataLoading: false,
      visiblePanel: "sms"
    }
  }
}

</script>