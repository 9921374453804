<template>

  <dialogs-scaffold>

    <template #archetype-selector>
      <dialog-archetype-select
          v-model="activeArchetype"
          :archetypes="supportedDialogArchetypes"
      />
    </template>

    <template #connection-selector>
      <dialog-connection-select
          v-model="selectedConnection"
          :loading="loading"
          :connections="availableConnections"
      />
    </template>

    <template #dialog-selector="{onDialogSelected}">
      <dialogs-select
          v-model="selectedChat"
          v-if="!!selectedConnection._id"
          :autoload="true"
          :load-dialogs-method="loadDialogsForConnection"
          :set-messages-read-method="setMessagesReadForDialog"
          :fetch-dialog-method="getDialogById"
          :avatar-href="dialogAvatarHref"

          :ref="el => dialogSelector = el"
          @dialogClicked="onDialogSelected"
      >
        <template #new-dialog="{onDialogCreated}">
          <new-dialog-modal :channel="selectedConnection" @dialogCreated="onDialogCreated"/>
        </template>
      </dialogs-select>
    </template>

    <template #subscriber-card>
      <tg-bot-subscriber-form :contact-id="selectedChat._id" :embedded="true"
                              :bot-tags="selectedChat.allTags || []"
                              :algorithms="[]" :chat-type="selectedChat.type"/>
    </template>

    <template #message-feed>
      <dialog-message-feed
          :supports-media="true"
          :chat-id="selectedChat._id"
          :chat-type="selectedChat.type"
          :disabled="!selectedChat._id"
          :fetch-messages-method="fetchDialogMessages"
          :send-message-method="sendMessage"
          :ref="el => messageFeed = el"
      />
    </template>

  </dialogs-scaffold>

</template>

<script>
import DialogMessageFeed from "@shared/components/dialogs/dialog-message-feed.component.vue";
import TgBotSubscriberForm from "../../components/chatbots/telegram/subscribers/tg-bot-subscriber-form.component.vue";
import DialogsSelect from "@shared/components/dialogs/dialogs-select.component.vue";
import DialogArchetypeSelect from "@shared/components/dialogs/archetype-select.component.vue";
import DialogConnectionSelect from "@shared/components/dialogs/connection-select.component.vue";
import NewDialogModal from "@shared/components/dialogs/new-dialog-modal.component.vue";
import DialogsScaffold from "@shared/components/dialogs/dialogs-scaffold.component.vue";

export default {
  name: "Dialogs",
  title: "Диалоги",
  components: {
    DialogsScaffold,
    NewDialogModal,
    DialogConnectionSelect, DialogArchetypeSelect, DialogsSelect, TgBotSubscriberForm, DialogMessageFeed
  },
  provide() {
    return {
      messageMediaHrefAppendix: this.messageMediaHrefAppendix
    }
  },
  watch: {
    "selectedConnection._id": function (v) {
      if (v && this.dialogSelector)
        this.dialogSelector.loadDialogs()
    },
    "selectedChat._id": function (v) {
      if (this.messageFeed) {
        this.messageFeed.clear()
        if(v)
          this.$nextTick(() => {
            this.messageFeed.loadConversation()
          })
      }
    }
  },
  mounted() {
    this.loadAllConnections()
    this.$emitter.on("dialogMessage", async (payload) => {
      payload = JSON.parse(payload)
      if ((payload.botId || payload.channelId) === this.selectedConnection._id) {
        let forThisChat = payload.contactId === this.selectedChat._id
        this.dialogSelector.onNewMessageReceived(payload, forThisChat)
        if(forThisChat)
          this.messageFeed.onNewMessageReceived(payload)
      }
    })
  },
  beforeUnmount() {
    this.$emitter.off("dialogMessage")
  },
  methods: {
    loadAllConnections() {
      this.loading = true
      this.axios.get("/dialogs/getChannels")
          .then(resp => {
            this.allConnections = resp.data
            this.loading = false
          })
    },
    loadDialogsForConnection(searchToken, offset) {
      return this.axios.get("/dialogs/getDialogs", {
        params: {
          channelType: this.selectedConnection.type,
          channelId: this.selectedConnection._id,
          offset,
          searchToken
        }
      })
    },
    getDialogById(dialogId){
      return this.axios.get("/dialogs/getById", {
        params: {
          dialogId,
          type: this.selectedConnection.type
        }
      })
    },
    dialogAvatarHref(dialog) {
      return `${this.axios.defaults.baseURL}/dialogs/avatar?token=${String(this.$store.getters.bareToken)}&contactId=${String(dialog._id)}&viewType=${this.selectedConnection.type}`
    },
    messageMediaHrefAppendix(baseHref) {
      return baseHref + `&contactId=${this.selectedChat._id}`
    },
    setMessagesReadForDialog(dialog) {
      return this.axios.post("/dialogs/setMessagesRead", {dialogId: dialog._id, channelId: this.selectedConnection._id})
    },
    fetchDialogMessages(sentOffset, receivedOffset, signal) {
      return this.axios.get("/dialogs/getConversationForDialog", {
        signal,
        params: {
          dialogId: this.selectedChat._id,
          dialogType: this.selectedChat.type,
          sentOffset,
          receivedOffset
        }
      })
    },
    sendMessage(messagePayload) {
      return this.axios.post(
          "/dialogs/sendMessage",
          {...messagePayload, dialogId: this.selectedChat._id, dialogType: this.selectedChat.type}
      )
    }
  },
  computed: {
    availableConnections() {
      return this.allConnections[this.activeArchetype] || []
    }
  },
  data() {
    return {
      loading: false,
      activeArchetype: "",
      allConnections: {},

      selectedConnection: {_id: "", type: ""},
      selectedChat: {_id: "", type: ""},


      supportedDialogArchetypes: [
          {key: 'tgBot', label: 'Telegram / Боты', image: 'telegram.png'},
        {key: 'tgPersonal', label: 'Telegram / Личные', image: 'telegram.png'}
      ],
      dialogSelector: null,
      messageFeed: null
    }
  }
}

</script>
