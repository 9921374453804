<template>
  <div>
    <Breadcrumbs primary="Личный кабинет" secondary="Карточка пользователя"/>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
            <user-card-component />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import moment from "moment";
import UserCardComponent from "../../../shared/components/lk/user-card.component.vue";

export default {
  name: "UserCard",
  title: "Карточка пользователя",
  components: {UserCardComponent},

  data() {
    return {

    }
  }
}

</script>