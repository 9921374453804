<template>

  <el-form-item label="Текст сообщения">
    <el-input type="textarea" v-model="modelValue.messageText"/>
  </el-form-item>
  <el-form-item label="Текст кнопки">
    <el-input type="textarea" v-model="modelValue.buttonText"/>
  </el-form-item>

</template>

<script>

export default {
  name: "ContactRequestSettings",
  components: {},
  props: ["modelValue"],
  emits: ['update:modelValue'],
  watch: {},
  beforeMount() {

  },
  mounted() {

  },
  methods: {},
  computed: {},
  data() {
    return {}
  }
}

</script>

<style>

</style>