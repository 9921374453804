<template>
  <div>
    <Breadcrumbs primary="Личный кабинет" secondary="Входящие номера"/>

    <el-row :gutter="40">
      <el-col :span="24">
        <el-card class="box-card">

          <el-row :gutter="40" style="margin-bottom: 40px" v-if="!$store.getters.user.balanceBlocked">
            <el-col :md="24" :xs="24">
              <h4 class="report-parameters-title">Заявка на входящий номер</h4>
              <el-form label-position="top">
                <el-form-item label="Тип номера">
                  <el-select v-model="selectedInboxType" style="width: 100%;">
                    <el-option value=""></el-option>
                    <el-option value="common" label="Общий номер (с индивидуальным префиксом)"/>
                    <el-option value="individual" label="Персональный номер"/>
                  </el-select>
                </el-form-item>
              </el-form>
              <div v-show="selectedInboxType === 'common'">
              <p>Арендная плата: {{formatNumber(prices.common.total)}} руб/мес. ({{formatNumber(prices.common.thisMonth)}} руб. за текущий месяц)</p>
              <p>
                Для добавления входящего ящика SMS введите желаемый префикс.<br/>
                Префикс - это ваш индивидуальный идентификатор на коллективном номере.<br/>
                Чтобы отправить сообщение на коллективный номер, в начале сообщения необходимо указать префикс.<br/>
                При отправке сообщения клиент должен поставить после префикса пробел<br/>
              </p>
              <p>Префиксы не зависят от регистра.</p>
              <el-form label-position="top" ref="commonInboxForm" :rules="validationRules" :model="commonRequest" style="margin-top: 20px">
                <el-form-item label=" Введите префикс (мин. 3 символа, макс. 10 символов, одно слово без пробелов, только латиница)"
                              prop="requestPrefix">
                  <el-input v-model="commonRequest.requestPrefix"></el-input>
                </el-form-item>
                <el-form-item label=" Выберите номер" prop="requestNumber">
                  <el-select class="full-width" v-model="commonRequest.requestNumber">
                    <el-option
                        v-for="(phoneOpt, i) in phoneOptions"
                        :key="i"
                        :label="phoneOpt.phone"
                        :value="phoneOpt.phone"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="success" plain @click="createVirtualInboxRequest">Подать заявку</el-button>
                </el-form-item>
              </el-form>
              </div>
              <div v-show="selectedInboxType === 'individual'">
                <p>Арендная плата {{formatNumber(prices.individual.total)}} руб/мес. ({{formatNumber(prices.individual.thisMonth)}} руб. за текущий месяц)</p>
                <el-button type="success" plain @click="createIndividualInbox()">Запросить персональный номер</el-button>
              </div>
            </el-col>

          </el-row>
          <el-row>
            <el-col :md="24" :xs="24">
              <el-divider class="hidden-md-and-up"/>
              <h4 class="report-parameters-title mid-top-margin-mid-screen">Мои ящики</h4>
              <TableSpinner :loading="dataLoading">
                <el-table  :data="inboxRequests" style="width: 100%" >

                  <el-table-column prop="phone" label="Номер" min-width="120" />
                  <el-table-column prop="prefix" label="Префикс" min-width="120" />
                  <el-table-column :formatter="row => row.type === 'common' ? 'Общий' : 'Индивидуальный'" label="Тип"
                                   min-width="120"/>
                  <el-table-column :formatter="status" label="Статус" min-width="120"/>
                  <el-table-column :formatter="activationPeriod" label="Период подключения" min-width="180" />
                  <el-table-column prop="_id" label="Идентификатор" min-width="201" />
                  <el-table-column align="right">
                    <template #default="scope">
                      <RemoveButton @confirm="remove(scope.row)">Удалить</RemoveButton>
                    </template>
                  </el-table-column>
                </el-table>
              </TableSpinner>
            </el-col>
          </el-row>

        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>



import RemoveButton from "@shared/components/layout/removeButton.component"
import moment from "moment";

export default {
  name: "Inbox",
  title: "Номера для входящих",
  components: {
    RemoveButton
  },
  mounted() {
    this.refreshMyInboxes()
    this.axios.get("/support/getPublicInboxNumbers")
        .then(resp => {
          this.phoneOptions = resp.data.numbers
        })
    this.axios.get("/support/getInboxPrices")
        .then(resp => {
          this.prices = resp.data
        })
  },
  computed: {},
  methods: {
    refreshMyInboxes() {
      this.dataLoading = true
      this.axios.get("/support/getMyInboxes")
          .then(resp => {
            this.inboxRequests = resp.data.filter(r => !r.rejectedByUser && !r.expired).sort((r1, r2) => new Date(r2.createdAt).valueOf() - new Date(r1.createdAt).valueOf())
            this.dataLoading = false
          })
    },
    status(row) {
      return row.status === "approved" ? "Подтвержден" : row.status === "pending" ? "Ожидает подтверждения" : "Отклонен"
    },
    activationPeriod(row) {
      let retval = ""
      if (row.activeFrom) retval += `с ${moment(row.activeFrom).format("DD.MM.YYYY")}`
      if (row.activeTo) retval += `по ${moment(row.activeTo).format("DD.MM.YYYY")}`
      return retval
    },
    createVirtualInboxRequest() {
      this.$refs.commonInboxForm.validate(valid => {
        if (!valid) return
        this.axios.post("/support/createVirtualInboxRequest", {number: this.commonRequest.requestNumber, prefix: this.commonRequest.requestPrefix})
            .then(resp => {
              if (resp.data.success) {
                this.$refs.commonInboxForm.resetFields()
                this.$gNotify("Заявка на общий номер создана", "success")
                this.refreshMyInboxes()
              } else
                this.$gNotify(resp.data.error === "non_unique_prefix" ? "Ящик с таким префиксом уже зарегистрирован в системе" : resp.data.error === "nen_money" ? "Недостаточно денег на счету" : "Неизвестная ошибка", "error")

            })
      })
    },
    createIndividualInbox() {
      this.axios.post("/support/createInboxRequest")
          .then(resp => {
            this.$gNotify("Заявка на персональный номер создана", "success")
            this.refreshMyInboxes()
          })
    },
    remove(row) {
      this.axios.delete("/support/removeInbox", {params: {inboxId: row._id}})
          .then(resp => {
            this.$gNotify("Ящик успешно удален", "success")
            this.refreshMyInboxes()
          })
    },
    validatePrefix(rule, value, callback) {
      console.log(value)
      if (!value || !/^[a-z]{3,10}$/gi.test(value.trim()))
        callback(new Error('Префикс не соответствует требованиям'))
      else callback()
    }
  },
  data() {
    return {
      dataLoading: false,
      commonRequest: {
        requestPrefix: "",
        requestNumber: "",
      },
      prices: {
        common: {total: 0, thisMonth: 0},
        individual: {total: 0, thisMonth: 0}
      },
      selectedInboxType: "",
      phoneOptions: [],
      inboxRequests: [],
      validationRules: {
        requestPrefix: [{required: true, validator: this.validatePrefix, trigger: 'change'}],
        requestNumber: [{required: true, message: "Выберите номер", trigger: 'change'}]
      }
    }
  }
}

</script>