import {markRaw, reactive} from "vue";
import {v1} from "uuid"
import StartNode from "./start.node.vue"
import {default as MessageChainNode, settingsModel as messageChainSettingsModel} from "./messageChain.node.vue";
import {default as ContactRequestNode, settingsModel as contactRequestSettingsModel} from "./contactRequest.node.vue";
import {settingsModel as addRemoveTagSettingsModel} from "./addRemoveTag.node.vue";
import {settingsModel as setRemoveVariableSettingsModel} from "./setRemoveVariable.node.vue";
import {settingsModel as switchCaseSettingsModel} from "./switchCase.node.vue";

function getDefaultSettings(nodeType){
  switch(nodeType){
    case "messageChain":
      return messageChainSettingsModel()
    case "contactRequest":
      return contactRequestSettingsModel()
    case "addTag":
      return addRemoveTagSettingsModel()
    case "removeTag":
      return addRemoveTagSettingsModel()
    case "setVariable":
      return setRemoveVariableSettingsModel()
    case "removeVariable":
      return setRemoveVariableSettingsModel()
    case "switchCase":
      return switchCaseSettingsModel()
    default:
      return {}
  }
}

function nodeFactory(type, data, onRemove) {
  switch (type) {
    case "start":
      return {
        id: String(v1()),
        type: 'start',
        position: {x: 10, y: 10},
        class: 'light',
        label: "Start"
      }
    default:
      data.settings = data.settings || getDefaultSettings(type)
      return {
        id: String(v1()),
        type,
        position: {x: 0, y: 100},
        class: 'light',
        data: reactive(data),
        events: {
          removeMe: onRemove
        }
      }
  }
}

const nodeTypes = {
  // start: markRaw(StartNode),
  // messageChain: markRaw(MessageChainNode),
  // contactRequest: markRaw(ContactRequestNode),
}

export {
  nodeTypes,
  nodeFactory
}

// module.exports = {
//   nodes: {
//     StartNode
//   },
//   ,
//   nodeFactory
// }