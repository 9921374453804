<template>
  <div>
    <form style="display: none" method="POST" id="exportForm"
          :action="`${axios.defaults.baseURL}/contacts/exportGroups`">
      <input name="params" type="hidden" id="exportParams"/>
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>
    <el-row>
      <el-col :md="16" :xs="24">
        <el-form label-position="top">
          <!--          <h4 style="margin-top: 0px">Использовать формат:</h4>-->
          <el-form-item label="Использовать формат">
            <el-select v-model="exportFormat" style="width: 100%;">
              <el-option value="semicolon" label="CSV, разделитель точка с запятой (;)"></el-option>
              <el-option value="comma" label="CSV, разделитель запятая (,)"></el-option>
              <el-option value="tabulation" label="CSV, разделитель табуляция (tab)"></el-option>
            </el-select>

          </el-form-item>

          <!--        <h4>Группы контактов</h4>-->
          <el-form-item label="Группы контактов">
            <el-select v-model="selectedGroups" multiple style="width: 100%">
              <el-option
                  v-for="item in allGroups"
                  :key="item._id"
                  :label="item.name"
                  :value="item._id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span
                    style="
          float: right;
          color: var(--el-text-color-secondary);
          font-size: 13px;
        "
                >{{ item.size }}</span
                >
              </el-option>

            </el-select>
          </el-form-item>
        </el-form>
        <!--        <TableSpinner :loading="groupsLoading">-->
        <!--          <el-table-->
        <!--              :data="allGroups" style="width: 100%; margin-top: 10px"-->
        <!--              @selection-change="handleGroupSelected"-->
        <!--              border-->
        <!--          >-->
        <!--            <el-table-column type="selection" width="55" align="center"/>-->
        <!--            <el-table-column prop="name" label="Наименование" align="center"/>-->
        <!--            <el-table-column prop="size" label="Номеров" align="center"></el-table-column>-->
        <!--          </el-table>-->
        <!--        </TableSpinner>-->
        <el-button type="success" @click="exportGroups()" :disabled="selectedGroups.length === 0" :loading="exporting"
                   style="margin-top: 0px">Скачать файл
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>


<script>


export default {
  name: "ExportGroups",
  props: ["allGroups"],
  components: {
    },
  mounted() {
    // this.loadGroups()
  },
  computed: {},
  methods: {
    handleGroupSelected(groups) {
      this.selectedGroups = groups
    },
    exportGroups() {
      // this.exporting = true
      document.getElementById("exportParams").value = JSON.stringify({
        exportFormat: this.exportFormat,
        groups: this.selectedGroups//.map(g => g._id)
      })
      document.getElementById("exportForm").submit()
      // this.exporting = false
    },
    // loadGroups() {
    //   this.groupsLoading = true
    //   this.axios.get("/contacts/myGroups")
    //       .then(resp => {
    //         this.allGroups = resp.data
    //         this.groupsLoading = false
    //       })
    // }
  },
  data() {
    return {
      exporting: false,
      groupsLoading: false,
      exportFormat: "semicolon",
      // allGroups: [],
      selectedGroups: []
    }
  }
}

</script>