<template>
  <div>

    <el-dialog
        v-model="intVisible"
        title="Перенос контактов"
        class="modal-95-70-60"
        @close="$emit('close')"
    >

      <el-row>
        <el-col :span="24">
          <el-form label-position="top">
            <el-form-item label="Исходные группы">
              <el-select
                  v-model="sourceGroups"
                  multiple
                  style="width: 100%"
              >
                <el-option
                    v-for="item in allGroups"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="Целевая группа">
              <el-select
                  v-model="targetGroup"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in allGroups"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                />
              </el-select>
            </el-form-item>

          </el-form>
        </el-col>
      </el-row>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" :loading="loading" @click="moveContacts">Переместить</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "MoveContacts",
  components: {},
  props: ["visible", "allGroups"],
  watch: {
    visible(v){
      this.intVisible = v
    }
  },
  methods: {
    moveContacts() {
      this.loading = true
      this.axios.post("/contacts/moveContacts", {fromGroups: this.sourceGroups, toGroup: this.targetGroup})
          .then(resp => {
            this.loading = false
            this.$gNotify("Контакты перенесены", "success")
            this.$emit("contactsMoved")
          })
          .catch(console.error)
    }
  },
  data() {
    return {
      loading: false,
      sourceGroups: [],
      targetGroup: "",
      intVisible: false
    }
  }
}

</script>