<template>
<!--  <div style="display: flex;flex-direction: row; align-items: center; justify-content: space-between">-->
<!--    <span class="no-margin">{{label}}</span>-->
<!--    <el-progress :percentage="percentage" :color="__color" style="height:19px; width: 80%; margin: 0px 20px;" :show-text="false"/>-->
<!--    <span class="no-margin"><span class="text-bold">{{displayMessages}}</span>&nbsp; {{ msgLabelText }}</span>-->
<!--  </div>-->
  <el-row style="margin-bottom: 15px">
    <el-col :span="4" style="text-align: right; padding-right: 20px">
      <span class="no-margin" style="font-size: 14px">{{label}}</span>
    </el-col>
    <el-col :span="16" class="align-center">
      <el-progress :percentage="percentage" :color="__color" style="height:19px" :show-text="false"/>
    </el-col>
    <el-col :span="4" style="text-align: left; padding-left: 20px">
      <span class="no-margin" style="font-size: 14px"><span class="text-bold">{{displayMessages}}</span>&nbsp; адр.</span>
    </el-col>
  </el-row>
</template>

<script>

export default {
  props: [
    "globalTotalMessages",
    "displayMessages",
    "label",
    "color"
  ],
  name: "MsgProgressBar",
  components: {},
  computed: {
    msgLabelText(){
      if(String(this.displayMessages).endsWith("1")) return "адресат"
      if(String(this.displayMessages).endsWith("2") || String(this.displayMessages).endsWith("3") || String(this.displayMessages).endsWith("4")) return "адресата"
      return "адресатов"
    },
    percentage(){
      return (this.displayMessages / Math.max(this.globalTotalMessages, 1)) * 100
    },
    __color(){
      if(this.color === "blue") return "#2f8ecb"
      if(this.color === "green") return "#37acab"
      if(this.color === "red") return "#e32e3e"
      return ""
    }
  },
  data() {
    return {}
  },
}

</script>