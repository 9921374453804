<template>
  <p style="word-break: break-word">
    Для регистрации имени отправителя по операторам МТС, Мегафон, Билайн и Теле2 по тарифу "Профи" необходимо
    предоставить подтверждающие документы на имя - сертификат на домен или товарный знак. Наименование домена и/или
    товарного знака должно совпадать с именем отправителя. Также имя может совпадать с наименованием юр. лица, в
    таком случае необходимо предоставить свидетельство ИНН/ОГРН.
    <br/>
  </p>
  <p>По оператору Теле2 для регистрации по тарифу "Профи" также необходимо предоставить заполненное гарантийное
    письмо (<a
        :href="`${axios.defaults.baseURL}/sender_names/downloadTele2Letter?token=${$store.getters.bareToken}`"
        target="_blank">образец</a>).</p>
  <br/>
  <p style="font-weight: bold; word-break: break-word">
    <span style="color: red">*</span>&nbsp;Тариф "Профи" подразумевает списание фиксированной абонентской платы,
    установленной оператором. При успешной регистрации имени отправителя на данном тарифе цены будут ниже + будет
    возможность тарификации Сервисных/Авторизационных сообщений</p>
  <p style="font-weight: bold; word-break: break-word"><span style="color: red">*</span>&nbsp;Абонентская плата
    списывается каждое первое число месяца. В случае, когда платное имя активируется внутри месяца, абонентская
    плата списывается в полном объёме в дату активации и далее первого числа следующего месяца</p>
  <p style="font-weight: bold; word-break: break-word"><span style="color: red">*</span>&nbsp;Для отправки имени на
    платную регистрацию у Вас на балансе должна быть необходимая сумма для списания</p>
  <p style="font-weight: bold; word-break: break-word"><span style="color: red">*</span>&nbsp;Для отзыва имени с
    платного тарифа необходимо направить запрос в личном кабинете или менеджеру не позднее, чем за 3 рабочих дня до
    конца месяца</p>
  <el-divider class="blue-divider"/>
</template>

<script>
export default {
  name: "ClientSenderNamesApplicationDescription",
  data() {
    return {}
  }
}

</script>
