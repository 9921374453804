<template>
  <div>
    <el-row>
      <el-col :xs="24" :md="12">
        <el-form label-position="top" v-model="settings">
          <el-form-item label="URL">
            <el-input v-model="settings.url"/>
          </el-form-item>
          <el-form-item label="">
            <el-switch v-model="settings.hooks.onSubscribe"/>&nbsp;Новый подписчик
          </el-form-item>
          <el-form-item label="">
            <el-switch v-model="settings.hooks.onUnsubscribe"/>&nbsp;Отписка
          </el-form-item>
          <el-form-item label="">
            <el-switch v-model="settings.hooks.onInMessage"/>&nbsp;Входящее сообщение
          </el-form-item>
          <el-form-item label="">
            <el-switch v-model="settings.hooks.onOutMessage"/>&nbsp;Исходящее сообщение
          </el-form-item>
          <el-form-item label="">
            <el-button type="success" @click="saveHooks()" :loading="saving">Сохранить</el-button>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>

  </div>
</template>

<script>

export default {
  name: "TgBotWebhooks",
  props: ["bot"],
  components: {},
  watch: {
    bot(v){
      if(v && v.webhooks){
        this.settings.url = v.webhooks.url || ""
        this.settings.hooks.onSubscribe = v.webhooks.hooks.onSubscribe || false
        this.settings.hooks.onUnsubscribe = v.webhooks.hooks.onUnsubscribe || false
        this.settings.hooks.onInMessage = v.webhooks.hooks.onInMessage || false
        this.settings.hooks.onOutMessage = v.webhooks.hooks.onOutMessage || false
      }
    }
  },
  methods: {
    saveHooks() {
      this.saving = true
      this.axios.post("/chatbot/setWebhooks", {botId: this.bot._id, settings: this.settings})
          .then(resp => {
            this.saving = false
            this.$gNotify("Настройки обновлены", "success")
          })
          .catch(e => {
            this.saving = false
            this.$gNotify("Ошибка", "error")
          })
    }
  },
  computed: {},
  data() {
    return {
      saving: false,
      settings: {
        url: "",
        hooks: {
          onSubscribe: false,
          onUnsubscribe: false,
          onInMessage: false,
          onOutMessage: false
        }
      }
    }
  }
}

</script>
