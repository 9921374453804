<template>
  <el-dialog
      v-model="intVisible"
      title="Новый блок"
      class="modal-95-70-60"
      @close="$emit('close')"
  >

    <el-row>
      <el-col :span="24">
        <el-input placeholder="Название блока" v-model="searchToken" style="margin-bottom: 20px" />
        <div
            class="new-block-option"
            v-for="block in blockOptions"
            @click="$emit('blockSelected', JSON.parse(JSON.stringify(block)))"
        >
          <p class="new-block-title" >{{block.title}}</p>
          <p class="new-block-description">{{block.description}}</p>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>


export default {
  name: "NewAlgoBlock",
  components: {},
  props: ["visible"],

  watch: {
    visible(v){
      this.intVisible = v
    }
  },
  beforeMount() {

  },
  mounted() {

  },
  methods: {

  },
  computed: {},
  data() {
    return {
      intVisible: false,
      searchToken : "",
      blockOptions: [
        {title: "Цепочка сообщений", description: "Отправьте одно или несколько сообщений пользователю бота", type: "messageChain"},
        {title: "Запрос контакта", description: "Запросите у подписчика номер телефона. Подписчикам с известным номером телефона можно отправлять СМС/Звонки и даже писать во Вконтакте", type: "contactRequest"},

        {title: "Условное ветвление", description: "Добавьте одно или несколько условий чтобы ветвить алгоритм", type: "switchCase"},

        {title: "Добавить тег", description: "Назначьте теги подписчику", type: "addTag"},
        {title: "Удалить тег", description: "Удалите теги у подписчика", type: "removeTag"},

        {title: "Записать переменную", description: "Запишите данные в переменную подписчика. Может пригодиться, если нужно запросить у клиента дополнительную информацию", type: "setVariable"},
        {title: "Удалить переменную", description: "Удалите переменную у подписчика", type: "removeVariable"},

        {title: "HTTP запрос", description: "Запросите данные у стороннего сервиса по протоколу HTTP. А можете просто отправить на свой сервер уведомление", type: "httpRequest"},

        {title: "Заблокировать подписчика", description: "Данные об этом подписчике останутся в системе, но ему нельзя отправлять сообщениям, и бот не будет реагировать на его запросы", type: "blockSubscriber", noSettings: true},
        {title: "Удалить подписчика", description: "Все данные об этом подписчике будут стёрты, а бот перестанет отвечать на его запросы", type: "removeSubscriber", noSettings: true},



      ]
    }
  }
}

</script>

<style>
.new-block-option{
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid var(--el-text-color-secondary);
  cursor: pointer;
}
.new-block-title{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;

}
.new-block-description{
  font-weight: normal;
  font-size: 14px;
  margin: 0px
}

.new-block-option:hover{
  background: rgba(0, 0, 0, .1);
}
</style>