<template>

  <h4 style="margin-top: 0px">{{ SENDERS_OPERATOR_CONFIG[operatorKey].name }}</h4>
  <el-radio-group v-model="form.type" style="display: block">
    <el-radio :label="'common'" style="display: block" v-if="!SENDERS_OPERATOR_CONFIG[operatorKey].freeNameAvailable">
      Использовать общее имя (Индивидуальное имя на бесплатном <a href="https://smsgold.ru/plans/" target="_blank">тарифе</a>
      недоступно)
      <el-tooltip
          class="box-item"
          effect="light"
          content="Общее имя - имя, которое явным образом не отображает бренд или товар, к примеру SMSINFO"
          placement="right-start"
      >
        <font-awesome-icon icon="question-circle"></font-awesome-icon>
      </el-tooltip>
    </el-radio>
    <el-radio :label="'common'" style="display: block" v-if="SENDERS_OPERATOR_CONFIG[operatorKey].freeNameAvailable">
      Зарегистрировать на <a href="https://smsgold.ru/plans/" target="_blank">тарифе</a> без абонентской платы
    </el-radio>


    <el-radio :label="'individual'" style="display: block">Зарегистрировать на <a href="https://smsgold.ru/plans/"
                                                                                  target="_blank">тарифе</a> с
      абонентской платой
      {{ parseInt($store.getters.defaultSenderNamesPrices[operatorKey]) }}
      руб/мес
      <span v-if="operatorKey === 'beeline'">(одно имя на одно юр. лицо бесплатно)</span>
    </el-radio>
  </el-radio-group>

  <el-select class="full-width" v-model="form.activation" v-show="form.type==='individual'" style="margin-top:15px">
    <el-option label="Активировать сразу по согласованию с оператором" value='immediate'/>
    <el-option label="Активировать с 1 числа следующего месяца" value="next_month"/>
  </el-select>
  <el-upload
      v-show="form.type === 'individual'"
      :auto-upload="false" style="margin-top:15px"
      v-model:file-list="form.files"
      :multiple="true"
  >
    <template #trigger>
      <el-button type="primary" size="small">Загрузить файлы</el-button>
    </template>
  </el-upload>


</template>

<script>

export default {
  name: "ClientSenderNameApplicationOperatorForm",
  props: [
    "operatorKey",
  ],
  watch: {
    operatorKey(){
      this.reset()
    }
  },
  methods: {
    reset(){
      this.form = this.initialState()
    },
    initialState(){
      return {
        type: 'common',
        files: [],
        activation: 'immediate'
      }
    },
    async getState(){
      let retval = Object.assign({}, this.form)
      retval.operKey = this.operatorKey
      retval.operName = SENDERS_OPERATOR_CONFIG[this.operatorKey].name
      if (this.form.type === 'individual' && !this.form.files.length)
        return "E_NO_FILES"
      else {
        retval.filesParsed = await Promise.all(this.form.files.map(async f => {
          return {
            name: f.name,
            mime: f.raw.type,
            content: await this.$fileToBase64(f.raw)
          }
        }))
        delete retval.files
      }
      return retval
    }
  },
  data() {
    return {
      form: this.initialState(),
      SENDERS_OPERATOR_CONFIG
    }
  }
}

</script>
