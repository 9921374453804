<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="СМС ожидающие оплаты"/>
    <el-row :gutter="20">
      <el-col :span="24">
        <TableSpinner :loading="dataLoading">
          <h3 v-if="unpayedGroups.length === 0" class="align-center">Нет сообщений</h3>
          <el-card
              class="box-card"
              v-for="(group, i) in unpayedGroups"
              :key="i"
              style="margin-bottom: 20px"
          >
            <h4 class="report-parameters-title">
              <span style="display: inline-block;  margin-right: 15px">{{ group.task.name }}</span>
              <span
                  style="color: var(--el-color-danger) !important;font-weight: normal; display: inline-block; white-space: nowrap"
              >Осталось времени: {{ timeLeft(group) }}</span>
            </h4>
            <el-table :data="getGroupTableData(group)">
              <el-table-column label="Время рассылки" prop="date" min-width="100" />
              <el-table-column label="Количество" prop="amount" min-width="110" />
              <el-table-column label="Подпись" prop="sender" min-width="100" />
              <el-table-column label="Текст" prop="text" min-width="100" />
              <el-table-column label="Стоимость" :formatter="row => formatNumber(row.price)" prop="price" min-width="100" />
            </el-table>

            <el-row style="margin-top: 20px">
              <el-col :md="12" :xs="24">
                <el-button type="success" @click="resendGroup(group)" :loading="removeLoading">Разослать</el-button>
                <RemoveButton @confirm="removeGroup(group)" :regular="true" :loading="removeLoading" type="default" style="background-color: #D9D9D9">Удалить</RemoveButton>
              </el-col>
            </el-row>


          </el-card>
        </TableSpinner>
      </el-col>
    </el-row>

  </div>
</template>

<script>



import moment from "moment"
import RemoveButton from "@shared/components/layout/removeButton.component";

export default {
  name: "AwaitingPayment",
  title: "Ожидающие оплаты",
  components: {
    RemoveButton,


  },
  mounted() {
    this.loadGroups()
    this.__timer = setInterval(() => {
      this.__moment = moment()
    })
  },
  beforeDestroy() {
    clearInterval(this.__timer)
  },
  methods: {
    loadGroups() {
      this.dataLoading = true
      this.axios.get("/sms/getUnpayedGroups")
          .then(resp => {
            this.dataLoading = false
            this.unpayedGroups = resp.data.groups.filter(g => this.groupVisible(g))
            if(!this.unpayedGroups.length) this.$emitter.emit("unpaidGroupsCleared")
          })
    },
    nameAndTime(row) {
      return moment(row.subtask.start_date).format("DD.MM.YYYY HH:mm:ss")
    },
    groupVisible(group){
      return moment(group.subtask.start_date).add(7, "days").diff(this.__moment, "seconds") >= 0
    },
    timeLeft(group) {
      let minDelta = moment(group.subtask.start_date).add(7, "days").diff(this.__moment, "seconds")
      let seconds = minDelta % 60
      let minutes = (minDelta / 60) % 60
      let hours = (minDelta / 60 / 60) % 24
      let days = (minDelta / 60 / 60 / 24)
      return `${parseInt(days)}д. ${parseInt(hours)} ч.  ${parseInt(minutes)} м.  ${seconds} с.`
      // return moment().startOf("day").seconds(minDelta).format("HH:mm:ss")
    },
    removeGroup(group) {
      this.removeLoading = true
      this.axios.post("/sms/removeUnpayedGroup", {groupId: group.subtask._id})
          .then(resp => {
            this.removeLoading = false
            this.loadGroups()
          })
    },
    resendGroup(group) {
      this.removeLoading = true
      this.axios.post("/sms/resendUnpayedGroup", {groupId: group.subtask._id})
          .then(resp => {
            this.removeLoading = false
            this.$router.push({path: `/cab/messages/sending_statistics/${group.subtask._id}`})
          })
    },
    getGroupTableData(group){
      return [{
        date: this.nameAndTime(group),
        amount: group.count,
        sender: group.task.sendData.sms_sender_name,
        text: group.task.sendData.sms_text,
        price: group.total_price
      }]
    }
  },
  computed: {},
  data() {
    return {
      __timer: null,
      __moment: moment(),
      dataLoading: false,
      unpayedGroups: [],
      removeLoading: false,
    }
  },
}

</script>