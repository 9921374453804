<template>

  <div class="message-chain-element" v-for="(message, i) in modelValue.messages">
    <font-awesome-icon icon="times" class="remove-flow-subelement-button" @click="removeMessage(message)" v-if="modelValue.messages.length > 1"/>
    <el-form-item label="Текст сообщения">
      <el-input type="textarea" v-model="message.text"/>
    </el-form-item>
    <el-divider>Кнопки</el-divider>
    <div class="message-button-def" v-for="button in message.buttons">
      <font-awesome-icon icon="times" class="remove-flow-subelement-button" @click="removeButton(message, button)"/>
      <el-form-item label="Текст">
        <el-input v-model="button.text" placeholder="Текст кнопки" size="small"/>
      </el-form-item>
      <el-form-item label="Тип" style="margin-bottom: 0px">
        <el-radio-group v-model="button.type">
          <el-radio label="continueAlgo">Продолжить алгоритм</el-radio>
          <el-radio label="url">URL</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="URL" v-if="button.type === 'url'" style="margin-top: 15px; margin-bottom: 0px">
        <el-input v-model="button.url" size="small"/>
      </el-form-item>
    </div>
    <el-button size="small" type="default" style="width:100%; justify-content: center; padding: 5px;"
               @click="addButton(message)">Добавить кнопку
    </el-button>
  </div>
<!--  <el-divider><font-awesome-icon icon="chevron-down"/></el-divider>-->
  <el-button type="primary" @click="addMessage()" style="width: 100%; justify-content: center" size="small">Добавить
    сообщение
  </el-button>
  <el-divider class="blue-divider"/>

</template>

<script>

import {oneMessageModel} from "../nodes/messageChain.node.vue";
import {v1} from "uuid";

export default {
  name: "MessageChainSettings",
  components: {},
  props: ["modelValue"],
  emits: ['update:modelValue'],
  watch: {},
  beforeMount() {

  },
  mounted() {

  },
  methods: {
    addMessage() {
      this.modelValue.messages.push(oneMessageModel())
    },
    addButton(msg) {
      msg.buttons.push({
        text: "",
        type: "continueAlgo",
        url: "",
        id: v1()
      })
    },
    removeButton(message, button) {
      message.buttons.splice(message.buttons.findIndex(b => b.id === button.id), 1)
    },
    removeMessage(message) {
      this.modelValue.messages.splice(this.modelValue.messages.findIndex(m => m.id === message.id), 1)
    }
  },
  computed: {},
  data() {
    return {}
  }
}

</script>

<style>

.message-chain-element {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
}

.message-button-def {
  /*border: 1px solid #c9c9c9;*/
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: white
}

.remove-flow-subelement-button{
  position: absolute; top: 10px; right: 10px; color: var(--el-color-danger); font-size: 16px; cursor: pointer
}

</style>