<template>

  <el-form-item label="Название переменной">

    <el-autocomplete
        v-model="modelValue.name"
        placeholder="Добавьте или выберите переменную"
        :fetch-suggestions="(_, cb) => cb(bot.tags.filter(t => t.includes(modelValue.name)).map(t => {return {value: t}}))"
        @select="v => modelValue.name = v.value"
        size="small"
        class="no-padding-append"
        style="width: 100%; margin-top: 5px;"
    >
    </el-autocomplete>

  </el-form-item>
  <el-form-item label="Значение" v-if="type === 'setVariable'">
    <el-input v-model="modelValue.value"/>
  </el-form-item>

</template>

<script>

export default {
  name: "SetRemoveVariableSettings",
  components: {},
  props: ["modelValue", "bot", "type"],
  emits: ['update:modelValue'],
  watch: {},
  beforeMount() {

  },
  mounted() {

  },
  methods: {

  },
  computed: {},
  data() {
    return {

    }
  }
}

</script>

<style>

</style>