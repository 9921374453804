<template>
  <div class="tasks-dropdown" style="border-right: 1px solid #CCC; padding: 7.5px 10px; height: 100%" v-show="activeTasks.length">
    <el-dropdown trigger="click">
      <el-button type="warning">
        <font-awesome-icon icon="list" />&nbsp;{{ activeTasks.length }}
      </el-button>
      <template #dropdown>
        <el-dropdown-menu class="non-clickable-dropdown">
          <el-dropdown-item
              v-if="!activeTasks.length"
              class="task-container"
          >
            Нет активных задач
          </el-dropdown-item>
          <el-dropdown-item
              v-for="task in baseLoadTasks"
              class="task-container default-task-container"
          >
            <p>{{ task.name }}</p>
            <el-progress
                :percentage="task.progress || 0"
                :status="task.progress === 100 ? 'success' : ''"
                :text-inside="true"
                :stroke-width="24"
                style="width: 100%"
            />
          </el-dropdown-item>
          <el-dropdown-item
              v-for="task in unpaidMessagesTasks"
              class="task-container red-task-container"

          >
            <span>Есть сообщения ожидающие оплаты</span>
            <router-link to="/cab/messages/awaiting_payment">Перейти</router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>


export default {
  name: "TasksDropdown",
  components: {},
  mounted() {
    this.refresh()
    this.$emitter.on("taskCreated", (payload) => {
      this.activeTasks.push(JSON.parse(payload))
    })
    this.$emitter.on("unpaidGroupsAppeared", (payload) => {
      if(payload.popup)
        this.$gNotify("Есть сообщения ожидающие оплаты", "error")
      else if(!this.activeTasks.find(t => t.type === "unpaidMessages"))
        this.activeTasks.push({type: "unpaidMessages"})
    })
    this.$emitter.on("unpaidGroupsCleared", (payload) => {
      this.activeTasks = this.activeTasks.filter(t => t.type !== "unpaidMessages")
    })
    this.$emitter.on("baseLoadProgressGlobal", (payload) => {
      payload = JSON.parse(payload)

      let task = this.activeTasks.find(t => t.id === payload.taskId)
      if(!task) return
      if(payload.status === 1)
        setTimeout(() => {
          this.activeTasks = this.activeTasks.filter(t => t.id !== payload.taskId)
        }, 3000)
      else
        task.progress = parseInt((payload.processedCount / payload.totalLines) * 100) || 0
    })
  },
  beforeUnmount() {
    this.$emitter.off("baseLoadProgressGlobal")
    this.$emitter.off("taskCreated")
  },
  methods: {
    refresh() {
      this.dataLoading = true
      this.axios.get("/user/getMyActiveTasks")
          .then(resp => {
            this.dataLoading = false
          })
          .catch(console.error)
    }
  },
  computed: {
    baseLoadTasks(){
      return this.activeTasks.filter(t => t.type === "baseLoad")
    },
    unpaidMessagesTasks(){
      return this.activeTasks.filter(t => t.type === "unpaidMessages")
    }
  },
  data() {
    return {
      activeTasks: []
    }
  }
}

</script>

<style>
.task-container {
  min-width: 300px;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black !important;
}

.task-container p{
  margin-top: 0px;
  margin-bottom: 5px;
}

.task-container span:first-child {
  margin-bottom: 10px;
}
.non-clickable-dropdown{
  padding: 0px !important;
}
.non-clickable-dropdown li:hover,
.non-clickable-dropdown li:hover *{
  color: black !important;
}

.task-container.default-task-container,
.non-clickable-dropdown li.task-container.default-task-container:hover{
  background-color: #c6e2ff !important;
  cursor: initial !important;
}
.task-container.red-task-container,
.non-clickable-dropdown li.red-task-container:hover{
  background-color: rgba(245, 108, 108, .2) !important;
}
</style>