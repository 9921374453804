<template>
  <DefaultNode @removeMe="events.removeMe(id)">
    <template #header>
      <span>{{data.type === 'addTag' ? 'Назначить теги' : 'Удалить теги'}}</span>
      <Handle type="target" position="left"/>

    </template>
    <template #body>
      <el-tag v-for="tag in data.settings.tags" type="danger" style="margin-bottom: 5px">{{ tag }}</el-tag>
      <Handle type="source" position="right" />
    </template>
  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";

export default {
  name: "AddRemoveTagNode",
  components: {DefaultNode, Handle},
  props: ["events", "id", "data"],
  watch: {},
  mounted(){},
  methods: {},
  computed: {},
  data() {
    return {}
  }
}

export let settingsModel = () => {
  return {
    tags: [],
  }
}
</script>