<template>
    <el-input v-model="interVal" @input="formatValue" ref="theInput"/>

</template>

<script>

let PLACEHOLDER = "+7 (___) ___-__-__"
let POS_MAP = {
  0: 4,
  1: 5,
  2: 6,
  3: 9,
  4: 10,
  5: 11,
  6: 13,
  7: 14,
  8: 16,
  9: 17
}
export default {
  name: "PhoneInput",
  props: ["modelValue", "fullWidth"],
  methods: {
    formatValue(newVal){
      // if(newVal.length === 11 && (newVal.startsWith("7") || newVal.startsWith("8")))
      //   newVal = newVal.slice(1)
      // console.log("Called", newVal, this.prevInterVal)

      let digits = newVal.replace(/\D/g, '')
      let digitsOld = this.prevInterVal.replace(/\D/g, '')


      let visibleVal = digits.slice(1).split("").reduce((acc, symbol, i) => {
        if(i > 9) return acc
        // console.log(acc, symbol, i, POS_MAP[i])
        acc = acc.substring(0, POS_MAP[i]) + symbol + acc.substring(POS_MAP[i] + 1)
        return acc
      }, PLACEHOLDER + "")


      this.$emit("update:modelValue", visibleVal.replace(/\D/g, ''))
      this.interVal = visibleVal
      this.prevInterVal = visibleVal
      this.$refs.theInput.input.value = String(newVal)
      let cursorPosition = this.$refs.theInput.input.selectionStart
      // console.log("Original cursor", this.$refs.theInput.input.selectionStart)
      if(newVal.length < this.prevInterVal.length) {
        if (digitsOld.length === digits.length) {
          let newCPosition = this.prevInterVal.slice(0, cursorPosition).split("").reverse().findIndex(v => /\d/.test(v))
          // console.log("positionShift", newCPosition)
          if (newCPosition >= 0) {
            cursorPosition = cursorPosition - newCPosition
            if (cursorPosition < POS_MAP[0]) cursorPosition = POS_MAP[0]
          } else cursorPosition = -1
        }//else{
        //   console.log("Case digits removal")
        //   cursorPosition -= 1
        // }
      }else
        cursorPosition = POS_MAP[newVal.replace(/\D/g, '').slice(1).length]
      if(cursorPosition >= 0)
        this.$nextTick(() => {
          // console.log("C position", cursorPosition)
          // console.log(this.$refs.theInput.input)
          setTimeout(() => {
            this.$refs.theInput.input.focus()
            this.$refs.theInput.input.setSelectionRange(cursorPosition, cursorPosition)
          }, 1)
        })

    }
  },
  watch: {
    // interVal(newVal){
    //
    // }
  },
  beforeUnmount() {
    // this.$refs.theInput.input.removeEventListeners()
  },
  mounted() {
    // this.$refs.theInput.input.value = this.interVal
    // this.$refs.theInput.input.addEventListener("input", this.formatValue)
  },
  data() {
    return {
      interVal: String(PLACEHOLDER),
      prevInterVal: String(PLACEHOLDER),
    }
  },
}

</script>