<template>
  <el-row justify="center">
    <el-col :span="20">
      <TableSpinner :loading="loading" :rows="20" style="margin-top: 80px">
        <span v-html="offer"/>
      </TableSpinner>
    </el-col>
  </el-row>
</template>

<script>



export default {
  name: "Ofert",
  title: "Оферта",
  mounted() {

    this.loading = true
    this.axios.get("/resources/offer")
        .then(resp => {
          this.loading = false
          this.offer = resp.data
        })
        .catch(console.error)

  },
  data() {
    return {
      loading: false,
      offer: ""
    }
  },
}

</script>