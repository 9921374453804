<template>

  <el-form v-model="filter" label-position="top" style="margin-bottom: 15px">
    <el-form-item label="Статус" v-show="!hideStatusSelect">
      <el-select v-model="filter.status" style="width: 100%" size="small">
        <el-option label="Все" value=""></el-option>
        <el-option label="Активные" value="active"></el-option>
        <el-option label="Заблокированные" value="blocked"></el-option>
        <el-option label="Отписанные" value="unsubscribed"></el-option>
      </el-select>
    </el-form-item>
<!--    <el-divider/>-->
    <div v-for="fil in filter.filters"
         style="display: flex; flex-direction: row; align-items: center; margin-bottom: 15px; justify-content: flex-start">
      <el-select v-model="fil.type" style="display: flex; flex: 1; margin-right: 15px" size="small" placeholder="Тип" @change="fixFilterValueType(fil)">
        <el-option label="" value=""></el-option>
        <el-option label="Имя" value="name"></el-option>
        <el-option label="Телефон" value="phone"></el-option>
        <el-option label="Теги" value="tags"></el-option>
        <el-option-group label="Переменные">
          <el-option v-for="v in bot.variables || []" :label="v" :value="'var_'+v" />
        </el-option-group>
      </el-select>
      <el-select v-model="fil.condition" style="display: flex; flex: 1; margin-right: 15px" size="small"
                 placeholder="Условие" :disabled="!fil.type">
        <el-option-group v-if="['name', 'phone'].includes(fil.type)">
          <el-option label="Равно" value="equals"></el-option>
          <el-option label="Не равно" value="not_equals"></el-option>
        </el-option-group>
        <el-option-group v-if="fil.type === 'tags'">
          <el-option label="Назначены" value="assigned"></el-option>
          <el-option label="Не назначены" value="not_assigned"></el-option>
        </el-option-group>
        <el-option-group v-if="fil.type.startsWith('var_')">
          <el-option label="Содержит" value="includes"></el-option>
          <el-option label="Не содержит" value="excludes"></el-option>
          <el-option label="Равно" value="equals"></el-option>
          <el-option label="Не равно" value="not_equals"></el-option>
          <el-option label="Начинается с" value="starts_with"></el-option>
          <el-option label="Заканчивается на" value="ends_with"></el-option>
          <el-option label="Определена" value="defined"></el-option>
          <el-option label="Не определена" value="undefined"></el-option>
        </el-option-group>
      </el-select>

      <el-input
          v-if="['name', 'phone'].includes(fil.type) || (fil.type.startsWith('var_') && !['defined', 'undefined'].includes(fil.condition)) || !fil.type"
          size="small"
          v-model="fil.value"
          style="display: flex; flex: 1; margin-right: 15px"
          placeholder="Значение" :disabled="!fil.type"
      />

      <el-select v-if="fil.type === 'tags'" v-model="fil.value" style="display: flex; flex: 1; margin-right: 15px" size="small"
                 placeholder="Значение" :disabled="!fil.type" multiple>
        <el-option v-for="tag in bot.tags" :label="tag" :value="tag"></el-option>
      </el-select>

      <el-button type="danger" text class="text-button table-text-button" @click="removeFilter(fil)">
        <font-awesome-icon icon="trash"/>
      </el-button>
    </div>
    <el-button text class="text-button table-text-button" @click="addFilter()">Добавить фильтр</el-button>
  </el-form>
<!--  <el-divider/>-->
  <div style="display: flex; flex-direction: row; align-items: center;">
  <el-button @click="resetFilterForm()" style="margin-right: 10px;">Сбросить</el-button>
  <el-button @click="filterSubscribers()" type="success" :loading="buttonLoading || false">{{buttonText || "Найти"}}</el-button>
  <slot />
  </div>

</template>

<script>

export default {
  name: "TgBotSubscribersFilterForm",
  props: ["bot", "buttonText", "buttonLoading", "hideStatusSelect"],
  components: {},
  watch: {},
  mounted() {

  },
  methods: {
    resetFilterForm() {
      this.filter.status = ""
      this.filter.filters = []
    },
    filterSubscribers() {
      this.$emit("filter", this.filter)
    },
    getFilter(){
      return this.filter
    },
    addFilter() {
      this.filter.filters.push({
        id: String(Math.random()),
        type: "",
        condition: "",
        value: ""
      })
    },
    removeFilter(filter){
      this.filter.filters.splice(this.filter.filters.findIndex(f => f.id === filter.id), 1)
    },
    fixFilterValueType(f){
      if(['name', 'phone'].includes(f.type) || f.type.startsWith("var_")){
        f.condition = "equals"
        f.value = ""
      }
      if(f.type === 'tags'){
        f.condition="assigned"
        f.value = []
      }
    },
    setAllFilters(data){
      this.filter.status = data.status || "active"
      this.filter.filters = data.filters || []
    }
  },
  computed: {},
  data() {
    return {
      filter: {
        // last24hActive: false,
        status: "active",
        filters: []
      }

    }
  }
}

</script>
