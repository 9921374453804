<template>
  <div>

    <el-row :gutter="40">

      <el-col :xs="24" :md="12">
        <el-form-item v-if="allBots.length">
          <template #label>
            Выберите бота
            <el-button text class="text-button" size="small" style="margin-left: 20px;flex:1">
              <router-link to="/cab/chat_bots/my_bots" style="width: auto">Управление</router-link>
            </el-button>
          </template>
          <el-select v-model="selectedBot" class="full-width" filterable style="margin-bottom: 15px">
            <el-option v-for="bot in allBots" :value="bot._id" :label="bot.name"></el-option>
          </el-select>
          <el-switch v-model="toSegmentSubscribers" :disabled="!modelValue._id"/>&nbsp;Сегментировать подписчиков
        </el-form-item>
        <div v-else>
          <h3 style="color:var(--el-color-danger)">У вас пока нет ботов для рассылки</h3>
          <router-link to="/cab/chat_bots/my_bots" style="width: auto">
            <el-button type="success" size="small">
              Добавить
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :xs="24" :md="12" v-show="allBots.length && modelValue._id && toSegmentSubscribers">
        <div style="padding: 15px; background: rgba(200, 200, 200, .1); border-radius: 5px">
          <tg-bot-subscribers-filter-form
              :bot="fullSelectedBot" @filter="applyFilter" ref="filterForm"
              :hide-status-select="true"
              button-text="Предпросмотр" :button-loading="contactsLoading"
          >
            <el-button
                text class="text-button table-text-button"
                v-show="previewLoaded" style="flex-grow: 1; justify-content: flex-end"
            >Получателей: {{this.totalContacts}}</el-button>
          </tg-bot-subscribers-filter-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import TgBotSubscribersFilterForm from "../../chatbots/telegram/subscribers/subscribers-segmentation-form.component.vue";

export default {
  name: "TelegramRecepientsSelect",
  props: ['isEditMode', "modelValue"],
  emits: ["update:modelValue"],
  components: {TgBotSubscribersFilterForm},
  mounted() {
  },
  watch: {
    modelValue(v){
      if(v) {
        console.log(`Update: ${v}`)
        this.selectedBot = v._id
      }
    },
    selectedBot(v){
      if(v)
        this.$emit("update:modelValue", this.allBots.find(b => b._id === v))
    }
  },
  methods: {
    loadBots() {
      this.axios.get("/chatbot/getMyBots", {params: {loadAdmins: true}})
          .then(resp => {
            this.allBots = resp.data
            this.$emit("botsLoaded")
          })
    },
    getTargetBot() {
      return {id: this.modelValue._id, name: (this.fullSelectedBot || {name: ""}).name}
    },
    applyFilter(f) {
      let params = {
        pageNum: 0,
        pageSize: 50,
        botId: this.modelValue._id,
        filter: f
      }
      this.contactsLoading = true
      this.axios.post("/chatbot/getSubscribers", {params, doCount: true})
          .then(resp => {
            this.previewLoaded = true
            this.contactsLoading = false
            this.contactsPreview = resp.data.subscribers
            this.totalContacts = resp.data.count || 0
          })
    },
    getSubscribersFilter(){
      return  this.$refs.filterForm ?  {filter: this.$refs.filterForm.getFilter(), enabled: this.toSegmentSubscribers} : {enabled: this.toSegmentSubscribers, filter: {}}
    },
    setFilter(data){
      this.toSegmentSubscribers = data.enabled || false
      this.$refs.filterForm.setAllFilters(data.filter || {})
    },
    reset(){
      if(this.$refs.filterForm)
        this.$refs.filterForm.resetFilterForm()
      this.toSegmentSubscribers = false
    }
  },
  computed: {
    fullSelectedBot() {
      return this.allBots.find(b => b._id === this.modelValue._id)
    },
  },
  data() {
    return {
      selectedBot: "",
      toSegmentSubscribers: false,
      allBots: [],
      contactsLoading: false,
      contactsPreview: [],
      previewVisible: false,
      previewLoaded: false,
      totalContacts: 0
    }
  },
}

</script>

<style>
</style>
}