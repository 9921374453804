<template>
  <div>
    <Breadcrumbs primary="Финансы" secondary="Документы"/>

    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="24">
        <el-card class="box-card">
          <span class="text-bold">Закрывающие документы</span>
          <TableSpinner :loading="loading" style="margin-top: 20px">
            <el-table :data="visibleActs" style="width: 100%" stripe>
              <el-table-column prop="act_number" label="Номер акта" min-width="100"/>
              <el-table-column :formatter="formatDate" label="Дата" min-width="120"/>
              <el-table-column label="Сумма акта" :formatter="row => formatNumber(row.act_value)" min-width="140"
                               />
              <!--            <el-table-column label="Компания" :formatter="company" min-width="180" align="center"/>-->
              <el-table-column :formatter="actSendStatus" label="Статус" min-width="180"/>
              <el-table-column min-width="120" align="right">
                <template #default="scope">
                  <a :href="`${axios.defaults.baseURL}/finance/downloadDocument?documentId=${scope.row._id}&token=${$store.getters.bareToken}`" target="_blank">
                    <el-button text class="text-button" size="small" style="min-height: auto; padding: 0px">
                      <font-awesome-icon icon="external-link-alt" class="el-icon--left"></font-awesome-icon>
                      Скачать
                    </el-button>
                  </a>
                </template>
              </el-table-column>
            </el-table>
            <div class="solo-pagination-container">
            <el-pagination background
                           layout="prev, pager, next"
                           :pager-count="3"
                           :total="acts.length" :page-size="paging.acts.pageSize"
                           :hide-on-single-page="true" :current-page="paging.acts.currentPage"
                           @current-change="handleActsPageChange"
            >
            </el-pagination>
            </div>

          </TableSpinner>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="24">
        <el-card class="box-card">
          <h4 class="text-bold" style="margin: 0px !important;" v-if="$store.getters.user.contractType === '0'">
            Договор-оферта: <a href="https://web.smsgold.ru/offer" target="_blank">прочитать</a>
          </h4>
          <span class="text-bold" style="margin-bottom: 20px" v-if="$store.getters.user.contractType === '1'">Договор</span>
          <TableSpinner :loading="loading" style="margin-top: 20px" v-if="$store.getters.user.contractType === '1'">
            <el-table :data="visibleContracts"  style="width: 100%">
              <el-table-column prop="user_id" label="Пользователь" min-width="120"/>
              <el-table-column label="Наименование" prop="title" min-width="200"/>
              <!--            <el-table-column label="Вид пользователя" prop="title" min-width="180" align="center"/>-->
              <el-table-column :formatter="formatDate" label="Дата создания" min-width="180"/>
              <el-table-column :formatter="docStatus" label="Статус" min-width="140"/>
              <el-table-column :formatter="docType" label="Вид документа" min-width="120"/>
              <el-table-column min-width="120" align="right">
                <template #default="scope">

                  <a :href="`${axios.defaults.baseURL}/finance/downloadDocument?documentId=${scope.row._id}&token=${$store.getters.bareToken}`" target="_blank">
                    <el-button text class="text-button" size="small" style="min-height: auto; padding: 0px">
                      <font-awesome-icon icon="external-link-alt" class="el-icon--left"></font-awesome-icon>
                      Скачать
                    </el-button>
                  </a>
                </template>
              </el-table-column>
            </el-table>
            <div class="solo-pagination-container">
            <el-pagination background
                           layout="prev, pager, next"
                           :pager-count="3"
                           :total="contracts.length" :page-size="paging.contracts.pageSize"
                           :hide-on-single-page="true" :current-page="paging.contracts.currentPage"
                           @current-change="handleContractsPageChange"
            >
            </el-pagination>
            </div>
          </TableSpinner>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import moment from "moment"


export default {
  name: "Documents",
  title:"",
  components: {},
  mounted() {
    this.getMyDocuments()
  },
  methods: {
    handleActsPageChange(newPage) {
      this.paging.acts.currentPage = newPage
    },
    handleContractsPageChange(newPage) {
      this.paging.contracts.currentPage = newPage
    },
    formatDate(row) {
      return moment(row.date).format("DD.MM.YYYY HH:mm")
    },
    getMyDocuments() {
      this.loading = true
      this.axios.get("/documents/getMyDocuments")
          .then(resp => {
            this.loading = false
            this.allDocuments = resp.data
          })
    },
    actSendStatus(row) {
      return row.delivery_status === "email" ? "отправлено на емайл" : row.delivery_status === "email_edo" ? "отправлено на емайл, отправка в ЭДО в течение 5 дней" : "N/A"
    },
    company(row) {

    },
    docStatus(row) {
      return String(row.status) === '0' ? 'Завершен' : 'Действует'
    },
    docType(row) {
      return String(row.original) === '0' ? 'Копия' : 'Оригинал'
    }
  },
  computed: {
    acts() {
      return this.allDocuments.filter(d => String(d.type) === '0').sort((d1, d2) => moment(d2.date).valueOf() - moment(d1.date).valueOf())
    },
    contracts() {
      return this.allDocuments.filter(d => String(d.type) === '1').sort((d1, d2) => moment(d2.date).valueOf() - moment(d1.date).valueOf())
    },
    visibleActs() {
      return this.acts.sort((a1, a2) => a2.__created_at - a1.__created_at).slice((this.paging.acts.currentPage - 1) * this.paging.acts.pageSize, this.paging.acts.currentPage * this.paging.acts.pageSize)
    },
    visibleContracts() {
      return this.contracts.slice((this.paging.contracts.currentPage - 1) * this.paging.contracts.pageSize, this.paging.contracts.currentPage * this.paging.contracts.pageSize)
    }
  },
  data() {
    return {
      loading: false,
      allDocuments: [],
      paging: {
        acts: {
          pageSize: 10,
          currentPage: 1
        },
        contracts: {
          pageSize: 10,
          currentPage: 1
        }
      }
    }
  }
}

</script>