<template>
  <DefaultNode :node-type="type">
    <template #header>
      <span>Начало</span>
      <Handle id="start-handle" type="source" position="right"/>
    </template>

  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";

export default {
  name: "StartNode",
  components: {DefaultNode, Handle},
  props: ["type"],
  mounted(){
    console.log(this.$attrs)
    console.log(this.type)
  },
  methods: {},
  computed: {},
  data() {
    return {}
  }
}
</script>