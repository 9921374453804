<template>
  <div>
    <el-dialog
        v-model="intVisible"
        title="Заблокированые направления"
        @close="$emit('close')"
        class="modal-95-70-60"
    >
      <el-row>
        <el-col :xs="24">
          <blocked-routes-table :blocked-routes="blockedRoutes" />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>


<script>

import BlockedRoutesTable from "@shared/components/finance/blocked-routes-table.component.vue";

export default {
  name: "ClientBlockedRoutes",
  components: {BlockedRoutesTable},
  props: ["blockedRoutes", "visible"],
  watch: {
    visible(v){
      this.intVisible = v
    }
  },
  data() {
    return {
      intVisible: false
    }
  }
}

</script>
