<template>
  <div :class="`subscriber-container ${expanded || !embedded ? 'expanded' : ''}`"  v-show="contactId" v-if="chatType === 'tgBot'">
    <TableSpinner :loading="loading" :rows="embedded ? 1 :10" :no-min-height="embedded">
      <div id="subscriber-short-data">
        <div>
          <h3 style="margin-bottom: 0px; margin-top: 0px;">
            {{ contact.userData.first_name }}&nbsp;
            <font-awesome-icon :icon="expanded ? 'chevron-up' : 'chevron-down'" style="cursor: pointer" @click="expanded = !expanded" v-if="embedded"/>
          </h3>
          <span class="small-label"
                v-if="contact.userData.username"
                style=" margin-bottom: 0px"
          >@{{ contact.userData.username }}</span>
        </div>
        <el-checkbox v-model="contact.isAdmin" label="Администратор" @change="setAdmin" style="height: auto"/>

      </div>

      <el-row :gutter="40" id="subscriber-extended-data" style="margin-top: 20px">
        <el-col :xs="24" :md="12">
          <div style="display: flex; flex-direction: column; align-items: flex-start">

            <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px">
              <div :class="`circle small ${contact.state === 'active' ? 'success' : 'danger'}`"
                   style="display: inline-flex; margin-right: 10px"></div>
              <span>{{
                  contact.state === 'active' ? 'Активен' : contact.state === 'blocked' ? 'Заблокирован' : 'Отписан'
                }}</span>
            </div>
            <span class="small-label">Идентификатор: {{ contact._id }}</span>
            <span class="small-label">Добавлен: {{ formatDateTime(contact.createdAt) }}</span>
            <span class="small-label">Последняя активность: {{ formatDateTime(contact.lastActivity) }}</span>
            <span class="small-label">
              Телефон: <span style="color: var(--el-color-danger)" v-if="!contact.phone">Неизвестен</span>
              <span v-else>{{ contact.phone }}</span>
            </span>
            <!--            <el-button text class="text-button table-text-button" type="danger" v-if="!contact.phone"-->
            <!--                       style="margin-top: 10px; font-weight: normal">Запросить телефон-->
            <!--            </el-button>-->


            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 20px">
              <el-button type="default" v-show="contact.state === 'active'" @click="toggleBlocked(true)"
                         :loading="contactActionsBlocked">Заблокировать
              </el-button>
              <el-button type="default" v-show="contact.state === 'blocked'" @click="toggleBlocked(false)"
                         :loading="contactActionsBlocked" style="margin-left: 0px">Разблокировать
              </el-button>
              <remove-button @confirm="removeContact" :regular="true" :type="'danger'" :loading="contactActionsBlocked"
                             v-if="!embedded">Удалить
              </remove-button>
            </div>
          </div>
          <el-divider class="hidden-md-and-up"/>
        </el-col>
        <el-col :xs="24" :md="12">
          <h3 style="margin-bottom: 15px; margin-top: 0px">Теги</h3>
          <el-tag
              v-for="tag in contact.tags"
              closable
              class="tag-with-margin"
              :disable-transitions="false"
              @close="removeTag(tag)"
              style="margin-top: 5px"
          >
            {{ tag }}
          </el-tag>
          <el-autocomplete
              v-model="newTag"
              :fetch-suggestions="(_, cb) => cb(botTags.filter(t => !contact.tags.includes(t)).map(t => {return {value: t}}))"
              placeholder="Добавьте или выберите тег"
              @select="v => newTag = v.value"
              size="small"
              class="no-padding-append"
              style="width: 100%; margin-top: 5px;"
          >
            <template #append>
              <div style="padding: 0px 20px;color: var(--el-color-success); cursor: pointer; " @click="addTag()">
                <font-awesome-icon icon="plus" style=""/>
              </div>
            </template>
          </el-autocomplete>

          <el-divider/>
          <h3 style="margin-bottom: 20px; margin-top: 0px; display:flex;justify-content: space-between">
            Переменные
            <el-button class="text-button table-text-button" text type="success" @click="addBlankVariable()">
              <font-awesome-icon icon="plus"/>&nbsp;Добавить
            </el-button>
          </h3>
          <el-table :data="contact.variables" row-class-name="semi-flat-row">
            <el-table-column label="Название" min-width="100">
              <template #default="scope">
                <span v-if="!scope.row.isEditing">{{ scope.row.name }}</span>
                <el-input v-else v-model="scope.row.name" size="small"/>
              </template>
            </el-table-column>
            <el-table-column label="Значение" min-width="100">
              <template #default="scope">
                <span v-if="!scope.row.isEditing">{{ scope.row.value }}</span>
                <el-input v-else v-model="scope.row.value" size="small"/>
              </template>
            </el-table-column>
            <el-table-column width="80">
              <template #default="scope">
                <el-button text class="text-button table-text-button" style="margin-right: 10px"
                           v-show="!scope.row.isEditing" @click="editVariable(scope.row)"
                           :disabled="variableActionsBlocked">
                  <font-awesome-icon icon="edit"/>
                </el-button>

                <el-button text class="text-button table-text-button" type="success" style="margin-right: 10px; margin-left: 0px"
                           v-show="scope.row.isEditing" @click="saveVariable(scope.row)"
                           :disabled="variableActionsBlocked">
                  <font-awesome-icon icon="save"/>
                </el-button>

                <el-button text class="text-button table-text-button" type="danger" :disabled="variableActionsBlocked"
                           @click="removeVariable(scope.row)">
                  <font-awesome-icon icon="trash"/>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
<!--          <el-button type="success" @click="addBlankVariable()" style="margin-top: 20px">Добавить</el-button>-->

          <!--          <div v-if="embedded">-->
          <!--            <el-divider v-if="embedded"/>-->
          <!--            <h3 style="margin-bottom: 20px; margin-top: 0px">Запустить алгоритм</h3>-->
          <!--            <el-select class="full-width" v-model="startAlgoId" style="margin: 15px;">-->
          <!--              <el-option value="" label="" />-->
          <!--              <el-option v-for="algo in algorithms" :value="algo._id" :label="algo.name" />-->
          <!--            </el-select>-->
          <!--            <el-button type="primary" size="small" @click="launchAlgo()" :loading="algoStarting">Запустить</el-button>-->
          <!--          </div>-->
        </el-col>
      </el-row>
    </TableSpinner>
  </div>
</template>

<script>

import TableSpinner from "@shared/components/layout/table-spinner.component.vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "TgBotSubscriberForm",
  components: {RemoveButton, TableSpinner},
  props: ["contactId", "embedded", "botTags", "algorithms", "chatType"],
  watch: {
    contactId(newVal) {
      if (newVal && this.chatType === 'tgBot') {
        this.expanded = false
        this.loadContact()
      }
    }
  },
  methods: {
    loadContact() {
      this.loading = true
      this.axios.get("/chatbot/getSubscriber", {params: {id: this.contactId}})
          .then(resp => {
            this.contact = resp.data.subscriber
            this.contact.isAdmin = resp.data.subscriber.isAdmin || false
            this.loading = false
          })
          .catch(console.error)
    },
    launchAlgo() {
      this.algoStarting = true
      this.axios.post("/chatbot/startAlgo", {dialogId: this.contactId, algoId: this.startAlgoId})
          .then(resp => {
            this.algoStarting = false
            this.$gNotify("Алгоритм запущен", "success")
          })
          .catch(e => {
            this.algoStarting = false
            this.$gNotify("Ошибка", "error")
          })
    },
    addBlankVariable() {
      this.contact.variables.push({
        isEditing: true,
        value: "",
        name: "",
        uid: String(Math.random())
      })
    },
    saveVariable(v) {
      if (!v.name.trim() || !v.value.trim()) return
      this.variableActionsBlocked = true
      this.axios.post("/chatbot/saveSubscriberVariable", {contactId: this.contactId, v})
          .then(resp => {
            this.contact.variables[this.contact.variables.findIndex(i => i.uid === v.uid)].isEditing = false
            this.variableActionsBlocked = false
          })
    },
    removeVariable(v) {
      this.variableActionsBlocked = true
      this.axios.delete("/chatbot/deleteSubscriberVariable", {params: {contactId: this.contactId, vId: v.uid}})
          .then(resp => {
            this.contact.variables.splice(this.contact.variables.findIndex(i => i.uid === v.uid), 1)
            this.variableActionsBlocked = false
          })
    },
    editVariable(v) {
      this.contact.variables[this.contact.variables.findIndex(i => i.uid === v.uid)].isEditing = true
    },

    toggleBlocked(state) {
      this.contactActionsBlocked = true
      this.axios.post("/chatbot/toggleSubscriberBlockedState", {contactId: this.contactId, state})
          .then(resp => {
            this.contact.state = state ? 'blocked' : 'active'
            this.contactActionsBlocked = false
          })
    },
    removeContact() {
      this.contactActionsBlocked = true
      this.axios.delete("/chatbot/deleteSubscriber", {params: {contactId: this.contactId}})
          .then(resp => {
            this.$emit("removed")
            this.contactActionsBlocked = false
          })
    },
    setAdmin(val) {
      this.axios.post("/chatbot/setSubscriberAdmin", {
        contactId: this.contactId,
        isAdmin: val,
        botId: this.contact.botId
      })
          .then(resp => {
            if (!resp.data || !resp.data.success) {
              this.$gNotify("Превышено максимальное количество администраторов", "error")
              this.contact.isAdmin = false
            }
          })
    },
    removeTag(t) {
      this.axios.delete("/chatbot/removeSubscriberTag", {params: {contactId: this.contactId, tag: t}})
          .then(resp => {
            this.contact.tags.splice(this.contact.tags.findIndex(tg => tg === t), 1)
          })

    },
    addTag() {
      if (!this.newTag.trim() || this.tagSaving) return
      this.tagSaving = true
      this.axios.post("/chatbot/addSubscriberTag", {contactId: this.contactId, tag: this.newTag})
          .then(resp => {
            this.tagSaving = false
            if (resp.data && resp.data.success) {
              this.contact.tags.push(String(this.newTag))
              this.newTag = ""
            } else {
              this.$gNotify("Ошибка", "error")
            }
          })

    }
  },
  computed: {},
  data() {
    return {
      loading: true,
      contact: {
        botId: "",
        userData: {
          first_name: "",
          username: ""
        },
        state: "active",
        unsubscribed: false,
        createdAt: new Date(),
        lastAcitivty: new Date(),
        phone: "",
        variables: [],
        tags: [],
        isAdmin: false
      },
      variableActionsBlocked: false,
      contactActionsBlocked: false,
      newTag: "",
      tagSaving: false,
      startAlgoId: "",
      algoStarting: false,
      expanded: false
    }
  }
}

</script>

<style scoped>

.subscriber-container {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.subscriber-container:not(.expanded) #subscriber-extended-data {
  display: none
}

.subscriber-container #subscriber-short-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subscriber-container.expanded #subscriber-extended-data {
  display: flex
}


</style>