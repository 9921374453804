<template>
    <el-select v-model="interVal" :style="fullWidth ? 'width: 100%' : ''" :filterable="true">
      <el-option label="Все страны" value=""></el-option>
      <el-option
          v-for="(v, i) in countries"
          :key="i"
          :label="`${v.value} : ${v.label}`"
          :value="v.value"></el-option>
    </el-select>
</template>

<script>

export default {
  name: "CountrySelect",
  props: ["modelValue", "fullWidth"],
  methods: {
    getCountryByMcc(mcc){
      return this.countriesRaw.find(c => String(c.country_code) === String(mcc))
    },
    getCountryById(id){
      return this.countriesRaw.find(c => String(c._id) === String(id))
    }
  },
  watch: {
    interVal(newVal){
      this.$emit("update:modelValue",newVal)
    }
  },
  mounted() {
    this.axios.get("/resources/countries")
      .then(resp => {
        this.countriesRaw = resp.data
        this.countries = resp.data.map(c => {
          return {label: c.country_name, value: c.country_code}
        })
      })
  },
  data() {
    return {
      interVal: "",
      countries: [],
      countriesRaw: []
    }
  },
}

</script>