<template>
  <div>
    <el-dialog
        v-model="visible"
        title="Рассылка"
        class="modal-95-70-60"
    >
      <el-table
          :data="sendPreviewTableData" style="width: 100%;margin-bottom: 40px;"
          :show-header="false"
          :row-style="({row}) => row.demandType && !row.demandType.includes(plannerType) ? {'display': 'none'} : {}"
      >
        <el-table-column prop="c1" label="" min-width="150"/>
        <el-table-column prop="c2" label="" min-width="150">
          <template #default="scope">
            <span v-html="scope.row.c2"></span>
          </template>
        </el-table-column>
      </el-table>


      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">Отмена</el-button>
          <el-button type="success" class="uppercase" @click="$emit('commitSend')"
                     :loading="sendingExecuting">{{ plannerType === -1 ? "Отправить" : "Запланировать" }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>


export default {
  name: "SendPreview",
  props: ['sendingExecuting', 'plannerType'],

  methods: {
    open(payload, dynamic, extra, cb) {
      this.axios.post("/sms/getSendPreview", payload)
          .then(resp => {
            let schedulerRows = Object.assign({}, this.static.schedulerRows)
            let rows = {}

            if (payload.channel === 'sms'){
              rows = Object.assign({}, this.static.smsRows)
              rows.text.c2 = resp.data.text
              rows.targetsCount.c2 = dynamic.amountOfTargetsInSelectedGroups + extra.amountOfEnteredNumbers
              rows.sender.c2 = payload.sender_name.label
              rows.exlusionsCount.c2 = dynamic.amountOfExclusionsInSelectedGroups
            }
            if (payload.channel === 'telegram'){
              rows = Object.assign({}, this.static.telegramRows)
              rows.botName.c2 = payload.telegram.bot.name
            }
            if (payload.channel === 'whatsapp'){
              rows = Object.assign({}, this.static.whatsappRows)
              rows.channelName.c2 = payload.whatsapp.channelName
              rows.targetsCount.c2 = dynamic.amountOfTargetsInSelectedGroups + extra.amountOfEnteredNumbers
              rows.exlusionsCount.c2 = dynamic.amountOfExclusionsInSelectedGroups
            }


            rows.schedulerType.c2 = SCHEDULE_TYPES[String(this.plannerType)]
            schedulerRows.localTimeDelivery.c2 = payload.planner_config.local_time_delivery ? 'Да' : 'Нет'
            schedulerRows.excludeWeekend.c2 = payload.planner_config.exclude_weekend ? 'Да' : 'Нет'

            switch (this.plannerType) {
              case 0:
                schedulerRows.launchTime.c2 = payload.planner_config.date + " " + payload.planner_config.time
                break
              case 1:
                schedulerRows.launchTime.c2 = payload.planner_config.from.date + " " + payload.planner_config.from.time
                schedulerRows.endTime.c2 = payload.planner_config.to.date + " " + payload.planner_config.to.time
                schedulerRows.executionPoints.c2 = payload.planner_config.intervals.join("<br/>")
                break
              case 2:
                schedulerRows.launchTime.c2 = "Ежедневно в  " + payload.planner_config.execution_time + " (начиная со следующего дня)"
                break
            }
            this.sendPreviewTableData = Object.values(rows).concat(Object.values(schedulerRows))
            this.visible = true
            cb()
          })
    },
    close() {
      this.visible = false
    }
  },
  computed: {},
  data() {
    return {
      visible: false,
      sendPreviewTableData: [],
      static: {
        smsRows: {
          'channel': {c1: 'Канал', c2: 'СМС'},
          'schedulerType': {c1: 'Тип', c2: ''},
          'text': {c1: 'Текст сообщения', c2: ''},
          'sender': {c1: 'Подпись', c2: ''},
          'targetsCount': {c1: 'Количество адресатов', c2: ''},
          'exlusionsCount': {c1: 'Количество исключений', c2: ''},
        },
        telegramRows: {
          'channel': {c1: 'Канал', c2: 'Telegram бот'},
          'schedulerType': {c1: 'Тип', c2: ''},
          'botName': {c1: 'Бот', c2: ''},
          // 'targetsCount': {c1: 'Количество адресатов', c2: ''}
          // 'text': {c1: 'Текст сообщения', c2: ''},
        },
        whatsappRows: {
          'channel': {c1: 'Канал', c2: 'Whatsapp'},
          'schedulerType': {c1: 'Тип', c2: ''},
          'channelName': {c1: 'Название подключения', c2: ''},
          'targetsCount': {c1: 'Количество адресатов', c2: ''},
          'exlusionsCount': {c1: 'Количество исключений', c2: ''},
          // 'targetsCount': {c1: 'Количество адресатов', c2: ''}
          // 'text': {c1: 'Текст сообщения', c2: ''},
        },
        schedulerRows: {
          'localTimeDelivery': {c1: 'Доставка по местному времени адресата', c2: '', demandType: [0, 1, 2]},
          'excludeWeekend': {c1: 'Исключить выходные', c2: '', demandType: [1]},
          'launchTime': {c1: 'Время запуска', c2: '', demandType: [0, 1, 2]},
          'endTime': {c1: 'Время окончания', c2: '', demandType: [1]},
          'executionPoints': {c1: 'Точки срабатывания', c2: '', demandType: [1]}
        }
      }
    }
  },
}

</script>