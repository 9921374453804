<template>
    <el-row>
      <el-col :span="24">
        <el-form label-position="top">
          <el-form-item label="Использовать формат">
            <el-select v-model="importType" style="width: 100%">
              <el-option value="file" label="Из файла"></el-option>
              <el-option value="text" label="Текст"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Выберите CSV или EXCEL файл для импорта адресной книги" v-show="importType === 'file'">
            <el-upload
                :action="`${axios.defaults.baseURL}/contacts/uploadFile?token=${$store.getters.bareToken}`"
                style="width: 100%"
                v-model:file-list="uploadedFiles"
                :on-change="handleFileUpload"
                :on-success="showPortion"
                class="contact-import-file"
            >
              <div class="upload-content">
                <u>Выбрать файл</u>
                <div v-show="uploadedFile.name">
                  <font-awesome-icon icon="file" class="el-icon--left"></font-awesome-icon>
                  {{ uploadedFile.name }}
                </div>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="Вставьте содержимое базы" v-show="importType === 'text'">

            <el-input
                type="textarea"
                rows="5"
                v-model="importFileText"
            ></el-input>
            <p class="small-label" style="width: 100%;margin-bottom: 0px">Каждый контакт на отдельной строке</p>
            <p class="small-label">Разделители строки: табуляция, запятая или точка с запятой</p>
          </el-form-item>
          <el-form-item label="" v-show="importType === 'text'">
            <el-button type="success" @click="uploadText">Загрузить</el-button>
          </el-form-item>

        </el-form>
        <div v-show="portionLoaded">

          <el-divider class="blue-divider" style="margin-bottom: 20px"/>
          <span @click="importSettingsVisible = !importSettingsVisible" style="color: var(--el-color-primary); cursor: pointer;">Настройки импорта...</span>

          <el-row :gutter="40" style="margin-bottom: 40px; margin-top:40px" v-show="importSettingsVisible">
            <el-col :md="12" :xs="24">
<!--              <el-checkbox v-model="importSettings.addSeven" class="break-label"-->
<!--                           label="Дописывать +7 к номеру телефона (если он не начинается на +7 или 8)"/>-->
              <br/>
              <el-checkbox v-model="importSettings.removeDuplicates" label="Удалять дублирующие записи"/>
              <!--              <br/>-->
              <!--              <el-checkbox v-model="importSettings.skipFirstLine" label="Не импортировать первую строку"/>-->
              <!--              <br/>-->
              <!--              <el-checkbox v-model="importSettings.autoDetectSex" label="Автоматически определять пол"/>-->
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form label-position="top" class="mid-top-margin-mid-screen">
                <el-form-item label="Разделитель">
                  <el-select v-model="importSettings.delimiter" @change="detectPortionSchema" style="width: 100%">
                    <el-option value="," label="Запятая (,)"/>
                    <el-option value=";" label="Точка с запятой (;)"/>
                    <el-option value="  " label="Табуляция (TAB)"/>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>


          <el-row style="margin-bottom: 40px; margin-top: 40px;">
            <el-col :span="24">
              <el-table :data="portion.data">
                <el-table-column
                    v-for="(def, k) in portion.schema" :prop="def.prop" min-width="140"
                >
                  <template #header>
                    <el-select v-model="portion.schema[k].type" class="schema-element-select">
                      <el-option value="no" label="Не загружать"></el-option>
                      <el-option value="phone" label="Телефон"></el-option>
                      <el-option value="show_name" label="Имя для отображения"></el-option>
                      <el-option value="surname" label="Фамилия"></el-option>
                      <el-option value="name" label="Имя"></el-option>
                      <el-option value="patronymic" label="Отчество"></el-option>
                      <el-option value="date_of_birth" label="Дата рождения"></el-option>
                      <el-option value="sex" label="Пол"></el-option>
                      <el-option value="email" label="E-mail"></el-option>
                      <el-option value="add_field_1" label="Дополнительное поле 1"></el-option>
                      <el-option value="add_field_2" label="Дополнительное поле 2"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-divider class="blue-divider"/>
              <el-form label-position="top" class="mid-top-margin-mid-screen">

                <el-form-item label="Группа контактов">
                  <el-select v-model="importSettings.targetBase" style="width: 100%">
                    <el-option
                        v-for="base in allGroups" :value="base._id" :label="base.name"/>
                  </el-select>
                </el-form-item>
              </el-form>

              <el-progress v-show="importProgressVisible" :percentage="importProgress" color="#37acab"
                           :text-inside="true" :stroke-width="24"
                           style="margin-bottom: 40px; margin-top: 20px width: 100%"/>

              <el-button type="success" @click="importFile()" :loading="importing" style="">
                Импортировать
              </el-button>
            </el-col>
          </el-row>

        </div>

      </el-col>
    </el-row>
</template>


<script>

export default {
  name: "ImportContacts",
  components: {},
  props: ['allGroups'],
  mounted() {
    // this.loadGroups()
    this.$emitter.on("baseLoadProgress", (payload) => {
      payload = JSON.parse(payload)

      if (payload.status === 1) {
        this.importing = false
        this.importProgress = 100
        this.$gNotify("База загружена", "success")
        this.$emit("reloadGroups")
        this.reset()
        return this.$emit("navigateToMain")

      }
      this.importProgress = parseInt((payload.processedCount / payload.totalLines) * 100) || 0
    })
  },
  beforeUnmount() {
    this.$emitter.off("baseLoadProgress")
  },
  watch: {
    allGroups(){
      this.importSettings.targetBase = ""
    },
    importType() {
      this.portionLoaded = false
    }
  },
  computed: {},
  methods: {
    // loadGroups() {
    //   this.axios.get("/contacts/myGroups")
    //       .then(resp => {
    //         this.allGroups = resp.data
    //       })
    // },
    handleFileUpload(file, list) {
      this.uploadedFile = file
    },
    uploadText() {
      this.uploadTextLoading = true
      this.axios.post("/contacts/uploadFile", {text: this.importFileText})
          .then(resp => this.showPortion(resp.data))
          .catch(console.error)
    },
    showPortion(resp) {
      console.log(resp)
      this.portionLoaded = true
      this.importSettings.delimiter = resp.separatorSuggestion || ","
      this.portion.rawData = resp.portion
      this.importSettings.originalFile = resp.file
      this.detectPortionSchema()
    },
    async detectPortionSchema() {
      let splitRegex = new RegExp(`${this.importSettings.delimiter}(?=(?:(?:[^"]*"){2})*[^"]*$)`)
      let maxCols = Math.max(...this.portion.rawData.map(l => l.split(splitRegex).length))
      console.log(`Max cols: ${maxCols}`)
      let colsDef = [...Array(maxCols).keys()].map(i => {
        return {
          type: "no", prop: i + 1
        }
      })
      // console.log(colsDef)
      let tableData = this.portion.rawData.map((l, i) => {
        let template = {}
        let splitedLine = l.split(splitRegex)
        for (let k of [...Array(maxCols).keys()]) {
          template[k + 1] = (splitedLine[k] || "").replace(/^["']|["']$/g, "")
        }
        if ((i === 1 && this.portion.rawData.length > 1) || this.portion.rawData.length === 1) {
          for (let [j, part] of splitedLine.entries()) {
            part = part.trim().replace(/^["']|["']$/g, "")
            if (/^([0-9]+|\+|\(|\)|\s)+$/g.test(part))
              colsDef[j].type = "phone"
            else if (/(.+)@(.+){2,}\.(.+){2,}/g.test(part))
              colsDef[j].type = "email"
            else if (/^(m|муж|м|f|жен|ж)$/g.test(part))
              colsDef[j].type = "sex"
            else if (/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g.test(part))
              colsDef[j].type = "date_of_birth"
          }
        }
        return template
      })

      this.portion.data = []
      await this.$nextTick()
      this.portion.schema = colsDef
      await this.$nextTick()
      this.portion.data = tableData
    },
    importFile() {
      console.log(this.portion)
      if (!this.importSettings.targetBase)
        return this.$gNotify("Не выбрана база для загрузки", "error")

      if (!this.portion.schema.filter(s => s.type === 'phone').length)
        return this.$gNotify("Необходимо указать колонку с телефоном", "error")

      this.importing = true
      this.importProgressVisible = true
      this.axios.post("/contacts/importFile", {
        settings: this.importSettings,
        schema: this.portion.schema.map(s => s.type)
      })
          .then(resp => {
            // this.importing = false
            // this.getTaskProgress(resp.data.taskId, 0)
          })
          .catch(console.error)
    },
    getTaskProgress(taskId, reqCount, prevProgress=0) {
      console.log(`Req count : ${reqCount} | Prev progress : ${prevProgress} | Current progress: ${this.importProgress}`)
      if (reqCount > 20 && prevProgress === this.importProgress){
        console.log(`Process hanged`)
        this.$gNotify("Что-то пошло не так! Попробуйте перезагрузить страницу и повторить загрузку или обратитесь в службу поддержки", "error")
        return
      }
      if(prevProgress !== this.importProgress){
        prevProgress = this.importProgress
        reqCount = 0
      }

      this.axios.get("/contacts/getImportProgress", {params: {taskId}})
          .then(resp => {
            if (resp.data.status === 1) {
              this.importing = false
              this.importProgress = 100
              this.$gNotify("База загружена", "success")
              this.$emit("reloadGroups")
              this.reset()
              return this.$emit("navigateToMain")

            }
            this.importProgress = parseInt((resp.data.processedCount / resp.data.totalLines) * 100) || 0
            setTimeout(() => {
              this.getTaskProgress(taskId, reqCount + 1, prevProgress)
            }, 5000)

          })
          .catch(err => {
            console.error(err)
            setTimeout(() => {
              this.getTaskProgress(taskId, reqCount + 1, prevProgress)
            }, 5000)
          })
    },
    reset(){
      Object.assign(this.$data, initialState())
    }
  },
  data() {
    return initialState()
  }
}

function initialState(){
  return {
    importType: "file",
    importFileText: "",
    uploadedFile: {name: ""},
    portionLoaded: false,
    importing: false,
    importSettingsVisible: false,
    importSettings: {
      addSeven: true,
      removeDuplicates: true,
      skipFirstLine: false,
      autoDetectSex: false,
      delimiter: ",",
      targetBase: "",
      originalFile: ""
    },
    portion: {
      schema: [],
      rawData: [],
      data: []
    },
    importProgressVisible: false,
    importProgress: 0,

    uploadedFiles: []
  }
}

</script>

<style>
.contact-import-file .el-upload.el-upload--text {
  width: 100%
}

.upload-content {
  width: 100%;
  height: 48px;
  border: 1px solid #adb8bd;
  background-color: #f8f8f8;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.schema-element-select .el-input input {
  height: 40px !important;
}

.schema-element-select .el-input{
  border-radius: 0px;
  height: 40px !important;
  border: 1px solid black;
}
.schema-element-select .el-input .el-input__wrapper{
  border: none !important;
}
</style>