<template>
  <DefaultNode @removeMe="events.removeMe(id)">
    <template #header>
      <span>Запрос номера телефона</span>
      <Handle type="target" position="left"/>

    </template>
    <template #body>
      <span>{{ data.settings.messageText }}</span>
      <div class="flow-button">
        <span>{{ data.settings.buttonText }}</span>
        <Handle type="source" position="right" :style="buttonHandleStyle"/>
      </div>
    </template>
  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";

export default {
  name: "ContactRequestNode",
  components: {DefaultNode, Handle},
  props: ["events", "id", "data"],
  watch: {},
  mounted(){
    this.addButton()
  },
  methods: {
    addButton(){
      this.$emit("addChildren", {type: "__button", text: ""})
    }
  },
  computed: {},
  data() {
    return {
      buttonHandleStyle : {
        top: '50%',
        transform: "translate(0, -50%)"
      }
    }
  }
}

export let settingsModel = () => {
  return {
    messageText: "Пожалуйста, поделитесь своим номером телефона",
    buttonText: "Поделиться"
  }
}
</script>