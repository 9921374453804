<template>
  <el-row>
    <el-col :span="24">
      <Breadcrumbs primary="Отчеты" secondary="Звонки"/>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-card class="box-card no-border">
            <!--          <template #header>-->
            <!--            <div class="card-header">-->
            <!--              <span>Детализация звонков</span>-->
            <!--            </div>-->
            <!--          </template>-->
            <DetailTable :is-call-view="true"/>
          </el-card>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>


import DetailTable from "@shared/components/reports/detail-sent-messages-table.component"

export default {
  name: "SentCalls",
  title: "Звонки",
  components: {

    DetailTable
  },
  mounted() {
  },
  computed: {},
  methods: {},
  data() {
    return {}
  }
}

</script>