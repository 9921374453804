<template>
  <div>
    <el-dialog v-model="detailsDialogVisible" width="95%">
      <template #title>
        <h3 class="uppercase">Отчет о задании</h3>
        <p>Выполнение задания "{{ taskToShow.name }}"</p>
      </template>

      <el-table :data="taskToShow.details">
        <el-table-column :formatter="getDetailPlannedLaunchTime" label="Запланированное время запуска" min-width="180"></el-table-column>
<!--        <el-table-column :formatter="getDetailActualLaunchTime" label="Время запуска" min-width="200"></el-table-column>-->
        <el-table-column :formatter="getDetailTotalTargets" label="Всего адресатов" min-width="100" v-if="taskToShow.taskItself.type !== 2"></el-table-column>
        <el-table-column :formatter="getDetailSuccessTargets" label="Успешно отправлено"
                         min-width="120"></el-table-column>
        <el-table-column :formatter="getDetailFailedTargets" label="Ошибок обработки" min-width="120"></el-table-column>
      </el-table>

    </el-dialog>

    <el-dialog v-model="infoDialogVisible" width="95%">
      <template #title>
        <h3 class="uppercase">Параметры задачи </h3>
      </template>

      <el-table
          :data="viewTaskTableData" style="width: 100%;margin-bottom: 40px;"
          :show-header="false"
          :row-style="({row}) => row.demandType && !row.demandType.includes(planner_type) ? {'display': 'none'} : {}"
      >
        <el-table-column prop="c1" label="" min-width="150"/>
        <el-table-column prop="c2" label="" min-width="150">
          <template #default="scope">
            <span v-html="scope.row.c2"></span>
          </template>
        </el-table-column>
      </el-table>

    </el-dialog>

    <el-row>
      <el-col :span="24" >

        <el-table :data="visibleTasks"  style="width: 100%;margin-bottom: 40px;">
          <el-table-column prop="name" label="Наименование" width="200"/>
          <el-table-column :formatter="getTaskChannel" label="Канал" width="100"/>
          <el-table-column label="Задание" min-width="180">
            <template #default="scope">
              <span v-html="getTaskText(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column :formatter="getSchedule" label="Расписание" width="150"/>
          <el-table-column :formatter="r => r.total_cost ? formatNumber(r.total_cost || 0) : ''" label="Стоимость" width="120"/>
          <el-table-column v-if="showStatus"  label="Статус" width="80">
            <template #default="slot">
              <div :class="`circle ${taskActive(slot.row) ? 'success' : 'danger'}`"></div>

            </template>
          </el-table-column>
          <!--          <el-table-column :formatter="getTotalTargets" label="Адресаты" min-width="100" align="center" />-->
          <el-table-column label="" width="100">
            <template #default="scope">
              <ActionsDropdown>
                <ActionButton icon="info-circle" @click="showDetails(scope.row)">Детали выполнения</ActionButton>

                <ActionButton icon="eye" @click="viewTask(scope.row)" v-if="scope.row.sendData.channel === 'sms'">Посмотреть данные</ActionButton>
                <ActionButton v-if="isEditableTask(scope.row)" icon="pencil-alt" @click="editTask(scope.row)">Редактировать</ActionButton>
                <ActionButton icon="copy" @click="copyTask(scope.row)">Копировать</ActionButton>

                <ActionButton icon="ban" :loading="taskRemoving" v-show="isCancellableTask(scope.row)" @click="rejectTask(scope.row)">Отменить</ActionButton>
                <ActionButton icon="times" :loading="taskRemoving" type="remove" @confirm="removeTask(scope.row)">Удалить</ActionButton>
              </ActionsDropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="solo-pagination-container">
        <el-pagination
            background
            class="wide-page-size"
            layout="sizes, prev, pager, next"
            :total="tasks.length"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :pager-count="3"
            :hide-on-single-page="true"
            :current-page="currentPage"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentPageChange"
        >
        </el-pagination>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import moment from "moment"
import ActionButton from "@shared/components/layout/actionButton.component";
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";

export default {
  name: "SendTasksTable",
  components: {ActionsDropdown, ActionButton},
  props: ["tasks", "showStatus", "contactGroups"],
  emits: ['refresh'],
  computed: {
    visibleTasks() {
      return this.tasks.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    taskGenderFilter(){
      let target = this.viewTaskData.sendData.sex_target && Object.keys(this.viewTaskData.sendData.sex_target).length ? this.viewTaskData.sendData.sex_target[Object.keys(this.viewTaskData.sendData.sex_target)[0]] : "2"
      return target === "0" ? "Мужчины" : target === "1" ? "Женщины" : "Все"
    },

    viewTaskTableData(){
      if(!this.viewTaskData || !this.viewTaskData._id || !this.viewTaskData.sendData) return  []
      let __rows = [
        {c1:"Название задачи", c2: this.viewTaskData.name},
        {c1:"Список телефонов получателей", c2: this.viewTaskData.sendData.numbers.slice(0, 3).join(", ") + (this.viewTaskData.sendData.numbers.length > 3 ? ` (+ ${this.viewTaskData.sendData.numbers.length - 3} н.)` : '')},
        {c1:"Группы контактов", c2: this.taskGroupsNames(this.viewTaskData.sendData.contact_bases).join(", ")},
        {c1:"Таргетинг по полу", c2: this.taskGenderFilter},
        {c1:"Группы исключений", c2:  this.taskGroupsNames(this.viewTaskData.excl_bases || []).join(", ")},
        {c1:"Имя отправителя", c2: this.viewTaskData.sendData.sms_sender_name},
        {c1:"Текст сообщения", c2: this.viewTaskData.sendData.sms_text},
        {c1:"Время жизни сообщения", c2: this.viewTaskData.sendData.lifetime ? this.viewTaskData.sendData.lifetime + " мин.": "24 часа" },
        {c1:"Тип задачи", c2: this.getSchedule(this.viewTaskData)},
      ]
      if(this.viewTaskData.type !== -1){
        if(this.viewTaskData.type === 0)
          __rows.push({c1: "Время выполнения", c2: this.viewTaskData.config.date + " " + this.viewTaskData.config.time})
        if(this.viewTaskData.type === 1){
          __rows.push({c1: "Диапазон дат", c2: this.viewTaskData.config.from.date + " - " + this.viewTaskData.config.to.date})
          __rows.push({c1: "Диапазон времени", c2: this.viewTaskData.config.from.time + " - " + this.viewTaskData.config.to.time})
          __rows.push({c1: "Интервал рассылки", c2: this.viewTaskData.config.distribution_interval + " мин."})
          __rows.push({c1: "Без выходных", c2: this.viewTaskData.config.exclude_weekend ? "Да" : "Нет"})
        }
        if(this.viewTaskData.type === 2){
          __rows.push({c1: "Отклонение от даты события адресата", c2: this.viewTaskData.config.execution_padding + " д."})
          __rows.push({c1: "Время выполнения", c2: this.viewTaskData.config.execution_time})
        }
        __rows.push({c1: "Доставка по местному времени адресата", c2: this.viewTaskData.config.local_time_delivery ? "Да" : "Нет"})
      }
      return __rows
    }
  },
  methods: {
    taskGroupsNames(groups){
      return groups.map(g => (this.contactGroups.find(c => c._id === g) || {name: "[Удалённая группа]"}).name).filter(v => v)
    },
    taskActive(row){
      return  !row.executed && !row.rejected
    },
    isEditableTask(task) {
      return task.type !== -1 && (!task.processingStarted || task.type === 2) && !task.rejected  && !task.executed
    },
    isCancellableTask(task) {
      return task.type !== -1 && !task.rejected && !task.executed
    },
    handlePageSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentPageChange(val) {
      this.currentPage = val
    },
    showDetails(taskData) {
      // let taskData = this.tasks[taskIndex]
      this.taskToShow.taskItself = taskData
      this.taskToShow.name = taskData.name
      this.schedulerDetailsLoading = true
      this.axios.get("/scheduler/taskExecutionDetails/", {params: {task_id: taskData._id}})
          .then(resp => {
            this.schedulerDetailsLoading = false
            this.detailsDialogVisible = true
            this.taskToShow.details = resp.data.executions
          })
    },
    getDetailActualLaunchTime(row) {
      return row.start_date ?
          moment(row.start_date).format("DD.MM.YYYY HH:mm") +
          (this.taskToShow.taskItself.config && this.taskToShow.taskItself.config.local_time_delivery ? " (Местное время)" : "")
          : "Не запущено"
    },
    getDetailPlannedLaunchTime(row) {
      let __format = "DD.MM.YYYY HH:mm"
      if(this.taskToShow.taskItself.type === 2)
        __format = "HH:mm"

      let baseString = row.planned_start_date ? moment(row.planned_start_date).utcOffset(this.$store.getters.user.utcOffset || 0).format(__format) : ""
      if(this.taskToShow.taskItself.config.local_time_delivery)
        baseString += " (Местное время)"
      if(this.taskToShow.taskItself.type === 2)
        baseString = "Ежедневно в " + baseString + ` (${this.taskToShow.taskItself.config.execution_padding ? `За ${this.taskToShow.taskItself.config.execution_padding} д. до события` : 'В день события'})`
      return baseString
      // return row.planned_start_date ?
      //     moment(row.planned_start_date).utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm") +
      //     (this.taskToShow.taskItself.config && this.taskToShow.taskItself.config.local_time_delivery ? " (Местное время)" : "")
      //     : ""
    },
    getDetailTotalTargets(row) {
      return row.report.total
    },
    getDetailSuccessTargets(row) {
      return row.report.success
    },
    getDetailFailedTargets(row) {
      return row.report.fail
    },

    getTaskChannel(row) {
      return CHANNEL_NAMES[row.sendData.channel] || "N/A"
    },
    getTaskText(row) {
      if (row.sendData.channel === "sms") return row.sendData.sms_text
      if (row.sendData.channel === "telegram") return row.sendData.telegram.messages.map(m => {
        let rv = `${(m.text || "").replace(/\n/g, "<br/>")}`
        if(m.files && m.files.length)
          rv += `<br/>📎 ${m.files[0].name}`
        return rv
      }).join("<hr style='border: 1px solid #eee'/>")
      if (row.sendData.channel === "whatsapp") return (row.sendData.whatsapp || {messages : []}).messages.map(m => {
        let rv = `${(m.text || "").replace(/\n/g, "<br/>")}`
        if(m.files && m.files.length)
          rv += `<br/>📎 ${m.files[0].name}`
        return rv
      }).join("<hr style='border: 1px solid #eee'/>")
      return "N/A"
    },
    getSchedule(row) {
      return SCHEDULE_TYPES[String(row.type)] || ""
    },
    editTask(row, ) {
      this.$router.push({path: `/cab/messages/edit_task/${row._id}`})
    },
    copyTask(row, ) {
      this.$router.push({path: `/cab/messages/edit_task/${row._id}`, query: {copy: true}})
    },
    viewTask(row){
      this.viewTaskData = row
      this.infoDialogVisible = true
    },
    removeTask(row) {
      this.taskRemoving = true
      this.axios.delete("/scheduler/removeTask", {params: {task_id: row._id}})
          .then(() => {
            this.$emit("refresh")
            this.taskRemoving = false
          })
    },
    rejectTask(row) {
      this.$confirm("Подтвердите отмену задачи", {type:"danger", confirmButtonText: "Подтвердить", cancelButtonText: "Закрыть", confirmButtonClass: "force-danger-button"})
          .then(() => {
            this.taskRemoving = true
            this.axios.post("/scheduler/rejectTask", {task_id: row._id})
                .then(resp => {
                  if(resp.data && resp.data.error === "E_PROCESSING_STARTED") return this.$gNotify("Задача в процессе выполнения. \n" +
                      "В данный момент отмена невозможна.", "error")
                  this.$emit("refresh")
                  this.taskRemoving = false
                })          })
          .catch(e => {

          })

    },
  },
  data() {
    return {
      taskRemoving: false,
      detailsDialogVisible: false,

      schedulerDetailsLoading: false,
      pageSize: 10,
      currentPage: 1,
      viewTaskData:{},
      infoDialogVisible: false,
      taskToShow: {
        name: "",
        details: [],
        taskItself: {}
      }
    }
  },
}

</script>