<template>

  <el-form-item label="Теги">
    <el-tag
        v-for="tag in modelValue.tags"
        closable
        class="tag-with-margin"
        :disable-transitions="false"
        @close="removeTag(tag)"
        style="margin-top: 5px"
    >
      {{ tag }}
    </el-tag>
    <el-autocomplete
        v-model="newTag"
        :fetch-suggestions="(_, cb) => cb(bot.tags.filter(t => !modelValue.tags.includes(t)).map(t => {return {value: t}}))"
        placeholder="Добавьте или выберите тег"
        @select="v => newTag = v.value"
        size="small"
        class="no-padding-append"
        style="width: 100%; margin-top: 5px;"
    >
      <template #append>
        <div style="padding: 0px 20px;color: var(--el-color-success); cursor: pointer; " @click="addTag()">
          <font-awesome-icon icon="plus" style=""/>
        </div>
      </template>
    </el-autocomplete>

  </el-form-item>

</template>

<script>

export default {
  name: "AddRemoveTagSettings",
  components: {},
  props: ["modelValue", "bot"],
  emits: ['update:modelValue'],
  watch: {},
  beforeMount() {

  },
  mounted() {

  },
  methods: {
    addTag(){
      this.modelValue.tags.push(String(this.newTag))
    },
    removeTag(t){
      this.modelValue.tags.splice(this.modelValue.tags.findIndex(tg => tg === t), 1)
    }
  },
  computed: {},
  data() {
    return {
      newTag: ""
    }
  }
}

</script>

<style>

</style>