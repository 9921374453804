<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="Отправка"/>
    <el-row>
      <el-col :span="24">

        <el-row :gutter="20">
          <el-col :span="24">
            <el-card>
              <TableSpinner :loading="!formVisible" :rows="10">
                <el-row align="middle" justify="space-between">
                  <el-col :xs="24" :md="6">
                    <h4 class="report-parameters-title">Параметры рассылки</h4>
                  </el-col>
                  <el-col :xs="24" :md="18" style="text-align: right">
                    <span class="el-form-item__label" style="margin-bottom: 40px;">{{ mainHeader }}</span>
                  </el-col>
                </el-row>
              <MainSend
                  :editing-task="editingTask"
                  :is-edit-mode="isEditMode"
                  :is-visible="formVisible"
                  @dataLoaded="dataLoadedHandler"
              />
              </TableSpinner>
            </el-card>
          </el-col>
        </el-row>
      </el-col>

    </el-row>
  </div>
</template>

<script>


import MainSend from "@/components/new_send/main-send.component"

export default {
  name: "NewSend",
  title: "Новое сообщение",
  components: {
    MainSend
  },
  watch: {
    dataLoaded(newVal) {
      if(newVal && this.isEditMode)
        this.loadTask()
    },
    $route(){
      if(!this.$route.params.taskId || !this.$route.params.taskId.trim()) {
        this.isEditMode = false
        this.editingTask = null
      }
    },

  },
  beforeMount(){
    if(this.$route.params.taskId && this.$route.params.taskId.trim()) {
      this.isEditMode = true
      this.taskLoaded = false
    }else this.taskLoaded = true
  },
  methods: {
    loadTask(){
      if(this.$route.params.taskId && this.$route.params.taskId.trim()) {
        this.taskLoaded = false
        this.axios.get("/scheduler/getTask", {params: {taskId: this.$route.params.taskId.trim()}})
            .then(resp => {
              this.editingTask = resp.data.task
              if(this.$route.query.copy && this.$route.query.copy === 'true')
                this.editingTask._id = ""
              this.taskLoaded = true
            })
      }
    },
    handleSelectedContactGroups(groups) {
      this.selectedContactGroups = groups
    },
    groupsLoaded(gps){
      this.allContactGroups = gps
    },

    isCopy(){

    },
    dataLoadedHandler(){
      // console.log('DATA LOADED');
      this.dataLoaded = true
    }
  },
  computed: {
    mainHeader() {
      return this.isEditMode ? `${this.$route.query.copy ? 'Копия' : this.isEditableTask ? "Редактирование" : "Просмотр"} задачи  [ ${this.editingTask && this.editingTask.name ? this.editingTask.name : ''} ]` : ""
    },
    isEditableTask() {
      return this.editingTask.type !== -1 && !this.editingTask.processingStarted && !this.editingTask.rejected
    },
    // mainHeader(){
    //   return this.isEditMode ? `${this.$route.query.copy ? 'Копия' : this.isEditableTask() ? "Редактирование" : "Просмотр"} задачи  [ ${this.editingTask && this.editingTask.name ? this.editingTask.name : ''} ]` : "Отправить сообщение"
    // },
    formVisible(){
      return this.dataLoaded && this.taskLoaded && this.$store.getters.offertLoaded
    }
  },
  data() {
    return {
      dataLoaded: false,
      taskLoaded: false,
      canDisplayTask: false,
      isEditMode: false,
      editingTask: {},
      selectedContactGroups: [],
      allContactGroups: [],
      selectedExclBases: []
    }
  },
}

</script>