<template>
  <div>

    <el-dialog
        v-model="intVisible"
        title="Удаление дублирующих контактов"
        class="modal-95-70-60"
        @close="$emit('close')"
    >

      <el-row>
        <el-col :span="24">
          <el-form label-position="top">
            <el-form-item label="Выберите группы в которых хотите удалить дубли">
              <el-select
                  v-model="bases"
                  multiple
                  style="width: 100%"
              >
                <el-option
                    v-for="item in allGroups"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                />
              </el-select>
            </el-form-item>

          </el-form>
        </el-col>
      </el-row>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" :loading="loading" @click="removeDuplicates">Удалить</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "RemoveDuplicateContacts",
  components: {},
  props: ["visible", "allGroups"],
  watch: {
    visible(v){
      this.intVisible = v
    }
  },
  methods: {
    removeDuplicates() {
      this.loading = true
      this.axios.delete("/contacts/removeDuplicateContacts", {params: {bases: this.bases.join(",")}})
          .then(resp => {
            this.loading = false
            this.$gNotify(`Удалено ${resp.data.count} дублирующих контактов`, "success")
            this.$emit("duplicatesRemoved")
          })
      .catch(console.error)
    }
  },
  data() {
    return {
      loading: false,
      bases: [],
      intVisible: false
    }
  }
}

</script>