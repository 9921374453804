<template>
  <div>
    <view-edit-base
        ref="viewEditBase"
    />
    <el-row :gutter="40">
      <el-col :xs="24" :md="12">
        <el-form-item v-if="allChannels.length">
          <template #label>
            Выберите подключение
            <el-button text class="text-button" size="small" style="margin-left: 20px;flex:1">
              <router-link to="/cab/chat_bots/my_bots" style="width: auto">Управление</router-link>
            </el-button>
          </template>
          <el-select v-model="selectedChannel" class="full-width" filterable style="margin-bottom: 15px">
            <el-option v-for="channel in allChannels" :value="channel._id" :label="channel.name"></el-option>
          </el-select>
        </el-form-item>
        <div v-else>
          <h3 style="color:var(--el-color-danger)">У вас пока нет подключений для рассылки</h3>
          <router-link to="/cab/chat_bots/my_bots" style="width: auto">
            <el-button type="success" size="small">
              Добавить
            </el-button>
          </router-link>
        </div>
        <phones-list-input ref="phonesList" :phones="phones" label="Список телефонов получателей" v-show="selectedChannel"/>
      </el-col>
      <el-col :xs="24" :md="12"  v-show="selectedChannel">

        <el-form-item>
          <template #label>
            Группы контактов для рассылки
            <el-button text class="text-button" size="small" style="margin-left: 20px;flex:1">
              <router-link to="/cab/messages/addressbooks" style="width: auto">Управление</router-link>
            </el-button>

          </template>
          <el-select v-model="selectedContactGroups" class="full-width" :multiple="true" filterable>
            <el-option
                v-for="group in allContactGroupsWithRemoved"
                :value="group._id"
                :label="group.name"
                :disabled="exclGroups.includes(group._id)"
            >
              <div style="display: flex; align-items: center;">
                  <span :style="`flex-grow: 1;${group.removed ? 'color: var(--el-color-danger)' : ''}`">{{
                      group.name
                    }}</span>
                <span
                    style="color: var(--el-text-color-secondary);font-size: 13px;"
                    v-show="!group.removed"
                >{{ group.size }}</span>
                <font-awesome-icon icon="eye" class="el-icon--left" v-show="!group.removed"
                                   style="color: #888;margin-left: 10px"
                                   @click.stop="viewBase(group, $event)"
                ></font-awesome-icon>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Таргетинг по полу">
          <el-select v-model="sexTarget" class="full-width">
            <el-option :value="2" label="Все"></el-option>
            <el-option :value="0" label="Только мужчины"></el-option>
            <el-option :value="1" label="Только женщины"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Группы исключений">
          <el-select v-model="exclGroups" class="full-width" :multiple="true" filterable>
            <el-option
                v-for="group in allContactGroupsWithRemoved"
                :value="group._id"
                :label="group.name"
                :disabled="selectedContactGroups.includes(group._id)"
            >
              <div style="display: flex; align-items: center;">
                  <span :style="`flex-grow: 1;${group.removed ? 'color: var(--el-color-danger)' : ''}`">{{
                      group.name
                    }}</span>
                <span
                    style="color: var(--el-text-color-secondary);font-size: 13px;"
                    v-show="!group.removed"
                >{{ group.size }}</span>
                <font-awesome-icon icon="eye" class="el-icon--left" v-show="!group.removed"
                                   style="color: #888;margin-left: 10px"
                                   @click.stop="viewBase(group, $event)"
                ></font-awesome-icon>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import ViewEditBase from "@/components/addressbooks/viewEditBase.component"
import PhonesListInput from "@shared/components/phones-list-input-component.vue";

export default {
  name: "WhatsappRecepientsSelect",
  props: ['isEditMode', "modelValue"],
  emits: ["update:modelValue"],
  components: {PhonesListInput, ViewEditBase},
  mounted() {
  },
  watch: {
    amountOfTargetsInSelectedGroups() {
      this.$emit("newTargets", this.amountOfTargetsInSelectedGroups)
    },
    amountOfExclusionsInSelectedGroups() {
      this.$emit("newExclusions", this.amountOfExclusionsInSelectedGroups)
    },
    selectedContactGroups() {
      this.$emit("selectedGroups", this.selectedContactGroups)
    },
    exclGroups() {
      this.$emit("exclGroups", this.exclGroups)
    },
    sexTarget() {
      this.$emit("sexTarget", this.sexTarget)
    },
    modelValue(v){
      if(v)
        this.selectedChannel = v._id

    },
    selectedChannel(v){
      if(v)
        this.$emit("update:modelValue", this.allChannels.find(b => b._id === v))
    }
  },
  methods: {
    loadChannels() {
      this.axios.get("/chatbot/getWaChannels")
          .then(resp => {
            this.allChannels = resp.data
            this.$emit("channelsLoaded")
          })
    },
    loadContactGroups() {
      this.axios.get("/contacts/myGroups")
          .then(resp => {
            this.allContactGroups = resp.data
            this.$emit("groupsLoaded")
          })
    },
    viewBase(base, event) {
      event.preventDefault()
      this.$refs.viewEditBase.viewBase(base)
    },
    getPhones() {
      return this.$refs.phonesList.getPhones()
    },
    reset() {
      this.selectedContactGroups = []
      this.exclGroups = []
      this.phones = []
      this.allChannels = []
      this.sexTarget = 2
    },
    setData(data) {
      this.exclGroups = data.excl_bases
      this.selectedContactGroups = data.contact_bases
      this.phones = data.numbers
      // this.selectedChannel = data.whatsapp.channelId
      if (Object.keys(data.sex_target).length > 0)
        this.sexTarget = Number(Object.values(data.sex_target)[0])
    },
    getData(){
      return {
        phones: this.$refs.phonesList.getPhones(),
        selectedGroups: this.selectedContactGroups.filter(v => this.allContactGroups.find(g => g._id === v)),
        exclGroups: this.exclGroups.filter(v => this.allContactGroups.find(g => g._id === v)),
        channel: this.allChannels.find(b => b._id === this.selectedChannel)
      }
    }
  },
  computed: {
    allContactGroupsWithRemoved() {
      let __retval = Array.from(this.allContactGroups)
      if (this.isEditMode) {
        for (let bId of (this.exclGroups || []).concat(this.selectedContactGroups || []))
          if (!this.allContactGroups.find(c => c._id === bId)) __retval.push({
            _id: bId,
            removed: true,
            name: "[Удалённая группа]"
          })

      }
      return __retval
    },
    amountOfTargetsInSelectedGroups() {
      return this.allContactGroups.reduce((acc, g) => acc + (this.selectedContactGroups.includes(g._id) ? g.size : 0), 0)
    },
    amountOfExclusionsInSelectedGroups() {
      return this.allContactGroups.reduce((acc, g) => acc + (this.exclGroups.includes(g._id) ? g.size : 0), 0)
    },
  },
  data() {
    return {
      allContactGroups: [],
      selectedContactGroups: [],
      exclGroups: [],
      phones: [],
      sexTarget: 2,
      allChannels: [],
      selectedChannel: ""
    }
  },
}

</script>

<style>
</style>
}