<template>

  <VkTemplateForm
      ref="addEditVkTemplate"
      :visible="templateVkFormVisible"
      :editing-template-id="editingVkTemplateId"
      @close="templateVkFormVisible = false"
      @templateSaved="refreshMyTemplates()"
  />

  <el-input class="full-width" style="margin-bottom: 20px" placeholder="Поиск" clearable
            v-model="vkTemplatesSearchToken"/>
  <TableSpinner :loading="dataLoading">
    <el-table :data="vkTemplatesVisible" style="width: 100%" stripe>
      <el-table-column label="Дата создания" width="100" :formatter="row => formatDateTime(row.local.createdAt)"/>
      <el-table-column prop="remote.name" label="Название шаблона" min-width="250"/>
      <el-table-column prop="remote.tmpl" label="Текст шаблона" min-width="280"/>
      <el-table-column prop="remote.service" label="Идентификатор группы" min-width="150"/>
      <el-table-column
          label="Статус"
          min-width="110"
      >
        <template #default="scope">
          <span v-if="scope.row.remote.is_active" style="color:var(--el-color-success)">Активен</span>
          <span v-else style="color:var(--el-color-danger)">Не активен</span>
          <br/>
          <span v-if="scope.row.remote.is_confirmed" style="color:var(--el-color-success)">Подтвержден</span>
          <span v-else style="color:var(--el-color-danger)">Не подтвержден</span>

        </template>
      </el-table-column>
      <el-table-column label="" min-width="150" align="right">
        <template #default="scope">
          <ActionsDropdown>
            <ActionButton icon="pencil-alt" @click="() => showEditVkForm(scope.$index)">Изменить
            </ActionButton>
            <ActionButton type="remove" icon="times" @confirm="() => removeVkTemplate(scope.$index)">Удалить
            </ActionButton>
          </ActionsDropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="solo-pagination-container">
      <el-button class="button" type="success" @click="showAddVkForm">
        <font-awesome-icon icon="plus" class="el-icon--left"></font-awesome-icon>
        Добавить шаблон
      </el-button>
      <el-pagination
          background
          class="wide-page-size"
          layout="sizes, prev, pager, next"
          :total="vkTemplatesFiltered.length"
          :pager-count="3"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="vkTempalatesPageSize"
          :hide-on-single-page="true"
          @size-change="handlePageSizeChange"
          @current-change="handleCurrentPageChange"
      >
      </el-pagination>
    </div>
  </TableSpinner>

</template>

<script>


import MessageTemplateForm from "@/components/message_template_form.component"
import moment from "moment"
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";
import ActionButton from "@shared/components/layout/actionButton.component";
import VkTemplateForm from "@/components/vk_template_form.component.vue";

export default {
  name: "VkTemplatesTable",
  components: {
    VkTemplateForm,
    ActionButton,
    ActionsDropdown,
  },
  mounted() {
    this.refreshMyTemplates()
  },
  methods: {
    refreshMyTemplates() {
      this.dataLoading = true
      this.axios.get("/sms/messageTemplates/vk")
          .then(resp => {
            this.dataLoading = false
            this.vkTemplatesData = (resp.data || []).sort((r1, r2) => new Date(r2.local.createdAt).valueOf() - new Date(r1.local.createdAt).valueOf())
          })
          .catch(console.error)
    },
    showAddVkForm() {
      this.editingVkTemplateId = ""
      this.templateVkFormVisible = true
    },
    showEditVkForm(index) {
      this.editingVkTemplateId = this.vkTemplatesData[index].local._id
      this.editPreload = true

      this.$refs.addEditVkTemplate.preloadEditingTemplate(this.vkTemplatesData[index].local._id, () => {
        this.editPreload = false
        this.templateVkFormVisible = true
      })

    },
    removeVkTemplate(index) {
      this.removing = true
      this.axios.delete("/sms/removeVkTemplate", {params: {templateId: this.vkTemplatesData[index].local._id}})
          .then(() => {
            this.removing = false
            this.refreshMyTemplates()
          })
    },
    handlePageSizeChange(newSize) {
      this.vkTempalatesPageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.vkTempalatesCurrentPage = newPage
    },
    filterVkTemplatesByState(value, row) {
      console.log(value)
      return true
    }
  },
  computed: {
    vkTemplatesFiltered() {
      return this.vkTemplatesData.filter(r => {
        if (this.vkTemplatesSearchToken)
          if (
              !(new RegExp(this.vkTemplatesSearchToken, "gi")).test(r.remote.name) &&
              !(new RegExp(this.vkTemplatesSearchToken, "gi")).test(r.remote.tmpl) &&
              !(new RegExp(this.vkTemplatesSearchToken, "gi")).test(r.remote.service)
          ) return false
        return true
      })
    },
    vkTemplatesVisible() {
      return this.vkTemplatesFiltered.slice((this.vkTempalatesCurrentPage - 1) * this.vkTempalatesPageSize, this.vkTempalatesCurrentPage * this.vkTempalatesPageSize)
    }
  },
  data() {
    return {
      vkTemplatesData: [],

      templateVkFormVisible: false,
      editingVkTemplateId: "",

      editPreload: false,
      removing: false,
      dataLoading: false,
      vkTemplatesSearchToken: "",
      vkTempalatesPageSize: 10,
      vkTempalatesCurrentPage: 1
    }
  }
}

</script>