<template>

    <div :style="`display: flex; flex-direction: row; align-items: center; justify-content: flex-start;`"
         id="header-container">
      <div @click="$store.dispatch('toggleMenu')" id="menu-trigger">
        <font-awesome-icon icon="bars" class="control-button"
        ></font-awesome-icon>
      </div>
      <img id="main-logo" src="@shared/assets/static_img/logo.png" style=" margin: 10px 0px" v-if="!$store.getters.isSurrogateLogin"/>

      <div class="spare-cab-data">
        <div>
          <span>Пользователь:&nbsp;<b>{{ $store.getters.user.systemId(true) }}</b></span>
          <span>Баланс:&nbsp;{{ formatNumber($store.getters.userBalance) }}&nbsp;р.&nbsp;&nbsp;<router-link
              to="/cab/finance/operations"
              style="margin-left: 10px; font-size:13px; color: var(--el-color-danger-dark-2)"
              @click.native="$emit('closeMenu')">+Пополнить
                </router-link></span>
          <span v-show="$store.getters.userOverdraft > 0">Овердрафт:&nbsp;{{
              formatNumber($store.getters.userOverdraft)
            }}&nbsp;р.</span>
        </div>

      </div>
      <h2 v-if="$store.getters.isSurrogateLogin" style="color: red">{{$store.getters.user.systemId(true)}}</h2>
      <div @click="$store.dispatch('toggleMenu')" id="menu-trigger-header">
        <font-awesome-icon icon="bars" class="control-button"
        ></font-awesome-icon>
      </div>

      <div class="phones-list" style="border-right: 1px solid #CCC">
        <div style="text-align: right">
          <span class="header-phone">Москва:&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
          <span class="header-phone">Санкт-Петербург:&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
          <span class="header-phone">Регионы:&nbsp;&nbsp;&nbsp;&nbsp;</span><br/>
        </div>
        <div>
          <span class="header-phone">8(495)374-80-34</span><br/>
          <span class="header-phone">8(812)426-13-66</span><br/>
          <span class="header-phone">8 800-500-80-16</span><br/>
        </div>
      </div>
      <tasks-dropdown />
      <div
          style="border-right: 1px solid #CCC; height: 100%; padding: 0px 20px; display: flex; justify-content: space-between; align-items: center"
          id="clock-container">
        <font-awesome-icon icon="clock" style="font-size: 25px" class="el-form-item__label"/>
        <span class="header-phone" style="line-height: 15px">{{ timer }}</span>
      </div>
      <div style="height: 100%;display: flex; align-items: center; justify-content: center; cursor: pointer"
           @click="logout()" data-test="logoutButton" id="logout-button">
        <img src="@shared/assets/static_img/signout.svg" style="height: 25px;margin-left: 20px" />
      </div>
    </div>

</template>


<script>

import moment from "moment"
import TasksDropdown from "@shared/components/layout/tasks-view.component.vue";

const timeoutInMS = Math.round(1.5 * 60 * 60 * 1000)
const lastActionDelay = 60 * 60 * 1000
export default {
  name: 'Header',
  components: {TasksDropdown},
  methods: {
    logout(allDevices = false) {
      this.removeEventListeners()
      this.$store.dispatch("logout", {
        allDevices, callback: () => {
          this.$router.push({path: "/login"})
        }
      })

    },

    handleInactive() {
      console.log("Token expired")
      console.log(`Last action at: ${this.lastActionTime} | Now : ${Date.now()}`)
      if (Date.now() - this.lastActionTime < lastActionDelay) {
        console.log("refreshing token")
        this.$store.dispatch("refreshUser")
            .then(() => {
              this.restartLogoutTimer()
            })
      } else {
        console.log("Logout")
        clearTimeout(this.logoutTimer)
        this.logout()
      }
    },
    removeEventListeners() {
      document.removeEventListener("keypress", this.setLastActivityTime, false);
      document.removeEventListener("mousemove", this.setLastActivityTime, false);
      document.removeEventListener("mousedown", this.setLastActivityTime, false);
      document.removeEventListener("touchmove", this.setLastActivityTime, false);
    },
    setLastActivityTime() {
      this.lastActionTime = Date.now()
    },
    restartLogoutTimer() {
      this.removeEventListeners()
      clearTimeout(this.logoutTimer)
      this.logoutTimer = setTimeout(this.handleInactive, timeoutInMS);
      // this.balanceTimer = setInterval(() => {
      //   this.$store.dispatch('loadUserBalance', () => {this.logout()})
      // }, 10000);

      document.addEventListener("keypress", this.setLastActivityTime, false);
      document.addEventListener("mousemove", this.setLastActivityTime, false);
      document.addEventListener("mousedown", this.setLastActivityTime, false);
      document.addEventListener("touchmove", this.setLastActivityTime, false);


    }
  },
  beforeUnmount() {
    this.removeEventListeners()
    clearTimeout(this.logoutTimer)
    // clearInterval(this.balanceTimer)
  },
  mounted() {
    setInterval(() => {
      this.timer = moment().utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss")
    }, 1000)
    this.axios.get("/tokenPing")
        .then()
        .catch(e => {
          if (JSON.parse(JSON.stringify(e)).status === 401)
            this.logout()
        })
    this.restartLogoutTimer();
  },
  data() {
    return {
      timer: moment().utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss"),
      logoutTimer: null,
      // balanceTimer: null,
      lastActionTime: 0
    }
  }
}

</script>

<style>
.spare-cab-data {
  padding: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  flex-grow: 1;
  flex-direction: row;
}

.spare-cab-data div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spare-cab-data div span {
  font-size: 13px;
  padding: 0px !important;
  color: #727272;
  display: initial;
}

.phones-list {
  padding: 0px;
  padding-right: 20px;
  /*margin: 0px;*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.header-phone {
  font-size: 13px;
  padding: 0px !important;
  line-height: 5px;
  color: #727272;
  display: initial;
}

.control-button {
  cursor: pointer;
  font-size: 25px;
  color: #31373a;
  margin-right: 20px
}

#menu-trigger-header {
  display: none
}
</style>