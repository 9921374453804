<template>
  <div>
    <Breadcrumbs primary="Финансы" secondary="Тарифы"/>
    <client-rate-details
        :selected-rate="selectedRate"
        :senders="senders"
        :inboxes="inboxes"
        :visible="rateDetailsVisible"
        @close="rateDetailsVisible = false"
    />
    <client-blocked-routes
        :blocked-routes="blockedRoutes"
        :visible="routeRestrictionsVisible"
        @close="routeRestrictionsVisible = false"
    />

    <h4
        @click="routeRestrictionsVisible=true"
        style="color: var(--el-color-danger);cursor: pointer;text-decoration: underline"
        v-if="blockedRoutes && blockedRoutes.length">
      Есть ограничения по направлениям
    </h4>
    <TableSpinner :rows="20" :loading="loading">
      <el-card class="box-card"  v-if="Object.keys(rates).length > 0">
        <client-rates-table
            :rates="rates[Object.keys(rates)[0]]"
            :inboxes="inboxes"
            @showRate="showRateDetails"

        />
      </el-card>
      <h3 v-if="Object.keys(rates).length === 0" class="align-center">Нет тарифов</h3>
    </TableSpinner>
  </div>
</template>

<script>


import moment from "moment"
import ClientRatesTable from "../../../shared/components/finance/client-rates-table.component.vue";
import ClientRateDetails from "../../../shared/components/finance/client-rate-details.component.vue";
import ClientBlockedRoutes from "../../../shared/components/finance/client-blocked-routes.component.vue";


export default {
  name: "Rates",
  title: "Тарифы",
  components: {
    ClientBlockedRoutes,
    ClientRateDetails,
    ClientRatesTable
  },
  mounted() {
    this.getMyRates()
  },
  methods: {
    async getMyRates() {
      this.loading = true
      let [resp, respBlockedRoutes] = await Promise.all([
        this.axios.get("/finance/myRates"),
        this.axios.get("/finance/myRouteRestrictions"),
      ])
      this.rates = resp.data.rates
      this.senders = resp.data.senders
      this.inboxes = resp.data.inboxes
      this.blockedRoutes = respBlockedRoutes.data || []
      this.activeTab = Object.keys(resp.data.rates).length ? Object.keys(resp.data.rates)[0] : ""
      this.loading = false
    },
    showRateDetails(rateToShow) {
      let _r = rateToShow.actual_data
      _r.sender_names = rateToShow.user_data.sender_names || []
      this.selectedRate = _r
      this.rateDetailsVisible = true
    },
  },
  computed: {
    allUserIds() {
      return Object.keys(this.rates)
    },
    anySubuserHasRates() {
      return this.allUserIds.length > 1 || this.allUserIds.length && this.allUserIds[0].includes(".")
    }
  },
  data() {
    return {
      rates: {},
      blockedRoutes: [],
      senders: [],
      inboxes: [],
      loading: false,
      routeRestrictionsVisible: false,
      rateDetailsVisible: false,
      selectedRate: {},
      activeTab: ""
    }
  }
}

</script>

<style>

/*.inactive-rate td {*/
/*  background-color: transparent !important;*/
/*}*/

/*.inactive-rate td .cell {*/
/*  color: var(--el-color-danger) !important;*/
/*  font-weight: bold;*/
/*}*/


</style>