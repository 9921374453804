<template>

  <div class="message-chain-element" v-for="(casse, i) in modelValue.cases">
    <font-awesome-icon icon="times" class="remove-flow-subelement-button" @click="removeCase(casse)" v-if="modelValue.cases.length > 1"/>
    <el-form-item label="Текст сообщения">
      <el-input type="textarea" v-model="message.text"/>
    </el-form-item>
    <el-divider>Кнопки</el-divider>
    <div class="message-button-def" v-for="button in message.buttons">
      <font-awesome-icon icon="times" class="remove-flow-subelement-button" @click="removeButton(message, button)"/>
      <el-form-item label="Текст">
        <el-input v-model="button.text" placeholder="Текст кнопки" size="small"/>
      </el-form-item>
      <el-form-item label="Тип" style="margin-bottom: 0px">
        <el-radio-group v-model="button.type">
          <el-radio label="continueAlgo">Продолжить алгоритм</el-radio>
          <el-radio label="url">URL</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="URL" v-if="button.type === 'url'" style="margin-top: 15px; margin-bottom: 0px">
        <el-input v-model="button.url" size="small"/>
      </el-form-item>
    </div>
    <el-button size="small" type="default" style="width:100%; justify-content: center; padding: 5px;"
               @click="addButton(message)">Добавить кнопку
    </el-button>
  </div>
<!--  <el-divider><font-awesome-icon icon="chevron-down"/></el-divider>-->
  <el-button type="primary" @click="addMessage()" style="width: 100%; justify-content: center" size="small">Добавить
    сообщение
  </el-button>
  <el-divider class="blue-divider"/>

</template>

<script>

import {oneCaseModel} from "../nodes/switchCase.node.vue";
import {v1} from "uuid";

export default {
  name: "SwitchCaseSettings",
  components: {},
  props: ["modelValue", "bot"],
  emits: ['update:modelValue'],
  watch: {},
  beforeMount() {

  },
  mounted() {

  },
  methods: {
    addCase() {
      this.modelValue.cases.push(oneCaseModel())
    },
    removeCase(casse) {
      this.modelValue.cases.splice(this.modelValue.cases.findIndex(c => c.id === casse.id), 1)
    }
  },
  computed: {},
  data() {
    return {}
  }
}

</script>

<style>

</style>