<template>
  <div>
    <el-form-item label="">
      <template #label>
        Имя отправителя
        <el-button text class="text-button" size="small" style="margin-left: 20px;flex:1">
          <router-link to="/cab/lk/sender_names" style="width: auto">Добавить имя</router-link>
        </el-button>

      </template>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
        <el-select v-model="senderName" style="flex: 1" @change="$emit('change')">
          <el-option
              v-for="item in senderNames"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>

      </div>
    </el-form-item>
    <el-form-item label="Шаблон сообщения">
      <el-select v-model="template" class="full-width">
        <el-option
            v-for="item in messageTemplates"
            :key="item._id"
            :label="item.title"
            :value="item._id"
        >
        </el-option>
      </el-select>
    </el-form-item>


    <MessageTextInput
        ref="messageTextInput"
        @substitutionCheck="checkVariablesSubstitution"
        @change="$emit('change')"
        :show-check-substitution="selectedContactGroups.length > 0"/>

    <el-form-item class="no-margin">
      <el-checkbox v-model="send_test_message" label="Отправить тестовое сообщение"></el-checkbox>
      <el-input
          v-model="test_sms_number"
          v-show="send_test_message"
          :disabled="!$store.getters.registrationFullyFinalized"
          placeholder="Введите номер"
          style="margin-bottom: 15px"
      >
        <template #append>
          <el-button
              :type="!$store.getters.registrationFullyFinalized ? 'success' : 'default'"
              @click="sendTestMessage()"
              :loading="sendingTestMessage"
              :disabled="!test_sms_number.trim()"
          >Отправить
          </el-button>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item class="no-margin">
      <el-checkbox v-model="lifetime_select" label="Время жизни"></el-checkbox>
      <el-select v-model="lifetime" class="full-width" v-show="lifetime_select">
        <el-option
            v-for="item in static.lifetime_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>

import MessageTextInput from "../message-text-input.component.vue";
import {ElMessageBox} from "element-plus";
import store from "@/store";

export default {
  name: "SmsMessageInput",
  props: ["selectedContactGroups", "validateForm", "testSendOpened"],
  components: {MessageTextInput},
  mounted() {
    this.loadAll()
  },
  watch: {
    template(newVal) {
      if(newVal)
        this.$refs.messageTextInput.setTextByTemplate(this.messageTemplates.find(t => t._id === newVal))
    },
    testSendOpened(val) {
      if (val) this.send_test_message = !this.$store.getters.registrationFullyFinalized
    },

  },
  methods: {
    loadAll() {
      this.loadMessageTemplates()
      this.loadSenderNames()
    },
    loadMessageTemplates() {
      this.axios.get("/sms/messageTemplates/sms")
          .then(resp => {
            this.messageTemplates = resp.data
            this.$emit("templatesLoaded")
          })
    },
    loadSenderNames() {
      this.axios.get("/sender_names/myNames", {params: {activeOnly: 1}})
          .then(resp => {
            this.senderNames = resp.data.filter(n => !n.forCalls).map(n => {
              return {
                label: n.name,
                value: n._id
              }
            })
            this.senderName = (resp.data.filter(n => !n.forCalls).find(n => n.default === 1) || {_id: ""})._id
            this.$emit("namesLoaded", {cnt: this.senderNames.length})
            this.$emit("change")
          })
    },
    sendTestMessage() {
      let payload = {
        text: this.$refs.messageTextInput.getText(),
        sender_name: this.senderName,
        lifetime: this.lifetime,
        test_phone: this.test_sms_number.trim(),
        phones: [this.test_sms_number.trim()]
      }
      if (!this.validateForm(payload, true)) return
      this.sendingTestMessage = true
      this.axios.post("/sms/sendTestMessage", payload)
          .then(resp => {
            this.sendingTestMessage = false
            this.$gNotify("Тестовое сообщение отправлено", "success")
          })
    },
    checkVariablesSubstitution() {
      let text = this.$refs.messageTextInput.getText()
      if (!text.length || !this.selectedContactGroups.length) return
      let defaultContact = {
        show_name: "Наталия работа",
        name: "Наталия",
        surname: "Иванова",
        patronymic: "Ивановна",
        email: "nat.iv@mail.ru",
        add_field_1: "кое-какой текст",
        add_field_2: "и еще немного текста",
        sex: "1"
      }
      let showSubstituted = (contacts, i) => {

        let showText = ""
        let substituteVars = (params, __text) => {
          let showText = String(__text)
          showText = showText.replace(/{{Назв\. контакта}}/g, params.show_name || "");
          showText = showText.replace(/{{Имя}}/g, params.name || "");
          showText = showText.replace(/{{Фамилия}}/g, params.surname || "");
          showText = showText.replace(/{{Отчество}}/g, params.patronymic || "");
          showText = showText.replace(/{{Е-мейл}}/g, params.email || "");
          showText = showText.replace(/{{Знач.1}}/g, params.add_field_1 || "");
          showText = showText.replace(/{{Знач.2}}/g, params.add_field_2 || "");
          if (params.sex === "0") showText = showText.replace(/{{Уважаемый\(ая\)}}/g, "Уважаемый");
          if (params.sex === "1") showText = showText.replace(/{{Уважаемый\(ая\)}}/g, "Уважаемая");
          if (params.sex === "2") showText = showText.replace(/{{Уважаемый\(ая\)}}/g, "");
          return showText
        }
        showText = substituteVars(!contacts || !contacts.length ? defaultContact : contacts[i], text)

        ElMessageBox.alert(showText, 'Проверка подстановки', {
          confirmButtonText: contacts && contacts.length && i + 1 <= contacts.length - 1 ? "Следующий контакт" : "",
          showConfirmButton: contacts && contacts.length && i + 1 <= contacts.length - 1,
          closeOnClickModal: true,
          showCancelButton: true,
          cancelButtonText: "Закрыть",
          customClass: "wide",
          callback: (action) => {
            if (action !== "confirm") return
            if (contacts && contacts.length && i + 1 <= contacts.length - 1)
              showSubstituted(contacts, i + 1)

          }
        })
      }
      this.axios.get("/contacts/basePreview", {
        params: {base_id: this.selectedContactGroups[0], skip: 0, limit: 10, token: ""}
      })
          .then(resp => {
            showSubstituted(resp.data[0], 0)
          })
    },
    getLifetime(){
      return this.lifetime_select ? this.lifetime : null
    },
    setLifetime(lt){
      this.lifetime_select = true
      this.lifetime = lt
    },
    setSenderName(nname){
      this.senderName = this.senderNames.find(n => n.label === nname).value
    },
    getSenderName(){
      return this.senderNames.find(n => n.value === this.senderName) || {label : ""}
    },
    proxy(method, arg){
      return this.$refs.messageTextInput[method](arg)
    },
    reset() {
      this.$refs.messageTextInput.reset()
      this.lifetime = ""
      this.template = ""
      this.lifetime_select = false
      this.send_test_message = false
      // this.test_sms_number = ""
      this.senderName = ""
    }
  },
  computed: {},
  data() {
    return {
      messageTemplates: [],
      template: "",
      sendingTestMessage: false,
      test_sms_number: String(store.getters.userOwnPhone || ""),
      send_test_message: false,

      lifetime_select: false,
      lifetime: "",

      senderName: "",
      senderNames: [],
      static: {
        lifetime_options: [
          {label: "5 минут", value: 5},
          {label: "10 минут", value: 10},
          {label: "30 минут", value: 30},
          {label: "45 минут", value: 45},
          {label: "1 час", value: 60},
          {label: "2 часа", value: 60 * 2},
          {label: "3 часа", value: 60 * 3},
          {label: "4 часа", value: 60 * 4},
          {label: "5 часов", value: 60 * 5},
          {label: "6 часов", value: 60 * 6},
          {label: "7 часов", value: 60 * 7},
          {label: "8 часов", value: 60 * 8},
          {label: "9 часов", value: 60 * 9},
          {label: "10 часов", value: 60 * 10},
          {label: "11 часов", value: 60 * 11},
          {label: "12 часов", value: 60 * 12},
          {label: "13 часов", value: 60 * 13},
          {label: "14 часов", value: 60 * 14},
          {label: "15 часов", value: 60 * 15},
          {label: "16 часов", value: 60 * 16},
          {label: "17 часов", value: 60 * 17},
          {label: "18 часов", value: 60 * 18},
          {label: "19 часов", value: 60 * 19},
          {label: "20 часов", value: 60 * 20},
          {label: "21 час", value: 60 * 21},
          {label: "22 часа", value: 60 * 22},
          {label: "23 часа", value: 60 * 23},
          {label: "24 часа", value: 60 * 24},
        ]
      },
    }
  },
}

</script>

<style>
</style>
}