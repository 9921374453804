<template>
  <div>

    <el-dialog
        v-model="intVisible"
        title="Добавить группу контактов"
        class="modal-95-70-60"
        @close="$emit('close')"
    >

      <el-row>
        <el-col :span="24">
          <el-form label-position="top" ref="newBaseForm" :rules="{newName: {required: true, trigger: 'change', message: 'Укажите наименование'}, codeName: {required: false, trigger: 'change'}}" :model="form">
            <el-form-item label="Название группы" prop="newName">
              <el-input v-model="form.newName"/>
            </el-form-item>
            <el-form-item label="Кодовое имя группы (только для запросов по АПИ)" prop="codeName">
              <el-input v-model="form.newCodeName"/>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" @click="addGroup">Сохранить</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "AddContactGroup",
  components: {},
  props: ["visible"],
  watch: {
    visible(v){
      this.intVisible = v
    }
  },
  methods: {
    addGroup() {

      this.$refs.newBaseForm.validate()
          .then(isValid => {
            if(isValid)
              this.axios.post("/contacts/addGroup", {name: this.form.newName, codeName: this.form.newCodeName})
                  .then(async resp => {
                    if (resp.data.error === 'group_exists')
                      return this.$gNotify("Группа контактов с таким именем уже существует", "error")

                    this.$gNotify("Группа контактов добавлена", "success")
                    this.$refs.newBaseForm.resetFields()

                    this.$emit("groupCreated")
                  })
          })
    }
  },
  data() {
    return {
      form: {
        newName: "",
        newCodeName: ""
      },
      intVisible: false
    }
  }
}

</script>