<template>
  <el-dialog
      title="Рандомизатор текста"
      v-model="randomiserModalVisible"
      class="modal-95-50-30"
  >
    <el-form label-position="top">
      <el-form-item v-for="(token, k) in randomTokens" label="Вариант">
        <el-input v-model="randomTokens[k]" />
      </el-form-item>

    </el-form>
    <div style="display: flex;width: 100%; align-items: center; justify-content: space-between; margin-top: 15px">
      <el-button
          type="primary" text class="table-text-button"
          @click="randomTokens.push('')"
      >
        <font-awesome-icon icon="plus" />&nbsp;Добавить вариант
      </el-button>
    <el-button type="success" @click="updateRandomiserToken()">Сохранить</el-button>
    </div>
  </el-dialog>
  <div
      style="padding: 15px; background: rgba(200, 200, 200, .1); border-radius: 5px; margin-bottom:15px; position: relative">
    <font-awesome-icon icon="times"
                       style="color: red; font-size: 16px; cursor: pointer; position: absolute; top: 5px; right: 5px"
                       @click="$emit('remove')" v-if="showRemove"/>
    <el-row style="margin-bottom: 10px">
      <el-col :xs="24">
        <el-form-item label="Текст сообщения" style="margin-bottom: 0px;" class="message-text-input-form-item">
          <div style="display: flex; border: 1px solid #DDD;border-top-left-radius: 4px; border-top-right-radius: 4px;">

            <el-input v-model="modelValue.text" ref="originalTextInput" type="textarea" :rows="4"
                      class="message_text_area"/>
          </div>

          <div style="border: none;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #edf2f7;
          line-height: normal;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          justify-content: center;">
            <span style="margin: 10px 0px; color: #727272; font-size: 12px;">Введено {{ amountOfSymbols }} симв. Макс: 2000 символов</span>
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :xs="24">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <el-upload
              v-model:file-list="uploadedFiles"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleFileUpload"
              :on-success="addUploadThumbnail"
              v-if="!modelValue.files.length"
          >
            <el-button text class="text-button table-text-button">
              <font-awesome-icon icon="paperclip"></font-awesome-icon>&nbsp;Файл
            </el-button>

          </el-upload>
          <span v-for="file in modelValue.files" v-else>
              <font-awesome-icon icon="trash" style="color: var(--el-color-danger); cursor: pointer" @click="removeAttachment(file)"/>&nbsp;&nbsp;{{ file.name }}
          </span>
          <el-button
              text class="table-text-button"
              @click="addRandomiser()"
          >
            <font-awesome-icon icon="random"/>&nbsp;Рандомизация
          </el-button>
          <!--          <el-dropdown trigger="click" style="cursor:pointer;" :hide-on-click="false" ref="variablesDropdown">-->
          <!--              <span class="el-dropdown-link">-->
          <!--                <font-awesome-icon icon="wrench" class="el-icon&#45;&#45;left"></font-awesome-icon>Переменные-->
          <!--              </span>-->
          <!--            <template #dropdown>-->
          <!--              <el-dropdown-menu>-->
          <!--                <el-dropdown-item @click="injectVariable('$$contact_name$$')">Имя</el-dropdown-item>-->
          <!--                <el-dropdown-item @click="injectVariable(v)" v-for="v in bot.variables || []">{{ v }}</el-dropdown-item>-->
          <!--              </el-dropdown-menu>-->
          <!--            </template>-->
          <!--          </el-dropdown>-->
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>

export default {
  name: "WhatsappSingleMessageForm",
  props: ["modelValue", "showRemove", "bot", "isEditMode"],
  emits: ['update:modelValue'],
  watch: {
    "modelValue.text": function (v) {

      if(v) {
        let randomisers = this.extractRandomisers(v)
        console.log(randomisers)
        this.modelValue.randomisers = randomisers
      }
      this.$emit('change')
    },
    "modelValue.files": function () {
      this.$emit('change')
    }
  },
  mounted() {
  },
  computed: {
    amountOfSymbols() {
      return this.replaceCarriage(this.modelValue.text).length
    }
  },
  methods: {
    injectVariable(v) {
      let cursorPosition = this.$refs.originalTextInput.textarea.selectionStart
      this.modelValue.text = this.modelValue.text.slice(0, cursorPosition) + `{{${v}}}` + this.modelValue.text.slice(cursorPosition)
    },
    substitutionCheckTrigger() {
      this.$emit('substitutionCheck')
      this.$refs.variablesDropdown.handleClose()
    },
    replaceCarriage(val) {
      return String(val).replace(/(?:\r\n|\r|\n)/g, '\n')
    },
    async handleFileUpload(file, list) {
      console.log(file)
      this.uploadedFiles = [file]
      this.modelValue.files = [
        {name: file.name, mime: file.raw.type, baseString: await this.$fileToBase64(file.raw)}
      ]
    },
    addUploadThumbnail(arg, file, list) {
      console.log(arg)
      let index = list.findIndex(f => f.uid === file.uid)
      list[index].__uploaded_uid = arg
    },
    removeAttachment(file) {
      let index = this.modelValue.files.findIndex(f => f.uid === file.uid)
      this.modelValue.files.splice(index, 1)
    },
    onPreviewTextClicked(e){
      let currectRandomisers = this.extractRandomisers(this.modelValue.text)
      if(e.target && e.target.className === 'random-edit-trigger'){
        this.editingRandomiserIndex =  Number(e.target.dataset.tokenIndex)
        this.randomTokens = currectRandomisers[this.editingRandomiserIndex][0].replace(/[\[\]]+/g, "").split("|")
        this.randomiserModalVisible = true
      }
    },
    extractRandomisers(v){
      return Array.from(v.matchAll(/\[\[[^\[\]]+\]\]/g))
    },
    addRandomiser(){
      let p = this.$refs.originalTextInput.textarea.selectionStart || 0
      let currectRandomisers = this.extractRandomisers(this.modelValue.text)
      if(currectRandomisers.find(r => r.index < p && p < r.index + r[0].length))
        return this.$gNotify("Нельзя добавить рандомизатор внутрь другого рандомизатора", "error")
      this.randomTokens = [""]
      this.randomiserModalVisible = true
      this.positionToInjectRandomiser = p
    },
    updateRandomiserToken(){
      let randomiserText = "[[" + this.randomTokens.map(t => t.trim()).filter(t => t).join("|") + "]]"
      let currectRandomisers = this.extractRandomisers(this.modelValue.text)
      if(this.editingRandomiserIndex > -1){
        this.modelValue.text = this.modelValue.text.slice(0, currectRandomisers[this.editingRandomiserIndex].index) + randomiserText +
            this.modelValue.text.slice(currectRandomisers[this.editingRandomiserIndex].index + currectRandomisers[this.editingRandomiserIndex][0].length)
      }else{
        this.modelValue.text = this.modelValue.text.slice(0, this.positionToInjectRandomiser) + randomiserText + this.modelValue.text.slice(this.positionToInjectRandomiser)
      }
      this.editingRandomiserIndex = -1
      this.randomiserModalVisible = false
    },
  },
  data() {
    return {
      positionToInjectRandomiser: 0,
      randomiserModalVisible: false,
      randomTokens: [""],
      editingRandomiserIndex: -1,
      uploadedFiles: []
    }
  },
}

</script>