<template>
  <div>
    <Breadcrumbs primary="Чат-боты" secondary="Боты и Каналы"/>

    <el-dialog
        v-model="newChannelFormVisible"
        title="Новый канал"
        class="modal-95-70-60"
    >
      <el-row v-if="selectedChannelType === ''">
        <el-col :xs="24">
          <div style="display: flex; flex-direction: row; flex-grow: 1;flex-wrap: wrap; gap: 20px; justify-content: space-around" >
            <div
                class="new-channel-option"
                @click="selectedChannelType = option.type"
                v-for="option in newChannelOptions.filter(v => v)"
            >
              <img :src="option.img"  style="margin-bottom: 20px; width: 50px"/>
              <p>{{option.name}}</p>
            </div>
          </div>

        </el-col>
      </el-row>

      <el-row v-if="selectedChannelType === 'tgPersonal'">
        <el-col :xs="24" :md="12">
          <p style="word-break: break-word">В своём мобильном приложении телеграм перейдите в Настроки -> Устройства и
            нажмите на кнопку "Добавить устройство", после чего отсканируйте QR-код</p>
          <br/>
          <p style="word-break: break-word">Если видите ошибку "AUTH_TOKEN_EXPIRED" просто закройте это окно и повторите
            процедуру заново</p>
        </el-col>
        <el-col :xs="24" :md="12">
          <TableSpinner :loading="qrCodeLoading" style="margin-top: 20px; text-align: center">
            <qrcode-vue :value="qrCode" :size="200" level="H"/>
            <el-form v-model="newChannelForm" label-position="top" v-show="newChannelPasswordRequired"
                     style="margin-top: 20px">
              <p style="word-break: break-word; text-align: left">В вашем аккаунте настроена двухфакторная авторизация,
                пожалуйста, предоставьте пароль от аккаунта.</p>
              <p style="word-break: break-word; text-align: left; color: var(--el-color-danger)">Мы не сохраняем этот
                пароль в собственной базе данных</p>
              <el-form-item label="2FA пароль">
                <el-input v-model="newChannelForm.password" type="password"/>
              </el-form-item>
              <el-form-item label=" ">
                <el-button type="success" @click="authorizeChannel()" :loading="authorizing"
                           :disabled="!this.newChannelForm.password.trim()">
                  Авторизовать
                </el-button>
              </el-form-item>
            </el-form>

          </TableSpinner>
        </el-col>
      </el-row>
      <el-row v-if="selectedChannelType === 'tgBot'">
        <el-col :span="24">
          <el-form v-model="newBotForm" label-position="top">
            <el-form-item label="API-токен">
              <el-input v-model="newBotForm.token"/>
            </el-form-item>
            <el-form-item label=" ">
              <el-button type="success" @click="saveTgBot" :loading="saving" :disabled="!this.newBotForm.token.trim()">
                Создать
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-row v-if="selectedChannelType === 'whatsappGray'">
        <el-col :xs="24" :md="12">
          <p style="word-break: break-word">В своём мобильном приложении телеграм перейдите в Настроки -> Устройства и
            нажмите на кнопку "Добавить устройство", после чего отсканируйте QR-код</p>
          <br/>
<!--          <p style="word-break: break-word">Если видите ошибку "AUTH_TOKEN_EXPIRED" просто закройте это окно и повторите-->
<!--            процедуру заново</p>-->
        </el-col>
        <el-col :xs="24" :md="12">
          <TableSpinner :loading="qrCodeLoading" style="margin-top: 20px; text-align: center">
            <qrcode-vue :value="qrCode" :size="300" level="L"/>

          </TableSpinner>
        </el-col>
      </el-row>
    </el-dialog>

    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="24">

        <el-card class="box-card no-top-body-padding no-border">
          <el-button type="success" @click="showNewChannelDialog">Добавить канал</el-button>
          <TableSpinner :loading="loading" style="margin-top: 20px">
            <el-table :data="channels" style="width: 100%">
              <el-table-column label="Тип" min-width="170">
                <template #default="scope">
                  <span style="display: flex; align-items: center" v-if="scope.row.type === 'tgPersonal'">
                    <img src="@shared/assets/static_img/telegram.png" style="width: 20px"/>&nbsp;&nbsp;<span>Личный Telegram аккаунт</span>
                  </span>
                  <span style="display: flex; align-items: center" v-if="scope.row.type === 'tgBot'">
                    <img src="@shared/assets/static_img/telegram.png" style="width: 20px"/>&nbsp;&nbsp;<span>Telegram бот</span>
                  </span>
                  <span style="display: flex; align-items: center" v-if="scope.row.type === 'whatsappGray'" >
                    <img src="@shared/assets/static_img/whatsapp.png" style="width: 20px"/>&nbsp;&nbsp;<span>Whatsapp бот</span>
                  </span>

                </template>
              </el-table-column>
              <el-table-column prop="name" label="Название" min-width="180"/>
              <el-table-column prop="_id" label="Идентификатор" min-width="150"/>
              <el-table-column :formatter="v => formatDateTime(v.createdAt || v.importedAt)" label="Дата создания" min-width="120"/>
              <el-table-column width="100">
                <template #default="scope">
                  <router-link :to="getManageLink(scope.row)" v-if="scope.row.type ==='tgBot'">
                    <el-button text class="text-button" size="small"
                               style="min-height: auto; padding: 0px">
                      <font-awesome-icon icon="wrench" class="el-icon--left"></font-awesome-icon>
                      Управление
                    </el-button>
                  </router-link>
                  <actions-dropdown v-if="scope.row.type === 'tgPersonal'">
                    <remove-button :table-button="true" @confirm="removePrivateTgChannel(scope.row)" :loading="tgChannelRemoving">Удалить</remove-button>
                  </actions-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </TableSpinner>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import QrcodeVue from "qrcode.vue";
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component.vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "MyBots",
  title: "Боты & Каналы",
  components: {RemoveButton, ActionsDropdown, QrcodeVue},
  watch: {
    selectedChannelType(v){
      if(v === "tgPersonal") this.loadDirectTgQrCode()
      if(v === "whatsappGray") this.loadWhatsappQrCode()
    }
  },
  mounted() {
    this.loadAll()
    this.$emitter.on("requestTgPersonalPassword", (payload) => {
      payload = JSON.parse(payload)
      this.newChannelForm.key = payload.key
      this.newChannelPasswordRequired = true
      // this.$gNotify("", "error")
    })
    this.$emitter.on("tgPersonalChannelCreated", (payload) => {
      this.$gNotify("Личный Telegram-канал подключен", "success")
      this.newChannelFormVisible = false
      this.loadAll()
    })

    this.$emitter.on("whatsappPersonalChannelCreated", (payload) => {
      this.$gNotify("Личный Whatsapp-канал подключен", "success")
      this.newChannelFormVisible = false
      this.loadAll()
    })
    this.$emitter.on("tgPersonalChannelQrCode", (payload) => {
      payload = JSON.parse(payload)
      this.qrCodeLoading = false
      this.qrCode = payload.qr
    })
    this.$emitter.on("duplicatedTgChannel", (payload) => {
      this.$gNotify("Этот Telegram-аккаунт уже добавлен", "error")
      this.newChannelFormVisible = false
      this.authorizing = false
    })
    this.$emitter.on("whatsappPersonalChannelQrCode", (payload) => {
      payload = JSON.parse(payload)
      this.qrCodeLoading = false
      this.qrCode = payload.qr
    })
  },
  beforeUnmount() {
    this.$emitter.off("requestTgPersonalPassword")
    this.$emitter.off("tgPersonalChannelCreated")
    this.$emitter.off("tgPersonalChannelQrCode")
    this.$emitter.off("duplicatedTgChannel")
    this.$emitter.off("whatsappPersonalChannelQrCode")
  },
  methods: {
    loadAll() {
      this.loading = true
      Promise.all([
        this.axios.get("/chatbot/getMyBots"),
        this.axios.get("/tgPersonal/connections"),
        this.axios.get("/whatsapp/connections")
      ]).then(([bots, privateChannels, whatsappConnections]) => {
        this.channels = bots.data.map(b => {
          return {
            type: "tgBot",
            name: b.name,
            username: b.username,
            createdAt: b.importedAt,
            _id: b._id
          }
        }).concat(privateChannels.data.connections.map(c => {
          return {
            type: "tgPersonal",
            name: c.name + ` (${c.userData.phone})`,
            createdAt: c.createdAt,
            _id: c._id
          }
        })).concat(whatsappConnections.data.connections.map(c => {
              return {
                type: "whatsappGray",
                name: c.name,
                createdAt: c.createdAt,
                _id: c._id
              }
            }))
        this.loading = false
      })
    },
    getManageLink(row){
      return row.type === "tgBot" ? `/cab/chat_bots/manage_tg_bot?bot_id=${row._id}` : ""
    },
    showNewChannelDialog(){
      this.newBotForm.token = ""
      this.selectedChannelType = ""
      this.resetPersonalTgChannelForm()
      this.newChannelFormVisible = true
    },
    saveTgBot() {
      this.saving = true
      this.axios.post("/chatbot/createTgBot", this.newBotForm)
          .then(resp => {
            if (resp.data.error) {
              if (resp.data.error === "E_INCORRECT_TOKEN") this.$gNotify("Неверный токен", "error")
              else if (resp.data.error === "E_DUPLICATE_TOKEN") this.$gNotify("Бот с таким токеном уже создан", "error")
              else this.$gNotify("Ошибка импорта, обратитесь в техподдержку", "error")
            } else {
              this.newChannelFormVisible = false
              this.loadAll()
              this.$gNotify("Telegram-бот создан", "success")
            }
            this.saving = false
          })
          .catch(console.error)
    },
    loadDirectTgQrCode() {
      this.qrCodeLoading = true
      this.axios.post("/tgPersonal/newConnection", {})
          .then(resp => {
          })
          .catch(console.error)
    },
    loadWhatsappQrCode() {
      this.qrCodeLoading = true
      this.axios.post("/whatsapp/newConnection", {})
          .then(resp => {
          })
          .catch(console.error)
    },
    authorizeChannel() {
      this.authorizing = true
      this.axios.post("/tgPersonal/twoFaPassword", this.newChannelForm)
          .then(resp => {

          })
          .catch(console.error)
    },
    resetPersonalTgChannelForm() {
      this.newChannelPasswordRequired = false
      this.authorizing = false
      this.newChannelForm.password = ""
      this.newChannelForm.key = ""
      this.qrCode = ""
      this.qrCodeLoading = false
    },
    removePrivateTgChannel(row){
      this.tgChannelRemoving = true
      this.axios.delete("/tgPersonal/connection", {params: {connectionId: row._id}})
          .then(resp => {
            this.tgChannelRemoving=false
            this.$gNotify("Подключение к личному Telegram аккаунту удалено", "success")
            this.loadAll()
          })
          .catch(console.error)
    }
  },
  computed: {},
  data() {
    return {
      loading: false,
      saving: false,
      channels: [],
      newChannelFormVisible: false,

      selectedChannelType: "",
      newBotForm: {
        token: ""
      },
      newChannelPasswordRequired: false,
      newChannelForm: {
        password: "",
        key: ""
      },

      authorizing: false,
      qrCodeLoading: false,
      qrCode: "",

      tgChannelRemoving: false,


      newChannelOptions: [
        {type: "tgBot", name: "Telegram бот", img: "/static_img/telegram_large.png"},
        {type: "tgPersonal", name: "Личный Telegram аккаунт", img: "/static_img/telegram_large.png"},
        this.$store.getters.user.systemId() === "10004" ? {type: "whatsappGray", name: "Whatsapp", img: "/static_img/whatsapp.png"} : undefined,
      ]
    }
  }
}

</script>

<style scoped>
  .new-channel-option{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
</style>