<template>
  <div>
    <view-edit-base
        ref="viewEditBase"
        :edit-mode="true"
    />
    <add-contact-group
        :visible="actionsVisible.addGroup"
        @close="actionsVisible.addGroup = false"
        @groupCreated="actionsVisible.addGroup = Boolean(refreshGroups())"
    />
    <remove-duplicate-contacts
        :visible="actionsVisible.removeDuplicates"
        :all-groups="groups"
        @close="actionsVisible.removeDuplicates = false"
        @duplicatesRemoved="actionsVisible.removeDuplicates = Boolean(refreshGroups())"
    />
    <move-contacts
        :visible="actionsVisible.moveContact"
        :all-groups="groups"
        @close="actionsVisible.moveContact = false"
        @contactsMoved="actionsVisible.moveContact = Boolean(refreshGroups())"
    />
    <el-row :gutter="60">
      <el-col :md="16" :xs="24">
        <h4 class="report-parameters-title" style="display: flex; align-items: center;justify-content: space-between">
          <span style="flex-grow: 1">Группы контактов</span>
          <el-input
              v-model="groupsFilterToken"
              placeholder="Поиск групп контактов"
              clearable
          >
            <template #prepend>
              <el-select v-model="groupsFilterEntity" style="width: 130px">
                <el-option label="По имени" value="name" />
                <el-option label="По номеру" value="phone" />
              </el-select>
            </template>
            <template #append>

              <el-button
                  type="success" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px"

                  @click="filterGroups()"
              >
                <font-awesome-icon icon="search" />
              </el-button>
            </template>
          </el-input>
        </h4>
        <TableSpinner :loading="groupsLoading">
          <RemoveButton @confirm="removeSelected()" :loading="removingSelected" v-show="selectedGroups.length > 0">
            Удалить выбранные
          </RemoveButton>

          <el-table
              :data="groups" style="width: 100%;"
              :scrollbar-always-on="true"
              max-height="500px"
              @selection-change="handleGroupSelected"

          >
            <el-table-column type="selection" width="50"
                             v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_CONTACTS_CHANGE)"/>
            <el-table-column prop="name" label="Название" min-width="140" sortable/>
            <el-table-column prop="size" label="Размер" min-width="100" sortable />
            <el-table-column min-width="100">
              <template #default="scope">
                  <el-button text size="small" :loading="previewLoading" class="text-button table-text-button"
                             @click="viewBase(scope.row)">
                    <font-awesome-icon icon="edit" class="el-icon--left"></font-awesome-icon>&nbsp;Управление
                  </el-button>

              </template>
            </el-table-column>
          </el-table>

          <div class="solo-pagination-container">
            <el-button type="success" @click="actionsVisible.addGroup = true">
              <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Добавить группу
            </el-button>
            <el-dropdown
                @command="c => actionsVisible[c] = true"
                trigger="click"
                type="success"
                v-if="!$store.getters.user.hasPrivilege($PRIVILEGES.DENY_CONTACTS_CHANGE)"
            >
              <el-button type="default">
                <font-awesome-icon icon="chevron-down"></font-awesome-icon>&nbsp;Действия
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
<!--                  <el-dropdown-item command="addGroup">Добавить группу</el-dropdown-item>-->
                  <el-dropdown-item command="moveContact">Перенос контактов</el-dropdown-item>
                  <el-dropdown-item command="removeDuplicates">Удаление дублей</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
<!--            <el-pagination-->
<!--                background-->
<!--                class="wide-page-size"-->
<!--                layout="sizes, prev, pager, next"-->
<!--                :total="totalGroups"-->
<!--                :pager-count="3"-->
<!--                :page-sizes="[10, 20, 50, 100]"-->
<!--                :page-size="pageSize"-->
<!--                :current-page="currentPage"-->
<!--                :hide-on-single-page="true"-->
<!--                @size-change="handlePageSizeChange"-->
<!--                @current-change="handleCurrentPageChange"-->
<!--            >-->
<!--            </el-pagination>-->
          </div>
        </TableSpinner>

      </el-col>
      <el-col :md="8" :xs="24">
        <el-divider class="hidden-md-and-up"/>
        <h4 class="report-parameters-title" >Статистика операторов</h4>
        <TableSpinner :loading="statsLoading">
          <el-table
              :data="operatorsStatistics" style="width: 100%; margin-bottom: 20px"
              @selection-change="handleGroupSelected"
          >
            <el-table-column prop="name" label="Наименование"/>
            <el-table-column prop="pl" label="Номеров"/>
          </el-table>
        </TableSpinner>
      </el-col>
    </el-row>


  </div>
</template>

<script>

import ViewEditBase from "@/components/addressbooks/viewEditBase.component"
import AddContactGroup from "@/components/addressbooks/addBase.component";
import RemoveDuplicateContacts from "@/components/addressbooks/removeDuplicates.component";
import MoveContacts from "@/components/addressbooks/moveContacts.component";

import RemoveButton from "@shared/components/layout/removeButton.component";

export default {
  name: "AddressGroups",
  props: ["groups", "groupsLoading"],
  components: {
    RemoveButton,

    MoveContacts,
    RemoveDuplicateContacts,
    AddContactGroup,
    ViewEditBase
  },
  mounted() {
    this.refreshGroups()
  },
  methods: {
    handlePageSizeChange(newSize) {
      console.log(newSize)
      this.pageSize = newSize

      // this.refreshGroups(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      // this.refreshGroups(false)
    },

    handleGroupSelected(groups) {
      this.selectedGroups = groups
    },
    removeSelected() {
      this.removingSelected = true
      this.axios.delete("/contacts/removeGroups", {params: {groups: this.selectedGroups.map(g => g._id).join(",")}})
          .then(() => {
            this.removingSelected = false
            this.currentPage = 1
            this.refreshGroups()
            this.$gNotify("Группы контактов удалены", "success")
            this.$emit("reloadGroups")
          })
    },
    editOne() {

    },
    refreshGroups() {
      this.$emit("reloadGroups")
      // this.loading = true
      // this.axios.get("/contacts/myGroups")
      //     .then(resp => {
      //       this.groups = resp.data
      //       this.totalGroups = resp.data.length || 0
      //       this.loading = false
      //     })
      this.statsLoading = true
      this.axios.get("/contacts/operatorsStat")
          .then(resp => {
            this.operatorsStatistics = resp.data
            this.statsLoading = false
          })
    },

    viewBase(base) {
      this.$refs.viewEditBase.viewBase(base)
      // this.previewLoading = true
    },
    filterGroups(){
      this.$emit("filterGroups", {'type': this.groupsFilterEntity, 'token': this.groupsFilterToken})
    }
  },
  computed: {
    visiblePortion() {
      // console.log(this.pageSize)
      // console.log(this.groups.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize))
      return this.groups.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    totalGroups() {
      return this.groups.length
    }
  },

  data() {
    return {
      // loading: false,
      statsLoading: false,
      removingSelected: false,
      selectedGroups: [],
      // totalGroups: this.groups.length,
      pageSize: 10,
      currentPage: 1,
      // groups: [],
      previewLoading: false,
      operatorsStatistics: [],
      actionsVisible: {
        addGroup: false,
        moveContact: false,
        removeDuplicates: false
      },
      groupsFilterToken: "",
      groupsFilterEntity:"name"
    }
  },
}

</script>

<style>


</style>