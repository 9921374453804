<template>
  <div>
    <Breadcrumbs primary="Финансы" secondary="Детализация"/>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card no-top-body-padding no-border">

          <el-form label-position="top" style="width: 100%">
            <el-row :gutter="30">
              <el-col :xs="24" :md="8">
                <TimeSearch
                    :full-width-select="true"
                    ref="timeSearch"
                    :month="true"
                    :show-last-month="true"
                />
              </el-col>
              <el-col :xs="24" :md="8">
                <el-form-item label="Страна">
                  <CountrySelect v-model="selectedCountry" ref="countrySelect" :full-width="true"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8">
                <el-form-item label="Канал">
                  <el-select v-model="reportProduct" style="width: 100%">
                    <el-option label="Все" value=""></el-option>
                    <el-option v-for="(label, type) in $CHANNEL_NAMES" :label="label" :value="type"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="30" justify="start">
              <el-col :xs="24" :md="8">
                <el-form-item label="Группировать по">
                  <el-select v-model="grouping" class="full-width">
                    <el-option label="Группам операторов" :value="0"></el-option>
                    <el-option label="Операторам" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8" v-show="grouping === 0">
                <el-form-item label="Группа операторов">
                  <el-select v-model="operatorGroup" style="width: 100%" :filterable="true">
                    <el-option label="Все группы" value=""></el-option>
                    <el-option
                        v-for="(v, i) in $store.getters.operatorsGroupsSms"
                        :key="i"
                        :label="v.title"
                        :value="v._id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8" v-show="grouping === 1">
                <el-form-item label="Оператор">
                  <el-select v-model="operator" style="width: 100%;" :filterable="true">
                    <el-option label="Все операторы" value=""></el-option>
                    <el-option
                        v-for="(v, i) in operators"
                        :key="i"
                        :label="v.label"
                        :value="v.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :md="8">
                <el-form-item label="Тип отчета">
                  <el-select v-model="reportType" style="width: 100%">
                    <el-option label="Общий" :value="0"></el-option>
                    <el-option label="Подробный" :value="1"></el-option>
                    <el-option label="По пользователям" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-button type="success" plain @click="getReport" :loading="reportLoading">Применить</el-button>
          <el-button type="primary" plain @click="downloadReport" v-show="reportReady && reportTypeStatic !== 0" :loading="reportDownloading">Экспортировать</el-button>

          <div v-show="reportReady" style="margin-top: 50px">

            <h3 class="no-margin-top" style="display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap">
              <span>Детализация за {{ detailHeaderMonth }}</span>
              <el-checkbox-group v-model="unwrapBy" v-show="reportTypeStatic !== 0" >
                <el-checkbox label="countryName">Страна</el-checkbox>
                <el-checkbox label="type">Канал</el-checkbox>
                <el-checkbox label="trafficType">Тип трафика</el-checkbox>
                <el-checkbox label="senderName">Подпись</el-checkbox>
                <el-checkbox label="operatorName">Оператор</el-checkbox>
              </el-checkbox-group>
            </h3>

            <!-- GENERAL REPORT TABLE -->
            <el-table
                v-if="visibleReportType === 0"
                :data="commonReportTableData"
                style="width: 100%"
                show-summary
                :summary-method="getCommonReportSummaries"
            >
              <el-table-column prop="type" label="Тип" min-width="180"/>
              <el-table-column label="Кол-во для тарификации" min-width="190">
                <template #default="scope">
                  <span v-html="commonReportAmountFormatter(scope.row)"></span>
                </template>
              </el-table-column>
              <el-table-column label="Цена / ед." min-width="180">
                <template #default="scope">
                  <span v-html="commonReportUnitPriceFormatter(scope.row)"></span>
                </template>
              </el-table-column>
              <el-table-column :formatter="row => formatNumber(row.total)" label="Всего" min-width="180"/>
            </el-table>


            <!-- DETAIL REPORT TABLE -->
            <div v-if="visibleReportType !== 0">
              <FinDetailUserSmsTable
                  :table-data="detailReportTableDataUnwrapped"
                  :grouping="groupingStatic"
                  :unwrapBy="unwrapBy"
                  v-if="visibleReportType === 1 && !this.reportLoading"
              />

              <el-tabs v-model="visibleUserId" class="inside-tabs"
                       v-if="visibleReportType === 2  && !this.reportLoading"
                       v-show="Object.keys(detailReportTableData).length"
              >

                <el-tab-pane
                    v-for="(tableData, userId, index) in detailReportTableDataUnwrapped"
                    :key="userId + '_' + index"
                    :label="userId" :name="userId"
                >
                  <FinDetailUserSmsTable
                      :table-data="tableData"
                      :omit-first-separator="true"
                      :grouping="groupingStatic"
                      :unwrapBy="unwrapBy"
                  />
                </el-tab-pane>
              </el-tabs>
              <div v-show="detailReportNamesTableData.length">
                <el-divider class="blue-divider" style="margin-top: 40px; margin-bottom: 40px"/>
                <h4 class="no-margin">Детализация подписей</h4>
                <el-table

                    :data="detailReportNamesTableData"
                    style="width: 100%; margin-top: 30px"
                    :span-method="spanMethod"
                    :summary-method="getNamesSummaries"
                    :show-summary="true"
                    :row-class-name="({row})  => row.__rate_name_row ? 'rate-name-row' : ''"
                >
                  <el-table-column prop="rateName"/>
                  <el-table-column min-width="150px" label="Продукт">
                    <template #default="scope">Аренда подписи</template>
                  </el-table-column>

                  <el-table-column prop="amount" min-width="200px" label="Кол-во подписей"/>
                  <el-table-column min-width="100px" label="Цена">
                    <template #default="scope">
                      {{ formatNumber(scope.row.unitPrice) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Расходы" min-width="100px">
                    <template #default="scope">
                      {{ formatNumber(scope.row.spent) }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-divider class="blue-divider" style="margin-top: 40px; margin-bottom: 40px"/>
              <el-table
                  :data="detailReportGlobalSummaryRows"
                  style="width: 100%;"
                  :summary-method="getTotalSummary"
                  :show-summary="true"
              >
                <el-table-column prop="name" label=" "/>
                <el-table-column label="Кол-во для тарификации" prop="amount"/>
                <el-table-column label="Расходы" :formatter="r => formatNumber(r.spent, '.', r.__precision)"/>

              </el-table>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import CountrySelect from "@shared/components/country-select.component"
import FinDetailUserSmsTable from "../../components/finance/findetail-user-sms-table.component.vue";
import iconv from "iconv-lite";

export default {
  name: "FinancialOperations",
  title: "Детализация",
  components: {
    FinDetailUserSmsTable,

    TimeSearch,
    CountrySelect
  },
  watch: {
    // selectedCountry(newVal) {
    //   console.log(newVal)
    // }
  },
  computed: {
    detailReportTableDataUnwrapped() {
      let unwrapRateLevel = tableData => {
        return Object.entries(tableData).reduce((acc, [rateName, rows]) => {
          acc[rateName] = Object.values(rows.reduce((acc, r) => {
            let key = this.unwrapBy.map(k => r[k]).join("_")
            if (!acc[key]) {
              acc[key] = Object.assign({}, r)
              acc[key].sent = 0
              acc[key].spent = 0
              acc[key].avgPrice = []
              acc[key].__precision = []
            }
            acc[key].sent += r.sent
            acc[key].spent += r.spent
            acc[key].avgPrice.push(r.avgPrice)
            acc[key].__precision.push(r.__precision)
            return acc
          }, {})).sort((r1, r2) => r1.senderName.localeCompare(r2.senderName)).map(v => {
            v.__precision = Math.max(...v.__precision) || 2
            v.avgPrice = this.reduceAvg(v.avgPrice)
            return v
          })
          return acc
        }, {})
      }

      if (this.reportTypeStatic === 1) return this.deduplicateDetailRows(unwrapRateLevel(this.detailReportTableData))
      if (this.reportTypeStatic === 2) return Object.entries(this.detailReportTableData).reduce((acc, [userId, rateLevelData]) => {
        acc[userId] = this.deduplicateDetailRows(unwrapRateLevel(rateLevelData))
        return acc
      }, {})
      return {}
    }
  },
  methods: {
    commonReportAmountFormatter(row) {
      if (row._type !== 2) return row.sent
      return row.records.map(r => `${r.rateName}`).join("<br/>")
    },
    commonReportUnitPriceFormatter(row) {
      if (row._type !== 2) return parseFloat((row.total / Math.max(row.sent, 1))).toFixed(4)
      return row.records.map(r => `${r.amount} x ${r.unitPrice}`).join("<br/>")
    },
    groupResultRowsByUser(sms, retval, names) {
      let groupedNames = {}
      let groupedSms = {}


      for (let row of sms) {
        if (!groupedSms[row.target]) groupedSms[row.target] = {byRates: {}, retval: {}}
        if (!groupedSms[row.target].byRates[row.rate_name]) groupedSms[row.target].byRates[row.rate_name] = []
        groupedSms[row.target].byRates[row.rate_name].push(row)
      }

      for (let row of retval) {
        if (!groupedSms[row.target]) groupedSms[row.target] = {byRates: {}, retval: {}}
        if (!groupedSms[row.target].retval[row.rate_name]) groupedSms[row.target].retval[row.rate_name] = []
        groupedSms[row.target].retval[row.rate_name].push(row)
      }

      for (let row of names) {
        if (!groupedSms[row.target]) groupedSms[row.target] = {byRates: {}}
        if (!groupedNames[row.target]) groupedNames[row.target] = {byRates: {}}
        if (!groupedNames[row.target].byRates[row.rate_name]) groupedNames[row.target].byRates[row.rate_name] = []
        groupedNames[row.target].byRates[row.rate_name].push(row)
      }
      return {groupedSms, groupedNames}
    },
    deduplicateDetailRows(rows) {
      return Object.entries(rows).reduce((mAcc, [rateName, rateRows]) => {
        mAcc[rateName] = Object.values(rateRows.reduce((acc, r) => {
          let key = `${r.countryName}_${r.type}_${r.trafficType}_${r.senderName}_${r.operatorName}_${r.avgPrice}`
          if (!acc[key]) {
            acc[key] = Object.assign({}, r)
            acc[key].sent = 0
            acc[key].spent = 0
          }
          acc[key].sent += r.sent
          acc[key].spent += r.spent
          acc[key].__precision = Math.max(acc[key].__precision, r.__precision)
          return acc
        }, {}))
        return mAcc
      }, {})
    },
    generateReportData(groupedSms, groupedNames, __report_type) {
      let generalStatistics = {
        call: {
          sent: 0,
          spent: 0,
        },
        sms: {
          sent: 0,
          spent: 0
        },
        vk: {
          sent: 0,
          spent: 0
        },
        telegram: {
          sent: 0,
          spent: 0
        },
        whatsapp: {
          sent: 0,
          spent: 0
        },

        avgPrice: 0,
        names: {
          records: [],
          spent: 0,
          avgPrice: 0,
          totalNames: 0
        }
      }
      let totalRecords = 0

      this.detailReportGlobalSummaryRows = [
        {amount: 0, spent: 0, name: 'Сообщения и звонки'},
        {amount: 0, spent: 0, name: 'Подписи'},
      ]
      let detailData = {}
      for (let [userId, userData] of Object.entries(groupedSms)) {
        detailData[userId] = {}
        for (let [rateName, rateData] of Object.entries(userData.byRates)) {
          if (!detailData[userId][rateName]) detailData[userId][rateName] = []
          let retvalData = userData.retval[rateName] || []

          let addedOpsForThisRate = 0
          for (let record of rateData) {
            let retval = retvalData.find(r => record.operator_group_id === r.operator_group_id && record.sms_type === r.sms_type) || {
              totalSum: 0,
              totalMessages: 0
            }

            record.operator = this.operators.find(o => o.value === record.operator_id) || {
              OrgName: "Не оперделён",
              mcc: "",
              MNC: ""
            }
            if (this.grouping === 0) {
              record.operatorName = (this.$store.getters.operatorsGroups.find(g => g._id === record.operator_group_id) || {title: "Не оперделён"}).title
            } else record.operatorName = record.operator.OrgName
            record.mcc = record.operator.mcc

            let country = this.$refs.countrySelect.getCountryByMcc(record.mcc) || this.$refs.countrySelect.getCountryById(record.country_id) || {
              country_name: "",
              country_code: ""
            }
            if (country.country_code !== "" && this.selectedCountry !== "" && String(this.selectedCountry) !== String(country.country_code)) continue

            if (this.operatorGroup !== "" && this.operatorGroup !== record.operator_group_id) continue
            if (this.reportProduct !== "" && this.reportProduct !== record.sms_type) continue
            if (record.draft_traffic_type === "" && record.with_draft) record.draft_traffic_type = "service"

            let row = {
              countryName: country.country_name,
              type: CHANNEL_NAMES[record.sms_type],
              trafficType: TRAFFIC_TYPES[record.draft_traffic_type || ''],
              // mcc: String(country.country_code),
              // mnc: record.operator.mnc,
              senderName: record.draft_traffic_type === "flash" ? '' : record.sms_sender_name,
              operatorName: record.operatorName,
              sent: (parseInt(record.totalMessages) - (parseInt(retval.totalMessages) || 0)),
              avgPrice: (parseFloat(record.rec_unit_price) || parseFloat(record.unit_price) || 0),
              spent: (parseFloat(record.totalRecSum) || parseFloat(record.totalSum) || 0) - (parseFloat(retval.totalSum) || 0),
              __precision: 2,
            }
            row.__precision = Math.max(2, (String(row.avgPrice).split(".")[1] || "").replace(/0+$/, '').length)

            detailData[userId][rateName].push(row)
            addedOpsForThisRate++
            this.detailReportGlobalSummaryRows[0].amount += row.sent
            this.detailReportGlobalSummaryRows[0].spent += row.spent

            generalStatistics[record.sms_type].sent += parseInt(record.totalMessages) - (parseInt(retval.totalMessages) || 0)
            generalStatistics[record.sms_type].spent += ((parseFloat(record.totalRecSum) || parseFloat(record.totalSum) || 0) - (parseFloat(retval.totalSum) || 0))
            // if (record.sms_type === "call") {
            //
            // } else {
            //   generalStatistics.sms.sent += parseInt(record.totalMessages) - (parseInt(retval.totalMessages) || 0)
            //   generalStatistics.sms.spent += (parseFloat(record.totalRecSum) || parseFloat(record.totalSum) || 0) - (parseFloat(retval.totalSum) || 0)
            // }
            generalStatistics.avgPrice += (parseFloat(record.rec_unit_price) || parseFloat(record.unit_price) || 0)
            totalRecords++

          }
          if (addedOpsForThisRate === 0) delete detailData[userId][rateName]
        }
        if (groupedNames[userId]) {
          for (let [rateName, rateData] of Object.entries(groupedNames[userId].byRates)) {

            generalStatistics.names.records.push({
              rateName: rateName,
              amount: rateData[0].totalCount,
              unitPrice: Number(rateData[0].unit_price)
            })
            this.detailReportNamesTableData.push({
              __rate_name_row: true,
              rateName: rateName
            })
            let row = {
              amount: rateData[0].totalCount,
              unitPrice: Number(rateData[0].unit_price),
              spent: parseFloat(rateData[0].totalSum)
            }
            this.detailReportNamesTableData.push(row)
            this.detailReportGlobalSummaryRows[1].amount += row.amount
            this.detailReportGlobalSummaryRows[1].spent += row.spent
            generalStatistics.names.spent += Number(rateData[0].totalSum)
            generalStatistics.names.totalNames += Number(rateData[0].totalCount)
          }
        }
      }
      for (let [userId, rowsByRates] of Object.entries(detailData)) {
        if (!Object.keys(rowsByRates).length) delete detailData[userId]
        // detailData[userId] = this.deduplicateDetailRows(rows)
      }

      let detailDataSorted = Object.keys(detailData).sort((k1, k2) => {
        return Number(k1.split(".")[1] || 0) - Number(k2.split(".")[1] || 0)
      }).reduce((obj, key) => {
        obj[key] = detailData[key];
        return obj;
      }, {});
      let visibleIds = Object.keys(detailDataSorted)
      if (!visibleIds.length) this.visibleUserId = ""
      this.visibleUserId = visibleIds[0]

      generalStatistics.avgPrice /= totalRecords
      this.commonReportTableData = [
        {type: "Сообщения", sent: generalStatistics.sms.sent, total: generalStatistics.sms.spent, _type: 0},
        {type: "Звонки", sent: generalStatistics.call.sent, total: generalStatistics.call.spent, _type: 1},
        {type: "VK/OK", sent: generalStatistics.vk.sent, total: generalStatistics.vk.spent, _type: 1},
        {type: "Telegram", sent: generalStatistics.telegram.sent, total: generalStatistics.telegram.spent, _type: 1},
        {type: "Whatsapp", sent: generalStatistics.whatsapp.sent, total: generalStatistics.whatsapp.spent, _type: 1},
        {type: "Подписи", records: generalStatistics.names.records, total: generalStatistics.names.spent, _type: 2},
      ]

      this.generalStatistics = generalStatistics
      if (this.visibleUserId) {
        if (__report_type === 1) {
          if (Object.keys(detailDataSorted).length) this.detailReportTableData = detailDataSorted[Object.keys(detailDataSorted)[0]]
          else this.detailReportTableData = {}
        } else this.detailReportTableData = detailDataSorted
      } else this.detailReportTableData = {}//__report_type === 1 ? [] : {}
    },
    getHeaderMonth(){
      return this.$refs.timeSearch.getDateTimeFilter().from.locale("ru-RU").format("MMMM YYYY")
    },
    getReport() {
      this.reportReady = false
      this.reportLoading = true
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      this.detailHeaderMonth = this.getHeaderMonth()
      this.detailReportNamesTableData = []
      this.detailReportTableData = {}//this.reportType === 1 ? [] : {}
      let rpt = this.reportType
      this.reportTypeStatic = Number(rpt)
      this.groupingStatic = Number(this.grouping)
      this.axios.get(
          "/finance/getDetailReport",
          {
            params: {
              month: dateFilter.from.format("DD.MM.YYYY"),
              country: this.country,
              grouping: this.grouping,
              operatorGroup: this.operatorGroup,
              operator: this.operator,
              reportType: this.reportType
            }
          })
          .then(async resp => {

            let {sms, retval, names, managers} = resp.data
            let {groupedSms, groupedNames} = this.groupResultRowsByUser(sms, retval, names)
            // console.log("Results grouped")
            try {
              this.generateReportData(groupedSms, groupedNames, rpt)
            } catch (e) {
              console.log(e)
            }
            // await this.$nextTick()
            this.reportReady = true
            this.reportLoading = false
            this.visibleReportType = rpt

          })
          .catch(() => {

          })
    },
    getCommonReportSummaries() {
      return ["Итого", "", "", this.commonReportTableData.reduce((acc, r) => acc + r.total, 0).toFixed(2)]
    },
    getNamesSummaries() {
      return [
        "Всего", "",
        this.generalStatistics.names.totalNames,
        this.formatNumber(this.generalStatistics.names.spent / this.generalStatistics.names.totalNames),
        this.formatNumber(this.generalStatistics.names.spent || 0)
      ]
    },
    getTotalSummary() {
      return [
        "Общий итог",
        this.detailReportGlobalSummaryRows[0].amount + this.detailReportGlobalSummaryRows[1].amount,
        this.formatNumber(this.detailReportGlobalSummaryRows[0].spent + this.detailReportGlobalSummaryRows[1].spent)
        // (this.generalStatistics.names.totalNames || 0) + this.generalStatistics.call.sent + this.generalStatistics.sms.sent,

        // this.formatNumber(
        //     (this.generalStatistics.names.spent || 0) + (
        //         (this.generalStatistics.call.spent + this.generalStatistics.sms.spent + this.generalStatistics.vk.spent + this.generalStatistics.telegram.spent + this.generalStatistics.whatsapp.spent) || 0)
        // )
      ]

    },
    spanMethod({row, columnIndex}) {
      if (row.__rate_name_row)
        if (columnIndex === 0) return [1, 10]
        else return [0, 0]
    },
    downloadReport(){
      this.reportDownloading = true
      let rows = []
      let injectRateName = ([rateName, rateRows]) => {
        return rateRows.map(r => {
          r.rateName = rateName
          return r
        }).sort((r1, r2) => r1.senderName.localeCompare(r2.senderName))
      }
      if (this.reportTypeStatic === 1) rows = Object.entries(this.deduplicateDetailRows(this.detailReportTableData)).map(injectRateName).flat()
      if (this.reportTypeStatic === 2) rows = Object.entries(this.detailReportTableData).map(([userId, rateLevelData]) => {
        return  Object.entries(this.deduplicateDetailRows(rateLevelData)).map(injectRateName).flat().map(r => {
          r.userId = userId
          return r
        })
      }).flat()
      let csvContent = [
        iconv.encode(
            `${this.reportTypeStatic === 2 ? 'Пользователь;' : ''}Тариф;Страна;Продукт;Тип трафика;Подпись;Оператор;Кол-во сообщений;Цена;Расходы\n`,
            "win1251"
        ),
        ...rows.map(r => {
          return iconv.encode([
            this.reportTypeStatic === 2 ? r.userId : undefined,
              r.rateName,
              r.countryName,
              r.type,
              r.trafficType,
              r.senderName,
              r.operatorName,
              r.sent,
              this.formatNumber(r.avgPrice, ',', r.__precision),
              this.formatNumber(r.spent, ',', r.__precision)
          ].filter(v => v !== undefined).join(";") + "\n","win1251")
        })
      ]
      let blob = new Blob(csvContent, {type: 'text/csv'});

      let a = document.createElement('a');
      a.download = `Детализация расходов за ${this.getHeaderMonth()}.csv`;
      a.href = URL.createObjectURL(blob);
      a.click();
      this.reportDownloading = false
    }
  },
  mounted() {

    this.axios.get("/resources/operators")
        .then(resp => {
          this.operators = resp.data.map(c => {
            return {label: c.OrgName, value: c._id, mcc: Number(c.mcc), mnc: c.MNC, OrgName: c.OrgName}
          })
        })
  },
  data() {
    return {
      reportLoading: false,
      reportReady: false,
      detailHeaderMonth: "",
      commonReportTableData: [],
      detailReportTableData: {},
      detailReportGlobalSummaryRows: [],
      detailReportNamesTableData: [],
      grouping: 0,
      groupingStatic: 0,
      reportType: 0,
      reportTypeStatic: 0,
      reportProduct: "",
      visibleReportType: 0,
      selectedCountry: "",
      operatorGroup: "",
      operator: "",
      operators: [],
      generalStatistics: {call: {}, sms: {}, vk: {}, telegram: {}, whatsapp: {}, names: {}},
      userStatistics: {},
      visibleUserId: "",
      reportDownloading: false,
      unwrapBy: [
        "countryName",
        "type",
        "trafficType",
        "senderName",
        "operatorName"
      ]
    }
  }
}

</script>

<style>

.rate-name-row {
  font-size: 15px;
  font-weight: bold;
  color: #31373a !important;
  /*padding: 20px 40px !important;*/
}

.rate-name-row .el-table__cell {
  padding-top: 40px;
  border-bottom: 1px solid #31373a !important;
}

</style>