import TableSpinner from "@shared/components/layout/table-spinner.component";
import TitleMixin from "@shared/components/layout/title.component";
import Breadcrumbs from "@shared/components/layout/breadcrumbs.component";
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";
import ActionButton from "@shared/components/layout/actionButton.component";

import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/ru'
import moment from "moment"
import mitt from 'mitt'

moment.locale("ru")

let installElementPlus = require('../shared/plugins/element').default
let {createApp, h} = require('vue')
let VueAxios = require('vue-axios')
let {FontAwesomeIcon} = require('@fortawesome/vue-fontawesome')

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faHome,
  faChartBar,
  faEdit,
  faCreditCard,
  faLifeRing,
  faPlus,
  faTimes,
  faPencilAlt,
  faCopy,
  faCalendarAlt,
  faInfoCircle,
  faSignOutAlt,
  faSpinner,
  faMinus,
  faCheck,
  faExternalLinkAlt,
  faClone,
  faPlusCircle,
  faWrench,
  faEye,
  faFile,
  faRedo,
  faChevronDown,
  faClock,
  faBan,
  faCheckCircle,
  faBars,
  faTimesCircle,
  faQuestionCircle,
  faComments,
  faTrash,
  faPaperclip,
  faSave,
  faPaperPlane,
  faChevronRight, faChevronLeft, faChevronUp, faRandom, faSearch,
  faList, faDownload,
  faLock
} from '@fortawesome/free-solid-svg-icons'

library.add(faEnvelope, faHome, faChartBar, faEdit, faCreditCard, faLifeRing, faPlus, faTimes, faPencilAlt, faCopy, faCalendarAlt, faInfoCircle, faSignOutAlt, faSpinner, faMinus, faCheck, faExternalLinkAlt, faClone, faPlusCircle, faWrench, faEye, faFile, faRedo, faChevronDown, faClock, faBan, faCheckCircle, faBars, faTimesCircle, faQuestionCircle, faComments, faTrash, faPaperclip, faSave, faPaperPlane, faChevronRight, faChevronLeft, faChevronUp, faRandom, faSearch, faList, faDownload, faLock)


require('element-plus/dist/index.css')
require('@shared/assets/style/main.css')
require('@shared/assets/style/mobile.css')
require('@shared/assets/style/flow-nodes.css')
require('@shared/assets/style/dialogs.css')
import 'element-plus/theme-chalk/display.css'

import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';
import '@vue-flow/controls/dist/style.css';


let axios = require('@shared/axios')
let store = require('./store')
let router = require('./router')
let customNotify = require('../shared/plugins/notifyWrapper')
// let privilegesManager = require('./plugins/privilegesManager')
let privileges = require("./privileges")
let userRoles = require("./userRoles")

require("../shared/globals")

const app = createApp({render: () => h(require('./App.vue').default)})

// installElementPlus(app)
app.use(ElementPlus, {locale})
app.mixin(TitleMixin)
app.use(VueAxios, axios)
app.use(customNotify)

let reduceSum = (data, prop) => data.reduce((a, b) => a + (prop ? b[prop] : b) || 0, 0)
app.config.globalProperties.axios = axios
app.config.globalProperties.formatNumber = (n, d, p) => parseFloat(n).toFixed(p || 2).replace(/\./g, d ? d : '.')
app.config.globalProperties.reduceSum = reduceSum
app.config.globalProperties.reduceAvg = (data, prop) => reduceSum(data, prop) / (data.length || 1)
app.config.globalProperties.formatDate = n => moment(n).format("DD.MM.YYYY")
app.config.globalProperties.formatDateTime = n => moment(n).format("DD.MM.YYYY HH:mm")
app.config.globalProperties.formatUrl = url => /^http[s]?\:\/\//.test(url) ? url : `https://${url}`
app.config.globalProperties.fileToUrl = (file) => {
  try {
    return window.URL.createObjectURL(file.raw)
  } catch (e) {
    console.log(e)
    return ""
  }
}
app.config.globalProperties.$fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
})
app.config.globalProperties.validateUrl = v =>{

  return /^https?:\/\/.+\..{2,10}.*/i.test(v)
}
app.config.globalProperties.$PRIVILEGES = privileges

app.config.globalProperties.$USER_ROLES = userRoles
app.config.globalProperties.$CHANNEL_NAMES = CHANNEL_NAMES

const emitter = mitt()
app.config.globalProperties.$emitter = emitter;

store.dispatch("__loadResources")
store.dispatch("connectWs", emitter)
app.use(router)

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response  && error.response.status === 401) {
    store.dispatch("logout", {
      callback: () => {
        router.push({path: "/login"})
      }
    });
  }
  return Promise.reject( error);
})


global.cypressNavigate = path => router.push({path})
global.cypressSignOut = () => {
  store.dispatch("logout");
  router.push({path: "/login"})
}
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('TableSpinner', TableSpinner)
app.component('Breadcrumbs', Breadcrumbs)
app.component('ActionsDropdown', ActionsDropdown)
app.component('ActionButton', ActionButton)
app.mount("#app")