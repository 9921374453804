<template>
  <el-drawer
      v-model="visible"
      :title="nodeName"
  >

    <el-row>
      <el-col :span="24">
        <el-form label-position="top">
          <div v-if="nodeType === 'messageChain'">
            <message-chain-settings v-model="settings"/>
          </div>
          <div v-if="nodeType === 'contactRequest'">
            <contact-request-settings v-model="settings"/>
          </div>
          <div v-if="nodeType === 'addTag' || nodeType === 'removeTag'">
            <add-remove-tag-settings v-model="settings" :bot="bot"/>
          </div>
          <div v-if="nodeType === 'setVariable' || nodeType === 'removeVariable'">
            <set-remove-variable-settings v-model="settings" :bot="bot" :type="nodeType"/>
          </div>
          <div v-if="nodeType === 'switchCase'">
            <switch-case-settings v-model="settings" :bot="bot"/>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </el-drawer>
</template>

<script>

import MessageChainSettings from "./nodesSettings/messageChain.settings.vue";
import ContactRequestSettings from "./nodesSettings/contactRequest.settings.vue";
import AddRemoveTagSettings from "./nodesSettings/addRemoveTag.settings.vue";
import SetRemoveVariableSettings from "./nodesSettings/setRemoveVariable.settings.vue";
import SwitchCaseSettings from "./nodesSettings/switchCase.settings.vue";

export default {
  name: "NodeSettings",
  components: {
    SwitchCaseSettings,
    SetRemoveVariableSettings, AddRemoveTagSettings, ContactRequestSettings, MessageChainSettings},
  props: ["editingNode", "bot"],
  watch: {},
  beforeMount() {

  },
  mounted() {

  },
  methods: {
    open(node) {
      this.visible = true
      this.nodeName = node.data.title
      this.nodeType = node.data.type
      this.settings = node.data.settings
    }
  },
  computed: {},
  data() {
    return {
      visible: false,
      nodeName: "",
      nodeType: "",
      settings: null
    }
  }
}

</script>

<style>

</style>