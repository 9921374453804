<template>
  <BaseEdge :id="id" :style="style" :path="path[0]" :marker-end="markerEnd"/>

  <EdgeLabelRenderer>
    <div
        :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${path[1]}px,${path[2]}px)`,
      }"
        class="nodrag nopan"
    >
      <button class="edgebutton" @click="$emit('removeEdge', id)"><font-awesome-icon icon="times" /></button>
    </div>
  </EdgeLabelRenderer>
</template>

<script>
import {BaseEdge, EdgeLabelRenderer, getBezierPath} from '@vue-flow/core'

export default {
  name: "CustomEdge",
  components: {BaseEdge, EdgeLabelRenderer},
  props: {
    id: {
      type: String,
      required: true,
    },
    sourceX: {
      type: Number,
      required: true,
    },
    sourceY: {
      type: Number,
      required: true,
    },
    targetX: {
      type: Number,
      required: true,
    },
    targetY: {
      type: Number,
      required: true,
    },
    sourcePosition: {
      type: String,
      required: true,
    },
    targetPosition: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    markerEnd: {
      type: String,
      required: false,
    },
    style: {
      type: Object,
      required: false,
    }
  },
  inheritAttrs: false,
  computed:{
    path(){
      return getBezierPath(this.$props)
    }
  }
}
</script>

<style>

.edgebutton{
  background: var(--el-color-danger-lighter);
  border-radius: 5px;
  font-size: 16px;
  color: var(--el-color-danger-dark-2);
  border: 1px solid var(--el-color-danger-dark-2);
  cursor: pointer;
}

.edgebutton:hover{
  font-size: 20px;
}


</style>