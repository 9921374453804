<template>
  <div>
    <h4 class="report-parameters-title">Расписание отправки</h4>


    <el-select v-model="planner_type" style="width: 100%;margin-bottom: 20px">
      <el-option :value="-1" label="Сейчас" :disabled="isEditMode"></el-option>
      <el-option :value="0" label="В назначенное время"></el-option>
      <el-option :value="1" label="Равномерная отправка в период"></el-option>
      <el-option :value="2" label="События адресатов" v-if="channel === 'sms'"></el-option>
    </el-select>

    <el-form label-position="top">
      <div v-show="planner_type === 0">
        <el-form-item label="Дата" class="close-label">
          <el-date-picker
              class="full-width"
              v-model="planner_config.type_1.date"
              type="date"
              format="DD.MM.YYYY"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="Время" class="close-label">
          <el-time-picker
              class="full-width"
              v-model="planner_config.type_1.time"
              format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
      </div>


      <el-row v-show="planner_type === 1" :gutter="40">

        <el-col :xs="24" :md="12">
          <el-form-item label="Дата от" class="close-label">
            <el-date-picker
                class="full-width"
                v-model="planner_config.type_2.from.date"
                type="date"
                format="DD.MM.YYYY"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Время от" class="close-label">
            <el-time-picker
                class="full-width"
                v-model="planner_config.type_2.from.time"
                format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
          <el-divider class="hidden-md-and-up"/>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item label="Дата до" class="close-label">
            <el-date-picker
                class="full-width"
                v-model="planner_config.type_2.to.date"
                type="date"
                format="DD.MM.YYYY"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Время до" class="close-label">
            <el-time-picker
                class="full-width"
                v-model="planner_config.type_2.to.time"
                format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
          <el-divider class="hidden-md-and-up"/>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Интервал рассылки, мин." class="close-label">
            <el-input v-model="planner_config.type_2.distribution_interval"></el-input>
          </el-form-item>
          <el-form-item class="no-margin">
            <el-checkbox v-model="planner_config.type_2.exclude_weekend" label="Исключить выходные"></el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-show="planner_type === 2">
        <el-col :span="24">
          <el-form-item label="Выберите событие" class="close-label">
            <el-select v-model="planner_config.type_3.event_type" class="full-width">
              <el-option v-for="item in static.planner_type_3_event_type_options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Дата исполнения" class="close-label">
            <el-select v-model="planner_config.type_3.execution_padding" class="full-width">
              <el-option
                  v-for="item in static.planner_type_3_execution_padding_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Время рассылки" class="close-label" style="margin-bottom: 0px">
            <el-time-picker
                class="full-width"
                v-model="planner_config.type_3.execution_time"
                format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="" class="no-margin" v-if="channel === 'sms'">
        <el-checkbox
            class="break-label"
            v-model="local_time_delivery"
            label="Рассылка по местному времени адресата"
            v-show="planner_type !== -1"
        ></el-checkbox>
      </el-form-item>
    </el-form>


    <p v-show="planner_type === 1 && intervals.length" v-if="totalNumbers > 0">
      <font-awesome-icon icon="calendar-alt"></font-awesome-icon>&nbsp;
      <span>{{ intervals.length }} {{ deliveriesLabel }} по ~{{
          Math.ceil(totalNumbers / intervals.length)
        }} адр.</span>
    </p>
    <div v-show="planner_type === 1" class="el-form-item__label">
      <p class="no-margin-top " >
        В данном типе рассылки интервалы расчитываются на посуточной основе в пределах указанных границ.
        Например, при рассылке от 01.01.2345 12:00 до 02.01.2345 13:00 с интервалом в 30 минут фактические точки срабатывания будут:&nbsp;&nbsp;
        <u>01.01.2345 в <i>12:00, 12:30, 13:00</i></u>&nbsp;&nbsp;и&nbsp;&nbsp;<u>02.01.2345 в <i>12:00, 12:30, 13:00</i></u></p>
    </div>

  </div>
</template>

<script>

import moment from "moment"
import store from "@/store"

function dateTimeMoment(date, time) {
  if(!time) return moment(date, "DD.MM.YYYY HH:mm")
  return moment(moment(date).format("DD.MM.YYYY") + " " + moment(time).format("HH:mm"), "DD.MM.YYYY HH:mm")
}

export default {
  name: "Schedule",
  props: ["task", "isEditMode", "totalNumbers", "channel"],
  emits: ["typeChange"],
  components: {},
  computed: {
    intervals() {
      if (this.planner_type !== 1 || !parseInt(this.planner_config.type_2.distribution_interval)) return []
      let intervals = []
      let actualDates = []
      let startDate = moment(this.planner_config.type_2.from.date).startOf("day")
      let endDate = moment(this.planner_config.type_2.to.date).startOf("day")
      while (endDate.isSameOrAfter(startDate)) {
        if (!this.planner_config.type_2.exclude_weekend || startDate.day() % 6 !== 0)
          actualDates.push(moment(startDate))
        startDate.add(1, "day")
      }
      console.log(actualDates)
      for (let execDay of actualDates) {
        let __start = moment(execDay)
            .hour(moment(this.planner_config.type_2.from.time).hours())
            .minute(moment(this.planner_config.type_2.from.time).minutes())
        let __end = moment(execDay)
            .hour(moment(this.planner_config.type_2.to.time).hours())
            .minute(moment(this.planner_config.type_2.to.time).minutes())

        for (let m = moment(__start); m.diff(__end, 'minutes') <= 0; m.add(parseInt(this.planner_config.type_2.distribution_interval), 'minutes')) {
          intervals.push(m.format('DD.MM.YYYY HH:mm'))
          console.log("3")
        }
      }
      return intervals
    },
    deliveriesLabel() {
      let cnt = this.intervals.length
      if (String(cnt).endsWith("1") && (cnt > 11 || cnt < 10)) return "рассылка"
      if (
          String(cnt).endsWith("2") ||
          String(cnt).endsWith("3") ||
          String(cnt).endsWith("4") && (cnt > 11 || cnt < 10)
      ) return "рассылки"
      return "рассылок"
    }
  },
  watch: {
    task(newVal) {
      if (this.isEditMode && newVal) {
        this.local_time_delivery = newVal.config.local_time_delivery
        this.planner_type = newVal.type
        if (newVal.type === 0) {
          this.planner_config.type_1.date = moment(newVal.config.date, "DD.MM.YYYY").toDate()
          this.planner_config.type_1.time = moment(newVal.config.time, "HH:mm").toDate()
        }
        if (newVal.type === 1) {
          this.planner_config.type_2.from.date = moment(newVal.config.from.date, "DD.MM.YYYY").toDate()
          this.planner_config.type_2.from.time = moment(newVal.config.from.time, "HH:mm").toDate()
          this.planner_config.type_2.to.date = moment(newVal.config.to.date, "DD.MM.YYYY").toDate()
          this.planner_config.type_2.to.time = moment(newVal.config.to.time, "HH:mm").toDate()
          this.planner_config.type_2.exclude_weekend = newVal.config.exclude_weekend
        }
        if (newVal.type === 2) {
          this.planner_config.type_3.execution_time = moment(newVal.config.execution_time, "HH:mm").toDate()
          this.planner_config.type_3.execution_padding = newVal.config.execution_padding
          this.planner_config.type_3.event_type = newVal.config.event_type
        }
      } else {
        Object.assign(this.$data, initialState());
      }
    },
    planner_type(newVal) {
      console.log(newVal)
      this.$emit("typeChange", newVal)
    },
    channel(v){
      if(v !== "sms" && this.planner_type === 2) this.planner_type = -1
    },
  },
  methods: {

    getPlanerData() {
      let cfg = JSON.parse(JSON.stringify(this.planner_config))
      cfg.type_1.date = moment(cfg.type_1.date).format("DD.MM.YYYY")
      cfg.type_1.time = moment(cfg.type_1.time).format("HH:mm")

      cfg.type_2.from.date = moment(cfg.type_2.from.date).format("DD.MM.YYYY")
      cfg.type_2.from.time = moment(cfg.type_2.from.time).format("HH:mm")
      cfg.type_2.to.date = moment(cfg.type_2.to.date).format("DD.MM.YYYY")
      cfg.type_2.to.time = moment(cfg.type_2.to.time).format("HH:mm")
      cfg.type_3.execution_time = moment(cfg.type_3.execution_time).format("HH:mm")
      let config = {}
      switch (this.planner_type) {
        case 0:
          config = cfg.type_1
          break
        case 1:
          config = cfg.type_2
          break
        case 2:
          config = cfg.type_3
          break
      }
      config.local_time_delivery = this.local_time_delivery

      if (this.planner_type === 1) {
        config.intervals = this.intervals
      }
      return {
        planner_type: this.planner_type,
        planner_config: config
      }
    },
    reset() {
      Object.assign(this.$data, initialState());
    },
    inPast() {
      switch (this.planner_type) {
        case 0:
          return dateTimeMoment(moment().utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm")).isSameOrAfter(
              dateTimeMoment(this.planner_config.type_1.date, this.planner_config.type_1.time)
          )
        case 1:
          return dateTimeMoment(moment().utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm")).isSameOrAfter(
              dateTimeMoment(this.planner_config.type_2.from.date, this.planner_config.type_2.from.time)
          )
        default:
          return false
      }
    },
    incorrectEndingTime() {
      if (this.planner_type !== 1) return false
      return dateTimeMoment(this.planner_config.type_2.to.date, this.planner_config.type_2.to.time).isSameOrBefore(
          dateTimeMoment(this.planner_config.type_2.from.date, this.planner_config.type_2.from.time)
      ) || moment(this.planner_config.type_2.to.time).isSameOrBefore(this.planner_config.type_2.from.time)
    },
    incorrectInterval(){
      let di = Number(this.planner_config.type_2.distribution_interval)
      return this.planner_type === 1 && (isNaN(di) || di <= 0);

    }
  },
  data() {
    return initialState()
  },
}

function initialState() {
  return {
    planner_type: -1,
    local_time_delivery: false,
    planner_config: {
      type_1: {
        date: moment().toDate(),
        time: moment().add(30, 'minutes').toDate()
      },
      type_2: {
        exclude_weekend: false,
        from: {
          date: moment().toDate(),
          time: moment().add(30, 'minutes').toDate()
        },
        to: {
          date: moment().add(moment().add(3, 'hours').day() !== moment().day() ? 1 : 0, "day").toDate(),
          time: moment().add(3, 'hours').toDate()
        },
        distribution_interval: 30
      },
      type_3: {
        event_type: "birthday",
        execution_padding: 0,
        execution_time: moment().toDate()
      }
    },
    static: {
      planner_type_3_event_type_options: [{label: "День рождения / Дата события", value: "birthday"}],
      planner_type_3_execution_padding_options: [
        {label: "В день события", value: 0},
        {label: "За 1 день", value: 1},
        {label: "За 2 дня", value: 2},
        {label: "За 3 дня", value: 3},
        {label: "За 4 дня", value: 4},
        {label: "За 5 дней", value: 5},
        {label: "За 6 дней", value: 6},
        {label: "За 7 дней", value: 7},
        {label: "За 8 дней", value: 8},
        {label: "За 9 дней", value: 9},
        {label: "За 10 дней", value: 10},
        {label: "За 11 дней", value: 11},
        {label: "За 12 дней", value: 12},
        {label: "За 13 дней", value: 13},
        {label: "За 14 дней", value: 14}
      ],
      lifetime_options: [
        {label: "5 минут", value: 5},
        {label: "10 минут", value: 10},
        {label: "30 минут", value: 30},
        {label: "45 минут", value: 45},
        {label: "1 час", value: 60},
        {label: "2 часа", value: 60 * 2},
        {label: "3 часа", value: 60 * 3},
        {label: "4 часа", value: 60 * 4},
        {label: "5 часов", value: 60 * 5},
        {label: "6 часов", value: 60 * 6},
        {label: "7 часов", value: 60 * 7},
        {label: "8 часов", value: 60 * 8},
        {label: "9 часов", value: 60 * 9},
        {label: "10 часов", value: 60 * 10},
        {label: "11 часов", value: 60 * 11},
        {label: "12 часов", value: 60 * 12},
        {label: "13 часов", value: 60 * 13},
        {label: "14 часов", value: 60 * 14},
        {label: "15 часов", value: 60 * 15},
        {label: "16 часов", value: 60 * 16},
        {label: "17 часов", value: 60 * 17},
        {label: "18 часов", value: 60 * 18},
        {label: "19 часов", value: 60 * 19},
        {label: "20 часов", value: 60 * 20},
        {label: "21 час", value: 60 * 21},
        {label: "22 часа", value: 60 * 22},
        {label: "23 часа", value: 60 * 23},
        {label: "24 часа", value: 60 * 24},
      ]
    }
  }
}

</script>