<template>
  <DefaultNode @removeMe="$emit('removeMe', id)">
    <template #header>
      <span>Ветвление</span>
<!--      <Handle type="source" position="right"/>-->
      <Handle type="target" position="left" />
    </template>
    <template #body>
      <div v-for="(casse, i) in data.settings.cases" class="message-chain-message">
        <span>{{ casse.text }}</span>

          <Handle :id="casse.id" type="source" position="right" />
      </div>
    </template>
  </DefaultNode>
</template>


<script>

import {Handle} from '@vue-flow/core'
import DefaultNode from "./defaultNode.node.vue";
import {v1} from "uuid";

export default {
  name: "SwitchCaseNode",
  components: {DefaultNode, Handle},
  props: ["events", "id", "data", "bot"],
  mounted() {

  },
  watch: {},
  methods: {},
  computed: {

  },
  data() {
    return {
    }
  }
}

export let oneCaseModel = () => {
  return {id: String(v1())}
}

export let settingsModel = () => {
  return {
    cases: [
      oneCaseModel()
    ],
    settings: {}
  }
}
</script>