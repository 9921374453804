<template>
  <div>
    <el-button text class="text-button table-text-button" @click="addEmptyMessage('simple')" style="margin-bottom: 10px">
      <font-awesome-icon icon="plus"/>&nbsp;Добавить сообщение
    </el-button>
    <TelegramSingleMessageForm
        v-for="(message, i) in messages"
        v-model="messages[i]"
        :show-remove="messages.length > 1"
        :is-edit-mode="isEditMode"
        :bot="selectedBot"
        @remove="removeMessage(i)"
        @change="$emit('change')"
    />

<!--    <el-dropdown trigger="click" style="cursor:pointer;" :hide-on-click="true">-->
<!--      <span class="el-dropdown-link"><font-awesome-icon icon="plus"/>&nbsp;Добавить сообщение</span>-->
<!--      <template #dropdown>-->
<!--        <el-dropdown-menu>-->
<!--          <el-dropdown-item @click="addEmptyMessage('simple')">Простое</el-dropdown-item>-->
<!--          <el-dropdown-item disabled @click="addEmptyMessage('mediaGroup')">Медиа-группа</el-dropdown-item>-->
<!--        </el-dropdown-menu>-->
<!--      </template>-->
<!--    </el-dropdown>-->
    <el-form-item class="no-margin">
      <div style="display: flex; flex-direction: column">
        <el-checkbox v-model="send_test_message" label="Протестировать рассылку"></el-checkbox>
        <div v-show="send_test_message" style="display: flex; flex-direction: row">
          <el-select
              v-model="targetTestAdmin"
              v-show="send_test_message"
              :disabled="!$store.getters.registrationFullyFinalized"
              placeholder="Выберите администратора бота"
              style="flex-grow: 1"
          >
            <el-option v-for="a in selectedBot.admins || []" :label="a.userData.first_name" :value="a._id"/>

          </el-select>
          <el-button
              :type="!$store.getters.registrationFullyFinalized ? 'success' : 'default'"
              @click="sendTestMessage()"
              :loading="sendingTestMessage"
              :disabled="!targetTestAdmin"
              style="margin-left: 20px"
          >Отправить
          </el-button>
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>

import MessageTextInput from "../message-text-input.component.vue";
import {ElMessageBox} from "element-plus";
import TelegramSingleMessageForm from "./telegram-single-message-input.component.vue";


export default {
  name: "TelegramMessageInput",
  props: ["selectedBot", "validateForm", "isEditMode"],
  components: {TelegramSingleMessageForm, MessageTextInput},
  mounted() {
    this.loadAll()
  },
  watch: {
    messages() {
      this.$emit('change')
    }
  },
  methods: {
    loadAll() {
    },
    async sendTestMessage() {
      let payload = {
        botId: this.selectedBot._id,
        adminId: this.targetTestAdmin,
        telegram: {messages:  this.messages}
      }
      this.sendingTestMessage = true
      this.axios.post("/sms/sendTestTgMessage", payload)
          .then(resp => {
            this.sendingTestMessage = false
            this.$gNotify("Тестовая рассылка отправлена", "success")
          })
    },

    getEmptyMessage() {
      return {
        text: "",
        type: "simple",
        buttons: [],
        files: []
      }
    },
    addEmptyMessage(type) {
      let m = this.getEmptyMessage()
      m.type = type
      this.messages.push(m)
    },
    reset() {
      this.messages = []
      this.addEmptyMessage("simple")
    },
    removeMessage(i) {
      this.messages.splice(i, 1)
    },
    getMessages() {
      return this.messages
    },
    setMessages(messages){
      this.messages = messages
    },
    getBotName(){
      return (this.selectedBot || {name: ""}).name
    }
  },
  computed: {},
  data() {
    return {
      messages: [this.getEmptyMessage()],
      sendingTestMessage: false,
      targetTestAdmin: "",
      send_test_message: false,
    }
  },
}

</script>

<style>
</style>
}